import * as React from 'react';
import { Grid, SemanticWIDTHS } from 'semantic-ui-react';
import { IIncidentDataUploadConnectProps } from './incident-data-upload-container';
import { IncidentDataUploadOptionsContainer } from '../incident-data-upload-options';
import { DataOptionsContainer } from '../../data-upload/data-options';
import { DataRangeOptionsContainer } from '../../data-upload/data-range-options';
import { IncidentDataUploadActionsContainer } from '../incident-data-upload-actions';
import { IIncidentType } from '../../../interfaces';
import { DataApi } from '../../../api';
import { DATA_LOAD_STAGE } from '../../../constants';
import * as Actions from '../../../actions';
import './incident-data-upload.less';

/**
 * Incidents data upload component combines relevant containers
 * Contains unique option for incident type
 */
export class IncidentDataUpload extends React.Component<IIncidentDataUploadConnectProps, IIncidentDataUploadState> {
  constructor(props: IIncidentDataUploadConnectProps) {
    super(props);
    this.state = {
      loading: true,
      incidents: []
    };
  }

  /** Notify workflow about "upload" stage being in progress */
  public componentWillMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_UPLOAD));
  }

  public componentDidMount(): void {
    DataApi.getIncidentTypes().request().then((response: IIncidentType[]): void => {
      const incidents: IIncidentType[] = response.sort((a: IIncidentType, b: IIncidentType): number => {
        return a.name > b.name ? 1 : a.name === b.name ? 0 : -1;
      });
      this.setState({ loading: false, incidents });
    });
  }

  public render(): JSX.Element {
    const CONTENT_WIDTH: SemanticWIDTHS = 10;

    return (
      <Grid data-component="incident-data-upload">
        <Grid.Row columns="equal" centered>
          <Grid.Column width={CONTENT_WIDTH}>
            <IncidentDataUploadOptionsContainer loading={this.state.loading} incidents={this.state.incidents} />
            {!this.state.loading && this.props.upload.incidentType ? <DataOptionsContainer /> : null}
            {!this.state.loading && this.props.upload.dataFile ? <DataRangeOptionsContainer /> : null}
            {!this.state.loading && this.props.upload.dataFile ? <IncidentDataUploadActionsContainer /> : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export interface IIncidentDataUploadState {
  readonly loading: boolean;
  readonly incidents: IIncidentType[];
}
