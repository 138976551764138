export enum CONTROL_MAPPING {
  PLAN_ID = 'PLAN_ID',
  CONTROL_KEY = 'CONTROL_KEY'
}

/** Options with captions for "Mapping Type" */
export const CONTROL_MAPPING_OPTIONS: { [key: string]: string } = {
  [CONTROL_MAPPING.PLAN_ID]: 'Plan Identifier',
  [CONTROL_MAPPING.CONTROL_KEY]: 'Control Field',
};

export enum CONTROL_KEY {
  CONTROL_NAME = 'activity_name',
  CONTROL_ID = 'activity_id',
  OWNER = 'activity_owner',
  DESCRIPTION = 'fullDescription',
  AUDIT = 'internal_audit',
  KEY_CONTROL = 'key_control',
  COMPLETION_TARGET = 'date_completion',
  EXPECTED_COST = 'expected_cost', // Control cost reasoning
  CONTROL_COST = 'control_cost',
  STATUS = 'activity_field_status',
  FREQUENCY = 'activity_field_frequency',
  TYPE = 'activity_field_type',
  METHOD_REVISED_DATE = 'revised_date',
  START = 'start_date',
  STOP = 'end_date',
  METHOD = 'activity_field_method',
  CONTROL_CATEGORY = 'activity_category',
  CONTROL_OBJECTIVE = 'activity_objective'
}

export const CONTROL_KEY_OPTIONS: { [key: string]: string } = {
  [CONTROL_KEY.CONTROL_NAME]: 'Control Name',
  [CONTROL_KEY.CONTROL_ID]: 'Control ID',
  [CONTROL_KEY.OWNER]: 'Control Owner',
  [CONTROL_KEY.DESCRIPTION]: 'Description',
  [CONTROL_KEY.AUDIT]: 'Audit',
  [CONTROL_KEY.KEY_CONTROL]: 'Key Control',
  [CONTROL_KEY.COMPLETION_TARGET]: 'Completion Target',
  [CONTROL_KEY.EXPECTED_COST]: 'Control Cost Reasoning',
  [CONTROL_KEY.CONTROL_COST]: 'Control Cost',
  [CONTROL_KEY.STATUS]: 'Status',
  [CONTROL_KEY.FREQUENCY]: 'Frequency',
  [CONTROL_KEY.TYPE]: 'Type',
  [CONTROL_KEY.METHOD_REVISED_DATE]: 'Revised Date',
  [CONTROL_KEY.START]: 'Start',
  [CONTROL_KEY.STOP]: 'Stop',
  [CONTROL_KEY.METHOD]: 'Method',
  [CONTROL_KEY.CONTROL_CATEGORY]: 'Category',
  [CONTROL_KEY.CONTROL_OBJECTIVE]: 'Objective'
};
