import { BaseApi } from './base-api';
import { IUrlObject } from './api-interfaces';

/** URL request holds URL object and response processing callback */
export class UrlRequest<ResponseType = {}> {
  public readonly urlObject: IUrlObject;
  public readonly callback: (response: object) => ResponseType;

  constructor(options: {
    urlObject: IUrlObject,
    callback?(response: object): ResponseType
  }) {
    this.urlObject = { ...options.urlObject };
    this.callback = options.callback;
  }

  public request(): Promise<ResponseType> {
    return BaseApi.request({ urlObject: this.urlObject })
      .then((response: {}): ResponseType => {
        return (this.callback)
          ? this.callback(response)
          : response as ResponseType;
      });
  }
}
