import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { IncidentConfig } from './incident-config';
import { IConfigState, IDataState, IState, IUIState, IUploadState } from '../../../interfaces';

export interface IIncidentConfigStateProps {
  readonly ui: IUIState;
  readonly data: IDataState;
  readonly upload: IUploadState;
  readonly config: IConfigState;
}

export type IIncidentConfigConnectProps = IIncidentConfigStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IIncidentConfigStateProps {
  return {
    ui: state.ui,
    data: state.data,
    upload: state.upload,
    config: state.config
  };
}

export const IncidentConfigContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(IncidentConfig);
