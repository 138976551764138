import { IConfigState } from '../interfaces';
import * as Actions from '../actions';

export type ActionType = Actions.Config.ISetColumns
  & Actions.Config.ISetTxConfig
  & Actions.Config.ISetPIConfig
  & Actions.Config.ISetIndicatorConfig
  & Actions.Config.ISetILConfig
  & Actions.Config.ISetIPLConfig
  & Actions.Config.ISetIncidentConfig
  & Actions.Config.ISetActivityConfig
  & Actions.Config.ISetAILConfig
  & Actions.Config.ISetUserManagementConfig
  & Actions.Config.ISetMAConfig;

const initial: IConfigState = {
  columns: {},
  taxonomy: null,
  PI: null,
  indicators: null,
  IPL: null,
  IL: null,
  incidents: null,
  activity: null,
  AIL: null,
  userManagement: null,
  MA: null
};

export function config(
  state: IConfigState = initial,
  action: ActionType
): IConfigState {
  switch (action.type) {
    case Actions.Config.SET_COLUMNS:
      return { ...state, columns: action.columns };
    case Actions.Config.SET_ACTIVITY_CONFIG:
      return { ...state, activity: action.config };
    case Actions.Config.SET_AIL_CONFIG:
      return { ...state, AIL: action.config };
    case Actions.Config.SET_TX_CONFIG:
      return { ...state, taxonomy: action.config };
    case Actions.Config.SET_PI_CONFIG:
      return { ...state, PI: action.config };
    case Actions.Config.SET_INDICATOR_CONFIG:
      return { ...state, indicators: action.config };
    case Actions.Config.SET_USER_MANAGEMENT_CONFIG:
      return { ...state, userManagement: action.config };
    case Actions.Config.SET_IPL_CONFIG:
      return { ...state, IPL: action.config };
    case Actions.Config.SET_IL_CONFIG:
      return { ...state, IL: action.config };
    case Actions.Config.SET_INCIDENT_CONFIG:
      return { ...state, incidents: action.config };
    case Actions.Config.SET_MA_CONFIG:
      return { ...state, MA: action.config };
    case Actions.Batch.CLEAR_APP_STATE:
    case Actions.Batch.GO_TO_UPLOAD_STEP:
      return initial;
    default:
      return state;
  }
}
