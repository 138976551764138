import * as React from 'react';
import { IPIConfigActionsConnectProps } from './pi-config-actions-container';
import { ConfigActionsContainer } from '../../config/config-actions';
import { DataLoadApi } from '../../../api';
import { IPIConfig } from '../../../interfaces';
import { PLAN_EVENT_KEY, PLAN_KEY } from '../../../constants';
import { getApiPIConfig } from '../../../functions';

/**
 * Plan-Indicators "Check Configuration" action buttons
 * Component checks active Plan-Indicators configuration and makes server requests
 */
export class PIConfigActions extends React.Component<IPIConfigActionsConnectProps> {
  constructor(props: IPIConfigActionsConnectProps) {
    super(props);
    this.uploadConfig = this.uploadConfig.bind(this);
  }

  /** Check if Plan-Indicators config can be sent to server */
  public checkPIConfig(): boolean {
    const REQUIRED_COLUMNS_FOR_INDICATOR: number = 3;
    const colKeys: string[] = Object.keys(this.props.config.PI.planEventConfig);

    /* We need to have "plan name" or "plan ID" to find plan */
    let planId: boolean = false;
    /* We need to have "indicator ID" or "category", "factor" and "indicator" columns to find indicator */
    let indicatorId: number = 0;

    for (let i: number = 0; i < colKeys.length; i++) {
      const planEventKey: string = this.props.config.PI.planEventConfig[colKeys[i]];

      if (planEventKey === PLAN_KEY.PLAN_ID || planEventKey === PLAN_KEY.PLAN_NAME) {
        planId = true;
      }

      if (planEventKey === PLAN_EVENT_KEY.INDICATOR_ID) {
        indicatorId = REQUIRED_COLUMNS_FOR_INDICATOR;
      }

      const indicatorIdCondition: boolean = planEventKey === PLAN_EVENT_KEY.CATEGORY
        || planEventKey === PLAN_EVENT_KEY.FACTOR
        || planEventKey === PLAN_EVENT_KEY.INDICATOR;
      if (indicatorIdCondition) {
        indicatorId++;
      }
    }

    return planId && indicatorId === REQUIRED_COLUMNS_FOR_INDICATOR;
  }

  /** Try to upload config on server for dry run */
  public uploadConfig(): Promise<{}> {
    const config: IPIConfig = getApiPIConfig({
      config: this.props.config.PI,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadPIConfig({ config, columns: this.props.config.columns }).request();
  }

  public render(): JSX.Element {
    return (
      <ConfigActionsContainer
        warning={!this.checkPIConfig()}
        uploadConfig={this.uploadConfig}
      />
    );
  }
}
