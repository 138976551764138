import * as React from 'react';
import { DropdownItemProps, DropdownProps, Form, Loader } from 'semantic-ui-react';
import { IIncidentType } from '../../../interfaces';
import { COMMON_TEXT, DATA_UPLOAD_VIEW_TEXT } from '../../../constants';
import './incident-data-upload-options-view.less';

/**
 * View for "Incidents Data Upload Options" component
 */
export class IncidentDataUploadOptionsView extends React.Component<IIncidentDataUploadOptionsViewProps> {
  constructor(props: IIncidentDataUploadOptionsViewProps) {
    super(props);
  }

  public getIncidentTypeInput(): JSX.Element {
    const options: DropdownItemProps[] = [];

    for (let i: number = 0; i < this.props.incidents.length; i++) {
      options.push({ value: this.props.incidents[i].testId.toString(), text: this.props.incidents[i].name });
    }

    return (
      <Form.Dropdown
        options={options}
        value={this.props.incidentType || ''}
        onChange={this.props.onIncidentTypeChange}
        label={DATA_UPLOAD_VIEW_TEXT.INCIDENT_TYPE}
        selectOnBlur={false}
        selection
        search
        required
      />
    );
  }

  public render(): JSX.Element {
    if (this.props.loading) {
      return (
        <Loader active inline="centered" content={COMMON_TEXT.LOADING} />
      );
    }

    return (
      <Form data-component="incident-data-upload-options-view">
        {this.getIncidentTypeInput()}
      </Form>
    );
  }
}

export interface IIncidentDataUploadOptionsViewProps {
  readonly loading: boolean;
  readonly incidents: IIncidentType[];
  readonly incidentType: string;
  onIncidentTypeChange(e: React.ChangeEvent<HTMLInputElement>, changes: DropdownProps): void;
}
