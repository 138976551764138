import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { AILWorkflow } from './ail-workflow';
import { IState, IUIState } from '../../../interfaces';

export interface IAILWorkflowStateProps {
  readonly ui: IUIState;
}

export type IAILWorkflowConnectProps = IAILWorkflowStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IAILWorkflowStateProps {
  return { ui: state.ui };
}

export const AILWorkflowContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(AILWorkflow);
