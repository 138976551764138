import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { DataLoadActionOptionsContainer } from '../../config/data-load-action-options';
import { MatchByIndexOptionsContainer } from '../../config/match-by-index-options';
import { EmptyCellOptionsContainer } from '../../config/empty-cell-options';
import { DATA_LOAD_ACTION_OPTIONS } from '../../../constants';
import './incident-config-options.less';

/**
 * Incidents config options component combines relevant config option containers
 */
export class IncidentConfigOptions extends React.Component {
  constructor(props: object) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <Form data-component="incident-config-options">
        <Form.Group widths="equal">
          <DataLoadActionOptionsContainer options={DATA_LOAD_ACTION_OPTIONS} />
          <MatchByIndexOptionsContainer />
        </Form.Group>
        <EmptyCellOptionsContainer />
      </Form>
    );
  }
}
