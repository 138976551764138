import * as React from 'react';
import { IActivityConfigActionsConnectProps } from './activity-config-actions-container';
import { ConfigActionsContainer } from '../../config/config-actions';
import { DataLoadApi } from '../../../api';
import { IActivityConfig } from '../../../interfaces';
import { getApiActivityConfig } from '../../../functions';

/**
 * Control "Check Configuration" action buttons
 */
export class ActivityConfigActions extends React.Component<IActivityConfigActionsConnectProps> {
  constructor(props: IActivityConfigActionsConnectProps) {
    super(props);
    this.uploadConfig = this.uploadConfig.bind(this);
  }

  public uploadConfig(): Promise<{}> {
    const config: IActivityConfig = getApiActivityConfig({
      config: this.props.config.activity,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadActivityConfig({ config, columns: this.props.config.columns }).request();
  }

  public render(): JSX.Element {
    return (
      <ConfigActionsContainer
        warning={this.props.warning}
        uploadConfig={this.uploadConfig}
      />
    );
  }
}
