import * as React from 'react';
import { ITxConfigConnectProps } from './config-container';
import { ConfigView } from './config-view';
import { DATA_LOAD_STAGE } from '../../../constants';
import * as Actions from '../../../actions';
import { IAppUserManagementConfig } from '../../../interfaces';
import { USER_MANAGEMENT_OPTIONS } from '../../../constants/user-management';

/**
 * User Management column mapping configuration
 */
export class Config extends React.Component<ITxConfigConnectProps, ITxConfigState> {
  constructor(props: ITxConfigConnectProps) {
    super(props);
    this.state = {
      warning: false,
      dataReady: false
    };

    this.onFieldNameChange = this.onFieldNameChange.bind(this);
  }

  public componentWillMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.CONFIGURATION));
  }

  public onFieldNameChange(colIndex: string, key: USER_MANAGEMENT_OPTIONS): void {
    /* Copy and update user management config */
    const config: IAppUserManagementConfig = {
      ...this.props.config.userManagement,
      userConfig: {
        ...this.props.config.userManagement.userConfig,
        [colIndex]: key
      }
    };
    this.props.dispatch(Actions.Config.setUserManagementConfig(config));
  }

  public render(): JSX.Element {
    return (
      <ConfigView
        data={this.props.data}
        columns={this.props.config.columns}
        warning={this.state.warning}
        appUserManagementConfig={this.props.config.userManagement}
        dataLoadAction={this.props.ui.dataLoadAction}
        matchByRecord={this.props.ui.matchUserManagementKey}
        onFieldNameChange={this.onFieldNameChange}
      />
    );
  }
}

export interface ITxConfigState {
  readonly warning: boolean;
  readonly dataReady: boolean;
}
