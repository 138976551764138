import * as React from 'react';
import { DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { IMatchMonitoringActivityKeyOptionsConnectProps } from './match-monitoring-activity-key-options-container';
import { CONFIG_VIEW_TEXT, MATCH_MONITORING_ACTIVITY_KEY, MATCH_MONITORING_ACTIVITY_KEY_OPTIONS } from '../../../constants';
import { getOptions } from '../../../functions';
import * as Actions from '../../../actions';

/**
 * Options for "Match Monitoring Activity By"
 */
export class MatchMonitoringActivityKeyOptions extends React.Component<IMatchMonitoringActivityKeyOptionsProps> {
  public matchMonitoringActivityKeyOptions: DropdownItemProps[];

  constructor(props: IMatchMonitoringActivityKeyOptionsProps) {
    super(props);
    this.matchMonitoringActivityKeyOptions = getOptions(MATCH_MONITORING_ACTIVITY_KEY_OPTIONS);
    this.onMatchMonitoringActivityKeyChange = this.onMatchMonitoringActivityKeyChange.bind(this);
  }

  public onMatchMonitoringActivityKeyChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.props.dispatch(Actions.UI.setUIData({
      matchMonitoringActivityKey: changes.value as MATCH_MONITORING_ACTIVITY_KEY
    }));
    if (this.props.onChange) {
      this.props.onChange(changes.value as MATCH_MONITORING_ACTIVITY_KEY);
    }
  }

  public render(): JSX.Element {
    return (
      <Form.Dropdown
        label={CONFIG_VIEW_TEXT.MATCH_MONITORING_ACTIVITY_BY}
        options={this.matchMonitoringActivityKeyOptions}
        value={this.props.ui.matchMonitoringActivityKey}
        onChange={this.onMatchMonitoringActivityKeyChange}
        selectOnBlur={false}
        selection
        required
      />
    );
  }
}

export interface IMatchMonitoringActivityKeyOptionsProps extends IMatchMonitoringActivityKeyOptionsConnectProps {
  onChange?(matchMonitoringActivityKey: MATCH_MONITORING_ACTIVITY_KEY): void;
}
