import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { MAConfigActions } from './ma-config-actions';
import { IConfigState, IState, IUIState, IUploadState } from '../../../interfaces';

export interface IMAConfigActionsProps {
  readonly warning: boolean;
}

export interface IMAConfigActionsStateProps extends IMAConfigActionsProps {
  readonly ui: IUIState;
  readonly upload: IUploadState;
  readonly config: IConfigState;
}

export type IMAConfigActionsConnectProps = IMAConfigActionsStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IMAConfigActionsProps): IMAConfigActionsStateProps {
  return {
    ui: state.ui,
    upload: state.upload,
    config: state.config,
    ...props
  };
}

export const MAConfigActionsContainer: React.ComponentClass<IMAConfigActionsProps>
  = ReactRedux.connect(mapStateToProps)(MAConfigActions);
