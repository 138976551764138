import * as React from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { IMatchKeyOptionsConnectProps } from './match-key-options-container';
import { MatchKeyOptionsView } from './match-key-options-view';
import { MATCH_KEY } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Action for match key options
 */
export class MatchKeyOptions extends React.Component<IMatchKeyOptionsConnectProps> {
  constructor(props: IMatchKeyOptionsConnectProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  /** Update UI reducer and call parent callback */
  public onChange(e: React.SyntheticEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.props.dispatch(Actions.UI.setUIData({ matchKey: changes.value as MATCH_KEY }));
    if (this.props.onChange) {
      this.props.onChange(changes.value as MATCH_KEY);
    }
  }

  public render(): JSX.Element {
    return (
      <MatchKeyOptionsView
        value={this.props.ui.matchKey}
        options={this.props.options}
        labelKey={this.props.labelKey}
        onChange={this.onChange}
      />
    );
  }
}
