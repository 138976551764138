/** Dry run or data load status used in application */
export enum DATA_LOAD_STATUS {
  NEW = 'NEW',
  FILE_UPLOADED = 'FILE_UPLOADED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  DRY_RUN_IN_PROGRESS = 'DRY_RUN_IN_PROGRESS',
  DRY_RUN_COMPLETED = 'DRY_RUN_COMPLETED',
  DRY_RUN_FAILED = 'DRY_RUN_FAILED',
  FLUSH_IN_PROGRESS = 'FLUSH_IN_PROGRESS',
  FLUSH_COMPLETED = 'FLUSH_COMPLETED',
  FLUSH_FAILED = 'FLUSH_FAILED'
}

/** Log records have one of following types */
export enum LOG_ENTITY_TYPE {
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARN = 'WARN',
  ADDED = 'ADDED',
  UPDATED = 'UPDATED',
  SKIP = 'SKIP'
}

/** Types of fields in server config */
export enum CONFIG_FIELD_TYPE {
  FK_FIELD = 'fk_field',
  ROW_FK_FIELD = 'row_fk_field',
  SELF_FIELD = 'self_field',
  SELF_STATIC_FIELD = 'self_static_field',
  DYNAMIC_FIELD = 'dynamic_field',
  ASSESSMENT_SCORE = 'assessment_score',
  DYNAMIC_LINKED_FIELD = 'dynamic_linked_field',
  RESOURCE_LINK = 'resource_link',
  RESOURCE_CORE = 'resource_core',
  RESOURCE_ASSIGNMENT = 'resource_assignment',
  RESOURCE_DOCUMENT = 'resource_document',
  ACTIVITY_RESPONSIBLE = 'activity_responsible',
  ACTIVITY_FIELD = 'activity_field',
  ACTIVITY_CORE = 'activity_core',
  ACTIVITY_RESOURCE = 'activity_resource',
  ACTIVITY_ASSERTION = 'activity_assertion',
  ACTIVITY_ASSERTION_V2 = 'activity_assertionV2',
  ACTIVITY_CROSS_REFERENCE = 'activity_cross_reference',
  ACTIVITY_DOCUMENT = 'activity_document',
  TEST_ACTIVITY = 'test_activity',
  INCIDENT_FIELD = 'incident_field',
  INCIDENT_LINKED_FIELD = 'incident_linked_field'
}
