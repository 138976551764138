import { API_URL_CONFIG, HTTP_METHOD, IUrlObject } from './api-interfaces';
import { IAuthTicket } from '../interfaces';
import { joinUrls } from '../functions';

/**
 * Base class for making API requests
 */
export class BaseApi {
  /** Common request options for HTTP methods */
  public static readonly REQUEST_OPTIONS: { [key: string]: RequestInit } = {};
  /** Authentication header is added to every request */
  protected static readonly AUTH_HEADER: string = 'Authorization';
  /** Base URL contains host, port and prefix (used for development or production) */
  protected static BASE_URL: string;
  /** Authentication token (should not be modified outside Auth API) */
  protected static AUTH_TOKEN: string;

  public static setAuthToken(options: { token: string }): void {
    BaseApi.AUTH_TOKEN = options.token;
  }

  /** Base API class should be configured once using this function */
  public static configure(options: { baseUrl: string }): void {
    BaseApi.BASE_URL = new URL(options.baseUrl || '', window.location.href).toString();

    const headers: Headers = new Headers({});

    /* Generic options for GET and DELETE requests */
    BaseApi.REQUEST_OPTIONS[HTTP_METHOD.GET] = { method: 'GET', mode: 'cors', headers };
    BaseApi.REQUEST_OPTIONS[HTTP_METHOD.DELETE] = { method: 'DELETE', mode: 'cors', headers };

    /* Append headers for POST and PUT requests */
    headers.append('Content-Type', 'application/json');
    BaseApi.REQUEST_OPTIONS[HTTP_METHOD.PUT] = { method: 'PUT', mode: 'cors', headers };
    BaseApi.REQUEST_OPTIONS[HTTP_METHOD.POST] = { method: 'POST', mode: 'cors', headers };
  }

  /**
   * Add "GET" options and URL interpolation support to "fetch"
   * getURL({
   *  url: '/aaa/[id]/ccc',
   *  query: { hello: 'world' },
   *  params: { id: 'bbb' }
   * })
   * =>
   * [host]:[port]/aaa/bbb/ccc?hello=world
   */
  public static getURL(options: { url: string, params?: object, query?: object }): string {
    const query: URLSearchParams = new URLSearchParams();

    /* Create "GET" query part of URL if "options.query" is provided
       Undefined values are omitted in query string */
    const qKeys: string[] = Object.keys(options.query || {});
    for (let i: number = 0; i < qKeys.length; i++) {
      if (options.query[qKeys[i]] === undefined) {
        continue;
      }

      query.append(qKeys[i], options.query[qKeys[i]]);
    }

    /* Replace URL wildcards with given parameters
       Undefined values are replaced as is */
    const pKeys: string[] = Object.keys(options.params || {});
    let url: string = options.url;

    for (let i: number = 0; i < pKeys.length; i++) {
      url = url.replace(`[${pKeys[i]}]`, `${options.params[pKeys[i]]}`);
    }

    /* Join base URL and given url with replaced params */
    const newUrl: URL = joinUrls(url, BaseApi.BASE_URL);
    /* Join base URL and generated query part of request */
    newUrl.search = query.toString();
    return newUrl.toString();
  }

  /**
   * Make API request and attempt to read response as one of available methods
   * Throw response object in case of any error
   */
  public static request<T>(options: { urlObject: IUrlObject }): Promise<T> {
    const url: string = BaseApi.getURL({
      url: options.urlObject.url,
      query: options.urlObject.query,
      params: options.urlObject.params
    });

    const request: RequestInit = (options.urlObject.simple)
      ? BaseApi.getSimpleRequestInit(options.urlObject)
      : BaseApi.getRequestInit(options.urlObject);

    return fetch(url, request)
      .then((response: Response): Promise<T> => {
        if (!response.ok) { throw response; }

        return response[options.urlObject.getDataAs]()
          .catch((): void => { throw response; });
      });
  }

  /** Make simple API request and attempt to read response using given method */
  public static getSimpleRequestInit<T>(urlObject: IUrlObject): RequestInit {
    /* Add authorization header to each request */
    const headers: Headers = new Headers(urlObject.headers || {});
    headers.append(BaseApi.AUTH_HEADER, BaseApi.AUTH_TOKEN);
    return { ...BaseApi.REQUEST_OPTIONS[urlObject.method], headers, body: urlObject.body as FormData };
  }

  /** Merge common request attributes for given method with custom URL object attributes */
  protected static getRequestInit(urlObject: IUrlObject): RequestInit {
    const common: RequestInit = BaseApi.REQUEST_OPTIONS[urlObject.method];
    const headers: Headers = new Headers(common.headers || {});

    /* Append custom headers if they are present */
    const keys: string[] = Object.keys(urlObject.headers || {});
    for (const key of keys) {
      headers.append(key, urlObject.headers[key]);
    }

    headers.append(BaseApi.AUTH_HEADER, BaseApi.AUTH_TOKEN);
    return { ...common, headers, body: JSON.stringify(urlObject.body) };
  }

  /** Make API request for get auth token then update BaseApi.AUTH_TOKEN static field */
  public static getAuthToken(options: {
    username: string,
    password: string,
    domain: string
  }): Promise<IAuthTicket> {
    const body: object = { username: options.username, password: options.password, domain: options.domain };
    const urlObject: IUrlObject = { ...API_URL_CONFIG.auth.login, body };
    return BaseApi.request({ urlObject })
      .then((ticket: IAuthTicket): IAuthTicket => {
        BaseApi.setAuthToken({ token: ticket.token });
        return ticket;
      });
  }

  /** Make request to get if user from that ip is internal user */
  public static getInternalInfo(): Promise<{ result: boolean }> {
    const urlObject: IUrlObject = { ...API_URL_CONFIG.common.internal };
    return BaseApi.request({ urlObject });
  }

  /** Send "Log Out" request and reset token in any case */
  public static logOut(): Promise<void> {
    return BaseApi.request({ urlObject: API_URL_CONFIG.auth.logout })
      .then((): void => {
        BaseApi.setAuthToken({ token: '' });
      });
  }

  /** Get server API version */
  public static getVersion(): Promise<{ version: string }> {
    return BaseApi.request({ urlObject: API_URL_CONFIG.common.version });
  }
}
