import * as React from 'react';
import { Button, Table } from 'semantic-ui-react';
import { IDataLoadHistoryItem } from '../../../interfaces';
import { DATA_LOAD_HISTORY_TEXT, DATA_LOAD_STATUS } from '../../../constants';
import './data-load-history-item.less';

/**
 * Represents information and actions for data load history record
 */
export class DataLoadHistoryItem extends React.Component<IDataLoadHistoryItemProps> {
  constructor(props: IDataLoadHistoryItemProps) {
    super(props);
  }

  /** Show file name as "Download" button */
  public getDownloadFileButton(): JSX.Element {
    const onClick: () => void = this.props.onDataFileDownload.bind(this, this.props.item);
    return (
      <Button
        content={this.props.item.dataFile}
        onClick={onClick}
        disabled={this.props.loading}
        loading={this.props.loading}
        icon="download"
        primary
        fluid
      />
    );
  }

  /** Download logs for completed data loads */
  public getDownloadLogFileButton(): JSX.Element {
    const onClick: () => void = this.props.onLogFileDownload.bind(this, this.props.item);
    const enabled: boolean = this.props.item.originalStatus === DATA_LOAD_STATUS.FLUSH_COMPLETED
      || this.props.item.originalStatus === DATA_LOAD_STATUS.FLUSH_FAILED;

    return (
      <Button
        icon="download"
        content={DATA_LOAD_HISTORY_TEXT.DOWNLOAD_LOG_FILE}
        onClick={onClick}
        disabled={this.props.loading || !enabled}
        loading={this.props.loading}
        positive
        fluid
      />
    );
  }

  public render(): JSX.Element {
    const lastUpdateTime: string = new Date(this.props.item.lastUpdateTime).toLocaleString();

    return (
      <Table.Row data-component="data-load-history-item">
        <Table.Cell width={1} content={this.props.rowIndex + 1} />
        <Table.Cell width={1} content={this.props.item.userId} />
        <Table.Cell width={3} content={this.props.item.dataLoadType} />
        <Table.Cell width={5} content={this.getDownloadFileButton()} />
        <Table.Cell width={2} content={lastUpdateTime} />
        <Table.Cell width={2} content={this.props.item.status} />
        <Table.Cell width={2} content={this.getDownloadLogFileButton()} />
      </Table.Row>
    );
  }
}

export interface IDataLoadHistoryItemProps {
  readonly rowIndex: number;
  readonly item: IDataLoadHistoryItem;
  readonly loading: boolean;
  onLogFileDownload(item: IDataLoadHistoryItem): void;
  onDataFileDownload(item: IDataLoadHistoryItem): void;
}
