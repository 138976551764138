import { DropdownItemProps } from 'semantic-ui-react';
import { MAX_DATA_ROWS_ALLOWED } from '../constants';

/** Create dropdown options based on options-like object */
export function getOptions(object: { [key: string]: string }): DropdownItemProps[] {
  const options: DropdownItemProps[] = [];
  const keys: string[] = Object.keys(object);

  for (let i: number = 0; i < keys.length; i++) {
    const key: string = keys[i];
    options.push({ value: key, text: object[key] });
  }

  return options;
}

/**
 * Check data file row range for allowed values
 */
export function checkDataRange(options: { start: number | null, end: number | null }): boolean {
  const defaultStart: number = (options.start !== null) ? options.start : 1;
  const defaultEnd: number = (options.end !== null) ? options.end : 1;

  const startMoreThanEnd: boolean = (options.start !== null && options.end !== null && options.start > options.end);
  return !startMoreThanEnd
    && defaultStart >= 1 && defaultEnd >= 1
    && (defaultEnd - defaultStart < MAX_DATA_ROWS_ALLOWED);
}

/**
 * Example:
 * const c = joinUrls('[/]def', https://localhost:8080/abc[/]);
 * c => https://localhost:8080/abc/def
 */
export function joinUrls(url: string, baseUrl: string): URL {
  const URL_DELIMITER: string = '/';
  const condition: boolean = baseUrl.lastIndexOf(URL_DELIMITER) === (baseUrl.length - 1);
  const newBaseUrl: string = (condition) ? baseUrl : `${baseUrl}${URL_DELIMITER}`;
  const newUrl: string = (url[0] === URL_DELIMITER) ? url.slice(1) : url;
  return new URL(`${newBaseUrl}${newUrl}`);
}

/**
 * Convert enum to array of values
 */
export function enumToArray(enumerable: object): string[] {
  const result: string[] = [];
  const keys: string[] = Object.keys(enumerable);

  for (let i: number = 0; i < keys.length; i++) {
    result.push(enumerable[keys[i]]);
  }

  return result;
}

/**
 * Replace named wildcards with actual values
 */
export function format(source: string, values: object): string {
  let result: string = source;
  const keys: string[] = Object.keys(values);

  for (const key of keys) {
    result = result.replace(new RegExp(`\\{${key}\\}`, 'gi'), values[key]);
  }

  return result;
}
