import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { DataLoadActionOptionsContainer } from '../../config/data-load-action-options';
import { MatchKeyOptionsContainer } from '../../config/match-key-options';
import { LibraryTypeDropdownContainer } from '../../config/library-type-dropdown';
import { EmptyCellOptionsContainer } from '../../config/empty-cell-options';
import { CONFIG_VIEW_TEXT, INDICATOR_DATA_LOAD_ACTION_OPTIONS, MATCH_KEY_OPTIONS } from '../../../constants';
import './indicator-config-options.less';

/**
 * Indicators config options combines relevant config option containers
 */
export class IndicatorConfigOptions extends React.Component {
  constructor(props: object) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <Form data-component="indicator-config-options">
        <Form.Group widths="equal">
          <DataLoadActionOptionsContainer options={INDICATOR_DATA_LOAD_ACTION_OPTIONS} />
          <LibraryTypeDropdownContainer />
        </Form.Group>

        <Form.Group widths="equal">
          <MatchKeyOptionsContainer
            options={MATCH_KEY_OPTIONS}
            labelKey={CONFIG_VIEW_TEXT.INDICATOR_CATEGORY_FACTOR}
          />
          <EmptyCellOptionsContainer />
        </Form.Group>
      </Form>
    );
  }
}
