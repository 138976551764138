import { IUIState } from '../interfaces';
import { DATA_LOAD_STAGE, EMPTY_CELLS_ACTION } from '../constants';
import * as Actions from '../actions';

export type ActionType = Actions.UI.ISetUIData
  & Actions.UI.ISetDataLoadStage;

const initial: IUIState = {
  stage: DATA_LOAD_STAGE.DATA_UPLOAD_INITIAL,
  actionLoading: false,
  isLogoutRedirecting: false,
  emptyCellsAction: EMPTY_CELLS_ACTION.DO_NOTHING
};

export function ui(
  state: IUIState = initial,
  action: ActionType
): IUIState {
  switch (action.type) {
    case Actions.UI.SET_UI_DATA:
      return { ...state, ...action.options };
    case Actions.UI.SET_DATA_LOAD_STAGE:
      return { ...state, stage: action.stage };
    case Actions.Batch.GO_TO_UPLOAD_STEP:
      return { ...initial, stage: DATA_LOAD_STAGE.DATA_UPLOAD };
    case Actions.Batch.GO_TO_CONFIG_STEP:
      return { ...state, actionLoading: false, stage: DATA_LOAD_STAGE.CONFIGURATION };
    case Actions.Batch.GO_TO_DATA_SAVE_STEP:
      return { ...state, actionLoading: false, stage: DATA_LOAD_STAGE.DRY_RUN_IN_PROGRESS };
    case Actions.Batch.CLEAR_APP_STATE:
      return initial;
    default:
      return state;
  }
}
