export enum SAMPLING_FIELD_KEY {
  COLUMN_1_NAME = 'samplingFieldColumn1Name',
  COLUMN_1_TYPE = 'samplingFieldColumn1Type',
  COLUMN_1_REQUIRED = 'samplingFieldColumn1Required',
  COLUMN_2_NAME = 'samplingFieldColumn2Name',
  COLUMN_2_TYPE = 'samplingFieldColumn2Type',
  COLUMN_2_REQUIRED = 'samplingFieldColumn2Required',
  COLUMN_3_NAME = 'samplingFieldColumn3Name',
  COLUMN_3_TYPE = 'samplingFieldColumn3Type',
  COLUMN_3_REQUIRED = 'samplingFieldColumn3Required',
  COLUMN_4_NAME = 'samplingFieldColumn4Name',
  COLUMN_4_TYPE = 'samplingFieldColumn4Type',
  COLUMN_4_REQUIRED = 'samplingFieldColumn4Required',
  COLUMN_5_NAME = 'samplingFieldColumn5Name',
  COLUMN_5_TYPE = 'samplingFieldColumn5Type',
  COLUMN_5_REQUIRED = 'samplingFieldColumn5Required',
  COLUMN_6_NAME = 'samplingFieldColumn6Name',
  COLUMN_6_TYPE = 'samplingFieldColumn6Type',
  COLUMN_6_REQUIRED = 'samplingFieldColumn6Required',
  COLUMN_7_NAME = 'samplingFieldColumn7Name',
  COLUMN_7_TYPE = 'samplingFieldColumn7Type',
  COLUMN_7_REQUIRED = 'samplingFieldColumn7Required',
  COLUMN_8_NAME = 'samplingFieldColumn8Name',
  COLUMN_8_TYPE = 'samplingFieldColumn8Type',
  COLUMN_8_REQUIRED = 'samplingFieldColumn8Required',
  COLUMN_9_NAME = 'samplingFieldColumn9Name',
  COLUMN_9_TYPE = 'samplingFieldColumn9Type',
  COLUMN_9_REQUIRED = 'samplingFieldColumn9Required',
  COLUMN_10_NAME = 'samplingFieldColumn10Name',
  COLUMN_10_TYPE = 'samplingFieldColumn10Type',
  COLUMN_10_REQUIRED = 'samplingFieldColumn10Required',
  SAMPLING_SIZE = 'sampleSize'
}

export const SAMPLING_FIELD_KEY_OPTIONS: {[key: string]: string} = {
  [SAMPLING_FIELD_KEY.COLUMN_1_NAME]: 'Column 1 Name',
  [SAMPLING_FIELD_KEY.COLUMN_1_TYPE]: 'Column 1 Type',
  [SAMPLING_FIELD_KEY.COLUMN_1_REQUIRED]: 'Column 1 Required',
  [SAMPLING_FIELD_KEY.COLUMN_2_NAME]: 'Column 2 Name',
  [SAMPLING_FIELD_KEY.COLUMN_2_TYPE]: 'Column 2 Type',
  [SAMPLING_FIELD_KEY.COLUMN_2_REQUIRED]: 'Column 2 Required',
  [SAMPLING_FIELD_KEY.COLUMN_3_NAME]: 'Column 3 Name',
  [SAMPLING_FIELD_KEY.COLUMN_3_TYPE]: 'Column 3 Type',
  [SAMPLING_FIELD_KEY.COLUMN_3_REQUIRED]: 'Column 3 Required',
  [SAMPLING_FIELD_KEY.COLUMN_4_NAME]: 'Column 4 Name',
  [SAMPLING_FIELD_KEY.COLUMN_4_TYPE]: 'Column 4 Type',
  [SAMPLING_FIELD_KEY.COLUMN_4_REQUIRED]: 'Column 4 Required',
  [SAMPLING_FIELD_KEY.COLUMN_5_NAME]: 'Column 5 Name',
  [SAMPLING_FIELD_KEY.COLUMN_5_TYPE]: 'Column 5 Type',
  [SAMPLING_FIELD_KEY.COLUMN_5_REQUIRED]: 'Column 5 Required',
  [SAMPLING_FIELD_KEY.COLUMN_6_NAME]: 'Column 6 Name',
  [SAMPLING_FIELD_KEY.COLUMN_6_TYPE]: 'Column 6 Type',
  [SAMPLING_FIELD_KEY.COLUMN_6_REQUIRED]: 'Column 6 Required',
  [SAMPLING_FIELD_KEY.COLUMN_7_NAME]: 'Column 7 Name',
  [SAMPLING_FIELD_KEY.COLUMN_7_TYPE]: 'Column 7 Type',
  [SAMPLING_FIELD_KEY.COLUMN_7_REQUIRED]: 'Column 7 Required',
  [SAMPLING_FIELD_KEY.COLUMN_8_NAME]: 'Column 8 Name',
  [SAMPLING_FIELD_KEY.COLUMN_8_TYPE]: 'Column 8 Type',
  [SAMPLING_FIELD_KEY.COLUMN_8_REQUIRED]: 'Column 8 Required',
  [SAMPLING_FIELD_KEY.COLUMN_9_NAME]: 'Column 9 Name',
  [SAMPLING_FIELD_KEY.COLUMN_9_TYPE]: 'Column 9 Type',
  [SAMPLING_FIELD_KEY.COLUMN_9_REQUIRED]: 'Column 9 Required',
  [SAMPLING_FIELD_KEY.COLUMN_10_NAME]: 'Column 10 Name',
  [SAMPLING_FIELD_KEY.COLUMN_10_TYPE]: 'Column 10 Type',
  [SAMPLING_FIELD_KEY.COLUMN_10_REQUIRED]: 'Column 10 Required',
  [SAMPLING_FIELD_KEY.SAMPLING_SIZE]: 'Sampling Size'
};
