import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { DataLoadHistory } from './data-load-history';
import { IState } from '../../interfaces';

export interface IDataLoadHistoryStateProps extends RouteComponentProps<{}> {
  readonly state: IState;
}

export type IDataLoadHistoryConnectProps = IDataLoadHistoryStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: RouteComponentProps<{}>): IDataLoadHistoryStateProps {
  return { state, ...props };
}

export const DataLoadHistoryContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(DataLoadHistory);
