import * as React from 'react';
import { InputOnChangeData } from 'semantic-ui-react';
import { ILoginConnectProps } from './login-container';
import { LoginView } from './login-view';
import { BaseApi } from '../../api';
import { IAuthTicket } from '../../interfaces';
import * as Actions from '../../actions';
import { setLocalStorageValue } from '../../functions/storage';

/**
 * Login form with "user", "password" and "domain" inputs
 */
export class Login extends React.Component<ILoginConnectProps, ILoginState> {

  constructor(props: ILoginConnectProps) {
    super(props);
    this.state = {
      user: '',
      password: '',
      domain: new URL(window.location.href).searchParams.get('domain') || '',
      loading: false,
      checkingToken: false,
      error: null
    };

    this.onLogIn = this.onLogIn.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  /** Send login request and save received token in Redux state */
  public onLogIn(): void {
    if (this.state.loading === true) {
      return;
    }
    this.setState({ loading: true });

    BaseApi.getAuthToken({
      username: this.state.user,
      password: this.state.password,
      domain: this.state.domain
    }).then((ticket: IAuthTicket): void => {
      this.props.dispatch(Actions.Auth.setAuthToken(ticket));
      setLocalStorageValue('token', ticket.token);
    }).catch((response: Response): void => {
      this.setState({ loading: false, error: response });
    });
  }

  /** Update state on change event (see view for explanation) */
  public onInputChange(e: React.KeyboardEvent<HTMLInputElement>, changes: InputOnChangeData): void {
    const obj: object = { [changes.name]: changes.value };
    this.setState({ ...obj, error: null });
  }

  public render(): JSX.Element {
    return (
      <LoginView
        user={this.state.user}
        password={this.state.password}
        domain={this.state.domain}
        loading={this.state.loading}
        checkingToken={this.state.checkingToken}
        error={this.state.error}
        onChange={this.onInputChange}
        onLogIn={this.onLogIn}
      />
    );
  }
}

export interface ILoginState {
  readonly user: string;
  readonly password: string;
  readonly domain: string;
  readonly error: Response;
  /** "Log In" request is loading */
  readonly loading: boolean;
  /** "Check Token" request is loading */
  readonly checkingToken: boolean;
}
