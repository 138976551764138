import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { MADataUpload } from './ma-data-upload';
import { IState, IUploadState } from '../../../interfaces';

export interface IMADataUploadStateProps {
  readonly upload: IUploadState;
}

export type IMADataUploadConnectProps = IMADataUploadStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IMADataUploadStateProps {
  return { upload: state.upload };
}

export const MADataUploadContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(MADataUpload);
