import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { DataLoadActionOptionsContainer } from '../../config/data-load-action-options';
import { MatchPlanKeyOptionsContainer } from '../../config/match-plan-key-options';
import { MatchActivityKeyOptionsContainer } from '../../config/match-activity-key-options';
import { EmptyCellOptionsContainer } from '../../config/empty-cell-options';
import { ACTIVITY_DATA_LOAD_ACTION_OPTIONS } from '../../../constants';
import './activity-config-options.less';

/**
 * Control config options component combines relevant config option containers
 */
export class ActivityConfigOptions extends React.Component {
  constructor(props: object) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <Form data-component="activity-config-options">
        <Form.Group widths="equal">
          <DataLoadActionOptionsContainer options={ACTIVITY_DATA_LOAD_ACTION_OPTIONS} />
          <MatchPlanKeyOptionsContainer />
        </Form.Group>

        <Form.Group widths="equal">
          <MatchActivityKeyOptionsContainer />
          <EmptyCellOptionsContainer />
        </Form.Group>
      </Form>
    );
  }
}
