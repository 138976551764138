import * as React from 'react';
import {
  Dropdown, DropdownItemProps, DropdownProps, Icon, SemanticICONS, SemanticWIDTHS, Table
} from 'semantic-ui-react';
import {
  CATEGORY_FIELD_OPTIONS, CONFIG_VIEW_TEXT, FACTOR_FIELD_OPTIONS, INDICATOR_FIELD_OPTIONS, INDICATOR_KEY,
  INDICATOR_MAPPING, INDICATOR_MAPPING_OPTIONS, NO_MAPPING_OPTION
} from '../../../constants';
import { format, getOptions } from '../../../functions';
import './indicator-mapping-options.less';

export class IndicatorMappingOptions
  extends React.Component<IndicatorMappingOptionsProps, IndicatorMappingOptionsState> {
  constructor(props: IndicatorMappingOptionsProps) {
    super(props);
    this.state = {
      indicatorKey: this.getIndicatorKey(props),
      mappingType: this.getDefaultType(),
      mappingTypeOptions: this.getTypeOptions(),
      warning: false
    };

    this.onTypeChange = this.onTypeChange.bind(this);
    this.onIndicatorColMappingChange = this.onIndicatorColMappingChange.bind(this);
    this.onFactorColMappingChange = this.onFactorColMappingChange.bind(this);
    this.onCategoryColMappingChange = this.onCategoryColMappingChange.bind(this);
  }

  /** "Type" option can be reset using NO_MAPPING */
  public getTypeOptions(): DropdownItemProps[] {
    const options: DropdownItemProps[] = getOptions(INDICATOR_MAPPING_OPTIONS);
    options.push({ value: NO_MAPPING_OPTION.key, text: NO_MAPPING_OPTION.caption });
    return options;
  }

  public getIndicatorKey(props: IndicatorMappingOptionsProps): string {
    return props.indicatorConfig[props.colIndex] || props.factorConfig[props.colIndex] ||
      props.categoryConfig[props.colIndex] || NO_MAPPING_OPTION.key;
  }

  public getDefaultType(): string {
    const key: string = this.getIndicatorKey(this.props);
    let type: string = NO_MAPPING_OPTION.key;
    if (INDICATOR_FIELD_OPTIONS[key]) { type = INDICATOR_MAPPING.INDICATOR_FIELD; }
    if (FACTOR_FIELD_OPTIONS[key]) { type = INDICATOR_MAPPING.FACTOR_FIELD; }
    if (CATEGORY_FIELD_OPTIONS[key]) { type = INDICATOR_MAPPING.CATEGORY_FIELD; }

    return type;
  }

  public onTypeChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    const warning: boolean = !(changes.value === NO_MAPPING_OPTION.key);
    this.setState({
      mappingType: changes.value.toString(),
      indicatorKey: NO_MAPPING_OPTION.key
    });
    this.props.onMappingReset(this.props.colIndex);
    this.onWarningChange(warning);
  }

  /** Update own value and call parent callback */
  public onIndicatorColMappingChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.setState({ indicatorKey: changes.value.toString() });
    this.props.onIndicatorColMappingChange(this.props.colIndex, changes.value.toString() as INDICATOR_KEY);
    this.onWarningChange(false);
  }

  /** Update own value and call parent callback */
  public onFactorColMappingChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.setState({ indicatorKey: changes.value.toString() });
    this.props.onFactorColMappingChange(this.props.colIndex, changes.value.toString() as INDICATOR_KEY);
    this.onWarningChange(false);
  }

  /** Update own value and call parent callback */
  public onCategoryColMappingChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.setState({ indicatorKey: changes.value.toString() });
    this.props.onCategoryColMappingChange(this.props.colIndex, changes.value.toString() as INDICATOR_KEY);
    this.onWarningChange(false);
  }

  /** Warning state shows or hides error sign for current row */
  public onWarningChange(warning: boolean): void {
    this.setState({ warning });
    this.props.onWarningChange(warning);
  }

  /**
   * Get status for column mapping (done, empty or warning)
   * Empty rows are allowed, the ones with warning are not
   */
  public getStatus(): JSX.Element {
    const STATUS_CELL_WIDTH: SemanticWIDTHS = 1;
    const empty: boolean = this.state.mappingType === NO_MAPPING_OPTION.key;
    const icon: SemanticICONS = (empty)
      ? 'warning'
      : (this.state.warning)
        ? 'warning sign'
        : 'checkmark';

    return (
      <Table.Cell
        textAlign="center"
        negative={!empty && this.state.warning}
        positive={!empty && !this.state.warning}
        width={STATUS_CELL_WIDTH}
      >
        <Icon name={icon} />
      </Table.Cell>
    );
  }

  public getIndicatorOptions(): JSX.Element {
    let options: DropdownItemProps[] = [];
    let onChange: (e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps) => void = null;
    switch (this.state.mappingType) {
      case INDICATOR_MAPPING.FACTOR_FIELD:
        options = getOptions(FACTOR_FIELD_OPTIONS);
        onChange = this.onFactorColMappingChange;
        break;
      case INDICATOR_MAPPING.INDICATOR_FIELD:
        options = getOptions(INDICATOR_FIELD_OPTIONS);
        onChange = this.onIndicatorColMappingChange;
        break;
      case INDICATOR_MAPPING.CATEGORY_FIELD:
        options = getOptions(CATEGORY_FIELD_OPTIONS);
        onChange = this.onCategoryColMappingChange;
        break;
      default: {
        return null;
      }
    }

    return (
      <Dropdown
        options={options}
        value={this.state.indicatorKey}
        onChange={onChange}
        selectOnBlur={false}
        selection
        search
        fluid
      />
    );
  }

  public render(): JSX.Element {
    const name: string = this.props.name
      || format(CONFIG_VIEW_TEXT.COLUMN_INDEX, { index: (Number(this.props.colIndex) || 0) + 1 });

    return (
      <Table.Row data-component="indicator-mapping-options">
        {this.getStatus()}

        <Table.Cell width={5}>
          {name}
        </Table.Cell>

        <Table.Cell width={5}>
          <Dropdown
            options={this.state.mappingTypeOptions}
            value={this.state.mappingType}
            onChange={this.onTypeChange}
            selectOnBlur={false}
            selection
            fluid
          />
        </Table.Cell>

        <Table.Cell width={5}>
          {this.getIndicatorOptions()}
        </Table.Cell>
      </Table.Row>
    );
  }
}

export interface IndicatorMappingOptionsState {
  readonly indicatorKey: string;
  readonly mappingType: string;
  readonly mappingTypeOptions: DropdownItemProps[];
  /** Used to display row warnings or errors */
  readonly warning: boolean;
}

export interface IndicatorMappingOptionsProps {
  readonly name: string;
  readonly colIndex: string;
  readonly indicatorConfig: { [key: number]: INDICATOR_KEY };
  readonly factorConfig: { [key: number]: INDICATOR_KEY };
  readonly categoryConfig: { [key: number]: INDICATOR_KEY };

  onWarningChange(warning: boolean): void;
  onMappingReset(colIndex: string): void;
  onIndicatorColMappingChange(colIndex: string, key: INDICATOR_KEY): void;
  onFactorColMappingChange(colIndex: string, key: INDICATOR_KEY): void;
  onCategoryColMappingChange(colIndex: string, key: INDICATOR_KEY): void;
}
