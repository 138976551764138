import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { LibraryTypeDropdownContainer } from '../../config/library-type-dropdown';
import { MatchIndicatorKeyOptionsContainer } from '../../config/match-indicator-key-options';
import { MatchKeyOptionsContainer } from '../../config/match-key-options';
import { ProcessTypeOptionsContainer } from '../../config/process-type-options';
import { CONFIG_VIEW_TEXT, MATCH_KEY_OPTIONS } from '../../../constants';
import './ipl-config-options.less';

/**
 * Indicator-Process Links component combines relevant config option containers
 */
export class IPLConfigOptions extends React.Component {
  constructor(props: object) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <Form data-component="ipl-config-options">
        <Form.Group widths="equal">
          <LibraryTypeDropdownContainer />
          <MatchIndicatorKeyOptionsContainer />
        </Form.Group>

        <Form.Group widths="equal">
          <MatchKeyOptionsContainer options={MATCH_KEY_OPTIONS} labelKey={CONFIG_VIEW_TEXT.PROCESSES} />
          <ProcessTypeOptionsContainer />
        </Form.Group>
      </Form>
    );
  }
}
