import * as React from 'react';
import { IIndicatorConfigActionsConnectProps } from './indicator-config-actions-container';
import { ConfigActionsContainer } from '../../config/config-actions';
import { DataLoadApi } from '../../../api';
import { IIndicatorConfig } from '../../../interfaces';
import { getApiIndicatorConfig } from '../../../functions';

/**
 * Indicators "Check Configuration" action buttons
 */
export class IndicatorConfigActions extends React.Component<IIndicatorConfigActionsConnectProps> {
  constructor(props: IIndicatorConfigActionsConnectProps) {
    super(props);
    this.uploadConfig = this.uploadConfig.bind(this);
  }

  /** Upload config to server for dry run */
  public uploadConfig(): Promise<{}> {
    const config: IIndicatorConfig = getApiIndicatorConfig({
      config: this.props.config.indicators,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadIndicatorConfig({ config, columns: this.props.config.columns }).request();
  }

  public render(): JSX.Element {
    return (
      <ConfigActionsContainer
        warning={this.props.warning}
        uploadConfig={this.uploadConfig}
      />
    );
  }
}
