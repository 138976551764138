import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { MADataUploadOptions } from './ma-data-upload-options';
import { IState, IUploadState } from '../../../interfaces';

export interface IMADataUploadOptionsStateProps {
  readonly upload: IUploadState;
}

export type IMADataUploadOptionsConnectProps = IMADataUploadOptionsStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IMADataUploadOptionsStateProps {
  return { upload: state.upload };
}

export const MADataUploadOptionsContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(MADataUploadOptions);
