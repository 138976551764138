import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { IncidentDataUpload } from './incident-data-upload';
import { IState, IUploadState } from '../../../interfaces';

export interface IIncidentDataUploadStateProps {
  readonly upload: IUploadState;
}

export type IIncidentDataUploadConnectProps = IIncidentDataUploadStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IIncidentDataUploadStateProps {
  return { upload: state.upload };
}

export const IncidentDataUploadContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(IncidentDataUpload);
