import * as React from 'react';
import { DataSaveActionsView } from './data-save-actions-view';
import { IDataSaveActionsConnectProps } from './data-save-actions-container';
import { DataLoadApi } from '../../../api';
import { COMMON_TEXT, DATA_LOAD_STAGE } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Component with buttons for "Dry Run" and "Data Save" actions
 */
export class DataSaveActions extends React.Component<IDataSaveActionsConnectProps, IDataSaveActionsState> {

  constructor(props: IDataSaveActionsConnectProps) {
    super(props);
    this.state = {
      error: this.props.error,
      loading: false
    };

    this.onDone = this.onDone.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onRetry = this.onRetry.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSaveData = this.onSaveData.bind(this);
  }

  /** Update error received from parent */
  public componentWillReceiveProps(next: IDataSaveActionsConnectProps): void {
    this.setState({ error: next.error });
  }

  /** On complete success reset application state */
  public onDone(): void {
    this.props.dispatch(Actions.Batch.clearAppState());
  }

  /** Send cancel request and go to "Configuration" step */
  public onBack(): void {
    const confirm: boolean = window.confirm(COMMON_TEXT.UNSAVED_CHANGES);
    if (!confirm) { return; }

    this.props.dispatch(Actions.Batch.goToConfigStep());
  }

  /**
   * Reset data load result in Redux state
   * Call parent callback in order to trigger new load sequence
   */
  public onRetry(): void {
    this.props.dispatch(Actions.Batch.goToDataSaveStep());
    this.props.onRetry();
  }

  /** Send cancel request and reset Redux state */
  public onCancel(): void {
    const confirm: boolean = window.confirm(COMMON_TEXT.UNSAVED_CHANGES);
    if (!confirm) { return; }

    this.setState({ loading: true });
    this.props.dispatch(Actions.UI.setUIData({ actionLoading: true }));

    const onCancelCallback: () => void = (): void => {
      this.setState({ loading: false });
      this.props.dispatch(Actions.Batch.clearAppState());
      this.props.dispatch(Actions.UI.setUIData({ actionLoading: false }));
    };

    DataLoadApi.cancelDataLoad().request().then(onCancelCallback).catch(onCancelCallback);
  }

  /** Init new data load request */
  public onSaveData(): void {
    this.setState({ loading: true });
    this.props.dispatch(Actions.UI.setUIData({ actionLoading: true }));

    DataLoadApi.makeDataLoad({ confirm: true }).request()
      .then((): void => {
        this.setState({ loading: false });
        this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_SAVE_IN_PROGRESS));
      })
      .catch((response: Response): void => {
        this.setState({ error: response, loading: false });
        this.props.dispatch(Actions.UI.setUIData({ actionLoading: false }));
        this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_SAVE_FAILED));
      });
  }

  public render(): JSX.Element {
    return (
      <DataSaveActionsView
        error={this.state.error}
        loading={this.state.loading}
        disabled={this.state.loading || this.props.ui.actionLoading}
        ui={this.props.ui}

        onDone={this.onDone}
        onBack={this.onBack}
        onRetry={this.onRetry}
        onCancel={this.onCancel}
        onSaveData={this.onSaveData}
      />
    );
  }
}

export interface IDataSaveActionsState {
  readonly error: Response;
  readonly loading: boolean;
}
