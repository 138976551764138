import * as React from 'react';
import { IIndicatorConfigConnectProps } from './indicator-config-container';
import { IndicatorConfigView } from './indicator-config-view';
import { IAppIndicatorConfig, IAppIndicatorMapping } from '../../../interfaces';
import { INDICATOR_KEY } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Indicators column mapping configuration
 */
export class IndicatorConfig extends React.Component<IIndicatorConfigConnectProps, IIndicatorConfigState> {
  constructor(props: IIndicatorConfigConnectProps) {
    super(props);
    this.state = {
      warning: false
    };

    this.onWarningChange = this.onWarningChange.bind(this);
    this.onMappingReset = this.onMappingReset.bind(this);
    this.onIndicatorColMappingChange = this.onIndicatorColMappingChange.bind(this);
    this.onFactorColMappingChange = this.onFactorColMappingChange.bind(this);
    this.onCategoryColMappingChange = this.onCategoryColMappingChange.bind(this);
  }

  /** Update one of available indicators config parts */
  public onColMappingChange(key: keyof IAppIndicatorConfig, colIndex: string, value: INDICATOR_KEY): void {
    const config: IAppIndicatorConfig = {
      ...this.props.config.indicators,
      [key]: {
        ...this.props.config.indicators[key],
        [colIndex]: value
      }
    };

    this.props.dispatch(Actions.Config.setIndicatorConfig(config));
  }

  public onIndicatorColMappingChange(colIndex: string, key: INDICATOR_KEY): void {
    this.onColMappingChange('indicatorConfig', colIndex, key);
  }

  public onFactorColMappingChange(colIndex: string, key: INDICATOR_KEY): void {
    this.onColMappingChange('factorConfig', colIndex, key);
  }

  public onCategoryColMappingChange(colIndex: string, key: INDICATOR_KEY): void {
    this.onColMappingChange('categoryConfig', colIndex, key);
  }

  /** Reset mapping on type changes */
  public onMappingReset(colIndex: string): void {
    const indicatorConfig: IAppIndicatorMapping = this.props.config.indicators.indicatorConfig;
    const factorConfig: IAppIndicatorMapping = this.props.config.indicators.factorConfig;
    const categoryConfig: IAppIndicatorMapping = this.props.config.indicators.categoryConfig;

    delete indicatorConfig[colIndex];
    delete factorConfig[colIndex];
    delete categoryConfig[colIndex];

    const config: IAppIndicatorConfig = {
      ...this.props.config.indicators,
      indicatorConfig,
      factorConfig,
      categoryConfig
    };

    this.props.dispatch(Actions.Config.setIndicatorConfig(config));
  }

  /** If any row contains invalid UI data (not config), set "warning" value */
  public onWarningChange(warning: boolean): void {
    this.setState({ warning });
  }

  public showConfigMapping(): boolean {
    return !!this.props.ui.dataLoadAction && !!this.props.ui.libraryType
      && !!this.props.ui.matchKey;
  }

  public render(): JSX.Element {
    if (!this.props.config.indicators) { return null; }

    return (
      <IndicatorConfigView
        columns={this.props.config.columns}
        warning={this.state.warning}
        optionsReady={this.showConfigMapping()}
        appIndicatorConfig={this.props.config.indicators}

        onWarningChange={this.onWarningChange}
        onMappingReset={this.onMappingReset}
        onIndicatorColMappingChange={this.onIndicatorColMappingChange}
        onFactorColMappingChange={this.onFactorColMappingChange}
        onCategoryColMappingChange={this.onCategoryColMappingChange}
      />
    );
  }
}

export interface IIndicatorConfigState {
  readonly warning: boolean;
}
