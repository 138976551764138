import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { MADataSave } from './ma-data-save';
import { IConfigState, IResultState, IState, IUIState, IUploadState } from '../../../interfaces';

export interface IMADataSaveStateProps {
  readonly ui: IUIState;
  readonly upload: IUploadState;
  readonly config: IConfigState;
  readonly result: IResultState;
}

export type IMADataSaveConnectProps = IMADataSaveStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IMADataSaveStateProps {
  return {
    ui: state.ui,
    upload: state.upload,
    config: state.config,
    result: state.result
  };
}

export const MADataSaveContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(MADataSave);
