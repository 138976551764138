export enum USER_MANAGEMENT_OPTIONS {
  LOGIN_ID = 'userId',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE = 'phone',
  CELL = 'cell',
  TITLE = 'title',
  DEPARTMENT = 'department',
  COST_CENTER = 'costCenter',
  ACTIVE = 'active',
  TEMPORARY = 'temporary',
  EXPIRE_DATE = 'expireDate',
  ORGANIZATION = 'organization',
  MANAGER = 'manager',
  MANAGING_DIRECTOR = 'managingDirector'
}
