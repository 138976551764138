import * as React from 'react';
import { IILConfigConnectProps } from './il-config-container';
import { ILConfigView } from './il-config-view';
import { IAppILConfig } from '../../../interfaces';
import { IL_KEY } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Indicator-Indicator Links column mapping configuration
 */
export class ILConfig extends React.Component<IILConfigConnectProps, IILConfigState> {
  constructor(props: IILConfigConnectProps) {
    super(props);
    this.state = {
      warning: false
    };

    this.onWarningChange = this.onWarningChange.bind(this);
    this.onMappingReset = this.onMappingReset.bind(this);
    this.onEventEventColMappingChange = this.onEventEventColMappingChange.bind(this);
  }

  public onEventEventColMappingChange(colIndex: string, key: IL_KEY): void {
    const config: IAppILConfig = {
      ...this.props.config.IL,
      eventEventConfig: {
        ...this.props.config.IL.eventEventConfig,
        [colIndex]: key
      }
    };

    this.props.dispatch(Actions.Config.setILConfig(config));
  }

  public onMappingReset(colIndex: string): void {
    const eventEventConfig: { [key: string]: IL_KEY } = this.props.config.IL.eventEventConfig;
    delete eventEventConfig[colIndex];

    const config: IAppILConfig = {
      ...this.props.config.IL,
      eventEventConfig,
    };

    this.props.dispatch(Actions.Config.setILConfig(config));
  }

  public onWarningChange(warning: boolean): void {
    this.setState({ warning });
  }

  public showConfigMapping(): boolean {
    return !!this.props.ui.matchIndicatorKey;
  }

  public render(): JSX.Element {
    if (!this.props.config.IL) { return null; }

    return (
      <ILConfigView
        columns={this.props.config.columns}
        warning={this.state.warning}
        optionsReady={this.showConfigMapping()}
        appILConfig={this.props.config.IL}
        onWarningChange={this.onWarningChange}
        onMappingReset={this.onMappingReset}
        onEventEventColMappingChange={this.onEventEventColMappingChange}
      />
    );
  }
}

export interface IILConfigState {
  readonly warning: boolean;
}
