import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { IPLConfig } from './ipl-config';
import { IConfigState, IDataState, IState, IUIState, IUploadState } from '../../../interfaces';

export interface IIPLConfigStateProps {
  readonly ui: IUIState;
  readonly data: IDataState;
  readonly upload: IUploadState;
  readonly config: IConfigState;
}

export type IIPLConfigConnectProps = IIPLConfigStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IIPLConfigStateProps {
  return {
    ui: state.ui,
    data: state.data,
    upload: state.upload,
    config: state.config
  };
}

export const IPLConfigContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(IPLConfig);
