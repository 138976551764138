import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { ActivityWorkflow } from './activity-workflow';
import { IState, IUIState } from '../../../interfaces';

export interface IActivityWorkflowStateProps {
  readonly ui: IUIState;
}

export type IActivityWorkflowConnectProps = IActivityWorkflowStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IActivityWorkflowStateProps {
  return { ui: state.ui };
}

export const ActivityWorkflowContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(ActivityWorkflow);
