import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { ErrorHandler } from './error-handler';
import { IAuthTicket, IDataLoadResult, IState } from '../../interfaces';
import { GENERAL_ERROR_CODE_TYPE } from '../../constants';

export interface IErrorHandlerProps {
  readonly code?: GENERAL_ERROR_CODE_TYPE;
  readonly grid?: boolean;
  readonly response?: Response;
  readonly dataloadResult?: IDataLoadResult;
  onDismiss?(): void;
}

export interface IErrorHandlerStateProps extends IErrorHandlerProps {
  readonly auth: IAuthTicket;
}

export type IErrorHandlerConnectProps = IErrorHandlerStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IErrorHandlerProps): IErrorHandlerStateProps {
  return { auth: state.auth, ...props };
}

export const ErrorHandlerContainer: React.ComponentClass<IErrorHandlerProps>
  = ReactRedux.connect(mapStateToProps)(ErrorHandler);
