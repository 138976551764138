export enum TESTING_FIELD_KEY {
  TEST_PERIOD_VISIBLE = 'testPeriodVisible',
  TEST_PERIOD_REQUIRED = 'testPeriodRequired',
  SAMPLE_PERIOD_START_VISIBLE = 'samplePeriodStartVisible',
  SAMPLE_PERIOD_START_REQUIRED = 'samplePeriodStartRequired',
  SAMPLE_PERIOD_END_VISIBLE = 'samplePeriodEndVisible',
  SAMPLE_PERIOD_END_REQUIRED = 'samplePeriodEndRequired',
  TEST_TYPE_VISIBLE = 'testTypeVisible',
  TEST_TYPE_REQUIRED = 'testTypeRequired',
  PERSON_INQUIRED_OR_OBSERVED_VISIBLE = 'personInquiredObservedVisible',
  PERSON_INQUIRED_OR_OBSERVED_REQUIRED = 'personInquiredObservedRequired',
  CONCLUSION_NOTES_VISIBLE = 'conclusionNotesVisible',
  CONCLUSION_NOTES_REQUIRED = 'conclusionNotesRequired',
  CUSTOM_CONCLUSION_OPTIONS = 'customConclusionOptions'
}

export const TESTING_FIELD_KEY_OPTIONS: { [key: string]: string } = {
  [TESTING_FIELD_KEY.TEST_PERIOD_VISIBLE]: 'Test Period Visible',
  [TESTING_FIELD_KEY.TEST_PERIOD_REQUIRED]: 'Test Period Required',
  [TESTING_FIELD_KEY.SAMPLE_PERIOD_START_VISIBLE]: 'Sample Period (Start) Visible',
  [TESTING_FIELD_KEY.SAMPLE_PERIOD_START_REQUIRED]: 'Sample Period (Start) Required',
  [TESTING_FIELD_KEY.SAMPLE_PERIOD_END_VISIBLE]: 'Sample Period (End) Visible',
  [TESTING_FIELD_KEY.SAMPLE_PERIOD_END_REQUIRED]:  'Sample Period (End) Required',
  [TESTING_FIELD_KEY.TEST_TYPE_VISIBLE]: 'Test Type Visible',
  [TESTING_FIELD_KEY.TEST_TYPE_REQUIRED]: 'Test Type Required',
  [TESTING_FIELD_KEY.PERSON_INQUIRED_OR_OBSERVED_VISIBLE]: 'Person Inquired or Observed Visible',
  [TESTING_FIELD_KEY.PERSON_INQUIRED_OR_OBSERVED_REQUIRED]: 'Person Inquired or Observed Required',
  [TESTING_FIELD_KEY.CONCLUSION_NOTES_VISIBLE]: 'Conclusion Notes Visible',
  [TESTING_FIELD_KEY.CONCLUSION_NOTES_REQUIRED]: 'Conclusion Notes Required',
  [TESTING_FIELD_KEY.CUSTOM_CONCLUSION_OPTIONS]: 'Custom Conclusion Options'
};
