import * as React from 'react';
import { IIndicatorDataSaveConnectProps } from './indicator-data-save-container';
import { DataSaveContainer } from '../../data-save';
import { DataLoadApi } from '../../../api';
import { IIndicatorConfig } from '../../../interfaces';
import { DATA_LOAD_TYPE } from '../../../constants';
import { getApiIndicatorConfig } from '../../../functions';

/**
 * Wrapper around "Data Save" component for Indicators
 */
export class IndicatorDataSave extends React.Component<IIndicatorDataSaveConnectProps> {
  constructor(props: IIndicatorDataSaveConnectProps) {
    super(props);
    this.onRetry = this.onRetry.bind(this);
  }

  /** Re-send "Check Config" request */
  public onRetry(): Promise<{}> {
    const config: IIndicatorConfig = getApiIndicatorConfig({
      config: this.props.config.indicators,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadIndicatorConfig({ config, columns: this.props.config.columns }).request();
  }

  public render(): JSX.Element {
    if (!this.props.config.indicators) { return null; }

    const nRows: number = this.props.config.indicators.tableConfig.dataEndRow
      - this.props.config.indicators.tableConfig.dataStartRow;

    return (
      <DataSaveContainer
        nRows={nRows}
        dataLoadType={DATA_LOAD_TYPE.INDICATOR_LIBRARY}
        onRetry={this.onRetry}
      />
    );
  }
}
