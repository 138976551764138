import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { IncidentConfigActions } from './incident-config-actions';
import { IConfigState, IState, IUIState, IUploadState } from '../../../interfaces';

export interface IIncidentConfigActionsProps {
  readonly warning: boolean;
}

export interface IIncidentConfigActionsStateProps extends IIncidentConfigActionsProps {
  readonly ui: IUIState;
  readonly upload: IUploadState;
  readonly config: IConfigState;
}

export type IIncidentConfigActionsConnectProps = IIncidentConfigActionsStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IIncidentConfigActionsProps): IIncidentConfigActionsStateProps {
  return {
    ui: state.ui,
    upload: state.upload,
    config: state.config,
    ...props
  };
}

export const IncidentConfigActionsContainer: React.ComponentClass<IIncidentConfigActionsProps>
  = ReactRedux.connect(mapStateToProps)(IncidentConfigActions);
