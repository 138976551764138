import { IDataState } from '../interfaces';
import * as Actions from '../actions';

const initial: IDataState = {
  tabs: {},
  fields: {}
};

export function data(
  state: IDataState = initial,
  action: Actions.Data.ISetData
): IDataState {
  switch (action.type) {
    case Actions.Data.SET_DATA:
      return { ...state, ...action.options };
    default:
      return state;
  }
}
