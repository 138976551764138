import * as React from 'react';
import { IMADataSaveConnectProps } from './ma-data-save-container';
import { DataSaveContainer } from '../../data-save';
import { DataLoadApi } from '../../../api';
import { IMAConfig } from '../../../interfaces';
import { DATA_LOAD_TYPE } from '../../../constants';
import { getApiMAConfig } from '../../../functions';

/**
 * Wrapper around "Data Save" component for Taxonomy
 */
export class MADataSave extends React.Component<IMADataSaveConnectProps> {
  constructor(props: IMADataSaveConnectProps) {
    super(props);
    this.onRetry = this.onRetry.bind(this);
  }

  public onRetry(): Promise<{}> {
    const config: IMAConfig = getApiMAConfig({
      config: this.props.config.MA,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadMAConfig({ config, columns: this.props.config.columns }).request();
  }

  public render(): JSX.Element {
    if (!this.props.config.MA) { return null; }

    const nRows: number = this.props.config.MA.tableConfig.dataEndRow - this.props.config.MA.tableConfig.dataStartRow;

    return (
      <DataSaveContainer
        nRows={nRows}
        dataLoadType={DATA_LOAD_TYPE.MONITORING_ACTIVITY}
        onRetry={this.onRetry}
      />
    );
  }
}
