import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { AILDataUpload } from './ail-data-upload';
import { IState, IUploadState } from '../../../interfaces';

export interface IAILDataUploadStateProps {
  readonly upload: IUploadState;
}

export type IAILDataUploadConnectProps = IAILDataUploadStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IAILDataUploadStateProps {
  return { upload: state.upload };
}

export const AILDataUploadContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(AILDataUpload);
