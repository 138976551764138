import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { TxDataUploadActions } from './tx-data-upload-actions';
import { IState, IUploadState } from '../../../interfaces';

export interface ITxDataUploadActionsStateProps {
  readonly upload: IUploadState;
}

export type ITxDataUploadActionsConnectProps = ITxDataUploadActionsStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): ITxDataUploadActionsStateProps {
  return { upload: state.upload };
}

export const TxDataUploadActionsContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(TxDataUploadActions);
