import * as React from 'react';
import { NavLink, Prompt } from 'react-router-dom';
import { Location } from 'history';
import { Grid, Menu } from 'semantic-ui-react';
import { ErrorHandlerContainer } from '../error-handler';
import { IAuthTicket, IUploadState } from '../../interfaces';
import { COMMON_TEXT, CONTENT_VIEW_TEXT } from '../../constants';
import './content-view.less';

/**
 * View for "Content" component
 */
export class ContentView extends React.Component<IContentViewProps> {
  constructor(props: IContentViewProps) {
    super(props);
    this.state = { error: false };
    this.onLogOut = this.onLogOut.bind(this);
  }

  /** Get "Logic Manager" logo as menu item */
  public getLogo(): JSX.Element {
    const image: JSX.Element = (<NavLink to="/" exact><img src="/static/logo.png" /></NavLink>);
    return (
      <Menu.Item header content={image} className="content-logo" />
    );
  }

  /** Get fixed at top header with navigation links */
  public getTopHeader(): JSX.Element {
    /* User ID displayed in header */
    const user: string = `${this.props.auth.fullName} [${this.props.auth.domain}]`;

    return (
      <Menu size="large" borderless>
        {this.getLogo()}
        {this.props.links}

        <Menu.Menu position="right">
          <Menu.Item content={user} />
          <Menu.Item content={CONTENT_VIEW_TEXT.LOGOUT} onClick={this.onLogOut} />
        </Menu.Menu>
      </Menu>
    );
  }

  /** Warn about unsaved changes */
  public onLogOut(): void {
    if (this.props.upload.dataFile) {
      const logout: boolean = window.confirm(COMMON_TEXT.UNSAVED_CHANGES);
      return (logout) ? this.props.onLogOut() : null;
    }
    return this.props.onLogOut();
  }

  /** Block route transitions when there are non-saved data in Redux state */
  public getRoutePrompt(): JSX.Element {
    const message: (location: Location) => string | boolean = (location: Location): string | boolean => {
      if (this.props.location.pathname !== location.pathname && !!this.props.upload.dataFile) {
        return COMMON_TEXT.UNSAVED_CHANGES;
      }

      return true;
    };

    return <Prompt message={message} />;
  }

  public render(): JSX.Element {
    const content: JSX.Element = (this.props.error)
      ? <ErrorHandlerContainer grid />
      : this.props.routes;

    return (
      <Grid data-component="content-view">
        <Grid.Row>
          <Grid.Column>
            {this.getTopHeader()}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            {content}
            {this.getRoutePrompt()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export interface IContentViewProps {
  readonly error: boolean;
  readonly location: Location;
  readonly links: JSX.Element[];
  readonly routes: JSX.Element;
  readonly auth: IAuthTicket;
  readonly upload: IUploadState;
  onLogOut(): void;
}
