import * as React from 'react';
import { ITxConfigConnectProps } from './tx-config-container';
import { TxConfigView } from './tx-config-view';
import { IAppTxConfig, IAppTxMapping, IRiskFactor } from '../../../interfaces';
import { DataApi, IGetTabsAndFieldsResponse } from '../../../api';
import { DATA_LOAD_STAGE, TX_KEY } from '../../../constants';
import * as Actions from '../../../actions';
import { CONFIG_FIELD_TYPE } from '../../../constants/api';

/**
 * Taxonomy column mapping configuration
 */
export class TxConfig extends React.Component<ITxConfigConnectProps, ITxConfigState> {
  constructor(props: ITxConfigConnectProps) {
    super(props);
    this.state = {
      warning: false,
      dataReady: false
    };

    /* Wait for data to load before rendering */
    this.loadData();

    this.onMappingReset = this.onMappingReset.bind(this);
    this.onWarningChange = this.onWarningChange.bind(this);
    this.onSelfKeyMappingChange = this.onSelfKeyMappingChange.bind(this);
    this.onCustomTabMappingChange = this.onCustomTabMappingChange.bind(this);
    this.onCustomFieldMappingChange = this.onCustomFieldMappingChange.bind(this);
    this.onRiskFactorChange = this.onRiskFactorChange.bind(this);
  }

  public async loadData(): Promise<void> {
    const tabsAndFields: IGetTabsAndFieldsResponse =
      await DataApi.getTabsAndFields(this.props.upload.txResourceType).request();
    this.props.dispatch(Actions.Data.setData(tabsAndFields));
    const riskFactor: { [key: number]: IRiskFactor } =
      await DataApi.getRiskFactor(this.props.upload.txResourceType).request();
    this.props.dispatch(Actions.Data.setData({riskFactor}));
    this.setState({ dataReady: true });
  }

  public componentWillMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.CONFIGURATION));
  }

  /** Update config with new mapping for Taxonomy self field */
  public onSelfKeyMappingChange(colIndex: string, key: TX_KEY): void {
    /* Copy and update taxonomy config */
    const config: IAppTxConfig = {
      ...this.props.config.taxonomy,
      mapping: {
        ...this.props.config.taxonomy.mapping,
        [colIndex]: { key }
      }
    };
    this.props.dispatch(Actions.Config.setTxConfig(config));
  }

  /** Update config with new mapping for custom tab */
  public onCustomTabMappingChange(colIndex: string, tabId: string): void {
    /* Copy and update taxonomy config */
    const config: IAppTxConfig = {
      ...this.props.config.taxonomy,
      mapping: {
        ...this.props.config.taxonomy.mapping,
        [colIndex]: { tabId, fieldId: null }
      }
    };

    this.props.dispatch(Actions.Config.setTxConfig(config));
  }

  /** Update config with new mapping for custom tab */
  public onRiskFactorChange(colIndex: string, riskFactorId: number): void {
    const riskFactor: IRiskFactor = this.props.data.riskFactor[riskFactorId];
    /* Copy and update taxonomy config */
    const config: IAppTxConfig = {
      ...this.props.config.taxonomy,
      mapping: {
        ...this.props.config.taxonomy.mapping,
        [colIndex]: {
          riskFactorId: riskFactor.id,
          riskFactorScore: riskFactor.score,
          type: CONFIG_FIELD_TYPE.ASSESSMENT_SCORE
        }
      }
    };

    this.props.dispatch(Actions.Config.setTxConfig(config));
  }

  /** Update config with new mapping for custom field */
  public onCustomFieldMappingChange(colIndex: string, fieldId: string): void {
    /* Copy and update taxonomy config */
    const config: IAppTxConfig = {
      ...this.props.config.taxonomy,
      mapping: {
        ...this.props.config.taxonomy.mapping,
        [colIndex]: { ...this.props.config.taxonomy.mapping[colIndex], fieldId }
      }
    };

    this.props.dispatch(Actions.Config.setTxConfig(config));
  }

  /** Reset mapping when type changes */
  public onMappingReset(colIndex: string): void {
    /* Remove previous self or custom mapping from config if it exists */
    const mapping: { [key: string]: IAppTxMapping } = this.props.config.taxonomy.mapping;
    const previous: object = this.props.config.taxonomy.mapping[colIndex];
    if (previous !== null) {
      delete mapping[colIndex];
    }

    /* Copy and update taxonomy config */
    const config: IAppTxConfig = { ...this.props.config.taxonomy, mapping };

    this.onWarningChange(false);
    this.props.dispatch(Actions.Config.setTxConfig(config));
  }

  /** If any row contains invalid UI data (not config), set "warning" value */
  public onWarningChange(warning: boolean): void {
    this.setState({ warning });
  }

  public render(): JSX.Element {
    if (!this.props.config.taxonomy) { return null; }

    return (
      <TxConfigView
        data={this.props.data}
        columns={this.props.config.columns}
        warning={this.state.warning}
        dataReady={this.state.dataReady}
        appTxConfig={this.props.config.taxonomy}
        dataLoadAction={this.props.ui.dataLoadAction}
        matchByIndex={this.props.ui.matchByIndex}

        onMappingReset={this.onMappingReset}
        onWarningChange={this.onWarningChange}
        onSelfKeyMappingChange={this.onSelfKeyMappingChange}
        onCustomTabMappingChange={this.onCustomTabMappingChange}
        onCustomFieldMappingChange={this.onCustomFieldMappingChange}
        onRiskFactorChange={this.onRiskFactorChange}
      />
    );
  }
}

export interface ITxConfigState {
  readonly warning: boolean;
  readonly dataReady: boolean;
}
