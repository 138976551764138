import * as React from 'react';
import { IIncidentConfigActionsConnectProps } from './incident-config-actions-container';
import { ConfigActionsContainer } from '../../config/config-actions';
import { ITxConfig } from '../../../interfaces';
import { DataLoadApi } from '../../../api';
import { getApiTxConfig } from '../../../functions';

/**
 * Incidents "Check Configuration" action buttons
 */
export class IncidentConfigActions extends React.Component<IIncidentConfigActionsConnectProps> {
  constructor(props: IIncidentConfigActionsConnectProps) {
    super(props);
    this.uploadConfig = this.uploadConfig.bind(this);
  }

  /** Try to upload config on server for dry run */
  public uploadConfig(): Promise<{}> {
    const config: ITxConfig = getApiTxConfig({
      config: this.props.config.incidents,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadIncidentConfig({ config, columns: this.props.config.columns }).request();
  }

  public render(): JSX.Element {
    return (
      <ConfigActionsContainer
        warning={this.props.warning}
        uploadConfig={this.uploadConfig}
      />
    );
  }
}
