import { IUploadState } from '../interfaces';
import * as Actions from '../actions';

const initial: IUploadState = {
  dataFile: null,
  sheetIndex: 0,
  dataStartRow: null,
  dataEndRow: null,
  txResourceType: null,
  incidentType: null
};

export function upload(
  state: IUploadState = initial,
  action: Actions.Upload.ISetUploadInfo
): IUploadState {
  switch (action.type) {
    case Actions.Upload.SET_UPLOAD_INFO:
      return { ...state, ...action.options };
    case Actions.Batch.CLEAR_APP_STATE:
      return initial;
    default:
      return state;
  }
}
