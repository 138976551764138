import * as React from 'react';
import { Grid, SemanticWIDTHS } from 'semantic-ui-react';
import { IBasicDataUploadConnectProps } from './basic-data-upload-container';
import { DataOptionsContainer } from '../data-options';
import { DataRangeOptionsContainer } from '../data-range-options';
import { BasicDataUploadActionsContainer } from '../basic-data-upload-actions';
import { DATA_LOAD_STAGE } from '../../../constants';
import * as Actions from '../../../actions';
import './basic-data-upload.less';

/**
 * Basic data upload component combines only required data upload containers
 */
export class BasicDataUpload extends React.Component<IBasicDataUploadConnectProps> {
  constructor(props: IBasicDataUploadConnectProps) {
    super(props);
  }

  public componentWillMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_UPLOAD));
  }

  public render(): JSX.Element {
    const CONTENT_WIDTH: SemanticWIDTHS = 10;

    return (
      <Grid data-component="basic-data-upload">
        <Grid.Row columns="equal" centered>
          <Grid.Column width={CONTENT_WIDTH}>
            <DataOptionsContainer />
            {this.props.upload.dataFile ? <DataRangeOptionsContainer /> : null}
            {this.props.upload.dataFile ? <BasicDataUploadActionsContainer uploadFile={this.props.uploadFile} /> : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
