import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { ILConfigActions } from './il-config-actions';
import { IConfigState, IState, IUIState, IUploadState } from '../../../interfaces';

export interface IILConfigActionsProps {
  readonly warning: boolean;
}

export interface IILConfigActionsStateProps extends IILConfigActionsProps {
  readonly ui: IUIState;
  readonly upload: IUploadState;
  readonly config: IConfigState;
}

export type IILConfigActionsConnectProps = IILConfigActionsStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IILConfigActionsProps): IILConfigActionsStateProps {
  console.log('il-config-actions config', state.config);
  return {
    ui: state.ui,
    upload: state.upload,
    config: state.config,
    ...props
  };
}

export const ILConfigActionsContainer: React.ComponentClass<IILConfigActionsProps>
  = ReactRedux.connect(mapStateToProps)(ILConfigActions);
