import { IErrorDescription } from '../interfaces';
import { GENERAL_ERROR_MESSAGE_TEXT, LOG_IN_ERROR_MESSAGE_TEXT } from './strings';

/** All available error types */
export type ERROR_CODE = GENERAL_ERROR_CODE_TYPE | LOG_IN_ERROR_CODE_TYPE;

/** Application, API and HTTP error codes available for handling */
export enum GENERAL_ERROR_CODE_TYPE {
  /* Application errors */
  DEFAULT = 'DEFAULT',
  UNHANDLED_EXCEPTION = 'UNHANDLED_EXCEPTION',
  DATABASE_EXCEPTION = 'DATABASE_EXCEPTION',

  /* HTTP status errors */
  GENERAL_ERROR = 400,
  LOG_IN_DENIED = 401,
  AUTH_EXPIRED = 403,
  PAGE_NOT_FOUND = 404,
  DOMAIN_BUSY = 409,
  FILE_MAX_SIZE_EXCEEDED_HTTP = 413,
  FILE_CONVERSION_ERROR = 422,

  /* API response errors */
  EMPTY_FILE = 1001,
  MISSED_MANDATORY_FIELD_TAXONOMY = 1002,
  MISSED_MANDATORY_FIELD_INDICATOR = 1003,
  MISSED_CONFIGURATION_INDICATOR = 1004,
  ELEMENT_NAME_NOT_PRESENT = 1005,
  DB_FLUSH_FAILED = 1006,
  LOAD_STOPPED_BY_USER = 1007,
  DRY_RUN_WAS_CANCELLED = 1008,
  FILE_MAX_SIZE_EXCEEDED = 1009,
  INVALID_ROWS_CONFIGURATION = 1010,
  MAX_ROWS_NUMBER_EXCEEDED = 1011,
  MAX_FILENAME_LENGTH_EXCEEDED = 1012,
  FILE_STRUCTURE_WAS_CHANGED = 1013
}

/** Failed "Log In" request should have one of following reasons */
export enum LOG_IN_ERROR_CODE_TYPE {
  SUCCESS = 0,
  NO_PERMISSION = 1,
  EMPTY_CREDENTIALS = 2,
  LOCKED = 4,
  WRONG_LOGIN = 5,
  WRONG_PASSWORD = 6,
  PASSWORD_EXPIRED = 7,
  WARNING_PASSWORD_EXPIRED = 8,
  USER_EXPIRED = 9,
  MFA = 10,
  MFA_EXPIRE = 11,
  MFA_WRONG_KEY = 12,
  WRONG_TICKET_ERROR = 13,
  EXPIRED_TICKET_ERROR = 14,
  SSO = 100
}

/** Description objects for general errors */
export const GENERAL_ERROR_CODE_OPTIONS: { [key: string]: IErrorDescription } = {
  [GENERAL_ERROR_CODE_TYPE.DEFAULT]: {
    header: GENERAL_ERROR_MESSAGE_TEXT.UNHANDLED_HEADER,
    description: GENERAL_ERROR_MESSAGE_TEXT.UNHANDLED_DESCRIPTION
  },
  [GENERAL_ERROR_CODE_TYPE.UNHANDLED_EXCEPTION]: {
    header: GENERAL_ERROR_MESSAGE_TEXT.UNHANDLED_HEADER,
    description: GENERAL_ERROR_MESSAGE_TEXT.UNHANDLED_DESCRIPTION
  },
  [GENERAL_ERROR_CODE_TYPE.LOG_IN_DENIED]: {
    header: GENERAL_ERROR_MESSAGE_TEXT.LOG_IN_DENIED_HEADER,
    description: GENERAL_ERROR_MESSAGE_TEXT.LOG_IN_DENIED_DESCRIPTION
  },
  [GENERAL_ERROR_CODE_TYPE.PAGE_NOT_FOUND]: {
    header: GENERAL_ERROR_MESSAGE_TEXT.NOT_FOUND_HEADER,
    description: GENERAL_ERROR_MESSAGE_TEXT.NOT_FOUND_DESCRIPTION
  },
  [GENERAL_ERROR_CODE_TYPE.DOMAIN_BUSY]: {
    header: GENERAL_ERROR_MESSAGE_TEXT.DOMAIN_BUSY_HEADER,
    description: GENERAL_ERROR_MESSAGE_TEXT.DOMAIN_BUSY_DESCRIPTION
  },
  [GENERAL_ERROR_CODE_TYPE.AUTH_EXPIRED]: {
    header: GENERAL_ERROR_MESSAGE_TEXT.AUTH_DENIED_HEADER,
    description: GENERAL_ERROR_MESSAGE_TEXT.AUTH_DENIED_DESCRIPTION
  },
  [GENERAL_ERROR_CODE_TYPE.FILE_MAX_SIZE_EXCEEDED]: {
    header: GENERAL_ERROR_MESSAGE_TEXT.FILE_MAX_SIZE_EXCEEDED_HEADER,
    description: GENERAL_ERROR_MESSAGE_TEXT.FILE_MAX_SIZE_EXCEEDED_DESCRIPTION
  },
  [GENERAL_ERROR_CODE_TYPE.MAX_ROWS_NUMBER_EXCEEDED]: {
    header: GENERAL_ERROR_MESSAGE_TEXT.MAX_ROWS_NUMBER_EXCEEDED_HEADER,
    description: GENERAL_ERROR_MESSAGE_TEXT.MAX_ROWS_NUMBER_EXCEEDED_DESCRIPTION
  },
  [GENERAL_ERROR_CODE_TYPE.MAX_FILENAME_LENGTH_EXCEEDED]: {
    header: GENERAL_ERROR_MESSAGE_TEXT.MAX_FILENAME_LENGTH_EXCEEDED_HEADER,
    description: GENERAL_ERROR_MESSAGE_TEXT.MAX_FILENAME_LENGTH_EXCEEDED_DESCRIPTION
  },
  [GENERAL_ERROR_CODE_TYPE.FILE_STRUCTURE_WAS_CHANGED]: {
    header: GENERAL_ERROR_MESSAGE_TEXT.FILE_STRUCTURE_WAS_CHANGED_HEADER,
    description: GENERAL_ERROR_MESSAGE_TEXT.FILE_STRUCTURE_WAS_CHANGED_DESCRIPTION
  },
  [GENERAL_ERROR_CODE_TYPE.DATABASE_EXCEPTION]: {
    header: GENERAL_ERROR_MESSAGE_TEXT.DATABASE_EXCEPTION_HEADER,
    description: GENERAL_ERROR_MESSAGE_TEXT.DATABASE_EXCEPTION_DESCRIPTION
  }
};

/** Description objects for "Log In" request errors */
export const LOGIN_ERROR_CODE_OPTIONS: { [key: string]: IErrorDescription } = {
  [LOG_IN_ERROR_CODE_TYPE.NO_PERMISSION]: {
    header: LOG_IN_ERROR_MESSAGE_TEXT.NO_PERMISSION_HEADER,
    description: LOG_IN_ERROR_MESSAGE_TEXT.NO_PERMISSION_DESCRIPTION
  }
};
