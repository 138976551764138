import * as React from 'react';
import { IMAWorkflowConnectProps } from './ma-workflow-container';
import { Workflow } from '../../../components/workflow';
import { DataLoadApi } from '../../../api';
import { DATA_LOAD_STAGE } from '../../../constants';
import { MADataUploadContainer } from '../ma-data-upload';
import { MAConfigContainer } from '../ma-config';
import { MADataSaveContainer } from '../ma-data-save';
import * as Actions from '../../../actions';

/**
 * Monitoring Activity workflow manages transitions between data load flow components
 */
export class MAWorkflow extends React.Component<IMAWorkflowConnectProps> {
  public componentWillMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_UPLOAD_INITIAL));
  }

  public componentWillUnmount(): void {
    DataLoadApi.cancelDataLoad().request()
      .then((): void => {
        this.props.dispatch(Actions.Batch.clearAppState());
      })
      .catch((): void => {
        this.props.dispatch(Actions.Batch.clearAppState());
      });
  }

  public render(): JSX.Element {
    return (
      <Workflow
        stage={this.props.ui.stage}
        upload={<MADataUploadContainer />}
        config={<MAConfigContainer />}
        result={<MADataSaveContainer />}
      />
    );
  }
}
