import * as React from 'react';
import { IAILConfigConnectProps } from './ail-config-container';
import { AILConfigView } from './ail-config-view';
import { IAppAILConfig } from '../../../interfaces';
import { CONTROL_KEY, INDICATOR_KEY, PLAN_KEY } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Activity-Indicator Links column mapping configuration
 */
export class AILConfig extends React.Component<IAILConfigConnectProps, IAILConfigState> {
  constructor(props: IAILConfigConnectProps) {
    super(props);
    this.state = {
      warning: false
    };

    this.onWarningChange = this.onWarningChange.bind(this);
    this.onMappingReset = this.onMappingReset.bind(this);
    this.onActivityEventColMappingChange = this.onActivityEventColMappingChange.bind(this);
  }

  public onActivityEventColMappingChange(colIndex: string, key: PLAN_KEY | INDICATOR_KEY | CONTROL_KEY): void {
    const config: IAppAILConfig = {
      ...this.props.config.AIL,
      activityEventConfig: {
        ...this.props.config.AIL.activityEventConfig,
        [colIndex]: key
      }
    };

    this.props.dispatch(Actions.Config.setAILConfig(config));
  }

  public onMappingReset(colIndex: string): void {
    const activityEventConfig: { [key: string]: PLAN_KEY | INDICATOR_KEY | CONTROL_KEY }
      = this.props.config.AIL.activityEventConfig;
    delete activityEventConfig[colIndex];

    const config: IAppAILConfig = {
      ...this.props.config.AIL,
      activityEventConfig,
    };

    this.props.dispatch(Actions.Config.setAILConfig(config));
  }

  public onWarningChange(warning: boolean): void {
    this.setState({ warning });
  }

  public showConfigMapping(): boolean {
    return !!this.props.ui.libraryType && !!this.props.ui.matchPlanKey
      && !!this.props.ui.matchActivityKey && !!this.props.ui.matchIndicatorKey;
  }

  public render(): JSX.Element {
    if (!this.props.config.AIL) { return null; }

    return (
      <AILConfigView
        columns={this.props.config.columns}
        warning={this.state.warning}
        optionsReady={this.showConfigMapping()}
        appAILConfig={this.props.config.AIL}

        onWarningChange={this.onWarningChange}
        onMappingReset={this.onMappingReset}
        onActivityEventColMappingChange={this.onActivityEventColMappingChange}
      />
    );
  }
}

export interface IAILConfigState {
  readonly warning: boolean;
}
