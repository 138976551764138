import * as Redux from 'redux';

export const CLEAR_APP_STATE: string = 'CLEAR_APP_STATE';
export const GO_TO_UPLOAD_STEP: string = 'GO_TO_UPLOAD_STEP';
export const GO_TO_CONFIG_STEP: string = 'GO_TO_CONFIG_STEP';
export const GO_TO_DATA_SAVE_STEP: string = 'GO_TO_DATA_SAVE_STEP';

export type IGoToUploadStep = Redux.Action;
export type IGoToConfigStep = Redux.Action;
export type IGoToDataSaveStep = Redux.Action;
export type IClearAppState = Redux.Action;

/** Clear all information about current data load */
export function clearAppState(): IClearAppState {
  return { type: CLEAR_APP_STATE };
}

/** Clear all information except file upload */
export function goToUploadStep(): IGoToUploadStep {
  return { type: GO_TO_UPLOAD_STEP };
}

/** Clear information about dry run and data save results */
export function goToConfigStep(): IGoToConfigStep {
  return { type: GO_TO_CONFIG_STEP };
}

/** Clear information about data save result */
export function goToDataSaveStep(): IGoToDataSaveStep {
  return { type: GO_TO_DATA_SAVE_STEP };
}
