import { PLAN_KEY } from './pi';
import { INDICATOR_KEY } from './indicators';
import { CONTROL_KEY } from './mitigation-activity';

export enum AIL_MAPPING {
  PLAN_ID = 'PLAN_ID',
  INDICATOR_ID = 'INDICATOR_ID',
  CONTROL_ID = 'CONTROL_ID'
}

export const AIL_MAPPING_OPTIONS: { [key: string]: string } = {
  [AIL_MAPPING.PLAN_ID]: 'Plan Identifier',
  [AIL_MAPPING.INDICATOR_ID]: 'Indicator Identifier',
  [AIL_MAPPING.CONTROL_ID]: 'Control Field'
};

export const AIL_PLAN_ID_OPTIONS: { [key: string]: string } = {
  [PLAN_KEY.PLAN_ID]: 'Plan ID',
  [PLAN_KEY.PLAN_NAME]: 'Plan Name'
};

export const AIL_INDICATOR_ID_OPTIONS: { [key: string]: string } = {
  [INDICATOR_KEY.INDICATOR_ID]: 'Indicator ID',
  [INDICATOR_KEY.INDICATOR]: 'Indicator Name',
  [INDICATOR_KEY.FACTOR]: 'Factor Name',
  [INDICATOR_KEY.CATEGORY]: 'Category Name'
};

export const AIL_ACTIVITY_ID_OPTIONS: { [key: string]: string } = {
  [CONTROL_KEY.CONTROL_ID]: 'Control ID',
  [CONTROL_KEY.CONTROL_NAME]: 'Control Name'
};
