import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { BasicDataUploadActions } from './basic-data-upload-actions';
import { IState, IUploadState } from '../../../interfaces';
import { IUploadBasicFileOptions } from '../../../api';

export interface IBasicDataUploadActionsProps {
  uploadFile(options: IUploadBasicFileOptions): Promise<{}>;
}

export interface IBasicDataUploadActionsStateProps extends IBasicDataUploadActionsProps {
  readonly upload: IUploadState;
}

export type IBasicDataUploadActionsConnectProps = IBasicDataUploadActionsStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IBasicDataUploadActionsProps): IBasicDataUploadActionsStateProps {
  return { upload: state.upload, ...props };
}

export const BasicDataUploadActionsContainer: React.ComponentClass<IBasicDataUploadActionsProps>
  = ReactRedux.connect(mapStateToProps)(BasicDataUploadActions);
