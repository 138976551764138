import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { MatchActivityKeyOptions } from './match-activity-key-options';
import { IState, IUIState } from '../../../interfaces';

export interface IMatchActivityKeyOptionsStateProps {
  readonly ui: IUIState;
}

export type IMatchActivityKeyOptionsConnectProps = IMatchActivityKeyOptionsStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IMatchActivityKeyOptionsStateProps {
  return { ui: state.ui };
}

export const MatchActivityKeyOptionsContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(MatchActivityKeyOptions);
