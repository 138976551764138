import * as React from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { IEmptyCellOptionsConnectProps } from './empty-cell-options-container';
import { EmptyCellOptionsView } from './empty-cell-options-view';
import { EMPTY_CELLS_ACTION } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Action for empty cells configuration option
 */
export class EmptyCellOptions extends React.Component<IEmptyCellOptionsConnectProps> {
  constructor(props: IEmptyCellOptionsConnectProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  /** Update UI reducer and call parent callback */
  public onChange(e: React.SyntheticEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.props.dispatch(Actions.UI.setUIData({ emptyCellsAction: changes.value as EMPTY_CELLS_ACTION }));
    if (this.props.onChange) {
      this.props.onChange(changes.value as EMPTY_CELLS_ACTION);
    }
  }

  public render(): JSX.Element {
    return (
      <EmptyCellOptionsView
        value={this.props.ui.emptyCellsAction}
        onChange={this.onChange}
      />
    );
  }
}
