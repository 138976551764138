import {
  IActivityConfig, IAILConfig, IFieldData, IIndicatorConfig, IIPLConfig, IILConfig, IPIConfig, ITabData, ITxConfig
} from '../interfaces';
import { MA_TYPE, TX_RESOURCE_TYPE } from '../constants';
import { IMAConfig, IUserManagementConfig } from '../interfaces/config';

/** Options for parsing "Response" object */
export type GetDataOptions = 'arrayBuffer' | 'blob' | 'formData' | 'json' | 'text';

export enum HTTP_METHOD {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE'
}

export interface IUrlObject<Query = {}, Params = {}, Body = {}> {
  readonly url: string;
  readonly method?: HTTP_METHOD;
  readonly getDataAs?: GetDataOptions;
  readonly simple?: boolean;

  readonly params?: Params;
  readonly query?: Query;
  readonly headers?: { [key: string]: string } | Headers;
  readonly body?: Body;
}

/** Description of all server API request urls */
export interface IApiUrlConfig {
  readonly auth: {
    readonly login: IUrlObject;
    readonly logout: IUrlObject;
  };
  readonly data: {
    readonly tabs: IUrlObject;
    readonly riskFactor: IUrlObject;
    readonly incidents: IUrlObject;
    readonly incidentTabs: IUrlObject;
  };
  readonly common: {
    readonly version: IUrlObject;
    readonly internal: IUrlObject;
  };
  readonly dataLoad: {
    readonly updateFile: IUrlObject;
    readonly checkStatus: IUrlObject;
    readonly checkLogs: IUrlObject;
    readonly cancelDataLoad: IUrlObject;
    readonly makeDataLoad: IUrlObject;
  };
  readonly taxonomy: {
    readonly upload: IUrlObject;
    readonly dryRun: IUrlObject;
  };
  readonly PI: {
    readonly upload: IUrlObject;
    readonly dryRun: IUrlObject;
  };
  readonly indicators: {
    readonly upload: IUrlObject;
    readonly dryRun: IUrlObject;
  };
  readonly IPL: {
    readonly upload: IUrlObject;
    readonly dryRun: IUrlObject;
  };
  readonly IL: {
    readonly upload: IUrlObject;
    readonly dryRun: IUrlObject;
  };
  readonly incidents: {
    readonly upload: IUrlObject;
    readonly dryRun: IUrlObject;
  };
  readonly activity: {
    readonly upload: IUrlObject;
    readonly dryRun: IUrlObject;
  };
  readonly AIL: {
    readonly upload: IUrlObject;
    readonly dryRun: IUrlObject;
  };
  readonly userManagement: {
    readonly users: {
      readonly upload: IUrlObject;
      readonly dryRun: IUrlObject;
    };
  };
  readonly MA: {
    readonly upload: IUrlObject;
    readonly dryRun: IUrlObject;
  };
  readonly history: {
    readonly dataLoadList: IUrlObject;
    readonly loadLogFile: IUrlObject;
    readonly loadDataFile: IUrlObject;
  };
}

/** Templates for all server API request urls */
export const API_URL_CONFIG: IApiUrlConfig = {
  auth: {
    login: { url: '/login', method: HTTP_METHOD.POST, getDataAs: 'json' },
    logout: { url: '/lm-logout', method: HTTP_METHOD.POST, getDataAs: 'text' }
  },
  data: {
    tabs: { url: '/get-resource-tabs/[type]', method: HTTP_METHOD.GET, getDataAs: 'json' },
    riskFactor: { url: '/get-resource-assessment-weights/[type]', method: HTTP_METHOD.GET, getDataAs: 'json' },
    incidents: { url: '/get-incident-types', method: HTTP_METHOD.GET, getDataAs: 'json' },
    incidentTabs: { url: '/get-incident-tabs/[id]', method: HTTP_METHOD.GET, getDataAs: 'json' }
  },
  common: {
    version: { url: '/version', method: HTTP_METHOD.GET, getDataAs: 'json' },
    internal: { url: '/internal', method: HTTP_METHOD.GET, getDataAs: 'json' }
  },
  dataLoad: {
    updateFile: { url: '/update-file', method: HTTP_METHOD.POST, getDataAs: 'json', simple: true },
    checkStatus: { url: '/check-status', method: HTTP_METHOD.POST, getDataAs: 'json' },
    checkLogs: { url: './check-logs', method: HTTP_METHOD.POST, getDataAs: 'json' },
    cancelDataLoad: { url: '/cancel-load', method: HTTP_METHOD.POST, getDataAs: 'text' },
    makeDataLoad: { url: '/make-data-load/[confirm]', method: HTTP_METHOD.POST, getDataAs: 'text' }
  },
  taxonomy: {
    upload: { url: '/upload-taxonomy', method: HTTP_METHOD.POST, getDataAs: 'json', simple: true },
    dryRun: { url: '/validate-configuration-for-taxonomy', method: HTTP_METHOD.POST, getDataAs: 'text' }
  },
  PI: {
    upload: { url: '/upload-plan-indicator', method: HTTP_METHOD.POST, getDataAs: 'json', simple: true },
    dryRun: { url: '/validate-configuration-for-plan-indicator', method: HTTP_METHOD.POST, getDataAs: 'text' }
  },
  indicators: {
    upload: { url: '/upload-indicators', method: HTTP_METHOD.POST, getDataAs: 'json', simple: true },
    dryRun: { url: '/validate-configuration-for-indicators', method: HTTP_METHOD.POST, getDataAs: 'text' }
  },
  IPL: {
    upload: { url: '/upload-indicator-process-links', method: HTTP_METHOD.POST, getDataAs: 'json', simple: true },
    dryRun: { url: '/validate-configuration-for-indicator-process-links', method: HTTP_METHOD.POST, getDataAs: 'text' }
  },
  IL: {
    upload: { url: '/upload-indicator-indicator-links', method: HTTP_METHOD.POST, getDataAs: 'json', simple: true },
    dryRun: {
      url: '/validate-configuration-for-indicator-indicator-links',
      method: HTTP_METHOD.POST,
      getDataAs: 'text'
    }
  },
  incidents: {
    upload: { url: '/upload-incidents', method: HTTP_METHOD.POST, getDataAs: 'json', simple: true },
    dryRun: { url: '/validate-configuration-for-incidents', method: HTTP_METHOD.POST, getDataAs: 'text' }
  },
  activity: {
    upload: { url: '/upload-mitigation-activity', method: HTTP_METHOD.POST, getDataAs: 'json', simple: true },
    dryRun: { url: '/validate-configuration-for-mitigation-activity', method: HTTP_METHOD.POST, getDataAs: 'json' }
  },
  AIL: {
    upload: { url: '/upload-mitigation-indicator-links', method: HTTP_METHOD.POST, getDataAs: 'json', simple: true },
    dryRun: {
      url: '/validate-configuration-for-mitigation-indicator-links',
      method: HTTP_METHOD.POST,
      getDataAs: 'json'
    }
  },
  userManagement: {
    users: {
      upload: { url: '/user-management/upload-users', method: HTTP_METHOD.POST, getDataAs: 'json', simple: true },
      dryRun: { url: '/user-management/validate-configuration-for-users', method: HTTP_METHOD.POST, getDataAs: 'json' }
    }
  },
  MA: {
    upload: { url: '/upload-monitoring-activity', method: HTTP_METHOD.POST, getDataAs: 'json', simple: true },
    dryRun: { url: '/validate-configuration-for-monitoring-activity', method: HTTP_METHOD.POST, getDataAs: 'json' }
  },
  history: {
    dataLoadList: { url: '/loads-history', method: HTTP_METHOD.GET, getDataAs: 'json' },
    loadLogFile: { url: '/load-log/[id]', method: HTTP_METHOD.GET, getDataAs: 'blob' },
    loadDataFile: { url: '/load-excel/[id]', method: HTTP_METHOD.GET, getDataAs: 'blob' },
  }
};

export interface IGetTabsAndFieldsResponse {
  readonly tabs: ITabData[];
  readonly fields: IFieldData[];
}

export interface IUploadBasicFileOptions {
  readonly dataFile: File;
  readonly sheetIndex: number;
  readonly startRow?: number;
  readonly endRow?: number;
}

export interface IUploadTxFileOptions extends IUploadBasicFileOptions {
  readonly txResourceType: TX_RESOURCE_TYPE;
}

export interface IUploadIncidentFileOptions extends IUploadBasicFileOptions {
  readonly testId: string;
}

export interface IUploadMAFileOptions extends IUploadBasicFileOptions {
  readonly maType: MA_TYPE;
}

export type ITxConfigOptions = IBasicConfigOptions<ITxConfig>;
export type IPIConfigOptions = IBasicConfigOptions<IPIConfig>;
export type IIndicatorConfigOptions = IBasicConfigOptions<IIndicatorConfig>;
export type IIPLConfigOptions = IBasicConfigOptions<IIPLConfig>;
export type IILConfigOptions = IBasicConfigOptions<IILConfig>;
export type IIncidentConfigOptions = IBasicConfigOptions<ITxConfig>;
export type IActivityConfigOptions = IBasicConfigOptions<IActivityConfig>;
export type IAILConfigOptions = IBasicConfigOptions<IAILConfig>;
export type IUserManagementConfigOptions = IBasicConfigOptions<IUserManagementConfig>;
export type IMAConfigOptions = IBasicConfigOptions<IMAConfig>;

export interface IBasicConfigOptions<T = {}> {
  readonly config: T;
  readonly columns: { [key: number]: string };
}

export interface IUpdateFileResponse {
  readonly sheetIndex: number;
  readonly dataStartRow: number;
  readonly dataEndRow: number;
}
