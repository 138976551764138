export enum IL_MAPPING {
  INDICATOR_ID = 'INDICATOR_ID',
  INDICATOR_ID_2 = 'INDICATOR_ID_2',
}

/** Options with captions for "Mapping Type" */
export const IL_MAPPING_OPTIONS: { [key: string]: string } = {
  [IL_MAPPING.INDICATOR_ID]: 'Source Indicator Field',
  [IL_MAPPING.INDICATOR_ID_2]: 'Destination Indicator Field',
};

export enum IL_KEY {
  INDICATOR_ID = 'event_id',
  INDICATOR = 'event',
  FACTOR = 'factor',
  CATEGORY = 'category',
  INDICATOR_ID_2 = 'event_id_2',
  INDICATOR_2 = 'event_2',
  FACTOR_2 = 'factor_2',
  CATEGORY_2 = 'category_2'
}

export const IL_SOURCE_INDICATOR_ID_OPTIONS: { [key: string]: string } = {
  [IL_KEY.INDICATOR_ID]: 'Indicator ID',
  [IL_KEY.INDICATOR]: 'Indicator Name',
  [IL_KEY.FACTOR]: 'Factor Name',
  [IL_KEY.CATEGORY]: 'Category Name',
};

export const IL_DESTINATION_INDICATOR_ID_OPTIONS: { [key: string]: string } = {
  [IL_KEY.INDICATOR_ID_2]: 'Indicator ID',
  [IL_KEY.INDICATOR_2]: 'Indicator Name',
  [IL_KEY.FACTOR_2]: 'Factor Name',
  [IL_KEY.CATEGORY_2]: 'Category Name',
};
