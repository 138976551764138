import * as React from 'react';
import { ITxDataSaveConnectProps } from './tx-data-save-container';
import { DataSaveContainer } from '../../data-save';
import { DataLoadApi } from '../../../api';
import { ITxConfig } from '../../../interfaces';
import { DATA_LOAD_TYPE } from '../../../constants';
import { getApiTxConfig } from '../../../functions';

/**
 * Wrapper around "Data Save" component for Taxonomy
 */
export class TxDataSave extends React.Component<ITxDataSaveConnectProps> {
  constructor(props: ITxDataSaveConnectProps) {
    super(props);
    this.onRetry = this.onRetry.bind(this);
  }

  public onRetry(): Promise<{}> {
    const config: ITxConfig = getApiTxConfig({
      config: this.props.config.taxonomy,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadTxConfig({ config, columns: this.props.config.columns }).request();
  }

  public render(): JSX.Element {
    if (!this.props.config.taxonomy) { return null; }

    const nRows: number = this.props.config.taxonomy.dataEndRow - this.props.config.taxonomy.dataStartRow;

    return (
      <DataSaveContainer
        nRows={nRows}
        dataLoadType={DATA_LOAD_TYPE.TAXONOMY}
        onRetry={this.onRetry}
      />
    );
  }
}
