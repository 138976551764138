import * as React from 'react';
import { Grid, Message, SemanticWIDTHS } from 'semantic-ui-react';
import { IDataLoadResult, IErrorDescription } from '../../interfaces';
import {
  ERROR_CODE, GENERAL_ERROR_CODE_OPTIONS, GENERAL_ERROR_CODE_TYPE, LOGIN_ERROR_CODE_OPTIONS
} from '../../constants';
import './error-handler-view.less';
import { format } from '../../functions';
import isNil from 'lodash/isNil';

/**
 * View for "Error Handler" component
 */
export class ErrorHandlerView extends React.Component<IErrorHandlerViewProps> {
  constructor(props: IErrorHandlerViewProps) {
    super(props);
  }

  /** Display message in a grid component */
  public getGrid(): JSX.Element {
    const ERROR_MESSAGE_WIDTH: SemanticWIDTHS = 6;

    return (
      <Grid data-component="error-handler-view">
        <Grid.Row columns={1} centered>
          <Grid.Column width={ERROR_MESSAGE_WIDTH}>
            {this.getMessage()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  /** Message with icon, header and description */
  public getMessage(): JSX.Element {
    const obj: IErrorDescription = GENERAL_ERROR_CODE_OPTIONS[this.props.code]
      || LOGIN_ERROR_CODE_OPTIONS[this.props.code]
      || GENERAL_ERROR_CODE_OPTIONS[GENERAL_ERROR_CODE_TYPE.DEFAULT];

    const description: string = (!this.props.list)
      ? (this.props.code === GENERAL_ERROR_CODE_TYPE.DATABASE_EXCEPTION
          && !isNil(this.props.dataloadResult) && !isNil(this.props.dataloadResult.currentRowNumber))
        ? format(obj.description, {rowNumber: this.props.dataloadResult.currentRowNumber})
        : obj.description
      : undefined;

    return (
      <Message
        data-component="error-handler-view"
        list={this.props.list}
        header={this.props.header || obj.header}
        content={this.props.description || description}
        onDismiss={this.props.onDismiss}
        icon="warning sign"
        size="tiny"
        error
      />
    );
  }

  public render(): JSX.Element {
    return (this.props.grid) ? this.getGrid() : this.getMessage();
  }
}

export interface IErrorHandlerViewProps {
  readonly code?: ERROR_CODE;
  readonly list?: string[];
  readonly header?: string;
  readonly description?: string;
  readonly dataloadResult?: IDataLoadResult;
  /** Render component as Semantic Grid */
  readonly grid?: boolean;
  onDismiss?(): void;
}
