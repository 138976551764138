import * as React from 'react';
import {
  Dropdown, DropdownItemProps, DropdownProps, Icon, SemanticICONS, SemanticWIDTHS, Table
} from 'semantic-ui-react';
import { CustomMappingOptions } from '../custom-mapping-options';
import { TxSelfMappingOptions } from '../tx-self-mapping-options';
import { RiskFactorOptions } from '../../risk-factor-options';
import { IAppTxConfig, IAppTxMapping, IFieldData, IRiskFactor, ITabData } from '../../../interfaces';
import {
  CONFIG_FIELD_TYPE,
  CONFIG_VIEW_TEXT,
  NO_MAPPING_OPTION,
  TX_ELEMENT_FIELD_OPTIONS,
  TX_ELEMENT_FIELD_OPTIONS_BUSINESS_PROCESS,
  TX_ELEMENT_PARENT_FIELD_OPTIONS,
  TX_KEY,
  TX_MAPPING,
  TX_MAPPING_OPTIONS
} from '../../../constants';
import { format } from '../../../functions';
import './tx-mapping-options.less';

/**
 * Column mapping configuration options for Taxonomy
 */
export class TxMappingOptions extends React.Component<ITxMappingOptionsProps, ITxMappingOptionsState> {
  public readonly DEFAULT_MAPPING_OPTION: string = NO_MAPPING_OPTION.key;
  public readonly txMappingOptions: DropdownItemProps[];

  constructor(props: ITxMappingOptionsProps) {
    super(props);

    const defaultValue: string = this.getDefaultType();
    this.state = {
      txType: defaultValue,
      warning: false
    };

    this.txMappingOptions = this.getTxMappingOptions();
    this.onWarningChange = this.onWarningChange.bind(this);
    this.onTxMappingChange = this.onTxMappingChange.bind(this);
  }

  /** Ignore changes in other rows */
  public shouldComponentUpdate(props: ITxMappingOptionsProps, state: ITxMappingOptionsState): boolean {
    return this.props.appTxConfig.mapping[this.props.colIndex] !== props.appTxConfig.mapping[this.props.colIndex]
      || this.state !== state;
  }

  /** Set Taxonomy mapping type for automapped values */
  public getDefaultType(): string {
    let txType: string = this.DEFAULT_MAPPING_OPTION;
    const obj: IAppTxMapping = this.props.appTxConfig.mapping[this.props.colIndex];

    if (!obj) {
      return txType;
    }

    // Self Mapping
    if (obj.key) {
      if (TX_ELEMENT_FIELD_OPTIONS[obj.key] || TX_ELEMENT_FIELD_OPTIONS_BUSINESS_PROCESS[obj.key]) {
        txType = TX_MAPPING.ELEMENT_FIELD;
      }
      if (TX_ELEMENT_PARENT_FIELD_OPTIONS[obj.key]) { txType = TX_MAPPING.ELEMENT_PARENT_FIELD; }
    }

    // Custom field mapping (Profile field)
    if (obj.tabId && obj.fieldId) {
      txType = TX_MAPPING.CUSTOM_FIELD;
    }

    // Assessment and Risk Factor
    if (obj.type === CONFIG_FIELD_TYPE.ASSESSMENT_SCORE) {
      txType = TX_MAPPING.RISK_FACTOR;
    }

    return txType;
  }

  /** Taxonomy mapping options are static */
  public getTxMappingOptions(): DropdownItemProps[] {
    const options: DropdownItemProps[] = [];
    const keys: string[] = Object.keys(TX_MAPPING);

    for (let i: number = 0; i < keys.length; i++) {
      options.push({ value: TX_MAPPING[keys[i]], text: TX_MAPPING_OPTIONS[keys[i]] });
    }

    options.push({ text: NO_MAPPING_OPTION.caption, value: NO_MAPPING_OPTION.key });
    return options;
  }

  /** Get dropdown for selecting mapping type (element field, element parent field, etc.) */
  public getTxMappingSelect(): JSX.Element {
    return (
      <Dropdown
        options={this.txMappingOptions}
        value={this.state.txType}
        onChange={this.onTxMappingChange}
        selectOnBlur={false}
        selection
        search
        fluid
      />
    );
  }

  /** Update own state and notify parent about mapping type change */
  public onTxMappingChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.setState({ txType: changes.value as string });

    if (this.state.txType !== changes.value as string) {
      this.onWarningChange(true);
      this.props.onMappingReset(this.props.colIndex);
    }

    if (changes.value as string === this.DEFAULT_MAPPING_OPTION) {
      this.setState({ warning: false });
    }
  }

  /** Warning state shows or hides error sign for current row */
  public onWarningChange(warning: boolean): void {
    this.setState({ warning });
    this.props.onWarningChange(warning);
  }

  /** Get additional options based on selected mapping type */
  public getAdditionalOptions(): JSX.Element {
    const showUniqueOptions: boolean = this.state.txType === TX_MAPPING.ELEMENT_FIELD
      || this.state.txType === TX_MAPPING.ELEMENT_PARENT_FIELD;

    if (showUniqueOptions) {
      return (
        <TxSelfMappingOptions
          colIndex={this.props.colIndex}
          txType={this.state.txType as TX_MAPPING}
          appTxConfig={this.props.appTxConfig}
          onWarningChange={this.onWarningChange}
          onSelfKeyMappingChange={this.props.onSelfKeyMappingChange}
        />
      );
    }

    if (this.state.txType === TX_MAPPING.CUSTOM_FIELD) {
      return (
        <CustomMappingOptions
          colIndex={this.props.colIndex}
          tabs={this.props.tabs}
          fields={this.props.fields}
          config={this.props.appTxConfig.mapping}

          onWarningChange={this.onWarningChange}
          onCustomTabMappingChange={this.props.onCustomTabMappingChange}
          onCustomFieldMappingChange={this.props.onCustomFieldMappingChange}
        />
      );
    }

    if (this.state.txType === TX_MAPPING.RISK_FACTOR) {
      // TODO: change custom mapping component (split on 2 small components) ?
      return (
        <RiskFactorOptions
          riskFactor={this.props.riskFactor}
          colIndex={this.props.colIndex}
          config={this.props.appTxConfig.mapping}
          onRiskFactorTabChange={this.props.onRiskFactorChange}
          onWarningChange={this.onWarningChange}
        />
      );
    }

    return null;
  }

  /** Get column formatting for consistent layout based on current state */
  public getFormatting(): JSX.Element {
    if (this.state.txType === this.DEFAULT_MAPPING_OPTION) {
      return (
        <React.Fragment>
          <Table.Cell key="placeholder-a" />
          <Table.Cell key="placeholder-b" />
        </React.Fragment>
      );
    }

    if (this.state.txType !== TX_MAPPING.CUSTOM_FIELD) {
      return <Table.Cell key="placeholder-a" />;
    }

    return null;
  }

  /**
   * Get status for column mapping (done, empty or warning)
   * Empty rows are allowed, the ones with warning are not
   */
  public getStatus(): JSX.Element {
    const STATUS_CELL_WIDTH: SemanticWIDTHS = 1;
    const empty: boolean = this.state.txType === this.DEFAULT_MAPPING_OPTION;
    const icon: SemanticICONS = (empty)
      ? 'warning'
      : (this.state.warning)
        ? 'warning sign'
        : 'checkmark';

    return (
      <Table.Cell
        textAlign="center"
        negative={!empty && this.state.warning}
        positive={!empty && !this.state.warning}
        width={STATUS_CELL_WIDTH}
      >
        <Icon name={icon} />
      </Table.Cell>
    );
  }

  public render(): JSX.Element {
    const name: string = this.props.name
      || format(CONFIG_VIEW_TEXT.COLUMN_INDEX, { index: this.props.colIndex + 1 });

    return (
      <Table.Row data-component="tx-mapping-options">
        {this.getStatus()}
        <Table.Cell>{name}</Table.Cell>

        <Table.Cell>
          {this.getTxMappingSelect()}
        </Table.Cell>

        {this.getAdditionalOptions()}
        {this.getFormatting()}
      </Table.Row>
    );
  }
}

export interface ITxMappingOptionsState {
  /** Can be either TX_MAPPING_TYPE or NO_MAPPING */
  readonly txType: string;
  /** Used to display row warnings or errors */
  readonly warning: boolean;
}

export interface ITxMappingOptionsProps {
  readonly name: string;
  readonly colIndex: string;
  readonly appTxConfig: IAppTxConfig;
  readonly tabs: { [key: number]: ITabData };
  readonly fields: { [key: number]: IFieldData };
  readonly riskFactor: { [key: number]: IRiskFactor };

  onMappingReset(colIndex: string): void;
  onWarningChange(warning: boolean): void;
  onSelfKeyMappingChange(colIndex: string, key: TX_KEY): void;
  onCustomTabMappingChange(colIndex: string, tabId: string): void;
  onCustomFieldMappingChange(colIndex: string, fieldId: string): void;
  onRiskFactorChange(colIndex: string, riskFactorId: number): void;
}
