/** Data is saved in local storage as key => JSON string */
export const LOGIC_MANAGER_LOCAL_STORAGE_KEY: string = 'logicmanager';
export const MAX_DATA_ROWS_ALLOWED: number = 2000;

/** Special option for dropdowns that resets mapping for columns */
export const NO_MAPPING_OPTION: { key: string, caption: string } = {
  key: 'NO_MAPPING',
  caption: 'No Mapping'
};
/** Special default user-friendly option for dropdowns */
export const NO_MAPPING_BLANK_OPTION: { key: string, caption: string } = {
  key: 'BLANK_OPTION',
  caption: ''
};

/** Describes stages (upload, config, dry run, etc.) of data load process */
export enum DATA_LOAD_STAGE {
  DATA_UPLOAD_INITIAL = 'DATA_UPLOAD_INITIAL',
  DATA_UPLOAD = 'DATA_UPLOAD',
  DATA_UPLOAD_COMPLETE = 'DATA_UPLOAD_COMPLETE',

  CONFIGURATION_INITIAL = 'CONFIGURATION_INITIAL',
  CONFIGURATION = 'CONFIGURATION',
  CONFIGURATION_COMPLETE = 'CONFIGURATION_COMPLETE',

  DRY_RUN_INITIAL = 'DRY_RUN_INITIAL',
  DRY_RUN_IN_PROGRESS = 'DRY_RUN_IN_PROGRESS',
  DRY_RUN_COMPLETE = 'DRY_RUN_COMPLETE',
  DRY_RUN_FAILED = 'DRY RUN FAILED',

  DATA_SAVE_INITIAL = 'DATA_SAVE_INITIAL',
  DATA_SAVE_IN_PROGRESS = 'DATA_SAVE_IN_PROGRESS',
  DATA_SAVE_FAILED = 'DATA_SAVE_FAILED',
  DATA_SAVE_COMPLETE = 'DATA_SAVE_COMPLETE'
}

/** Generic data load workflow steps */
export enum WORKFLOW_STEP {
  INIT = 'INIT',
  UPLOAD = 'UPLOAD',
  CONFIG = 'CONFIG',
  RESULT = 'RESULT',
  DONE = 'DONE'
}

/** Data load types available in application */
export enum DATA_LOAD_TYPE {
  TAXONOMY = 'TAXONOMY',
  PLAN_INDICATOR = 'PLAN_INDICATOR',
  INDICATOR_LIBRARY = 'INDICATOR_LIBRARY',
  INDICATOR_PROCESS_LINK = 'INDICATOR_PROCESS_LINK',
  INDICATOR_INDICATOR_LINK = 'INDICATOR_INDICATOR_LINK',
  INCIDENT = 'INCIDENT',
  CONTROL = 'ACTIVITY',
  ACTIVITY_INDICATOR_LINK = 'MITIGATION_INDICATOR_LINK',
  USER_MANAGEMENT = 'USER',
  CATEGORY = 'CATEGORY',
  FACTOR = 'FACTOR',
  MONITORING_ACTIVITY = 'MONITORING_ACTIVITY'
}

/** Semantic UI sort direction values */
export enum SORT_DIRECTION {
  ASC = 'ascending',
  DESC = 'descending'
}
