import * as React from 'react';
import { IIncidentDataSaveConnectProps } from './incident-data-save-container';
import { DataSaveContainer } from '../../data-save';
import { DataLoadApi } from '../../../api';
import { ITxConfig } from '../../../interfaces';
import { DATA_LOAD_TYPE } from '../../../constants';
import { getApiTxConfig } from '../../../functions';

/**
 * Wrapper around "Data Save" component for Incidents
 */
export class IncidentDataSave extends React.Component<IIncidentDataSaveConnectProps> {
  constructor(props: IIncidentDataSaveConnectProps) {
    super(props);
    this.onRetry = this.onRetry.bind(this);
  }

  /** Re-send "Check Config" request */
  public onRetry(): Promise<{}> {
    const config: ITxConfig = getApiTxConfig({
      config: this.props.config.incidents,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadIncidentConfig({ config, columns: this.props.config.columns }).request();
  }

  public render(): JSX.Element {
    if (!this.props.config.incidents) { return null; }

    const nRows: number = this.props.config.incidents.dataEndRow - this.props.config.incidents.dataStartRow;
    return (
      <DataSaveContainer
        nRows={nRows}
        dataLoadType={DATA_LOAD_TYPE.INCIDENT}
        onRetry={this.onRetry}
      />
    );
  }
}
