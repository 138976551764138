import * as React from 'react';
import { IAILConfigActionsConnectProps } from './ail-config-actions-container';
import { ConfigActionsContainer } from '../../config/config-actions';
import { DataLoadApi } from '../../../api';
import { IAILConfig } from '../../../interfaces';
import { getApiAILConfig } from '../../../functions';

/**
 * Activity-Indicator Links "Check Configuration" action buttons
 */
export class AILConfigActions extends React.Component<IAILConfigActionsConnectProps> {
  constructor(props: IAILConfigActionsConnectProps) {
    super(props);
    this.uploadConfig = this.uploadConfig.bind(this);
  }

  public uploadConfig(): Promise<{}> {
    const config: IAILConfig = getApiAILConfig({
      config: this.props.config.AIL,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadAILConfig({ config, columns: this.props.config.columns }).request();
  }

  public render(): JSX.Element {
    return (
      <ConfigActionsContainer
        warning={this.props.warning}
        uploadConfig={this.uploadConfig}
      />
    );
  }
}
