import * as React from 'react';
import { Grid, SemanticWIDTHS, Table } from 'semantic-ui-react';
import { UpdateDataFileContainer } from '../../data-upload/update-data-file';
import { MAConfigOptions } from '../ma-config-options';
import { MAConfigActionsContainer } from '../ma-config-actions';
import { MAMappingOptions } from '../../../components';
import { IAppMAConfig, IColumns } from '../../../interfaces';
import { CONFIG_VIEW_TEXT, MA_MAPPING_KEYS } from '../../../constants';
import './ma-config-view.less';

/**
 * View for "Monitoring Activity Config" component
 */
export class MAConfigView extends React.Component<IMAConfigViewProps> {
  public readonly MAX_COLUMNS: SemanticWIDTHS = 5;
  public readonly CONTENT_WIDTH: SemanticWIDTHS = 12;

  constructor(props: IMAConfigViewProps) {
    super(props);
  }

  public getConfigRows(): JSX.Element[] {
    const rows: JSX.Element[] = [];
    const colIndexes: string[] = Object.keys(this.props.columns);

    for (let i: number = 0; i < colIndexes.length; i++) {
      const colIndex: string = colIndexes[i];

      const row: JSX.Element = (
        <MAMappingOptions
          key={i}
          colIndex={colIndex}
          name={this.props.columns[colIndex]}
          monitoringActivityConfig={this.props.appMAConfig.monitoringActivityConfig}

          onWarningChange={this.props.onWarningChange}
          onMappingReset={this.props.onMappingReset}
          onMAColMappingChange={this.props.onMAColMappingChange}
        />
      );
      rows.push(row);
    }
    return rows;
  }

  public getTableHeader(): JSX.Element {
    const caption: string = CONFIG_VIEW_TEXT.MA_FIELDS_CONFIG;

    return (
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell content={caption} colSpan={this.MAX_COLUMNS} />
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.STATUS_COLUMN_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.COLUMN_NAME_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.MAPPING_TYPE_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.FIELD_NAME_HEADER} />
        </Table.Row>
      </Table.Header>
    );
  }

  public getConfigTable(): JSX.Element {
    return (
      <Grid.Row columns="equal" centered>
        <Grid.Column width={this.CONTENT_WIDTH}>
          <Table celled compact columns={this.MAX_COLUMNS}>
            {this.getTableHeader()}

            <Table.Body>
              {this.getConfigRows()}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    );
  }

  public getConfigActions(): JSX.Element {
    return (
      <Grid.Row columns="equal" centered>
        <Grid.Column width={this.CONTENT_WIDTH}>
          <MAConfigActionsContainer warning={this.props.warning} />
        </Grid.Column>
      </Grid.Row>
    );
  }

  public render(): JSX.Element {
    return (
      <Grid data-component="ma-config-view">
        <Grid.Row columns="equal" centered>
          <Grid.Column width={this.CONTENT_WIDTH}>
            <UpdateDataFileContainer />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="no-padding" columns="equal" centered>
          <Grid.Column width={this.CONTENT_WIDTH}>
            <MAConfigOptions />
          </Grid.Column>
        </Grid.Row>

        {this.props.optionsReady ? this.getConfigTable() : null}
        {this.props.optionsReady ? this.getConfigActions() : null}
      </Grid>
    );
  }
}

export interface IMAConfigViewProps {
  readonly columns: IColumns;
  readonly warning: boolean;
  readonly optionsReady: boolean;
  readonly appMAConfig: IAppMAConfig;

  onWarningChange(warning: boolean): void;
  onMappingReset(colIndex: string): void;
  onMAColMappingChange(colIndex: string, key: MA_MAPPING_KEYS): void;
}
