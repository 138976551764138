import * as React from 'react';
import { IDryRunProgressConnectProps } from './dry-run-progress-container';
import { DataProgress } from '../../../components';
import { DataLoadApi } from '../../../api';
import { IDataLoadResult } from '../../../interfaces';
import { DATA_LOAD_STAGE, DATA_LOAD_STATUS, DATA_SAVE_VIEW_TEXT } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Displays progress of "Dry Run" action
 */
export class DryRunProgress extends React.Component<IDryRunProgressConnectProps> {

  constructor(props: IDryRunProgressConnectProps) {
    super(props);

    this.onDone = this.onDone.bind(this);
    this.onProgress = this.onProgress.bind(this);
  }

  /** Notify workflow about start of dry run */
  public componentWillMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DRY_RUN_IN_PROGRESS));
  }

  /** Save new status and progress in Redux state */
  public onProgress(response: IDataLoadResult): void {
    this.props.dispatch(Actions.Result.setUploadResult({ dryRun: { ...response } }));
  }

  /** Notify workflow about dry run completion */
  public onDone(response: IDataLoadResult): void {
    /* Request logs on successful completion */
    if (response.status === DATA_LOAD_STATUS.SUCCESS) {
      DataLoadApi.checkLogs().request()
        .then((withLogs: IDataLoadResult): void => {
          this.props.dispatch(Actions.Result.setUploadResult({ dryRun: { ...withLogs } }));
          this.props.dispatch(Actions.UI.setUIData({ actionLoading: false }));
          this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DRY_RUN_COMPLETE));
        })
        .catch((e: Response): void => {
          this.props.onError(e);
        });
    }

    if (response.status === DATA_LOAD_STATUS.FAILED) {
      this.props.dispatch(Actions.UI.setUIData({ actionLoading: false }));
      this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DRY_RUN_FAILED));
    }
  }

  public render(): JSX.Element {
    const current: number = this.props.result.dryRun.nReadyEntities;

    return (
      <DataProgress
        label={DATA_SAVE_VIEW_TEXT.DRY_RUN_PROGRESS}
        total={this.props.nRows}
        current={current}
        onDone={this.onDone}
        onError={this.props.onError}
        onProgress={this.onProgress}
      />
    );
  }
}
