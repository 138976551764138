import * as React from 'react';
import { Grid, Loader, SemanticWIDTHS, Table } from 'semantic-ui-react';
import { HelpButton, TxMappingOptions } from '../../../components';
import { TxConfigOptions } from '../tx-config-options';
import { TxConfigActionsContainer } from '../tx-config-actions';
import { UpdateDataFileContainer } from '../../data-upload/update-data-file';
import { IAppTxConfig, IColumns, IDataState } from '../../../interfaces';
import {
  COMMON_TEXT, CONFIG_VIEW_TEXT, DATA_LOAD_ACTION, HELP_TEXT, TX_KEY, TX_RESOURCE_OPTIONS
} from '../../../constants';
import './tx-config-view.less';

/**
 * View for "Taxonomy Configuration" component
 */
export class TxConfigView extends React.Component<ITxConfigViewProps> {
  public readonly MAX_COLUMNS: SemanticWIDTHS = 5;
  public readonly CONTENT_WIDTH: SemanticWIDTHS = 12;

  constructor(props: ITxConfigViewProps) {
    super(props);
  }

  /** Get components for mapping columns into Taxonomy self or profile fields */
  public getConfigRows(): JSX.Element[] {
    const rows: JSX.Element[] = [];
    const colIndexes: string[] = Object.keys(this.props.columns);

    for (let i: number = 0; i < colIndexes.length; i++) {
      const colIndex: string = colIndexes[i];

      const row: JSX.Element = (
        <TxMappingOptions
          key={i}
          colIndex={colIndex}
          name={this.props.columns[colIndex]}
          appTxConfig={this.props.appTxConfig}
          tabs={this.props.data.tabs}
          fields={this.props.data.fields}
          riskFactor={this.props.data.riskFactor}

          onMappingReset={this.props.onMappingReset}
          onWarningChange={this.props.onWarningChange}
          onSelfKeyMappingChange={this.props.onSelfKeyMappingChange}
          onCustomTabMappingChange={this.props.onCustomTabMappingChange}
          onCustomFieldMappingChange={this.props.onCustomFieldMappingChange}
          onRiskFactorChange={this.props.onRiskFactorChange}
        />
      );
      rows.push(row);
    }
    return rows;
  }

  /** Top table row contains title and help button */
  public getTableTitle(): JSX.Element {
    const txType: string = TX_RESOURCE_OPTIONS[this.props.appTxConfig.entity];
    const caption: string = `${CONFIG_VIEW_TEXT.TX_FIELDS_CONFIG}: ${txType}`;
    const help: JSX.Element = <HelpButton caption={HELP_TEXT.HELP} text={HELP_TEXT.TAXONOMY_CONFIG} />;

    return (
      <Table.HeaderCell className="top-title" colSpan={this.MAX_COLUMNS}>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>{caption}</Grid.Column>
            <Grid.Column textAlign="right">{help}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Table.HeaderCell>
    );
  }

  /** Get header with data load type and column captions */
  public getTableHeader(): JSX.Element {
    return (
      <Table.Header fullWidth>
        <Table.Row>
          {this.getTableTitle()}
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.STATUS_COLUMN_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.COLUMN_NAME_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.MAPPING_TYPE_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.TAB_ELEMENT_NAME_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.FIELD_NAME_HEADER} />
        </Table.Row>
      </Table.Header>
    );
  }

  /** Table for mapping columns into Taxonomy self or profile fields */
  public getConfigTable(): JSX.Element {
    return (
      <Grid.Row columns="equal" centered>
        <Grid.Column width={this.CONTENT_WIDTH}>
          <Table celled compact columns={this.MAX_COLUMNS}>
            {this.getTableHeader()}

            <Table.Body>
              {this.getConfigRows()}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    );
  }

  /** Actions can be disabled if config warning is present */
  public getConfigActions(): JSX.Element {
    return (
      <Grid.Row columns="equal" centered>
        <Grid.Column width={this.CONTENT_WIDTH}>
          <TxConfigActionsContainer warning={this.props.warning} />
        </Grid.Column>
      </Grid.Row>
    );
  }

  public render(): JSX.Element {
    if (!this.props.dataReady) {
      return <Loader active inline="centered" content={COMMON_TEXT.LOADING} />;
    }

    return (
      <Grid data-component="tx-config-view">
        <Grid.Row columns="equal" centered>
          <Grid.Column width={this.CONTENT_WIDTH}>
            <UpdateDataFileContainer />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="no-padding" columns="equal" centered>
          <Grid.Column width={this.CONTENT_WIDTH}>
            <TxConfigOptions />
          </Grid.Column>
        </Grid.Row>

        {this.props.dataLoadAction && this.props.matchByIndex ? this.getConfigTable() : null}
        {this.props.dataLoadAction && this.props.matchByIndex ? this.getConfigActions() : null}
      </Grid>
    );
  }
}

export interface ITxConfigViewProps {
  readonly data: IDataState;
  readonly columns: IColumns;
  readonly warning: boolean;
  readonly dataReady: boolean;
  readonly appTxConfig: IAppTxConfig;
  readonly dataLoadAction: DATA_LOAD_ACTION;
  readonly matchByIndex: string;

  onMappingReset(colIndex: string): void;
  onWarningChange(warning: boolean): void;
  onSelfKeyMappingChange(colIndex: string, key: TX_KEY): void;
  onCustomTabMappingChange(colIndex: string, tabId: string): void;
  onCustomFieldMappingChange(colIndex: string, fieldId: string): void;
  onRiskFactorChange(colIndex: string, riskFactorId: number): void;
}
