import * as React from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { IMatchIndicatorKeyOptionsConnectProps } from './match-indicator-key-options-container';
import { MatchIndicatorKeyOptionsView } from './match-indicator-key-options-view';
import { MATCH_INDICATOR_KEY } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Options for "Match Indicator Key"
 */
export class MatchIndicatorKeyOptions extends React.Component<IMatchIndicatorKeyOptionsConnectProps> {
  constructor(props: IMatchIndicatorKeyOptionsConnectProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  /** Update UI reducer and call parent callback */
  public onChange(e: React.SyntheticEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.props.dispatch(Actions.UI.setUIData({ matchIndicatorKey: changes.value as MATCH_INDICATOR_KEY }));
    if (this.props.onChange) {
      this.props.onChange(changes.value as MATCH_INDICATOR_KEY);
    }
  }

  public render(): JSX.Element {
    return (
      <MatchIndicatorKeyOptionsView
        value={this.props.ui.matchIndicatorKey}
        onChange={this.onChange}
      />
    );
  }
}
