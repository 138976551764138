import * as React from 'react';
import { DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { CONFIG_VIEW_TEXT } from '../../../constants';
import { format, getOptions } from '../../../functions';

/**
 * View for "Match Key By" component
 */
export class MatchKeyOptionsView extends React.Component<IMatchKeyOptionsViewProps> {
  constructor(props: IMatchKeyOptionsViewProps) {
    super(props);
  }

  public render(): JSX.Element {
    const options: DropdownItemProps[] = getOptions(this.props.options);
    const label: string = format(CONFIG_VIEW_TEXT.MATCH_KEY_BY, { key: this.props.labelKey });

    return (
      <Form.Dropdown
        label={label}
        options={options}
        value={this.props.value}
        onChange={this.props.onChange}
        selectOnBlur={false}
        selection
        required
      />
    );
  }
}

export interface IMatchKeyOptionsViewProps {
  readonly value: string;
  readonly options: { [key: string]: string };
  readonly labelKey: string;
  onChange(e: React.SyntheticEvent<HTMLDivElement>, changes: DropdownProps): void;
}
