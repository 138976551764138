import * as React from 'react';
import { IDataSaveConnectProps } from './data-save-container';
import { DataSaveContainer } from '../../data-save';
import { DataLoadApi } from '../../../api';
import { IUserManagementConfig } from '../../../interfaces';
import { DATA_LOAD_TYPE } from '../../../constants';
import { getApiUserManagementConfig } from '../../../functions';

/**
 * Wrapper around "Data Save" component for User Management
 */
export class DataSave extends React.Component<IDataSaveConnectProps> {
  constructor(props: IDataSaveConnectProps) {
    super(props);
    this.onRetry = this.onRetry.bind(this);
  }

  public onRetry(): Promise<{}> {
    const config: IUserManagementConfig = getApiUserManagementConfig({
      config: this.props.config.userManagement,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadUserManagementConfig(config).request();
  }

  public render(): JSX.Element {
    if (!this.props.config.userManagement) { return null; }

    const dataEndRow: number = this.props.upload.dataEndRow || this.props.config.userManagement.tableConfig.dataEndRow;
    const dataStartRow: number = this.props.upload.dataStartRow
      || this.props.config.userManagement.tableConfig.dataStartRow;

    const nRows: number = dataEndRow - dataStartRow;

    return (
      <DataSaveContainer
        nRows={nRows}
        dataLoadType={DATA_LOAD_TYPE.USER_MANAGEMENT}
        onRetry={this.onRetry}
      />
    );
  }
}
