import * as React from 'react';
import { ITxKindOptionsConnectProps } from './tx-kind-options-container';
import { ProcessTypeOptionsContainer } from '../process-type-options';
import { CONFIG_VIEW_TEXT, TX_RESOURCE_TYPE } from '../../../constants';

/**
 * Taxonomy process options switcher
 */
export class TxKindOptions extends React.Component<ITxKindOptionsConnectProps> {
  constructor(props: ITxKindOptionsConnectProps) {
    super(props);
  }

  public getTxOptions(): JSX.Element {
    if (this.props.activeType === TX_RESOURCE_TYPE.BUSINESS_PROCESS) {
      return (<ProcessTypeOptionsContainer label={CONFIG_VIEW_TEXT.TX_BUSINESS_PROCESS_TYPE} />);
    }

    return null;
  }

  public render(): JSX.Element {
    return this.getTxOptions();
  }
}
