import * as React from 'react';
import { InputOnChangeData } from 'semantic-ui-react';
import { IUpdateDataFileConnectProps } from './update-data-file-container';
import { UpdateDataFileView } from './update-data-file-view';
import { DataLoadApi, IUpdateFileResponse, IUploadBasicFileOptions } from '../../../api';
import { IUploadState } from '../../../interfaces';
import { DATA_LOAD_STAGE } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Update data file information in store and on server
 */
export class UpdateDataFile extends React.Component<IUpdateDataFileConnectProps, IUpdateDataFileState> {

  constructor(props: IUpdateDataFileConnectProps) {
    super(props);
    this.state = {
      error: null,
      success: false,
      loading: false,

      dataFile: this.props.upload.dataFile,
      sheetIndex: this.props.upload.sheetIndex,
      dataStartRow: this.props.upload.dataStartRow,
      dataEndRow: this.props.upload.dataEndRow
    };

    this.onUpdate = this.onUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.onDataFileChange = this.onDataFileChange.bind(this);
    this.onSheetIndexChange = this.onSheetIndexChange.bind(this);
    this.onRowRangeChange = this.onRowRangeChange.bind(this);
  }

  /** Reset own state on close or cancel actions */
  public onCancel(): void {
    this.setState({
      dataFile: this.props.upload.dataFile,
      sheetIndex: this.props.upload.sheetIndex,
      dataStartRow: this.props.upload.dataStartRow,
      dataEndRow: this.props.upload.dataEndRow
    });
  }

  /** Reset error and success states */
  public onDismiss(): void {
    this.setState({ success: false, error: null });
  }

  /** Update local state on input changes */
  public onDataFileChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const list: FileList = e.target.files;
    if (list && list.length > 0) {
      this.setState({ dataFile: list[0] });
    }
  }

  /** Update local state on input changes */
  public onSheetIndexChange(e: React.KeyboardEvent<HTMLInputElement>, changes: InputOnChangeData): void {
    /* Ignore non-number key inputs */
    const sheetIndex: number = parseInt(changes.value, 10) - 1;
    if (changes.value !== '' && isNaN(sheetIndex)) {
      return;
    }

    this.setState({ sheetIndex: changes.value === '' ? null : sheetIndex });
  }

  /** Empty optional values are treated as default */
  public onRowRangeChange(e: React.KeyboardEvent<HTMLInputElement>, changes: InputOnChangeData): void {
    /* Ignore non-number key inputs */
    const value: number = parseInt(changes.value, 10);
    if (changes.value !== '' && isNaN(value)) {
      return;
    }

    this.setState({
      [changes.name]: changes.value === '' ? null : value - 1
    });
  }

  /** Update store on successful response */
  public onUpdateSuccess(response: IUpdateFileResponse): void {
    /* Update info in "upload" section */
    const uploadUpdate: Partial<IUploadState> = {
      dataFile: this.state.dataFile,
      sheetIndex: response.sheetIndex,
      dataStartRow: response.dataStartRow,
      dataEndRow: response.dataEndRow
    };

    this.props.dispatch(Actions.UI.setUIData({ actionLoading: false }));
    this.props.dispatch(Actions.Upload.setUploadInfo(uploadUpdate));
    this.setState({ loading: false, success: true, ...uploadUpdate as object });
  }

  /** Update and show either success or error messages */
  public onUpdate(): void {
    this.props.dispatch(Actions.UI.setUIData({ actionLoading: true }));
    this.setState({ loading: true, success: false, error: null });

    const options: IUploadBasicFileOptions = {
      dataFile: this.state.dataFile,
      sheetIndex: this.state.sheetIndex,
      startRow: this.state.dataStartRow,
      endRow: this.state.dataEndRow
    };

    DataLoadApi.updateDataFile(options).request()
      .then((response: IUpdateFileResponse): void => {
        this.onUpdateSuccess(response);
      })
      .catch((response: Response): void => {
        /* Reset own state and display error message */
        this.props.dispatch(Actions.UI.setUIData({ actionLoading: false }));
        this.setState({ loading: false, error: response });
        this.onCancel();
      });
  }

  public render(): JSX.Element {
    const disabled: boolean = this.state.loading || this.props.ui.actionLoading
      || this.props.ui.stage === DATA_LOAD_STAGE.DRY_RUN_IN_PROGRESS
      || this.props.ui.stage === DATA_LOAD_STAGE.DATA_SAVE_IN_PROGRESS
      || this.props.ui.stage === DATA_LOAD_STAGE.DATA_SAVE_COMPLETE;

    return (
      <UpdateDataFileView
        error={this.state.error}
        success={this.state.success}
        loading={this.state.loading}
        disabled={disabled}

        dataFile={this.state.dataFile}
        sheetIndex={this.state.sheetIndex}
        dataStartRow={this.state.dataStartRow}
        dataEndRow={this.state.dataEndRow}

        onUpdate={this.onUpdate}
        onCancel={this.onCancel}
        onDismiss={this.onDismiss}
        onDataFileChange={this.onDataFileChange}
        onSheetIndexChange={this.onSheetIndexChange}
        onRowRangeChange={this.onRowRangeChange}
      />
    );
  }
}

export interface IUpdateDataFileState {
  readonly error: Response;
  readonly success: boolean;
  readonly loading: boolean;

  readonly dataFile: File;
  readonly sheetIndex: number;
  readonly dataStartRow: number;
  readonly dataEndRow: number;
}
