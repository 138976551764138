import * as React from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { IUserManagementKeyOptionsConnectProps } from './user-management-key-options-container';
import { MatchUserManagementKeyOptionsView } from './match-user-management-key-options-view';
import * as Actions from '../../../actions';
import { USER_MANAGEMENT_OPTIONS } from '../../../constants/user-management';

/**
 * Options for "Match record by" User Management
 */
export class UserManagementKeyOptions extends React.Component<IUserManagementKeyOptionsConnectProps> {
  constructor(props: IUserManagementKeyOptionsConnectProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  /** Update UI reducer and call parent callback */
  public onChange(e: React.SyntheticEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.props.dispatch(Actions.UI.setUIData({
      matchUserManagementKey: changes.value as USER_MANAGEMENT_OPTIONS
    }));
    if (this.props.onChange) {
      this.props.onChange(changes.value as USER_MANAGEMENT_OPTIONS);
    }
  }

  public render(): JSX.Element {
    return (
      <MatchUserManagementKeyOptionsView
        value={this.props.ui.matchUserManagementKey}
        onChange={this.onChange}
      />
    );
  }
}
