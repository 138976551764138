import * as React from 'react';
import { Form, InputOnChangeData } from 'semantic-ui-react';
import { HelpButton } from '../../common/help-button';
import { DATA_UPLOAD_VIEW_TEXT, HELP_TEXT } from '../../../constants';
import './data-range-options.less';

/**
 * Options for specifying row range in data file
 */
export class DataRangeOptions extends React.Component<IDataRangeOptionsProps> {
  constructor(props: IDataRangeOptionsProps) {
    super(props);
  }

  /** Data file optional start row value */
  public getStartRowInput(): JSX.Element {
    const error: boolean = this.props.dataStartRow !== null && this.props.dataStartRow < 1;
    const value: string = this.props.dataStartRow === null ? '' : (this.props.dataStartRow + 1).toString();

    return (
      <Form.Input
        type="text"
        name="dataStartRow"
        label={DATA_UPLOAD_VIEW_TEXT.START_ROW}
        onChange={this.props.onRowRangeChange}
        value={value}
        placeholder={DATA_UPLOAD_VIEW_TEXT.START_ROW_PLACEHOLDER}
        error={error}
        maxLength={6}
        width={8}
        min={1}
      />
    );
  }

  /** Data file optional end row value */
  public getEndRowInput(): JSX.Element {
    const error: boolean = this.props.dataEndRow !== null && this.props.dataEndRow < 1;
    const value: string = this.props.dataEndRow === null ? '' : (this.props.dataEndRow + 1).toString();

    return (
      <Form.Input
        type="text"
        name="dataEndRow"
        label={DATA_UPLOAD_VIEW_TEXT.END_ROW}
        onChange={this.props.onRowRangeChange}
        value={value}
        placeholder={DATA_UPLOAD_VIEW_TEXT.END_ROW_PLACEHOLDER}
        error={error}
        maxLength={6}
        width={8}
        min={1}
      />
    );
  }

  /** Help text for data row range */
  public getHelpButton(): JSX.Element {
    return (
      <div className="field">
        <HelpButton text={HELP_TEXT.DATA_RANGE} />
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <Form data-component="data-range-options">
        <Form.Group>
          {this.getStartRowInput()}
          {this.getEndRowInput()}
          {this.getHelpButton()}
        </Form.Group>
      </Form>
    );
  }
}

export interface IDataRangeOptionsProps {
  readonly dataStartRow: number;
  readonly dataEndRow: number;
  onRowRangeChange(e: React.KeyboardEvent<HTMLInputElement>, changes: InputOnChangeData): void;
}
