import * as React from 'react';
import { IIncidentWorkflowConnectProps } from './incident-workflow-container';
import { Workflow } from '../../../components/workflow';
import { IncidentDataUploadContainer } from '../incident-data-upload';
import { IncidentConfigContainer } from '../incident-config';
import { IncidentDataSaveContainer } from '../incident-data-save';
import { DataLoadApi } from '../../../api';
import { DATA_LOAD_STAGE } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Incidents workflow manages transitions between data load flow components
 */
export class IncidentWorkflow extends React.Component<IIncidentWorkflowConnectProps> {
  public componentWillMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_UPLOAD_INITIAL));
  }

  public componentWillUnmount(): void {
    DataLoadApi.cancelDataLoad().request()
      .then((): void => {
        this.props.dispatch(Actions.Batch.clearAppState());
      })
      .catch((): void => {
        this.props.dispatch(Actions.Batch.clearAppState());
      });
  }

  public render(): JSX.Element {
    return (
      <Workflow
        stage={this.props.ui.stage}
        upload={<IncidentDataUploadContainer />}
        config={<IncidentConfigContainer />}
        result={<IncidentDataSaveContainer />}
      />
    );
  }
}
