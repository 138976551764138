import * as React from 'react';
import { Accordion, AccordionPanelProps, Table } from 'semantic-ui-react';
import { IDataLoadResult, ILogEntity, IColumns } from '../../../interfaces';
import { DATA_LOAD_ITEM_CAPTIONS, DATA_SAVE_VIEW_TEXT, LOG_ENTITY_TYPE } from '../../../constants';
import { format } from '../../../functions';
import './data-result.less';

const NOTHING_CHANGE: string = 'N/A';
/**
 * Result of "Dry Run" or "Data Save" actions
 */
export class DataResult extends React.Component<IDataResultProps, IDataResultState> {
  private readonly LOG_RESULTS_MAPPED: Set<LOG_ENTITY_TYPE> = new Set<LOG_ENTITY_TYPE>()
    .add(LOG_ENTITY_TYPE.WARN)
    .add(LOG_ENTITY_TYPE.ADDED)
    .add(LOG_ENTITY_TYPE.ERROR)
    .add(LOG_ENTITY_TYPE.SKIP)
    .add(LOG_ENTITY_TYPE.UPDATED);

  constructor(props: IDataResultProps) {
    super(props);
    this.state = {
      added: { logs: [], items: 0 },
      updated: { logs: [], items: 0 },
      skipped: { logs: [], items: 0 },
      warn: { logs: [], items: 0 },
      other: { logs: [], items: 0 },
    };
  }

  /** Calculate result tables content on initial load */
  public componentDidMount(): void {
    this.setState(this.groupLogsIntoLists(this.props.logList.logs));
  }

  /** Group records into map by their row index attribute */
  public groupByRowIndex(logs: ILogEntity[]): { [key: string]: ILogEntity[] } {
    const map: { [key: string]: ILogEntity[] } = {};

    for (let i: number = 0; i < logs.length; i++) {
      const log: ILogEntity = logs[i];

      if (!map[log.rowIndex]) {
        map[log.rowIndex] = [log];
      } else {
        map[log.rowIndex].push(log);
      }
    }

    return map;
  }

  /** Join logs for result tables and get number of unique items for each group */
  public groupLogsIntoLists(logs: ILogEntity[]): IDataResultState {
    const map: { [key: string]: ILogEntity[] } = this.groupByRowIndex(logs);
    const keys: string[] = Object.keys(map);

    const added: { logs: ILogEntity[], items: number } = { logs: [], items: 0 };
    const skipped: { logs: ILogEntity[], items: number } = { logs: [], items: 0 };
    const updated: { logs: ILogEntity[], items: number } = { logs: [], items: 0 };
    const warn: { logs: ILogEntity[], items: number } = { logs: [], items: 0 };
    const other: { logs: ILogEntity[], items: number } = { logs: [], items: 0 };

    for (let i: number = 0; i < keys.length; i++) {
      const row: ILogEntity[] = map[keys[i]];

      /* If any log with same row index contains error, entire row is skipped */
      const error: ILogEntity = row.find((log: ILogEntity): boolean => {
        return log.logType === LOG_ENTITY_TYPE.ERROR;
      });

      /* If all logs with same row index are skipped, entire row is skipped */
      const skip: ILogEntity[] = row.filter((log: ILogEntity): boolean => {
        return log.logType === LOG_ENTITY_TYPE.SKIP;
      });

      if (error || skip.length === row.length) {
        skipped.logs.push(...map[keys[i]]);
        skipped.items++;
        continue;
      }

      /* If any log with same row index is updated, entire row is updated */
      const update: ILogEntity[] = row.filter((log: ILogEntity): boolean => {
        return log.logType === LOG_ENTITY_TYPE.UPDATED;
      });

      if (update.length) {
        updated.logs.push(...map[keys[i]]);
        updated.items += update.length;
        continue;
      }

      /* If all logs with same row index are added (or skipped), entire row is added */
      const add: ILogEntity[] = map[keys[i]].filter((log: ILogEntity): boolean => {
        return log.logType === LOG_ENTITY_TYPE.ADDED;
      });

      if (add.length) {
        added.logs.push(...map[keys[i]]);
        added.items += add.length;
        continue;
      }

      /* Take all warnings  */
      const warning: ILogEntity[] = map[keys[i]].filter((log: ILogEntity): boolean => {
        return log.logType === LOG_ENTITY_TYPE.WARN;
      });

      if (warning.length) {
        warn.logs.push(...map[keys[i]]);
        warn.items += warning.length;
        continue;
      }

      /* If server sends log type not in LOG_RESULTS_MAPPED we will add it to others */
      const others: ILogEntity[] = map[keys[i]].filter((log: ILogEntity): boolean => {
        return !this.LOG_RESULTS_MAPPED.has(log.logType);
      });

      if (others.length) {
        other.logs.push(...map[keys[i]]);
        other.items += others.length;
        continue;
      }
    }

    return { added, updated, skipped, warn, other };
  }

  public getColumnNameTableCell(log: ILogEntity, key: ITEM_TYPE): JSX.Element {
    return key === ITEM_TYPE.SKIPPED
      ? (<Table.Cell width={4} content={this.getColumnNameBy(log.columnIndex)} />)
      : null;
  }

  public getColumnNameTableHeader(key: ITEM_TYPE): JSX.Element {
    return key === ITEM_TYPE.SKIPPED
      ? (<Table.HeaderCell content={DATA_SAVE_VIEW_TEXT.COLUMN_NAME_HEADER} />)
      : null;
  }

  public getColumnNameBy(columnIndex: number): string {
    if (columnIndex < 0) {
      return NOTHING_CHANGE;
    }

    return this.props.columns[columnIndex] || NOTHING_CHANGE;
  }

  /** Get log table for given result type (add, update, skip) */
  public getLogTable(logs: ILogEntity[], key: ITEM_TYPE): JSX.Element {
    const rows: JSX.Element[] = [];

    for (let i: number = 0; i < logs.length; i++) {
      const row: JSX.Element = (
        <Table.Row key={i}>
          <Table.Cell width={2} content={logs[i].rowIndex} />
            {this.getColumnNameTableCell(logs[i], key)}
          <Table.Cell className="description" content={logs[i].message} />
        </Table.Row>
      );
      rows.push(row);
    }

    return (
      <Table celled compact striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content={DATA_SAVE_VIEW_TEXT.ROW_INDEX_COLUMN_HEADER} />
            {this.getColumnNameTableHeader(key)}
            <Table.HeaderCell content={DATA_SAVE_VIEW_TEXT.MESSAGE_COLUMN_HEADER} />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {rows}
        </Table.Body>
      </Table>
    );
  }

  /** Result is displayed as Semantic Accordion items */
  public getAccordionItem(key: ITEM_TYPE, message: string, items: number, logs: ILogEntity[]): AccordionPanelProps {
    const action: string = (items === 1)
      ? DATA_SAVE_VIEW_TEXT.DATA_SAVE_ACTION_SINGLE
      : DATA_SAVE_VIEW_TEXT.DATA_SAVE_ACTION_PLURAL;

    const type: string = (items === 1)
      ? DATA_LOAD_ITEM_CAPTIONS[this.props.dataLoadType].single
      : DATA_LOAD_ITEM_CAPTIONS[this.props.dataLoadType].plural;

    const caption: string = format(message, { amount: items, action, type });
    return {
      title: { key: `${key}-title`, content: caption },
      content: { key: `${key}-content`, content: this.getLogTable(logs, key) }
    };
  }

  /** Get table with "To Be Added" items */
  public getToBeAddedItems(): AccordionPanelProps {
    if (!this.state.added.items) { return null; }
    const message: string = (this.props.type === 'DRY_RUN')
      ? DATA_SAVE_VIEW_TEXT.DRY_RUN_ITEMS_ADDED
      : DATA_SAVE_VIEW_TEXT.DATA_SAVE_ITEMS_ADDED;

    return this.getAccordionItem(ITEM_TYPE.ADDED, message, this.state.added.items, this.state.added.logs);
  }

  /** Get table with "To Be Updated" items */
  public getToBeUpdatedItems(): AccordionPanelProps {
    if (!this.state.updated.items) { return null; }
    const message: string = (this.props.type === 'DRY_RUN')
      ? DATA_SAVE_VIEW_TEXT.DRY_RUN_ITEMS_UPDATED
      : DATA_SAVE_VIEW_TEXT.DATA_SAVE_ITEMS_UPDATED;

    return this.getAccordionItem(ITEM_TYPE.UPDATED, message, this.state.updated.items, this.state.updated.logs);
  }

  /** Get table with "To Be Skipped" items */
  public getToBeSkippedItems(): AccordionPanelProps {
    if (!this.state.skipped.items) { return null; }
    const message: string = (this.props.type === 'DRY_RUN')
      ? DATA_SAVE_VIEW_TEXT.DRY_RUN_ITEMS_SKIPPED
      : DATA_SAVE_VIEW_TEXT.DATA_SAVE_ITEMS_SKIPPED;

    return this.getAccordionItem(ITEM_TYPE.SKIPPED, message, this.state.skipped.items, this.state.skipped.logs);
  }

  /** Get table with "Warnings" items */
  public getWarningsItems(): AccordionPanelProps {
    if (!this.state.warn.items) { return null; }
    const message: string = (this.props.type === 'DRY_RUN')
      ? DATA_SAVE_VIEW_TEXT.DRY_RUN_ITEMS_WARN
      : DATA_SAVE_VIEW_TEXT.DATA_SAVE_ITEMS_WARN;

    return this.getAccordionItem(null, message, this.state.warn.items, this.state.warn.logs);
  }

  /** Get table with "Other" items */
  public getOtherItems(): AccordionPanelProps {
    if (!this.state.other.items) { return null; }
    const message: string = (this.props.type === 'DRY_RUN')
      ? DATA_SAVE_VIEW_TEXT.DRY_RUN_ITEMS_OTHER
      : DATA_SAVE_VIEW_TEXT.DATA_SAVE_ITEMS_OTHER;

    return this.getAccordionItem(null, message, this.state.other.items, this.state.other.logs);
  }

  public render(): JSX.Element {
    /* Show only non-empty tables */
    const options: AccordionPanelProps[] = [
      this.getToBeAddedItems(),
      this.getToBeUpdatedItems(),
      this.getToBeSkippedItems(),
      this.getWarningsItems(),
      this.getOtherItems()
    ].filter((option: AccordionPanelProps): boolean => !!option);

    return (
      <Accordion
        data-component="data-result"
        panels={options}
        exclusive={false}
        fluid
      />
    );
  }
}

export enum ITEM_TYPE {
  SKIPPED = 'skipped',
  UPDATED = 'updated',
  ADDED = 'added',
}

export interface IDataResultState {
  readonly added: {
    readonly logs: ILogEntity[];
    readonly items: number;
  };
  readonly updated: {
    readonly logs: ILogEntity[];
    readonly items: number;
  };
  readonly skipped: {
    readonly logs: ILogEntity[];
    readonly items: number;
  };
  readonly warn: {
    readonly logs: ILogEntity[];
    readonly items: number;
  };
  readonly other: {
    readonly logs: ILogEntity[];
    readonly items: number;
  };
}

export interface IDataResultProps {
  readonly logList: IDataLoadResult;
  /** Defines output messages */
  readonly type: 'DRY_RUN' | 'DATA_SAVE';
  /** Data load type determines item caption, like "Element", "Incident", etc */
  readonly dataLoadType: string;
  readonly columns: IColumns;
}
