import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { MatchMonitoringActivityKeyOptions } from './match-monitoring-activity-key-options';
import { IState, IUIState } from '../../../interfaces';

export interface IMatchMonitoringActivityKeyOptionsStateProps {
  readonly ui: IUIState;
}

export type IMatchMonitoringActivityKeyOptionsConnectProps = IMatchMonitoringActivityKeyOptionsStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IMatchMonitoringActivityKeyOptionsStateProps {
  return { ui: state.ui };
}

export const MatchMonitoringActivityKeyOptionsContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(MatchMonitoringActivityKeyOptions);
