import { normalize, schema } from 'normalizr';
import { UrlRequest } from './url-request';
import { API_URL_CONFIG, IGetTabsAndFieldsResponse, IUrlObject } from './api-interfaces';
import { TX_RESOURCE_TYPE } from '../constants';
import { IIncidentType, IRiskFactor } from '../interfaces';

const FIELD: schema.Entity = new schema.Entity('fields');
const TAB: schema.Entity = new schema.Entity('tabs', { resourceFields: [FIELD] });
const SCHEMA: schema.Array = new schema.Array(TAB);

/**
 * Wrapper for "Data" API (like tabs, fields, etc.)
 */
export class DataApi {
  /** Get normalized tabs and fields data */
  public static getTabsAndFields(type: TX_RESOURCE_TYPE): UrlRequest<IGetTabsAndFieldsResponse> {
    const urlObject: IUrlObject = { ...API_URL_CONFIG.data.tabs, params: { type } };
    return new UrlRequest({
      urlObject, callback: (data: object[]): IGetTabsAndFieldsResponse => {
        return normalize(data, SCHEMA).entities;
      }
    });
  }

  /** Get normalized risk factor data */
  public static getRiskFactor(type: TX_RESOURCE_TYPE): UrlRequest<{ [key: number]: IRiskFactor }> {
    const urlObject: IUrlObject = { ...API_URL_CONFIG.data.riskFactor, params: { type } };
    return new UrlRequest({
      urlObject, callback: (data: IRiskFactor[]): { [key: number]: IRiskFactor } => {
        const result: { [key: number]: IRiskFactor } = {};
        for (const riskFactor of data) {
          result[riskFactor.id] = riskFactor;
        }
        return result;
      }
    });
  }

  /** Get normalized tabs and fields data for incident profile */
  public static getIncidentTabsAndFields(testId: string): UrlRequest<IGetTabsAndFieldsResponse> {
    const urlObject: IUrlObject = { ...API_URL_CONFIG.data.incidentTabs, params: { id: testId } };
    return new UrlRequest({
      urlObject, callback: (data: object[]): IGetTabsAndFieldsResponse => {
        return normalize(data, SCHEMA).entities;
      }
    });
  }

  public static getIncidentTypes(): UrlRequest<IIncidentType[]> {
    const urlObject: IUrlObject = { ...API_URL_CONFIG.data.incidents };
    return new UrlRequest({ urlObject });
  }
}
