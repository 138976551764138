import * as React from 'react';
import { Route } from 'react-router-dom';
import { IRootConnectProps } from './root-container';
import { ContentContainer } from '../content';
import { HomeContainer } from '../home';
import {
  clearBothStorageValue,
  getSessionStorageValue
} from '../../functions/storage';

/**
 * Root component switches between "Login" and "Home" components
 */
export class Root extends React.Component<IRootConnectProps> {
  constructor(props: IRootConnectProps) {
    super(props);
  }

  public logoutRedirect(): null {
    const redirectUrl: string = getSessionStorageValue('logoutUrl');
    window.location.replace(redirectUrl);
    clearBothStorageValue('logoutUrl');
    return null;
  }

  /** Show "Login" component if token is not set or has expired */
  public getHomeRoute(): JSX.Element {
    return <Route path="/" component={HomeContainer} />;
  }

  /** Show "Home" component if token is valid */
  public getContentRoute(): JSX.Element {
    return <Route path="/" component={ContentContainer} />;
  }

  public getRedirectRoute(): JSX.Element {
    return <Route path="/" component={this.logoutRedirect}/>;
  }

  public render(): JSX.Element {
    if (this.props.ui.isLogoutRedirecting) {
      return this.getRedirectRoute();
    }
    return (this.props.auth.token) ? this.getContentRoute() : this.getHomeRoute();
  }
}
