import * as React from 'react';
import { IPIConfigConnectProps } from './pi-config-container';
import { PIConfigView } from './pi-config-view';
import { IAppPIConfig, IPlanEventConfig, IPlanEventScoreConfig } from '../../../interfaces';
import { PLAN_EVENT_KEY, PLAN_EVENT_SCORE_KEY } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Plan-Indicators column mapping configuration
 */
export class PIConfig extends React.Component<IPIConfigConnectProps> {
  constructor(props: IPIConfigConnectProps) {
    super(props);

    this.onMappingReset = this.onMappingReset.bind(this);
    this.onPlanEventColMappingChange = this.onPlanEventColMappingChange.bind(this);
    this.onPlanEventScoreColMappingChange = this.onPlanEventScoreColMappingChange.bind(this);
  }

  /** Update config with new mapping for column => plan event keys */
  public onPlanEventColMappingChange(colIndex: string, key: PLAN_EVENT_KEY): void {
    const config: IAppPIConfig = {
      ...this.props.config.PI,
      planEventConfig: {
        ...this.props.config.PI.planEventConfig,
        [colIndex]: key
      }
    };

    this.props.dispatch(Actions.Config.setPIConfig(config));
  }

  /** Update config with new mapping for column => plan event score keys */
  public onPlanEventScoreColMappingChange(colIndex: string, key: PLAN_EVENT_SCORE_KEY): void {
    const config: IAppPIConfig = {
      ...this.props.config.PI,
      planEventScoreConfig: {
        ...this.props.config.PI.planEventScoreConfig,
        [colIndex]: key
      }
    };

    this.props.dispatch(Actions.Config.setPIConfig(config));
  }

  /** Reset mapping on type changes */
  public onMappingReset(colIndex: string): void {
    const planEventConfig: Partial<IPlanEventConfig> = this.props.config.PI.planEventConfig;
    const planEventScoreConfig: Partial<IPlanEventScoreConfig> = this.props.config.PI.planEventScoreConfig;

    delete planEventConfig[colIndex];
    delete planEventScoreConfig[colIndex];

    const config: IAppPIConfig = {
      ...this.props.config.PI,
      planEventConfig,
      planEventScoreConfig
    };

    this.props.dispatch(Actions.Config.setPIConfig(config));
  }

  /** Columns mappings are available when all required options are set */
  public showConfigMapping(): boolean {
    return !!this.props.ui.dataLoadAction && !!this.props.ui.libraryType
      && !!this.props.ui.matchPlanKey && !!this.props.ui.matchIndicatorKey;
  }

  public render(): JSX.Element {
    if (!this.props.config.PI) { return null; }

    return (
      <PIConfigView
        ui={this.props.ui}
        columns={this.props.config.columns}
        appPIConfig={this.props.config.PI}
        optionsReady={this.showConfigMapping()}
        onMappingReset={this.onMappingReset}
        onPlanEventColMappingChange={this.onPlanEventColMappingChange}
        onPlanEventScoreColMappingChange={this.onPlanEventScoreColMappingChange}
      />
    );
  }
}
