import * as React from 'react';
import {
  Dropdown, DropdownItemProps, DropdownProps, Icon, SemanticICONS, SemanticWIDTHS, Table
} from 'semantic-ui-react';
import { CustomMappingOptions } from '../custom-mapping-options';
import { IAppTxConfig, IAppTxMapping, IFieldData, ITabData } from '../../../interfaces';
import {
  CONFIG_VIEW_TEXT, INCIDENT_KEY, INCIDENT_MAPPING, INCIDENT_MAPPING_OPTIONS, NO_MAPPING_OPTION
} from '../../../constants';
import { format, getOptions } from '../../../functions';
import './incident-mapping-options.less';

/**
 * Column mapping configuration options for Incidents
 */
export class IncidentMappingOptions
  extends React.Component<IIncidentMappingOptionsProps, IIncidentMappingOptionsState> {
  public readonly DEFAULT_MAPPING_OPTION: string = NO_MAPPING_OPTION.key;
  public readonly mappingOptions: DropdownItemProps[];

  constructor(props: IIncidentMappingOptionsProps) {
    super(props);

    const defaultValue: string = this.getDefaultType();
    this.state = {
      mappingType: defaultValue,
      warning: false
    };

    this.mappingOptions = this.getIncidentMappingOptions();
    this.onWarningChange = this.onWarningChange.bind(this);
    this.onIncidentMappingChange = this.onIncidentMappingChange.bind(this);
  }

  /** Ignore changes in other rows */
  public shouldComponentUpdate(props: IIncidentMappingOptionsProps, state: IIncidentMappingOptionsState): boolean {
    const current: IAppTxMapping = this.props.appIncidentConfig.mapping[this.props.colIndex];
    const next: IAppTxMapping = props.appIncidentConfig.mapping[this.props.colIndex];
    return current !== next || this.state !== state;
  }

  /** Set incident mapping type for automapped values */
  public getDefaultType(): string {
    let mappingType: string = this.DEFAULT_MAPPING_OPTION;
    const obj: IAppTxMapping = this.props.appIncidentConfig.mapping[this.props.colIndex];

    if (obj && obj.key) {
      if (INCIDENT_MAPPING_OPTIONS[obj.key]) { mappingType = INCIDENT_MAPPING.INCIDENT_ID; }
    }

    if (obj && obj.tabId && obj.fieldId) { mappingType = INCIDENT_MAPPING.CUSTOM_FIELD; }
    return mappingType;
  }

  /** Incidents mapping options are static */
  public getIncidentMappingOptions(): DropdownItemProps[] {
    const options: DropdownItemProps[] = getOptions(INCIDENT_MAPPING_OPTIONS);
    options.push({ text: NO_MAPPING_OPTION.caption, value: NO_MAPPING_OPTION.key });
    return options;
  }

  /** Get dropdown for selecting mapping type (incident field or profile field) */
  public getIncidentMappingSelect(): JSX.Element {
    return (
      <Dropdown
        options={this.mappingOptions}
        value={this.state.mappingType}
        onChange={this.onIncidentMappingChange}
        selectOnBlur={false}
        selection
        search
        fluid
      />
    );
  }

  /** Update own state and notify parent about mapping type change */
  public onIncidentMappingChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.setState({ mappingType: changes.value.toString() });

    /* Incident ID mapping requires no further configuration */
    if (changes.value.toString() === INCIDENT_MAPPING.INCIDENT_ID) {
      this.onWarningChange(false);
      this.props.onSelfKeyMappingChange(this.props.colIndex, changes.value.toString() as INCIDENT_KEY);
      return;
    }

    if (this.state.mappingType !== changes.value.toString()) {
      this.onWarningChange(true);
      this.props.onMappingReset(this.props.colIndex);
    }

    if (changes.value as string === this.DEFAULT_MAPPING_OPTION) {
      this.setState({ warning: false });
    }
  }

  /** Warning state shows or hides error sign for current row */
  public onWarningChange(warning: boolean): void {
    this.setState({ warning });
    this.props.onWarningChange(warning);
  }

  /** Get additional options based on selected mapping type */
  public getAdditionalOptions(): JSX.Element {
    if (this.state.mappingType === INCIDENT_MAPPING.CUSTOM_FIELD) {
      return (
        <CustomMappingOptions
          colIndex={this.props.colIndex}
          tabs={this.props.tabs}
          fields={this.props.fields}
          config={this.props.appIncidentConfig.mapping}

          onWarningChange={this.onWarningChange}
          onCustomTabMappingChange={this.props.onCustomTabMappingChange}
          onCustomFieldMappingChange={this.props.onCustomFieldMappingChange}
        />
      );
    }

    return null;
  }

  /** Get column formatting for consistent layout based on current state */
  public getFormatting(): JSX.Element {
    const showPlaceholder: boolean = this.state.mappingType === this.DEFAULT_MAPPING_OPTION
      || this.state.mappingType === INCIDENT_MAPPING.INCIDENT_ID;

    if (showPlaceholder) {
      return (
        <React.Fragment>
          <Table.Cell key="placeholder-a" />
          <Table.Cell key="placeholder-b" />
        </React.Fragment>
      );
    }

    if (this.state.mappingType !== INCIDENT_MAPPING.CUSTOM_FIELD) {
      return <Table.Cell key="placeholder-a" />;
    }

    return null;
  }

  /**
   * Get status for column mapping (done, empty or warning)
   * Empty rows are allowed, the ones with warning are not
   */
  public getStatus(): JSX.Element {
    const STATUS_CELL_WIDTH: SemanticWIDTHS = 1;
    const empty: boolean = this.state.mappingType === this.DEFAULT_MAPPING_OPTION;
    const icon: SemanticICONS = (empty)
      ? 'warning'
      : (this.state.warning)
        ? 'warning sign'
        : 'checkmark';

    return (
      <Table.Cell
        textAlign="center"
        negative={!empty && this.state.warning}
        positive={!empty && !this.state.warning}
        width={STATUS_CELL_WIDTH}
      >
        <Icon name={icon} />
      </Table.Cell>
    );
  }

  public render(): JSX.Element {
    const name: string = this.props.name
      || format(CONFIG_VIEW_TEXT.COLUMN_INDEX, { index: this.props.colIndex + 1 });

    return (
      <Table.Row data-component="incident-mapping-options">
        {this.getStatus()}
        <Table.Cell>{name}</Table.Cell>

        <Table.Cell>
          {this.getIncidentMappingSelect()}
        </Table.Cell>

        {this.getAdditionalOptions()}
        {this.getFormatting()}
      </Table.Row>
    );
  }
}

export interface IIncidentMappingOptionsState {
  /** Can be either INCIDENT_MAPPING_TYPE or NO_MAPPING */
  readonly mappingType: string;
  /** Used to display row warnings or errors */
  readonly warning: boolean;
}

export interface IIncidentMappingOptionsProps {
  readonly name: string;
  readonly colIndex: string;
  readonly appIncidentConfig: IAppTxConfig;
  readonly tabs: { [key: number]: ITabData };
  readonly fields: { [key: number]: IFieldData };

  onMappingReset(colIndex: string): void;
  onWarningChange(warning: boolean): void;
  onSelfKeyMappingChange(colIndex: string, key: INCIDENT_KEY): void;
  onCustomTabMappingChange(colIndex: string, tabId: string): void;
  onCustomFieldMappingChange(colIndex: string, fieldId: string): void;
}
