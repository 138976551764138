import * as React from 'react';
import { Button, Form, Grid, InputOnChangeData, Loader } from 'semantic-ui-react';
import { ErrorHandlerContainer } from '../error-handler';
import { COMMON_TEXT, LOGIN_VIEW_TEXT } from '../../constants';
import './login-view.less';

/**
 * View for "Login" component
 */
export class LoginView extends React.Component<ILoginViewProps> {
  constructor(props: ILoginViewProps) {
    super(props);
  }

  /** Form fields use "name" property to assign inputs */
  public getInputForm(): JSX.Element {
    const error: boolean = this.props.error !== null;

    return (
      <Form error={error}>
        <Form.Input
          label={LOGIN_VIEW_TEXT.USER_ID}
          name="user"
          type="text"
          value={this.props.user}
          onChange={this.props.onChange}
          required
        />
        <Form.Input
          label={LOGIN_VIEW_TEXT.PASSWORD}
          name="password"
          type="password"
          value={this.props.password}
          onChange={this.props.onChange}
          required
        />
        <Form.Input
          label={LOGIN_VIEW_TEXT.DOMAIN}
          name="domain"
          type="text"
          value={this.props.domain}
          onChange={this.props.onChange}
          required
        />
        {this.getError()}
        {this.getActionButton()}
      </Form>
    );
  }

  /** Get "Log In" button */
  public getActionButton(): JSX.Element {
    return (
      <Button
        content={LOGIN_VIEW_TEXT.LOG_IN}
        loading={this.props.loading}
        disabled={this.props.loading}
        onClick={this.props.onLogIn}
        primary
        fluid
      />
    );
  }

  /** Get error message if it exists */
  public getError(): JSX.Element {
    return (this.props.error)
      ? <ErrorHandlerContainer response={this.props.error} />
      : null;
  }

  public render(): JSX.Element {
    const loader: JSX.Element = (
      <Loader active inverted inline="centered" content={COMMON_TEXT.LOADING} />
    );

    const content: JSX.Element = (
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column width={16}>
            <img src={'/static/logo_navy.png'} alt={LOGIN_VIEW_TEXT.LOGO_ALT} />
            {this.getInputForm()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );

    return (
      <Grid data-component="login-view" textAlign="center" verticalAlign="middle">
        <Grid.Row textAlign="center">
          <Grid.Column width={4} textAlign="center" verticalAlign="middle">
            {this.props.checkingToken ? loader : content}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export interface ILoginViewProps {
  readonly user: string;
  readonly password: string;
  readonly domain: string;
  readonly loading: boolean;
  readonly checkingToken: boolean;
  readonly error: Response;
  onLogIn(): void;
  onChange(e: React.KeyboardEvent<HTMLInputElement>, changes: InputOnChangeData): void;
}
