import * as React from 'react';
import { IActivityDataSaveConnectProps } from './activity-data-save-container';
import { DataSaveContainer } from '../../data-save';
import { DataLoadApi } from '../../../api';
import { IActivityConfig } from '../../../interfaces';
import { DATA_LOAD_TYPE } from '../../../constants';
import { getApiActivityConfig } from '../../../functions';

/**
 * Wrapper around "Data Save" component for Control
 */
export class ActivityDataSave extends React.Component<IActivityDataSaveConnectProps> {
  constructor(props: IActivityDataSaveConnectProps) {
    super(props);
    this.onRetry = this.onRetry.bind(this);
  }

  public onRetry(): Promise<{}> {
    const config: IActivityConfig = getApiActivityConfig({
      config: this.props.config.activity,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadActivityConfig({ config, columns: this.props.config.columns }).request();
  }

  public render(): JSX.Element {
    if (!this.props.config.activity) { return null; }

    const nRows: number = this.props.config.activity.tableConfig.dataEndRow
      - this.props.config.activity.tableConfig.dataStartRow;

    return (
      <DataSaveContainer
        nRows={nRows}
        dataLoadType={DATA_LOAD_TYPE.CONTROL}
        onRetry={this.onRetry}
      />
    );
  }
}
