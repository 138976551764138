import * as React from 'react';
import { IConfigActionsConnectProps } from './config-actions-container';
import { ConfigActionsContainer } from '../../config/config-actions';
import { DataLoadApi } from '../../../api';
import { IUserManagementConfig } from '../../../interfaces';
import { getApiUserManagementConfig } from '../../../functions';

export class ConfigActions extends React.Component<IConfigActionsConnectProps> {

  constructor(props: IConfigActionsConnectProps) {
    super(props);
    this.uploadConfig = this.uploadConfig.bind(this);
  }

  /** Try to upload config on server for dry run */
  public uploadConfig(): Promise<{}> {
    const config: IUserManagementConfig = getApiUserManagementConfig({
      config: this.props.config.userManagement,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadUserManagementConfig(config).request();
  }

  public render(): JSX.Element {
    return (
      <ConfigActionsContainer
        warning={this.props.warning}
        uploadConfig={this.uploadConfig}
      />
    );
  }
}
