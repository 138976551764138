import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { MatchPlanKeyOptions } from './match-plan-key-options';
import { IState, IUIState } from '../../../interfaces';
import { MATCH_PLAN_KEY } from '../../../constants';

export interface IMatchPlanKeyOptionsProps {
  onChange?(action: MATCH_PLAN_KEY): void;
}

export interface IMatchPlanKeyStateProps extends IMatchPlanKeyOptionsProps {
  readonly ui: IUIState;
}

export type IMatchPlanKeyOptionsConnectProps = IMatchPlanKeyStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IMatchPlanKeyOptionsProps): IMatchPlanKeyStateProps {
  return { ui: state.ui, ...props };
}

export const MatchPlanKeyOptionsContainer: React.ComponentClass<IMatchPlanKeyOptionsProps>
  = ReactRedux.connect(mapStateToProps)(MatchPlanKeyOptions);
