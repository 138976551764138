import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { MatchIndicatorKeyOptionsContainer } from '../../config/match-indicator-key-options';
import './il-config-options.less';

/**
 * Indicator-Indicator Links component combines relevant config option containers
 */
export class ILConfigOptions extends React.Component {
  constructor(props: object) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <Form data-component="il-config-options">
        <Form.Group widths="equal">
          <MatchIndicatorKeyOptionsContainer />
        </Form.Group>
      </Form>
    );
  }
}
