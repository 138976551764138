import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { ProcessTypeOptions } from './process-type-options';
import { IState, IUIState } from '../../../interfaces';
import { PROCESS_TYPE } from '../../../constants';

export interface IProcessTypeOptionsProps {
  readonly label?: string;
  onChange?(action: PROCESS_TYPE): void;
}

export interface IProcessTypeOptionsStateProps extends IProcessTypeOptionsProps {
  readonly ui: IUIState;
}

export type IProcessTypeOptionsConnectProps = IProcessTypeOptionsStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IProcessTypeOptionsProps): IProcessTypeOptionsStateProps {
  return { ui: state.ui, ...props };
}

export const ProcessTypeOptionsContainer: React.ComponentClass<IProcessTypeOptionsProps>
  = ReactRedux.connect(mapStateToProps)(ProcessTypeOptions);
