import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { MADataUploadActions } from './ma-data-upload-actions';
import { IState, IUploadState } from '../../../interfaces';

export interface IMADataUploadActionsStateProps {
  readonly upload: IUploadState;
}

export type IMADataUploadActionsConnectProps = IMADataUploadActionsStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IMADataUploadActionsStateProps {
  return { upload: state.upload };
}

export const MADataUploadActionsContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(MADataUploadActions);
