import * as React from 'react';
import { ITxConfigActionsConnectProps } from './tx-config-actions-container';
import { ConfigActionsContainer } from '../../config/config-actions';
import { DataLoadApi } from '../../../api';
import { ITxConfig } from '../../../interfaces';
import { getApiTxConfig } from '../../../functions';

/**
 * Taxonomy "Check Configuration" action buttons
 * Component checks active Taxonomy configuration and makes server requests
 */
export class TxConfigActions extends React.Component<ITxConfigActionsConnectProps> {

  constructor(props: ITxConfigActionsConnectProps) {
    super(props);
    this.uploadConfig = this.uploadConfig.bind(this);
  }

  /** Try to upload config on server for dry run */
  public uploadConfig(): Promise<{}> {
    const config: ITxConfig = getApiTxConfig({
      config: this.props.config.taxonomy,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadTxConfig({ config, columns: this.props.config.columns }).request();
  }

  public render(): JSX.Element {
    return (
      <ConfigActionsContainer
        warning={this.props.warning}
        uploadConfig={this.uploadConfig}
      />
    );
  }
}
