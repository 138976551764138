export enum INDICATOR_MAPPING {
  INDICATOR_FIELD = 'INDICATOR_FIELD',
  FACTOR_FIELD = 'FACTOR_FIELD',
  CATEGORY_FIELD = 'CATEGORY_FIELD'
}

/** Options with captions for "Mapping Type" */
export const INDICATOR_MAPPING_OPTIONS: { [key: string]: string } = {
  [INDICATOR_MAPPING.INDICATOR_FIELD]: 'Indicator Field',
  [INDICATOR_MAPPING.FACTOR_FIELD]: 'Factor Field',
  [INDICATOR_MAPPING.CATEGORY_FIELD]: 'Category Field'
};

export enum INDICATOR_KEY {
  CATEGORY = 'category',
  FACTOR = 'factor',
  INDICATOR = 'event',
  INDICATOR_ID = 'event_id',
  FACTOR_ID = 'factor_id',
  CATEGORY_ID = 'category_id',
  INDICATOR_DESCRIPTION = 'ind_description',
  FACTOR_DESCRIPTION = 'fac_description',
  CATEGORY_DESCRIPTION = 'cat_description',
  INDICATOR_KEYWORDS = 'ind_keywords',
  FACTOR_KEYWORDS = 'fac_keywords',
  CATEGORY_KEYWORDS = 'cat_keywords',
  INDICATOR_STATUS = 'ind_status',
  FACTOR_STATUS = 'fac_status',
  CATEGORY_STATUS = 'cat_status',
  INDICATOR_SOURCE = 'source_text',
  FACTOR_TOLERANCE_FROM = 'toleranceFrom',
  FACTOR_TOLERANCE_T = 'toleranceTo',
}

export const INDICATOR_FIELD_OPTIONS: { [key: string]: string } = {
  [INDICATOR_KEY.INDICATOR_ID]: 'Indicator ID',
  [INDICATOR_KEY.INDICATOR]: 'Indicator Name',
  [INDICATOR_KEY.INDICATOR_DESCRIPTION]: 'Indicator Description',
  [INDICATOR_KEY.INDICATOR_SOURCE]: 'Indicator Source',
  [INDICATOR_KEY.INDICATOR_KEYWORDS]: 'Indicator Keywords',
  [INDICATOR_KEY.INDICATOR_STATUS]: 'Indicator Status'
};

export const FACTOR_FIELD_OPTIONS: { [key: string]: string } = {
  [INDICATOR_KEY.FACTOR_ID]: 'Factor ID',
  [INDICATOR_KEY.FACTOR]: 'Factor Name',
  [INDICATOR_KEY.FACTOR_DESCRIPTION]: 'Factor Description',
  [INDICATOR_KEY.FACTOR_KEYWORDS]: 'Factor Keywords',
  [INDICATOR_KEY.FACTOR_STATUS]: 'Factor Status',
  [INDICATOR_KEY.FACTOR_TOLERANCE_FROM]: 'Tolerance From',
  [INDICATOR_KEY.FACTOR_TOLERANCE_T]: 'Tolerance To'
};

export const CATEGORY_FIELD_OPTIONS: { [key: string]: string } = {
  [INDICATOR_KEY.CATEGORY_ID]: 'Category ID',
  [INDICATOR_KEY.CATEGORY]: 'Category Name',
  [INDICATOR_KEY.CATEGORY_DESCRIPTION]: 'Category Description',
  [INDICATOR_KEY.CATEGORY_KEYWORDS]: 'Category Keywords',
  [INDICATOR_KEY.CATEGORY_STATUS]: 'Category Status'
};
