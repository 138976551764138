import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { DataSave } from './data-save';
import { IColumns, IResultState, IState, IUIState, IUploadState } from '../../interfaces';
import { DATA_LOAD_TYPE } from '../../constants';

export interface IDataSaveProps {
  /** Amount of rows in active data load request */
  readonly nRows: number;
  /** Type caption like "Elements", "Incidents", etc */
  readonly dataLoadType: DATA_LOAD_TYPE;
  /** Callback for "Retry" button */
  onRetry(): Promise<{}>;
}

export interface IDataSaveStateProps extends IDataSaveProps {
  readonly ui: IUIState;
  readonly upload: IUploadState;
  readonly result: IResultState;
  readonly columns: IColumns;
}

export type IDataSaveConnectProps = IDataSaveStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IDataSaveProps): IDataSaveStateProps {
  return {
    ui: state.ui,
    upload: state.upload,
    result: state.result,
    columns: state.config.columns,
    ...props
  };
}

export const DataSaveContainer: React.ComponentClass<IDataSaveProps>
  = ReactRedux.connect(mapStateToProps)(DataSave);
