import 'core-js';
import 'regenerator-runtime';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './containers/app';
import { BaseApi } from './api';
import BUILD from '../config/config.js';
/* TODO: URLSearchParams for IE and Edge */
import 'url-search-params-polyfill';
import 'url-polyfill';
import './index.app.less';

/* tslint:disable-next-line */
console.warn('Version: ', BUILD.VERSION);
BaseApi.configure({ baseUrl: BUILD.BASE_URL });

BaseApi.getVersion().then((response: { version: string }): void => {
  /* tslint:disable-next-line */
  console.warn('API Version: ', response.version);
});

ReactDOM.render(
  <App />,
  document.getElementById('app-root')
);
