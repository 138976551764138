import * as React from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { ErrorHandlerContainer } from '../../error-handler';
import { IUIState } from '../../../interfaces';
import { DATA_LOAD_STAGE, DATA_SAVE_VIEW_TEXT } from '../../../constants';
import './data-save-actions-view.less';

/**
 * View for "Data Save Actions" component
 */
export class DataSaveActionsView extends React.Component<IDataSaveActionsViewProps> {
  constructor(props: IDataSaveActionsViewProps) {
    super(props);
  }

  /** "Back" button is disabled when request is loading */
  public getBackButton(): JSX.Element {
    const disabled: boolean = this.props.loading
      || this.props.disabled
      || this.props.ui.stage === DATA_LOAD_STAGE.DATA_SAVE_COMPLETE;

    return (
      <Button
        content={DATA_SAVE_VIEW_TEXT.BACK}
        onClick={this.props.onBack}
        disabled={disabled}
        icon="arrow left"
        primary
        fluid
      />
    );
  }

  /** "Cancel Data Load" button is disabled when request is loading */
  public getCancelButton(): JSX.Element {
    const disabled: boolean = this.props.loading
      || this.props.disabled
      || this.props.ui.stage === DATA_LOAD_STAGE.DATA_SAVE_COMPLETE;

    return (
      <Button
        content={DATA_SAVE_VIEW_TEXT.CANCEL_DATA_LOAD}
        onClick={this.props.onCancel}
        loading={this.props.loading}
        disabled={disabled}
        icon="cancel"
        negative
        fluid
      />
    );
  }

  /** Confirm save data action */
  public getSaveDataButton(): JSX.Element {
    const disabled: boolean = this.props.loading
      || this.props.disabled
      || this.props.ui.stage !== DATA_LOAD_STAGE.DRY_RUN_COMPLETE;

    return (
      <Button
        content={DATA_SAVE_VIEW_TEXT.PROCEED_WITH_DATA_LOAD}
        onClick={this.props.onSaveData}
        loading={this.props.loading}
        disabled={disabled}
        icon="database"
        positive
        fluid
      />
    );
  }

  /** Retry button is active when data saving has failed */
  public getRetryButton(): JSX.Element {
    const disabled: boolean = this.props.loading
      || this.props.disabled
      || this.props.ui.stage !== DATA_LOAD_STAGE.DATA_SAVE_FAILED;

    return (
      <Button
        content={DATA_SAVE_VIEW_TEXT.RETRY_DRY_RUN}
        onClick={this.props.onRetry}
        loading={this.props.loading}
        disabled={disabled}
        icon="repeat"
        positive
        fluid
      />
    );
  }

  /** "Done" button is active when data save has been successful */
  public getDoneButton(): JSX.Element {
    return (
      <Button
        content={DATA_SAVE_VIEW_TEXT.DONE}
        onClick={this.props.onDone}
        loading={this.props.loading}
        icon="check"
        positive
        fluid
      />
    );
  }

  /** Right button can be "Proceed With Data Load", "Retry Data Load" or "Done!" */
  public getRightButton(): JSX.Element {
    const allowRetry: boolean = this.props.ui.stage === DATA_LOAD_STAGE.DATA_SAVE_FAILED;

    return (this.props.ui.stage === DATA_LOAD_STAGE.DATA_SAVE_COMPLETE)
      ? this.getDoneButton()
      : (allowRetry)
        ? this.getRetryButton()
        : this.getSaveDataButton();
  }

  /** Display error message if it exists */
  public getErrorMessage(): JSX.Element {
    if (!this.props.error) { return null; }

    return (
      <Grid.Row columns="equal" className="error-message">
        <Grid.Column>
          <ErrorHandlerContainer response={this.props.error} />
        </Grid.Column>
      </Grid.Row>
    );
  }

  public render(): JSX.Element {
    return (
      <Grid data-component="data-save-actions-view">
        {this.getErrorMessage()}

        <Grid.Row columns="equal" className="buttons">
          <Grid.Column>
            {this.getBackButton()}
          </Grid.Column>

          <Grid.Column>
            {this.getCancelButton()}
          </Grid.Column>

          <Grid.Column>
            {this.getRightButton()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export interface IDataSaveActionsViewProps {
  readonly error: Response;
  readonly loading: boolean;
  readonly disabled: boolean;
  readonly ui: IUIState;
  onDone(): void;
  onBack(): void;
  onRetry(): void;
  onCancel(): void;
  onSaveData(): void;
}
