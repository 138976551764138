import * as React from 'react';
import { Grid, Loader, SemanticWIDTHS, Table } from 'semantic-ui-react';
import { DataLoadHistoryHeader, DataLoadHistoryItem } from '../../components';
import { ErrorHandlerContainer } from '../error-handler';
import { IDataLoadHistoryItem } from '../../interfaces';
import { COMMON_TEXT, DATA_LOAD_HISTORY_COLUMN, SORT_DIRECTION } from '../../constants';
import './data-load-history-view.less';

/**
 * View for "Data Load History" component
 */
export class DataLoadHistoryView extends React.Component<IDataLoadHistoryViewProps> {
  public MAX_COLUMNS: number = 7;

  constructor(props: IDataLoadHistoryViewProps) {
    super(props);
  }

  /** Table body contains rows with data load information */
  public getTableBody(): JSX.Element {
    const rows: JSX.Element[] = [];

    for (let i: number = 0; i < this.props.items.length; i++) {
      const item: IDataLoadHistoryItem = this.props.items[i];
      const row: JSX.Element = (
        <DataLoadHistoryItem
          key={item.id}
          rowIndex={i}
          item={item}
          loading={this.props.activeLoadingItemId === item.id}
          onLogFileDownload={this.props.onLogFileDownload}
          onDataFileDownload={this.props.onDataFileDownload}
        />
      );
      rows.push(row);
    }

    return <Table.Body>{rows}</Table.Body>;
  }

  /** Show loader on while history request is in progress */
  public getLoader(): JSX.Element {
    return (
      <Table.Body>
        <Table.Row>
          <Table.Cell colSpan={this.MAX_COLUMNS}>
            <Loader active inline="centered" content={COMMON_TEXT.LOADING} />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    );
  }

  /** Show default error message for failed request */
  public getErrorMessage(): JSX.Element {
    return (
      <Table.Body>
        <Table.Row>
          <Table.Cell colSpan={this.MAX_COLUMNS}>
            <ErrorHandlerContainer response={this.props.error} />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    );
  }

  /** Data load statuses are displayed as table */
  public getDataLoadTable(): JSX.Element {
    const content: JSX.Element = (this.props.loading)
      ? this.getLoader()
      : this.props.error
        ? this.getErrorMessage()
        : this.getTableBody();

    return (
      <Table selectable={!this.props.error} compact="very" sortable celled>
        <DataLoadHistoryHeader
          error={this.props.error}
          loading={this.props.loading}
          colSpan={this.MAX_COLUMNS}
          sortColumn={this.props.sortColumn}
          sortDirection={this.props.sortDirection}
          onSort={this.props.onSort}
        />
        {content}
      </Table>
    );
  }

  public render(): JSX.Element {
    const CONTENT_WIDTH: SemanticWIDTHS = 16;

    return (
      <Grid data-component="data-load-history-view">
        <Grid.Row columns={1} centered>
          <Grid.Column width={CONTENT_WIDTH}>
            {this.getDataLoadTable()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export interface IDataLoadHistoryViewProps {
  readonly error: Response;
  readonly loading: boolean;
  readonly activeLoadingItemId: string;
  readonly items: IDataLoadHistoryItem[];
  readonly sortColumn: DATA_LOAD_HISTORY_COLUMN;
  readonly sortDirection: SORT_DIRECTION;
  onSort(column: DATA_LOAD_HISTORY_COLUMN): void;
  onLogFileDownload(item: IDataLoadHistoryItem): void;
  onDataFileDownload(item: IDataLoadHistoryItem): void;
}
