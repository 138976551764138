import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { Workflow } from './workflow';
import { IState, IUIState } from '../../../interfaces';

export interface IUserManagementWorkflowStateProps {
  readonly ui: IUIState;
}

export type IUserManagementWorkflowConnectProps =
  IUserManagementWorkflowStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IUserManagementWorkflowStateProps {
  return { ui: state.ui };
}

export const WorkflowContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(Workflow);
