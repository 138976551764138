import * as Redux from 'redux';
import { IAuthTicket } from '../interfaces';

export const SET_AUTH_TOKEN: string = 'SET_AUTH_TOKEN';
export const LOGOUT_USER: string = 'LOGOUT_USER';

export interface ISetAuthToken extends Redux.Action {
  readonly token: IAuthTicket;
}

/** Set auth token as a complete new object */
export function setAuthToken(token: IAuthTicket): ISetAuthToken {
  return { type: SET_AUTH_TOKEN, token };
}

/** Remove auth information from Redux state */
export function logout(): Redux.Action {
  return { type: LOGOUT_USER };
}
