import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { ConfigActions } from './config-actions';
import { IState, IUIState, IUploadState } from '../../../interfaces';

export interface IConfigActionsProps {
  readonly warning: boolean;
  uploadConfig(): Promise<{}>;
}

export interface IConfigActionsStateProps extends IConfigActionsProps {
  readonly ui: IUIState;
  readonly upload: IUploadState;
}

export type IConfigActionsConnectProps = IConfigActionsStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IConfigActionsProps): IConfigActionsStateProps {
  return {
    ui: state.ui,
    upload: state.upload,
    ...props
  };
}

export const ConfigActionsContainer: React.ComponentClass<IConfigActionsProps>
  = ReactRedux.connect(mapStateToProps)(ConfigActions);
