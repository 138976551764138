import * as React from 'react';
import { IActivityConfigConnectProps } from './activity-config-container';
import { ActivityConfigView } from './activity-config-view';
import { IAppActivityConfig } from '../../../interfaces';
import { CONTROL_KEY, PLAN_KEY } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Control column mapping configuration
 */
export class ActivityConfig extends React.Component<IActivityConfigConnectProps, IActivityConfigState> {
  constructor(props: IActivityConfigConnectProps) {
    super(props);
    this.state = {
      warning: false
    };

    this.onWarningChange = this.onWarningChange.bind(this);
    this.onMappingReset = this.onMappingReset.bind(this);
    this.onActivityColMappingChange = this.onActivityColMappingChange.bind(this);
  }

  public onActivityColMappingChange(colIndex: string, key: CONTROL_KEY | PLAN_KEY): void {
    const config: IAppActivityConfig = {
      ...this.props.config.activity,
      mitigationActivityConfig: {
        ...this.props.config.activity.mitigationActivityConfig,
        [colIndex]: key
      }
    };

    this.props.dispatch(Actions.Config.setActivityConfig(config));
  }

  public onMappingReset(colIndex: string): void {
    const mitigationActivityConfig: { [key: string]: CONTROL_KEY | PLAN_KEY }
      = this.props.config.activity.mitigationActivityConfig;
    delete mitigationActivityConfig[colIndex];

    const config: IAppActivityConfig = {
      ...this.props.config.activity,
      mitigationActivityConfig,
    };

    this.props.dispatch(Actions.Config.setActivityConfig(config));
  }

  public onWarningChange(warning: boolean): void {
    this.setState({ warning });
  }

  public showConfigMapping(): boolean {
    return !!this.props.ui.dataLoadAction && !!this.props.ui.matchPlanKey
      && !!this.props.ui.matchActivityKey;
  }

  public render(): JSX.Element {
    if (!this.props.config.activity) { return null; }

    return (
      <ActivityConfigView
        columns={this.props.config.columns}
        warning={this.state.warning}
        optionsReady={this.showConfigMapping()}
        appActivityConfig={this.props.config.activity}

        onWarningChange={this.onWarningChange}
        onMappingReset={this.onMappingReset}
        onActivityColMappingChange={this.onActivityColMappingChange}
      />
    );
  }
}

export interface IActivityConfigState {
  readonly warning: boolean;
}
