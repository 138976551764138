import * as React from 'react';
import {
  Dropdown, DropdownItemProps, DropdownProps, Icon, SemanticICONS, SemanticWIDTHS, Table
} from 'semantic-ui-react';
import {
  CONTROL_KEY, CONTROL_KEY_OPTIONS, CONTROL_MAPPING, CONTROL_MAPPING_OPTIONS, CONFIG_VIEW_TEXT, NO_MAPPING_OPTION,
  PLAN_KEY, PLAN_KEY_OPTIONS
} from '../../../constants';
import { format, getOptions } from '../../../functions';
import './activity-mapping-options.less';

/**
 * Column mapping configuration options for Control
 */
export class ActivityMappingOptions
  extends React.Component<IActivityMappingOptionsProps, IActivityMappingOptionsState> {
  constructor(props: IActivityMappingOptionsProps) {
    super(props);
    this.state = {
      activityKey: this.getActivityKey(props),
      mappingType: this.getDefaultType(),
      mappingTypeOptions: this.getTypeOptions(),
      warning: false
    };

    this.onTypeChange = this.onTypeChange.bind(this);
    this.onActivityColMappingChange = this.onActivityColMappingChange.bind(this);
  }

  public getTypeOptions(): DropdownItemProps[] {
    const options: DropdownItemProps[] = getOptions(CONTROL_MAPPING_OPTIONS);
    options.push({ value: NO_MAPPING_OPTION.key, text: NO_MAPPING_OPTION.caption });
    return options;
  }

  public getActivityKey(props: IActivityMappingOptionsProps): string {
    return props.mitigationActivityConfig[props.colIndex] || NO_MAPPING_OPTION.key;
  }

  public getDefaultType(): string {
    const key: string = this.getActivityKey(this.props);
    let type: string = NO_MAPPING_OPTION.key;
    if (PLAN_KEY_OPTIONS[key]) { type = CONTROL_MAPPING.PLAN_ID; }
    if (CONTROL_KEY_OPTIONS[key]) { type = CONTROL_MAPPING.CONTROL_KEY; }

    return type;
  }

  public onTypeChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    const warning: boolean = !(changes.value.toString() === NO_MAPPING_OPTION.key);
    this.setState({
      mappingType: changes.value.toString(),
      activityKey: NO_MAPPING_OPTION.key
    });
    this.props.onMappingReset(this.props.colIndex);
    this.onWarningChange(warning);
  }

  public onActivityColMappingChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.setState({ activityKey: changes.value.toString() });
    this.props.onActivityColMappingChange(this.props.colIndex, changes.value.toString() as CONTROL_KEY);
    this.onWarningChange(false);
  }

  public onWarningChange(warning: boolean): void {
    this.setState({ warning });
    this.props.onWarningChange(warning);
  }

  public getStatus(): JSX.Element {
    const STATUS_CELL_WIDTH: SemanticWIDTHS = 1;
    const empty: boolean = this.state.mappingType === NO_MAPPING_OPTION.key;
    const icon: SemanticICONS = (empty)
      ? 'warning'
      : (this.state.warning)
        ? 'warning sign'
        : 'checkmark';

    return (
      <Table.Cell
        textAlign="center"
        negative={!empty && this.state.warning}
        positive={!empty && !this.state.warning}
        width={STATUS_CELL_WIDTH}
      >
        <Icon name={icon} />
      </Table.Cell>
    );
  }

  public getActivityOptions(): JSX.Element {
    let options: DropdownItemProps[] = [];
    switch (this.state.mappingType) {
      case CONTROL_MAPPING.PLAN_ID:
        options = getOptions(PLAN_KEY_OPTIONS);
        break;
      case CONTROL_MAPPING.CONTROL_KEY:
        options = getOptions(CONTROL_KEY_OPTIONS);
        break;
      default: {
        return null;
      }
    }

    return (
      <Dropdown
        options={options}
        value={this.state.activityKey}
        onChange={this.onActivityColMappingChange}
        selectOnBlur={false}
        selection
        search
        fluid
      />
    );
  }

  public render(): JSX.Element {
    const name: string = this.props.name
      || format(CONFIG_VIEW_TEXT.COLUMN_INDEX, { index: (Number(this.props.colIndex) || 0) + 1 });

    return (
      <Table.Row data-component="activity-mapping-options">
        {this.getStatus()}

        <Table.Cell width={5}>
          {name}
        </Table.Cell>

        <Table.Cell width={5}>
          <Dropdown
            options={this.state.mappingTypeOptions}
            value={this.state.mappingType}
            onChange={this.onTypeChange}
            selectOnBlur={false}
            selection
            fluid
          />
        </Table.Cell>

        <Table.Cell width={5}>
          {this.getActivityOptions()}
        </Table.Cell>
      </Table.Row>
    );
  }
}

export interface IActivityMappingOptionsState {
  readonly activityKey: string;
  readonly warning: boolean;
  readonly mappingType: string;
  readonly mappingTypeOptions: DropdownItemProps[];
}

export interface IActivityMappingOptionsProps {
  readonly colIndex: string;
  readonly name: string;
  readonly mitigationActivityConfig: { [key: number]: CONTROL_KEY | PLAN_KEY };

  onMappingReset(colIndex: string): void;
  onWarningChange(warning: boolean): void;
  onActivityColMappingChange(colIndex: string, key: CONTROL_KEY | PLAN_KEY): void;
}
