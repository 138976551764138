import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { ActivityDataUpload } from './activity-data-upload';
import { IState, IUploadState } from '../../../interfaces';

export interface IActivityDataUploadStateProps {
  readonly upload: IUploadState;
}

export type IActivityDataUploadConnectProps = IActivityDataUploadStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IActivityDataUploadStateProps {
  return { upload: state.upload };
}

export const ActivityDataUploadContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(ActivityDataUpload);
