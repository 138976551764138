import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { DataUploadActions } from './data-upload-actions';
import { IState, IUploadState } from '../../../interfaces';

export interface IDataUploadActionsProps {
  uploadFile(): Promise<{}>;
  getUploadButtonStatus(): boolean;
}

export interface IDataUploadActionsStateProps extends IDataUploadActionsProps {
  readonly upload: IUploadState;
}

export type IDataUploadActionsConnectProps = IDataUploadActionsStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IDataUploadActionsProps): IDataUploadActionsStateProps {
  return { upload: state.upload, ...props };
}

export const DataUploadActionsContainer: React.ComponentClass<IDataUploadActionsProps>
  = ReactRedux.connect(mapStateToProps)(DataUploadActions);
