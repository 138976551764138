import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { AILConfigActions } from './ail-config-actions';
import { IConfigState, IState, IUIState, IUploadState } from '../../../interfaces';

export interface IAILConfigActionsProps {
  readonly warning: boolean;
}

export interface IAILConfigActionsStateProps extends IAILConfigActionsProps {
  readonly ui: IUIState;
  readonly upload: IUploadState;
  readonly config: IConfigState;
}

export type IAILConfigActionsConnectProps = IAILConfigActionsStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IAILConfigActionsProps): IAILConfigActionsStateProps {
  return {
    ui: state.ui,
    upload: state.upload,
    config: state.config,
    ...props
  };
}

export const AILConfigActionsContainer: React.ComponentClass<IAILConfigActionsProps>
  = ReactRedux.connect(mapStateToProps)(AILConfigActions);
