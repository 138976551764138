export enum IPL_MAPPING {
  PROCESS_ID = 'PROCESS_ID',
  INDICATOR_ID = 'INDICATOR_ID',
}

/** Options with captions for "Mapping Type" */
export const IPL_MAPPING_OPTIONS: { [key: string]: string } = {
  [IPL_MAPPING.PROCESS_ID]: 'Process Field',
  [IPL_MAPPING.INDICATOR_ID]: 'Indicator Field',
};

export enum IPL_KEY {
  PROCESS_NAME = 'process_name',
  PROCESS_ID = 'core_id',
  PROCESS_PARENT_ID = 'core_parent_id',
  PROCESS_PARENT_NAME = 'process_parent_name',
  INDICATOR_ID = 'event_id',
  INDICATOR = 'event',
  FACTOR = 'factor',
  CATEGORY = 'category',
}

export const IPL_PROCESS_ID_OPTIONS: { [key: string]: string } = {
  [IPL_KEY.PROCESS_NAME]: 'Process Name',
  [IPL_KEY.PROCESS_ID]: 'Process ID',
  [IPL_KEY.PROCESS_PARENT_ID]: 'Process Parent ID',
  [IPL_KEY.PROCESS_PARENT_NAME]: 'Process Parent Name'
};

export const IPL_INDICATOR_ID_OPTIONS: { [key: string]: string } = {
  [IPL_KEY.INDICATOR_ID]: 'Indicator ID',
  [IPL_KEY.INDICATOR]: 'Indicator Name',
  [IPL_KEY.FACTOR]: 'Factor Name',
  [IPL_KEY.CATEGORY]: 'Category Name'
};
