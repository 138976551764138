import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { DATA_LOAD_HISTORY_COLUMN, DATA_LOAD_HISTORY_TEXT, SORT_DIRECTION } from '../../../constants';
import './data-load-history-header.less';

/**
 * Header for "Data Load History" table with sorting
 */
export class DataLoadHistoryHeader extends React.Component<IDataLoadHistoryHeaderProps> {
  constructor(props: IDataLoadHistoryHeaderProps) {
    super(props);
  }

  /** Check if column is sorted by given key */
  public checkSortOrder(column: DATA_LOAD_HISTORY_COLUMN): SORT_DIRECTION {
    return (column === this.props.sortColumn)
      ? this.props.sortDirection
      : null;
  }

  /** Columns can be sorted on click */
  public getColumnHeaders(): JSX.Element {
    const onTypeSort: () => void = this.props.onSort.bind(this, DATA_LOAD_HISTORY_COLUMN.TYPE);
    const onFileSort: () => void = this.props.onSort.bind(this, DATA_LOAD_HISTORY_COLUMN.FILE_NAME);
    const onStatusSort: () => void = this.props.onSort.bind(this, DATA_LOAD_HISTORY_COLUMN.STATUS);
    const onUserIdSort: () => void = this.props.onSort.bind(this, DATA_LOAD_HISTORY_COLUMN.USER_ID);
    const onLastUpdateSort: () => void = this.props.onSort.bind(this, DATA_LOAD_HISTORY_COLUMN.LAST_UPDATE);

    return (
      <Table.Row>
        <Table.HeaderCell content={DATA_LOAD_HISTORY_TEXT.ROW_INDEX} />

        <Table.HeaderCell
          sorted={this.checkSortOrder(DATA_LOAD_HISTORY_COLUMN.USER_ID)}
          onClick={onUserIdSort}
          content={DATA_LOAD_HISTORY_TEXT.USER_ID}
        />

        <Table.HeaderCell
          sorted={this.checkSortOrder(DATA_LOAD_HISTORY_COLUMN.TYPE)}
          onClick={onTypeSort}
          content={DATA_LOAD_HISTORY_TEXT.TYPE}
        />

        <Table.HeaderCell
          sorted={this.checkSortOrder(DATA_LOAD_HISTORY_COLUMN.FILE_NAME)}
          onClick={onFileSort}
          content={DATA_LOAD_HISTORY_TEXT.FILE_NAME}
        />

        <Table.HeaderCell
          sorted={this.checkSortOrder(DATA_LOAD_HISTORY_COLUMN.LAST_UPDATE)}
          onClick={onLastUpdateSort}
          content={DATA_LOAD_HISTORY_TEXT.LAST_UPDATE}
        />

        <Table.HeaderCell
          sorted={this.checkSortOrder(DATA_LOAD_HISTORY_COLUMN.STATUS)}
          onClick={onStatusSort}
          content={DATA_LOAD_HISTORY_TEXT.STATUS}
        />

        <Table.HeaderCell content={DATA_LOAD_HISTORY_TEXT.ACTIONS} />
      </Table.Row>
    );
  }

  public render(): JSX.Element {
    return (
      <Table.Header data-component="data-load-history-header">
        <Table.Row>
          <Table.HeaderCell
            colSpan={this.props.colSpan}
            content={DATA_LOAD_HISTORY_TEXT.DATA_LOAD_HISTORY_TITLE}
          />
        </Table.Row>

        {this.props.error || this.props.loading ? null : this.getColumnHeaders()}
      </Table.Header>
    );
  }
}

export interface IDataLoadHistoryHeaderProps {
  readonly error: Response;
  readonly loading: boolean;
  readonly colSpan: number;
  readonly sortColumn: DATA_LOAD_HISTORY_COLUMN;
  readonly sortDirection: SORT_DIRECTION;
  onSort(column: DATA_LOAD_HISTORY_COLUMN): void;
}
