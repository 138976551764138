import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { IncidentDataUploadOptions } from './incident-data-upload-options';
import { IIncidentType, IState, IUploadState } from '../../../interfaces';

export interface IIncidentDataUploadOptionsProps {
  readonly loading: boolean;
  readonly incidents: IIncidentType[];
}

export interface IIncidentDataUploadOptionsStateProps extends IIncidentDataUploadOptionsProps {
  readonly upload: IUploadState;
}

export type IIncidentDataUploadOptionsConnectProps = IIncidentDataUploadOptionsStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(
  state: IState,
  props: IIncidentDataUploadOptionsProps
): IIncidentDataUploadOptionsStateProps {
  return { upload: state.upload, ...props };
}

export const IncidentDataUploadOptionsContainer: React.ComponentClass<IIncidentDataUploadOptionsProps>
  = ReactRedux.connect(mapStateToProps)(IncidentDataUploadOptions);
