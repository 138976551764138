import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { BasicDataUpload } from './basic-data-upload';
import { IState, IUploadState } from '../../../interfaces';
import { IUploadBasicFileOptions } from '../../../api';

export interface IBasicDataUploadProps {
  uploadFile(options: IUploadBasicFileOptions): Promise<{}>;
}

export interface IBasicDataUploadStateProps extends IBasicDataUploadProps {
  readonly upload: IUploadState;
}

export type IBasicDataUploadConnectProps = IBasicDataUploadStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IBasicDataUploadProps): IBasicDataUploadStateProps {
  return { upload: state.upload, ...props };
}

export const BasicDataUploadContainer: React.ComponentClass<IBasicDataUploadProps>
  = ReactRedux.connect(mapStateToProps)(BasicDataUpload);
