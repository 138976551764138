import * as React from 'react';
import { IActivityWorkflowConnectProps } from './activity-workflow-container';
import { Workflow } from '../../../components';
import { ActivityDataUploadContainer } from '../activity-data-upload';
import { ActivityConfigContainer } from '../activity-config';
import { ActivityDataSaveContainer } from '../activity-data-save';
import { DataLoadApi } from '../../../api';
import { DATA_LOAD_STAGE } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Control workflow manages transitions between data load flow components
 */
export class ActivityWorkflow extends React.Component<IActivityWorkflowConnectProps> {
  public componentWillMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_UPLOAD_INITIAL));
  }

  public componentWillUnmount(): void {
    DataLoadApi.cancelDataLoad().request()
      .then((): void => {
        this.props.dispatch(Actions.Batch.clearAppState());
      })
      .catch((): void => {
        this.props.dispatch(Actions.Batch.clearAppState());
      });
  }

  public render(): JSX.Element {
    return (
      <Workflow
        stage={this.props.ui.stage}
        upload={<ActivityDataUploadContainer />}
        config={<ActivityConfigContainer />}
        result={<ActivityDataSaveContainer />}
      />
    );
  }
}
