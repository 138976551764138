import * as React from 'react';
import { IMAConfigActionsConnectProps } from './ma-config-actions-container';
import { ConfigActionsContainer } from '../../config/config-actions';
import { DataLoadApi } from '../../../api';
import { IMAConfig } from '../../../interfaces';
import { getApiMAConfig } from '../../../functions';

/**
 * Monitoring Activity "Check Configuration" action buttons
 */
export class MAConfigActions extends React.Component<IMAConfigActionsConnectProps> {
  constructor(props: IMAConfigActionsConnectProps) {
    super(props);
    this.uploadConfig = this.uploadConfig.bind(this);
  }

  public uploadConfig(): Promise<{}> {
    const config: IMAConfig = getApiMAConfig({
      config: this.props.config.MA,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadMAConfig({ config, columns: this.props.config.columns }).request();
  }

  public render(): JSX.Element {
    return (
      <ConfigActionsContainer
        warning={this.props.warning}
        uploadConfig={this.uploadConfig}
      />
    );
  }
}
