import * as React from 'react';
import { IMAConfigConnectProps } from './ma-config-container';
import { MAConfigView } from './ma-config-view';
import { IAppMAConfig } from '../../../interfaces';
import { MA_KEY, PLAN_KEY } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Control column mapping configuration
 */
export class MAConfig extends React.Component<IMAConfigConnectProps, IMAConfigState> {
  constructor(props: IMAConfigConnectProps) {
    super(props);
    this.state = {
      warning: false
    };

    this.onWarningChange = this.onWarningChange.bind(this);
    this.onMappingReset = this.onMappingReset.bind(this);
    this.onMAColMappingChange = this.onMAColMappingChange.bind(this);
  }

  public onMAColMappingChange(colIndex: string, key: MA_KEY | PLAN_KEY): void {
    const config: IAppMAConfig = {
      ...this.props.config.MA,
      monitoringActivityConfig: {
        ...this.props.config.MA.monitoringActivityConfig,
        [colIndex]: key
      }
    };

    this.props.dispatch(Actions.Config.setMAConfig(config));
  }

  public onMappingReset(colIndex: string): void {
    const monitoringActivityConfig: { [key: string]: MA_KEY | PLAN_KEY }
      = this.props.config.MA.monitoringActivityConfig;
    delete monitoringActivityConfig[colIndex];

    const config: IAppMAConfig = {
      ...this.props.config.MA,
      monitoringActivityConfig,
    };

    this.props.dispatch(Actions.Config.setMAConfig(config));
  }

  public onWarningChange(warning: boolean): void {
    this.setState({ warning });
  }

  public showConfigMapping(): boolean {
    return !!this.props.ui.dataLoadAction && !!this.props.ui.matchPlanKey
      && !!this.props.ui.matchMonitoringActivityKey;
  }

  public render(): JSX.Element {
    if (!this.props.config.MA) { return null; }

    return (
      <MAConfigView
        columns={this.props.config.columns}
        warning={this.state.warning}
        optionsReady={this.showConfigMapping()}
        appMAConfig={this.props.config.MA}

        onWarningChange={this.onWarningChange}
        onMappingReset={this.onMappingReset}
        onMAColMappingChange={this.onMAColMappingChange}
      />
    );
  }
}

export interface IMAConfigState {
  readonly warning: boolean;
}
