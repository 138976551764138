export enum FF_KEY {
  FREQUENCY = 'frequency',
  FREQUENCY_INTERVAL = 'frequencyInterval',
  FREQUENCY_DETAIL = 'frequencyDetail',
  CREATE_DAYS_BEFORE_DUE = 'createDaysBeforeDue'
}

export const FF_KEY_OPTIONS: {[key: string]: string} = {
  [FF_KEY.FREQUENCY]: 'Frequency',
  [FF_KEY.FREQUENCY_INTERVAL]: 'Frequency Interval',
  [FF_KEY.FREQUENCY_DETAIL]: 'Frequency Detail',
  [FF_KEY.CREATE_DAYS_BEFORE_DUE]: 'Create Task X day(s) before due date'
};
