import * as React from 'react';
import { IErrorHandlerConnectProps } from './error-handler-container';
import { ErrorHandlerView } from './error-handler-view';
import { IDomainBusyError, ILogEntity } from '../../interfaces';
import {
  ERROR_CODE, GENERAL_ERROR_CODE_OPTIONS, GENERAL_ERROR_CODE_TYPE, GENERAL_ERROR_MESSAGE_TEXT, LOG_IN_ERROR_CODE_TYPE
} from '../../constants';
import { format } from '../../functions';

/**
 * Entry point for handling all application errors
 */
export class ErrorHandler extends React.Component<IErrorHandlerConnectProps, IErrorHandlerState> {
  constructor(props: IErrorHandlerConnectProps) {
    super(props);
    this.state = {
      code: null,
      header: null,
      description: null
    };
  }

  public componentWillMount(): void {
    this.initialize(this.props);
  }

  public componentWillReceiveProps(next: IErrorHandlerConnectProps): void {
    this.initialize(next);
  }

  /** Handle API or application error based on props */
  public initialize(props: IErrorHandlerConnectProps): void {
    if (props.response) {
      return this.handleApiError(props.response);
    }

    if (props.code) {
      return this.setState({ code: props.code, header: null, description: null });
    }
  }

  /** API errors are distinguished by HTTP error code */
  public handleApiError(response: Response): void {
    switch (response.status) {
      case GENERAL_ERROR_CODE_TYPE.DEFAULT:
      case GENERAL_ERROR_CODE_TYPE.UNHANDLED_EXCEPTION:
      case GENERAL_ERROR_CODE_TYPE.PAGE_NOT_FOUND:
      case GENERAL_ERROR_CODE_TYPE.AUTH_EXPIRED:
        this.setState({ code: response.status, header: null, description: null });
        break;
      case GENERAL_ERROR_CODE_TYPE.DOMAIN_BUSY:
        this.handleDomainBusyError(response);
        break;
      case GENERAL_ERROR_CODE_TYPE.GENERAL_ERROR:
      case GENERAL_ERROR_CODE_TYPE.MAX_ROWS_NUMBER_EXCEEDED:
      case GENERAL_ERROR_CODE_TYPE.FILE_MAX_SIZE_EXCEEDED_HTTP:
      case GENERAL_ERROR_CODE_TYPE.FILE_CONVERSION_ERROR:
        this.handleBackendError(response);
        break;
      case GENERAL_ERROR_CODE_TYPE.LOG_IN_DENIED:
        this.handleLogInDenyError(response);
        break;
      default:
        return null;
    }
  }

  /** Get user ID and domain from response and display custom text */
  public handleDomainBusyError(response: Response): void {
    response.json()
      .then((data: IDomainBusyError): void => {
        const description: string = format(
          GENERAL_ERROR_MESSAGE_TEXT.DOMAIN_BUSY_DESCRIPTION,
          {
            domain: data.domain,
            username: data.lockedBy
          }
        );
        this.setState({ code: GENERAL_ERROR_CODE_TYPE.DOMAIN_BUSY, description });
      })
      .catch((): void => {
        this.setState({ code: GENERAL_ERROR_CODE_TYPE.UNHANDLED_EXCEPTION });
      });
  }

  /** User can be denied auth for different reasons */
  public handleLogInDenyError(response: Response): void {
    response.json()
      .then((logs: ILogEntity[]): void => {
        const log: ILogEntity = logs[0];

        switch (log.errorCode) {
          case LOG_IN_ERROR_CODE_TYPE.NO_PERMISSION:
            this.setState({ code: log.errorCode });
            return;
          default:
            this.setState({ code: GENERAL_ERROR_CODE_TYPE.LOG_IN_DENIED });
            return;
        }
      })
      .catch((): void => {
        this.setState({ code: GENERAL_ERROR_CODE_TYPE.UNHANDLED_EXCEPTION });
      });
  }

  /** Handle all DU backend own errors */
  public handleBackendError(response: Response): void {
    response.json()
      .then((logs: ILogEntity[]): void => {
        if (logs.length > 1) { this.getErrorMessageList(logs); }
        if (logs.length === 1) { this.getErrorMessage(logs); }
        if (logs.length === 0) { this.setState({ code: GENERAL_ERROR_CODE_TYPE.UNHANDLED_EXCEPTION }); }
      })
      .catch((): void => {
        this.setState({ code: GENERAL_ERROR_CODE_TYPE.UNHANDLED_EXCEPTION });
      });
  }

  /** If there is more than one error show error message list */
  public getErrorMessageList(logs: ILogEntity[]): void {
    const list: string[] = [];
    for (const log of logs) {
      if (log.message) { list.push(log.message); }
    }
    this.setState({ list, header: GENERAL_ERROR_MESSAGE_TEXT.UNHANDLED_HEADER });
  }

  /** Show single error based on message or error code */
  public getErrorMessage(logs: ILogEntity[]): void {
    const log: ILogEntity = logs[0];

    /* Message that has client description takes priority over server message */
    if (GENERAL_ERROR_CODE_OPTIONS[log.errorCode]) {
      return this.setState({ code: log.errorCode });
    }

    if (log.message) {
      return this.setState({ description: log.message, header: GENERAL_ERROR_MESSAGE_TEXT.UNHANDLED_HEADER });
    }

    if (!log.message) {
      return this.setState({ code: GENERAL_ERROR_CODE_TYPE.UNHANDLED_EXCEPTION });
    }
  }

  public render(): JSX.Element {
    return (
      <ErrorHandlerView
        code={this.state.code}
        grid={this.props.grid}
        list={this.state.list}
        header={this.state.header}
        description={this.state.description}
        dataloadResult={this.props.dataloadResult}
        onDismiss={this.props.onDismiss}
      />
    );
  }
}

export interface IErrorHandlerState {
  readonly code?: ERROR_CODE;
  readonly list?: string[];
  readonly header?: string;
  readonly description?: string;
}
