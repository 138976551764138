import * as React from 'react';
import { Grid, SemanticWIDTHS, Table } from 'semantic-ui-react';
import { UpdateDataFileContainer } from '../../data-upload/update-data-file';
import { ActivityConfigOptions } from '../activity-config-options';
import { ActivityConfigActionsContainer } from '../activity-config-actions';
import { ActivityMappingOptions } from '../../../components';
import { IAppActivityConfig, IColumns } from '../../../interfaces';
import { CONTROL_KEY, CONFIG_VIEW_TEXT, PLAN_KEY } from '../../../constants';
import './activity-config-view.less';

/**
 * View for "Control Config" component
 */
export class ActivityConfigView extends React.Component<IActivityConfigViewProps> {
  public readonly MAX_COLUMNS: SemanticWIDTHS = 5;
  public readonly CONTENT_WIDTH: SemanticWIDTHS = 12;

  constructor(props: IActivityConfigViewProps) {
    super(props);
  }

  public getConfigRows(): JSX.Element[] {
    const rows: JSX.Element[] = [];
    const colIndexes: string[] = Object.keys(this.props.columns);

    for (let i: number = 0; i < colIndexes.length; i++) {
      const colIndex: string = colIndexes[i];

      const row: JSX.Element = (
        <ActivityMappingOptions
          key={i}
          colIndex={colIndex}
          name={this.props.columns[colIndex]}
          mitigationActivityConfig={this.props.appActivityConfig.mitigationActivityConfig}

          onWarningChange={this.props.onWarningChange}
          onMappingReset={this.props.onMappingReset}
          onActivityColMappingChange={this.props.onActivityColMappingChange}
        />
      );
      rows.push(row);
    }
    return rows;
  }

  public getTableHeader(): JSX.Element {
    const caption: string = CONFIG_VIEW_TEXT.ACTIVITY_FIELDS_CONFIG;

    return (
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell content={caption} colSpan={this.MAX_COLUMNS} />
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.STATUS_COLUMN_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.COLUMN_NAME_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.MAPPING_TYPE_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.FIELD_NAME_HEADER} />
        </Table.Row>
      </Table.Header>
    );
  }

  public getConfigTable(): JSX.Element {
    return (
      <Grid.Row columns="equal" centered>
        <Grid.Column width={this.CONTENT_WIDTH}>
          <Table celled compact columns={this.MAX_COLUMNS}>
            {this.getTableHeader()}

            <Table.Body>
              {this.getConfigRows()}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    );
  }

  public getConfigActions(): JSX.Element {
    return (
      <Grid.Row columns="equal" centered>
        <Grid.Column width={this.CONTENT_WIDTH}>
          <ActivityConfigActionsContainer warning={this.props.warning} />
        </Grid.Column>
      </Grid.Row>
    );
  }

  public render(): JSX.Element {
    return (
      <Grid data-component="activity-config-view">
        <Grid.Row columns="equal" centered>
          <Grid.Column width={this.CONTENT_WIDTH}>
            <UpdateDataFileContainer />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="no-padding" columns="equal" centered>
          <Grid.Column width={this.CONTENT_WIDTH}>
            <ActivityConfigOptions />
          </Grid.Column>
        </Grid.Row>

        {this.props.optionsReady ? this.getConfigTable() : null}
        {this.props.optionsReady ? this.getConfigActions() : null}
      </Grid>
    );
  }
}

export interface IActivityConfigViewProps {
  readonly columns: IColumns;
  readonly warning: boolean;
  readonly optionsReady: boolean;
  readonly appActivityConfig: IAppActivityConfig;

  onWarningChange(warning: boolean): void;
  onMappingReset(colIndex: string): void;
  onActivityColMappingChange(colIndex: string, key: CONTROL_KEY | PLAN_KEY): void;
}
