import * as React from 'react';
import * as Actions from '../../../actions';
import { IUserManagementWorkflowConnectProps } from './workflow-container';
import { Workflow as BaseWorkflow } from '../../../components';
import { DataLoadApi, IUploadBasicFileOptions, IUserManagementConfigOptions } from '../../../api';
import { DATA_LOAD_STAGE } from '../../../constants';
import { BasicDataUploadContainer } from '../../data-upload/basic-data-upload';
import { getAppUserManagementConfig } from '../../../functions';
import { IAppUserManagementConfig } from '../../../interfaces';
import { ConfigContainer } from '../config';
import { DataSaveContainer } from '../data-save';

/**
 * User Management workflow manages transitions between data load flow components
 */
export class Workflow extends React.Component<IUserManagementWorkflowConnectProps> {
  public componentWillMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_UPLOAD_INITIAL));
    this.uploadFile = this.uploadFile.bind(this);
  }

  /** Clear state and cancel data load when route changes */
  public componentWillUnmount(): void {
    DataLoadApi.cancelDataLoad().request()
      .then((): void => {
        this.props.dispatch(Actions.Batch.clearAppState());
      })
      .catch((): void => {
        this.props.dispatch(Actions.Batch.clearAppState());
      });
  }

  public uploadFile(options: IUploadBasicFileOptions): Promise<{}> {
    return DataLoadApi.uploadUserManagementFile(options).request()
      .then((response: IUserManagementConfigOptions): {} => {
        const config: IAppUserManagementConfig = getAppUserManagementConfig(response.config);
        this.props.dispatch(Actions.Config.setColumns(response.columns));
        this.props.dispatch(Actions.Config.setUserManagementConfig(config));
        return {};
      });
  }

  public render(): JSX.Element {
    return (
      <BaseWorkflow
        stage={this.props.ui.stage}
        upload={<BasicDataUploadContainer uploadFile={this.uploadFile} />}
        config={<ConfigContainer />}
        result={<DataSaveContainer />}
      />
    );
  }
}
