import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { DataRangeOptions } from './data-range-options';
import { IState, IUploadState } from '../../../interfaces';

export interface IDataRangeOptionsStateProps {
  readonly upload: IUploadState;
}

export type IDataRangeOptionsConnectProps = IDataRangeOptionsStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IDataRangeOptionsStateProps {
  return { upload: state.upload };
}

export const DataRangeOptionsContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(DataRangeOptions);
