import * as React from 'react';
import { DataSaveView } from './data-save-view';
import { IDataSaveConnectProps } from './data-save-container';
import { DATA_LOAD_STAGE } from '../../constants';
import * as Actions from '../../actions';

/**
 * Component for displaying dry run and data load progress, results and actions
 */
export class DataSave extends React.Component<IDataSaveConnectProps, IDataSaveState> {

  constructor(props: IDataSaveConnectProps) {
    super(props);
    this.state = {
      error: null
    };

    this.onError = this.onError.bind(this);
    this.onRetry = this.onRetry.bind(this);
  }

  /** Notify workflow about data save process being initiated */
  public componentWillMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DRY_RUN_INITIAL));
  }

  public onError(response: Response): void {
    this.setState({ error: response });
  }

  public onRetry(): void {
    this.props.dispatch(Actions.Batch.goToDataSaveStep());
    this.props.onRetry()
      .then(() => {
        /* Trigger next step on successful dry run start */
        this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DRY_RUN_IN_PROGRESS));
      })
      .catch((error: Response): void => {
        this.setState({ error });
        this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_SAVE_FAILED));
      });
  }

  /** Restart dry run on upload data changes */
  public componentWillReceiveProps(next: IDataSaveConnectProps): void {
    if (this.props.upload !== next.upload) {
      this.onRetry();
    }
  }

  public render(): JSX.Element {
    return (
      <DataSaveView
        error={this.state.error}
        nRows={this.props.nRows}
        dataLoadType={this.props.dataLoadType}
        ui={this.props.ui}
        columns={this.props.columns}
        result={this.props.result}
        onRetry={this.onRetry}
        onError={this.onError}
      />
    );
  }
}

export interface IDataSaveState {
  readonly error: Response;
}
