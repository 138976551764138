import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { IncidentDataSave } from './incident-data-save';
import { IConfigState, IResultState, IState, IUIState, IUploadState } from '../../../interfaces';

export interface IIncidentDataSaveStateProps {
  readonly ui: IUIState;
  readonly upload: IUploadState;
  readonly config: IConfigState;
  readonly result: IResultState;
}

export type IIncidentDataSaveConnectProps = IIncidentDataSaveStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IIncidentDataSaveStateProps {
  return {
    ui: state.ui,
    upload: state.upload,
    config: state.config,
    result: state.result
  };
}

export const IncidentDataSaveContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(IncidentDataSave);
