import * as React from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { ErrorHandlerContainer } from '../../error-handler';
import { CONFIG_VIEW_TEXT } from '../../../constants';
import './config-actions-view.less';

/**
 * View for "Config Actions" component
 */
export class ConfigActionsView extends React.Component<IConfigActionsViewProps> {
  constructor(props: IConfigActionsViewProps) {
    super(props);
  }

  /** Get default error message */
  public getError(): JSX.Element {
    return (this.props.error)
      ? <ErrorHandlerContainer response={this.props.error} />
      : null;
  }

  public render(): JSX.Element {
    return (
      <Grid data-component="config-actions-view">
        <Grid.Row columns="equal">
          <Grid.Column>
            {this.getError()}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns="equal">
          <Grid.Column>
            <Button
              content={CONFIG_VIEW_TEXT.BACK}
              onClick={this.props.onBackButtonClick}
              disabled={this.props.disabled}
              icon="arrow left"
              primary
              fluid
            />
          </Grid.Column>

          <Grid.Column>
            <Button
              content={CONFIG_VIEW_TEXT.CHECK_CONFIGURATION}
              onClick={this.props.onCheckButtonClick}
              disabled={!this.props.checkButtonActive || this.props.disabled}
              loading={this.props.loading}
              icon="options"
              positive
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export interface IConfigActionsViewProps {
  readonly error: Response;
  readonly loading: boolean;
  readonly disabled: boolean;
  readonly checkButtonActive: boolean;
  onBackButtonClick(): void;
  onCheckButtonClick(): void;
}
