import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { MatchIndicatorKeyOptions } from './match-indicator-key-options';
import { IState, IUIState } from '../../../interfaces';
import { MATCH_INDICATOR_KEY } from '../../../constants';

export interface IMatchIndicatorKeyOptionsProps {
  onChange?(action: MATCH_INDICATOR_KEY): void;
}

export interface IMatchIndicatorKeyStateProps extends IMatchIndicatorKeyOptionsProps {
  readonly ui: IUIState;
}

export type IMatchIndicatorKeyOptionsConnectProps = IMatchIndicatorKeyStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IMatchIndicatorKeyOptionsProps): IMatchIndicatorKeyStateProps {
  return { ui: state.ui, ...props };
}

export const MatchIndicatorKeyOptionsContainer: React.ComponentClass<IMatchIndicatorKeyOptionsProps>
  = ReactRedux.connect(mapStateToProps)(MatchIndicatorKeyOptions);
