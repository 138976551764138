import * as React from 'react';
import { IIncidentDataUploadActionsConnectProps } from './incident-data-upload-actions-container';
import { DataUploadActionsContainer } from '../../data-upload/data-upload-actions';
import { IAppTxConfig } from '../../../interfaces';
import { DataLoadApi, IIncidentConfigOptions, IUploadIncidentFileOptions } from '../../../api';
import { checkDataRange, getAppTxConfig } from '../../../functions';
import * as Actions from '../../../actions';

/**
 * Incidents data upload actions component checks input data and performs upload
 */
export class IncidentDataUploadActions extends React.Component<IIncidentDataUploadActionsConnectProps> {
  constructor(props: IIncidentDataUploadActionsConnectProps) {
    super(props);
    this.uploadFile = this.uploadFile.bind(this);
    this.getUploadButtonStatus = this.getUploadButtonStatus.bind(this);
  }

  /** Upload data file to server and save config on success */
  public uploadFile(): Promise<{}> {
    const options: IUploadIncidentFileOptions = {
      dataFile: this.props.upload.dataFile,
      sheetIndex: this.props.upload.sheetIndex,
      testId: this.props.upload.incidentType,
      startRow: this.props.upload.dataStartRow,
      endRow: this.props.upload.dataEndRow
    };

    return DataLoadApi.uploadIncidentFile(options).request()
      .then((response: IIncidentConfigOptions): {} => {
        const config: IAppTxConfig = getAppTxConfig(response.config);

        this.props.dispatch(Actions.Config.setColumns(response.columns));
        this.props.dispatch(Actions.Config.setIncidentConfig(config));
        return {};
      });
  }

  /** Upload button is disabled when data is not valid */
  public getUploadButtonStatus(): boolean {
    const dataReady: boolean = !!this.props.upload.dataFile;
    const sheetIndexReady: boolean = this.props.upload.sheetIndex !== null && this.props.upload.sheetIndex >= 0;

    const indicatorTypeReady: boolean = !!this.props.upload.incidentType;
    const dataRangeReady: boolean = checkDataRange({
      start: this.props.upload.dataStartRow,
      end: this.props.upload.dataEndRow
    });

    return dataReady && sheetIndexReady && indicatorTypeReady && dataRangeReady;
  }

  public render(): JSX.Element {
    return (
      <DataUploadActionsContainer
        uploadFile={this.uploadFile}
        getUploadButtonStatus={this.getUploadButtonStatus}
      />
    );
  }
}
