import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Home } from './home';

export type IHomeProps = RouteComponentProps<{}>;
export type IHomeConnectProps = IHomeProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: object, props: RouteComponentProps<{}>): IHomeProps {
  return { ...props };
}

export const HomeContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(Home);
