import * as React from 'react';
import { Grid, SemanticWIDTHS } from 'semantic-ui-react';
import { ITxDataUploadConnectProps } from './tx-data-upload-container';
import { DataOptionsContainer } from '../../data-upload/data-options';
import { DataRangeOptionsContainer } from '../../data-upload/data-range-options';
import { TxDataUploadOptionsContainer } from '../tx-data-upload-options';
import { TxDataUploadActionsContainer } from '../tx-data-upload-actions';
import { DATA_LOAD_STAGE } from '../../../constants';
import * as Actions from '../../../actions';
import './tx-data-upload.less';

/**
 * Taxonomy data upload component combines relevant containers
 * Contains unique options for Taxonomy resource
 */
export class TxDataUpload extends React.Component<ITxDataUploadConnectProps> {
  constructor(props: ITxDataUploadConnectProps) {
    super(props);
  }

  public componentWillMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_UPLOAD));
  }

  public render(): JSX.Element {
    const CONTENT_WIDTH: SemanticWIDTHS = 10;

    return (
      <Grid data-component="tx-data-upload">
        <Grid.Row columns="equal" centered>
          <Grid.Column width={CONTENT_WIDTH}>
            <TxDataUploadOptionsContainer />
            {this.props.upload.txResourceType ? <DataOptionsContainer /> : null}
            {this.props.upload.dataFile ? <DataRangeOptionsContainer /> : null}
            {this.props.upload.dataFile ? <TxDataUploadActionsContainer /> : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
