import * as React from 'react';
import { IIPLConfigActionsConnectProps } from './ipl-config-actions-container';
import { ConfigActionsContainer } from '../../config/config-actions';
import { DataLoadApi } from '../../../api';
import { IIPLConfig } from '../../../interfaces';
import { getApiIPLConfig } from '../../../functions';

/**
 * Indicator-Process Links "Check Configuration" action buttons
 */
export class IPLConfigActions extends React.Component<IIPLConfigActionsConnectProps> {
  constructor(props: IIPLConfigActionsConnectProps) {
    super(props);
    this.uploadConfig = this.uploadConfig.bind(this);
  }

  public uploadConfig(): Promise<{}> {
    const config: IIPLConfig = getApiIPLConfig({
      config: this.props.config.IPL,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadIPLConfig({ config, columns: this.props.config.columns }).request();
  }

  public render(): JSX.Element {
    return (
      <ConfigActionsContainer
        warning={this.props.warning}
        uploadConfig={this.uploadConfig}
      />
    );
  }
}
