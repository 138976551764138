import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { DataLoadActionOptions } from './data-load-action-options';
import { IState, IUIState } from '../../../interfaces';
import { DATA_LOAD_ACTION } from '../../../constants';

export interface IDataLoadActionOptionsProps {
  readonly options: { [key: string]: string };
  onChange?(action: DATA_LOAD_ACTION): void;
}

export interface IDataLoadActionOptionsStateProps extends IDataLoadActionOptionsProps {
  readonly ui: IUIState;
}

export type IDataLoadActionOptionsConnectProps = IDataLoadActionOptionsStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IDataLoadActionOptionsProps): IDataLoadActionOptionsStateProps {
  return { ui: state.ui, ...props };
}

export const DataLoadActionOptionsContainer: React.ComponentClass<IDataLoadActionOptionsProps>
  = ReactRedux.connect(mapStateToProps)(DataLoadActionOptions);
