import {
  IActivityConfig,
  IAILConfig,
  IAppActivityConfig,
  IAppAILConfig,
  IAppILConfig,
  IAppIndicatorConfig,
  IAppIPLConfig,
  IAppMAConfig,
  IAppPIConfig,
  IAppTxConfig,
  IAppTxMapping,
  IAppUserManagementConfig,
  IColumnConfigObject,
  IIndicatorConfig,
  IIPLConfig,
  IPIConfig,
  ITxColumnConfig,
  ITxConfig,
  IUIState,
  IUploadState
} from '../interfaces';
import {
  CONFIG_FIELD_TYPE, DATA_LOAD_ACTION, EMPTY_CELLS_ACTION, INDICATOR_KEY, NO_MAPPING_OPTION, PLAN_KEY
} from '../constants';
import { enumToArray } from './common';
import { IILConfig, IMAConfig, IUserManagementConfig } from '../interfaces/config';
import { USER_MANAGEMENT_OPTIONS } from '../constants/user-management';

/** Convert API Taxonomy config to application config object */
export function getAppTxConfig(config: ITxConfig): IAppTxConfig {
  return {
    entity: config.entity,
    testId: config.testId,
    sheetIndex: config.sheet_index,
    dataStartRow: config.start_row,
    dataEndRow: config.end_row,
    searchDisabled: config.search_disabled,
    skipEmpty: config.skip_empty,
    onlyExisting: config.only_existing,
    matchBy: config.match_by,
    mapping: getAppTxMapping(config)
  };
}

/** Convert API column mapping into application object */
export function getAppTxMapping(config: ITxConfig): { [key: string]: IAppTxMapping } {
  const colIndexes: string[] = Object.keys(config.column_config);

  const mapping: { [key: string]: IAppTxMapping } = {};

  for (let i: number = 0; i < colIndexes.length; i++) {
    const index: number = parseInt(colIndexes[i], 10);
    const column: string | IColumnConfigObject = config.column_config[index];

    /* If column has one of ID values */
    if (typeof column === 'string') {
      mapping[index] = { key: column };
    }

    /* If column contains custom config object */
    if (typeof column === 'object') {
      if (column.type === CONFIG_FIELD_TYPE.ASSESSMENT_SCORE) {
        mapping[index] = {
          riskFactorId: column.weight_id,
          type: CONFIG_FIELD_TYPE.ASSESSMENT_SCORE
        };
      } else {
        mapping[index] = {
          tabId: column.field_tab.id.toString(),
          fieldId: column.field_id.toString()
        };
      }
    }
  }

  return mapping;
}

/** Convert application Taxonomy config to API config object */
export function getApiTxConfig(options: {
  config: IAppTxConfig,
  ui: IUIState,
  upload: IUploadState
}): ITxConfig {
  const config: ITxColumnConfig = getApiTxMapping(options.config);
  const searchDisabled: boolean = (options.ui.dataLoadAction === DATA_LOAD_ACTION.ADD_NEW);
  const onlyExisting: boolean = (options.ui.dataLoadAction === DATA_LOAD_ACTION.UPDATE_EXISTING);
  const matchBy: string = options.ui.matchByIndex;
  const dataStartRow: number = options.upload.dataStartRow || options.config.dataStartRow;
  const dataEndRow: number = options.upload.dataEndRow || options.config.dataEndRow;
  const clearEmpty: boolean = options.ui.emptyCellsAction === EMPTY_CELLS_ACTION.CLEAR_EMPTY_CELLS;

  return {
    entity: options.config.entity,
    testId: options.config.testId,
    sheet_index: options.upload.sheetIndex,
    process_type: options.ui.processType,
    start_row: dataStartRow,
    end_row: dataEndRow,
    search_disabled: searchDisabled,
    only_existing: onlyExisting,
    match_by: matchBy,
    column_config: config,
    clear_empty: clearEmpty
  };
}

/** Convert application column mapping into API object */
export function getApiTxMapping(config: IAppTxConfig): ITxColumnConfig {
  const colConfig: ITxColumnConfig = {};
  const colIndexes: string[] = Object.keys(config.mapping);

  for (let i: number = 0; i < colIndexes.length; i++) {
    const colIndex: string = colIndexes[i];
    const obj: IAppTxMapping = config.mapping[colIndex];

    /* ID keys are copied as is */
    if (obj.key) { colConfig[colIndex] = obj.key; }

    /* Assessment scoring */
    if (obj.type === CONFIG_FIELD_TYPE.ASSESSMENT_SCORE) {
      colConfig[colIndex] = {
        weight_id: obj.riskFactorId,
        type: CONFIG_FIELD_TYPE.ASSESSMENT_SCORE
      };
    }

    /* API object requires tab ID, field ID + special type value */
    if (obj.tabId && obj.fieldId) {
      /* Ignore NO_MAPPING values */
      const ignore: boolean = obj.tabId === NO_MAPPING_OPTION.key
        || obj.fieldId === NO_MAPPING_OPTION.key;

      if (!ignore) {
        colConfig[colIndex] = {
          field_id: parseInt(obj.fieldId, 10),
          field_tab: { id: parseInt(obj.tabId, 10) },
          type: CONFIG_FIELD_TYPE.DYNAMIC_FIELD
        };
      }
    }
  }

  return colConfig;
}

/** Convert API Plan-Indicators config to application config object */
export function getAppPIConfig(config: IPIConfig): IAppPIConfig {
  return {
    tableConfig: {
      sheetIndex: config.tableConfig.sheet_index,
      dataStartRow: config.tableConfig.start_row,
      dataEndRow: config.tableConfig.end_row,
      searchDisabled: config.tableConfig.search_disabled,
      skipEmpty: config.tableConfig.skip_empty,
      onlyExisting: config.tableConfig.only_existing,
      onlyNew: config.tableConfig.only_new,
    },
    planEventConfig: config.planEventConfig,
    planEventScoreConfig: config.planEventScoreConfig
  };
}

/** Convert application Plan-Indicators config to API object */
export function getApiPIConfig(options: {
  config: IAppPIConfig,
  ui: IUIState,
  upload: IUploadState
}): IPIConfig {
  const onlyNew: boolean = (options.ui.dataLoadAction === DATA_LOAD_ACTION.ADD_NEW);
  const onlyExisting: boolean = (options.ui.dataLoadAction === DATA_LOAD_ACTION.UPDATE_EXISTING);
  const dataStartRow: number = options.upload.dataStartRow || options.config.tableConfig.dataStartRow;
  const dataEndRow: number = options.upload.dataEndRow || options.config.tableConfig.dataEndRow;
  const clearEmpty: boolean = options.ui.emptyCellsAction === EMPTY_CELLS_ACTION.CLEAR_EMPTY_CELLS;

  return {
    initialConfig: {
      library_type: options.ui.libraryType,
      match_plan_key: options.ui.matchPlanKey,
      match_indicator_key: options.ui.matchIndicatorKey
    },
    tableConfig: {
      sheet_index: options.upload.sheetIndex,
      start_row: dataStartRow,
      end_row: dataEndRow,
      search_disabled: options.config.tableConfig.searchDisabled,
      clear_empty: clearEmpty,
      only_existing: onlyExisting,
      only_new: onlyNew
    },
    planEventConfig: options.config.planEventConfig,
    planEventScoreConfig: options.config.planEventScoreConfig
  };
}

/** Convert API Indicator config to application config object */
export function getAppIndicatorConfig(config: IIndicatorConfig): IAppIndicatorConfig {
  return {
    tableConfig: {
      sheetIndex: config.tableConfig.sheet_index,
      dataStartRow: config.tableConfig.start_row,
      dataEndRow: config.tableConfig.end_row,
      searchDisabled: config.tableConfig.search_disabled,
      skipEmpty: config.tableConfig.skip_empty,
      onlyExisting: config.tableConfig.only_existing,
      onlyNew: config.tableConfig.only_new,
    },
    categoryConfig: config.categoryConfig,
    factorConfig: config.factorConfig,
    indicatorConfig: config.indicatorConfig
  };
}

/** Convert application Indicator config to API object */
export function getApiIndicatorConfig(options: {
  config: IAppIndicatorConfig,
  ui: IUIState,
  upload: IUploadState
}): IIndicatorConfig {
  const onlyNew: boolean = (options.ui.dataLoadAction === DATA_LOAD_ACTION.ADD_NEW);
  const onlyExisting: boolean = (options.ui.dataLoadAction === DATA_LOAD_ACTION.UPDATE_EXISTING);
  const dataStartRow: number = options.upload.dataStartRow || options.config.tableConfig.dataStartRow;
  const dataEndRow: number = options.upload.dataEndRow || options.config.tableConfig.dataEndRow;
  const clearEmpty: boolean = options.ui.emptyCellsAction === EMPTY_CELLS_ACTION.CLEAR_EMPTY_CELLS;

  return {
    initialConfig: {
      library_type: options.ui.libraryType,
      match_key: options.ui.matchKey,
    },
    tableConfig: {
      sheet_index: options.upload.sheetIndex,
      start_row: dataStartRow,
      end_row: dataEndRow,
      search_disabled: options.config.tableConfig.searchDisabled,
      clear_empty: clearEmpty,
      only_existing: onlyExisting,
      only_new: onlyNew
    },
    indicatorConfig: options.config.indicatorConfig,
    factorConfig: options.config.factorConfig,
    categoryConfig: options.config.categoryConfig
  };
}

/** Convert API Indicator-Process Links config to application config object */
export function getAppIPLConfig(config: IIPLConfig): IAppIPLConfig {
  return {
    tableConfig: {
      sheetIndex: config.tableConfig.sheet_index,
      dataStartRow: config.tableConfig.start_row,
      dataEndRow: config.tableConfig.end_row,
      searchDisabled: config.tableConfig.search_disabled,
      skipEmpty: config.tableConfig.skip_empty,
      onlyExisting: config.tableConfig.only_existing,
      onlyNew: config.tableConfig.only_new,
    },
    coreEventConfig: config.coreEventConfig
  };
}

/** Convert application Indicator-Process Links config to API object */
export function getApiIPLConfig(options: {
  config: IAppIPLConfig,
  ui: IUIState,
  upload: IUploadState
}): IIPLConfig {
  const dataStartRow: number = options.upload.dataStartRow || options.config.tableConfig.dataStartRow;
  const dataEndRow: number = options.upload.dataEndRow || options.config.tableConfig.dataEndRow;
  const clearEmpty: boolean = options.ui.emptyCellsAction === EMPTY_CELLS_ACTION.CLEAR_EMPTY_CELLS;

  return {
    initialConfig: {
      library_type: options.ui.libraryType,
      match_indicator_key: options.ui.matchIndicatorKey,
      match_key: options.ui.matchKey,
      process_type: options.ui.processType
    },
    tableConfig: {
      sheet_index: options.upload.sheetIndex,
      start_row: dataStartRow,
      end_row: dataEndRow,
      search_disabled: options.config.tableConfig.searchDisabled,
      clear_empty: clearEmpty,
    },
    coreEventConfig: options.config.coreEventConfig
  };
}

/** Convert API Indicator-Indicator Links config to application config object */
export function getAppILConfig(config: IILConfig): IAppILConfig {
  return {
    tableConfig: {
      sheetIndex: config.tableConfig.sheet_index,
      dataStartRow: config.tableConfig.start_row,
      dataEndRow: config.tableConfig.end_row,
      searchDisabled: config.tableConfig.search_disabled,
      skipEmpty: config.tableConfig.skip_empty,
      onlyExisting: config.tableConfig.only_existing,
      onlyNew: config.tableConfig.only_new,
    },
    eventEventConfig: config.eventEventConfig
  };
}

/** Convert application Indicator-Indicator Links config to API object */
export function getApiILConfig(options: {
  config: IAppILConfig,
  ui: IUIState,
  upload: IUploadState
}): IILConfig {
  const dataStartRow: number = options.upload.dataStartRow || options.config.tableConfig.dataStartRow;
  const dataEndRow: number = options.upload.dataEndRow || options.config.tableConfig.dataEndRow;
  const clearEmpty: boolean = options.ui.emptyCellsAction === EMPTY_CELLS_ACTION.CLEAR_EMPTY_CELLS;

  return {
    initialConfig: {
      match_indicator_key: options.ui.matchIndicatorKey,
      match_indicator_key_2: options.ui.matchIndicatorKey2,
    },
    tableConfig: {
      sheet_index: options.upload.sheetIndex,
      start_row: dataStartRow,
      end_row: dataEndRow,
      search_disabled: options.config.tableConfig.searchDisabled,
      clear_empty: clearEmpty,
    },
    eventEventConfig: options.config.eventEventConfig
  };
}

/** Convert API Control config to application config object */
export function getAppActivityConfig(config: IActivityConfig): IAppActivityConfig {
  return {
    tableConfig: {
      sheetIndex: config.tableConfig.sheet_index,
      dataStartRow: config.tableConfig.start_row,
      dataEndRow: config.tableConfig.end_row,
      searchDisabled: config.tableConfig.search_disabled,
      skipEmpty: config.tableConfig.skip_empty,
      onlyExisting: config.tableConfig.only_existing,
      onlyNew: config.tableConfig.only_new,
    },
    mitigationActivityConfig: config.mitigationActivityConfig
  };
}

/** Convert application Control config to API object */
export function getApiActivityConfig(options: {
  config: IAppActivityConfig,
  ui: IUIState,
  upload: IUploadState
}): IActivityConfig {
  const dataStartRow: number = options.upload.dataStartRow || options.config.tableConfig.dataStartRow;
  const dataEndRow: number = options.upload.dataEndRow || options.config.tableConfig.dataEndRow;
  const onlyNew: boolean = (options.ui.dataLoadAction === DATA_LOAD_ACTION.ADD_NEW);
  const onlyExisting: boolean = (options.ui.dataLoadAction === DATA_LOAD_ACTION.UPDATE_EXISTING);
  const clearEmpty: boolean = options.ui.emptyCellsAction === EMPTY_CELLS_ACTION.CLEAR_EMPTY_CELLS;

  return {
    initialConfig: {
      match_plan_key: options.ui.matchPlanKey,
      match_activity_key: options.ui.matchActivityKey,
    },
    tableConfig: {
      sheet_index: options.upload.sheetIndex,
      start_row: dataStartRow,
      end_row: dataEndRow,
      only_new: onlyNew,
      only_existing: onlyExisting,
      search_disabled: options.config.tableConfig.searchDisabled,
      clear_empty: clearEmpty,
    },
    mitigationActivityConfig: options.config.mitigationActivityConfig
  };
}

/** Convert API MA config to application config object */
export function getAppMAConfig(config: IMAConfig): IAppMAConfig {
  return {
    tableConfig: {
      sheetIndex: config.tableConfig.sheet_index,
      dataStartRow: config.tableConfig.start_row,
      dataEndRow: config.tableConfig.end_row,
      searchDisabled: config.tableConfig.search_disabled,
      skipEmpty: config.tableConfig.skip_empty,
      onlyExisting: config.tableConfig.only_existing,
      onlyNew: config.tableConfig.only_new,
    },
    monitoringActivityConfig: config.monitoringActivityConfig
  };
}

/** Convert application Monitoring Activity config to API object */
export function getApiMAConfig(options: {
  config: IAppMAConfig,
  ui: IUIState,
  upload: IUploadState
}): IMAConfig {
  const dataStartRow: number = options.upload.dataStartRow || options.config.tableConfig.dataStartRow;
  const dataEndRow: number = options.upload.dataEndRow || options.config.tableConfig.dataEndRow;
  const onlyNew: boolean = (options.ui.dataLoadAction === DATA_LOAD_ACTION.ADD_NEW);
  const onlyExisting: boolean = (options.ui.dataLoadAction === DATA_LOAD_ACTION.UPDATE_EXISTING);
  const clearEmpty: boolean = options.ui.emptyCellsAction === EMPTY_CELLS_ACTION.CLEAR_EMPTY_CELLS;

  return {
    initialConfig: {
      match_plan_key: options.ui.matchPlanKey,
      match_monitoring_activity_key: options.ui.matchMonitoringActivityKey,
      maType: options.upload.maType
    },
    tableConfig: {
      sheet_index: options.upload.sheetIndex,
      start_row: dataStartRow,
      end_row: dataEndRow,
      only_new: onlyNew,
      only_existing: onlyExisting,
      search_disabled: options.config.tableConfig.searchDisabled,
      clear_empty: clearEmpty,
    },
    monitoringActivityConfig: options.config.monitoringActivityConfig
  };
}

/** Convert API User Management config to application config object */
export function getAppUserManagementConfig(config: IUserManagementConfig): IAppUserManagementConfig {
  return {
    tableConfig: {
      dataEndRow: config.tableConfig.end_row,
      onlyExisting: config.tableConfig.only_existing,
      onlyNew: config.tableConfig.only_new,
      searchDisabled: config.tableConfig.search_disabled,
      sheetIndex: config.tableConfig.sheet_index,
      skipEmpty: config.tableConfig.skip_empty,
      dataStartRow: config.tableConfig.start_row,
    },
    userConfig: config.userConfig
  };
}

/** Convert application User Management config to API config object */
export function getApiUserManagementConfig(options: {
  config: IAppUserManagementConfig,
  ui: IUIState,
  upload: IUploadState
}): IUserManagementConfig {
  const newConfig: { [key: string]: USER_MANAGEMENT_OPTIONS } = {};
  for (const configIndex of Object.keys(options.config.userConfig)) {
    if (options.config.userConfig[configIndex] !== NO_MAPPING_OPTION.key) {
      newConfig[configIndex] = options.config.userConfig[configIndex];
    }
  }

  return {
   tableConfig: {
     sheet_index: options.upload.sheetIndex || options.config.tableConfig.sheetIndex,
     start_row: options.upload.dataStartRow || options.config.tableConfig.dataStartRow,
     end_row: options.upload.dataEndRow || options.config.tableConfig.dataEndRow,
     skip_empty: false,
     search_disabled: false,
     only_existing: false,
     only_new: false,
   },
   userConfig: {
     ...newConfig,
     clear_empty : options.ui.emptyCellsAction === EMPTY_CELLS_ACTION.CLEAR_EMPTY_CELLS,
     match_by: options.ui.matchUserManagementKey,
   }
  } as any;
}

/** Convert API Activity-Indicator Links config to application config object */
export function getAppAILConfig(config: IAILConfig): IAppAILConfig {
  return {
    tableConfig: {
      sheetIndex: config.tableConfig.sheet_index,
      dataStartRow: config.tableConfig.start_row,
      dataEndRow: config.tableConfig.end_row,
      searchDisabled: config.tableConfig.search_disabled,
      skipEmpty: config.tableConfig.skip_empty,
      onlyExisting: config.tableConfig.only_existing,
      onlyNew: config.tableConfig.only_new,
    },
    activityEventConfig: config.activityEventConfig
  };
}

/** Convert application Activity-Indicator Links config to API object */
export function getApiAILConfig(options: {
  config: IAppAILConfig,
  ui: IUIState,
  upload: IUploadState
}): IAILConfig {
  const dataStartRow: number = options.upload.dataStartRow || options.config.tableConfig.dataStartRow;
  const dataEndRow: number = options.upload.dataEndRow || options.config.tableConfig.dataEndRow;
  const clearEmpty: boolean = options.ui.emptyCellsAction === EMPTY_CELLS_ACTION.CLEAR_EMPTY_CELLS;

  /* Copy some mappings into plan-event config */
  const keys: string[] = Object.keys(options.config.activityEventConfig);
  const planKeys: string[] = enumToArray(PLAN_KEY);
  const indicatorKeys: string[] = enumToArray(INDICATOR_KEY);
  const planEventConfig: { [key: string]: PLAN_KEY | INDICATOR_KEY } = {};

  for (let i: number = 0; i < keys.length; i++) {
    const value: string = options.config.activityEventConfig[keys[i]];

    if (planKeys.indexOf(value) !== -1 || indicatorKeys.indexOf(value) !== -1) {
      planEventConfig[keys[i]] = value as PLAN_KEY | INDICATOR_KEY;
    }
  }

  return {
    initialConfig: {
      library_type: options.ui.libraryType,
      match_plan_key: options.ui.matchPlanKey,
      match_activity_key: options.ui.matchActivityKey,
      match_indicator_key: options.ui.matchIndicatorKey
    },
    tableConfig: {
      sheet_index: options.upload.sheetIndex,
      start_row: dataStartRow,
      end_row: dataEndRow,
      search_disabled: options.config.tableConfig.searchDisabled,
      clear_empty: clearEmpty
    },
    activityEventConfig: options.config.activityEventConfig,
    planEventConfig
  };
}
