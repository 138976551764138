import * as React from 'react';
import { DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { CONFIG_VIEW_TEXT, PROCESS_TYPE_OPTIONS } from '../../../constants';
import { getOptions } from '../../../functions';

/**
 * View for "Process Type Config" component
 */
export class ProcessTypeOptionsView extends React.Component<IProcessTypeOptionsViewProps> {
  constructor(props: IProcessTypeOptionsViewProps) {
    super(props);
  }

  public render(): JSX.Element {
    const options: DropdownItemProps[] = getOptions(PROCESS_TYPE_OPTIONS);

    return (
      <Form.Dropdown
        label={this.props.label || CONFIG_VIEW_TEXT.PROCESS_TYPE}
        options={options}
        value={this.props.value}
        onChange={this.props.onChange}
        selectOnBlur={false}
        selection
        required
      />
    );
  }
}

export interface IProcessTypeOptionsViewProps {
  readonly value: string;
  readonly label?: string;
  onChange(e: React.SyntheticEvent<HTMLDivElement>, changes: DropdownProps): void;
}
