import * as React from 'react';
import { IBasicDataUploadActionsConnectProps } from './basic-data-upload-actions-container';
import { DataUploadActionsContainer } from '../data-upload-actions';
import { IUploadBasicFileOptions } from '../../../api';
import { checkDataRange } from '../../../functions';

/**
 * Basic data upload actions component checks input data and performs upload using given request
 */
export class BasicDataUploadActions extends React.Component<IBasicDataUploadActionsConnectProps> {

  constructor(props: IBasicDataUploadActionsConnectProps) {
    super(props);
    this.onDataFileUpload = this.onDataFileUpload.bind(this);
    this.getUploadButtonStatus = this.getUploadButtonStatus.bind(this);
  }

  /** Upload data file to server and save config on success */
  public onDataFileUpload(): Promise<{}> {
    const options: IUploadBasicFileOptions = {
      dataFile: this.props.upload.dataFile,
      sheetIndex: this.props.upload.sheetIndex,
      startRow: this.props.upload.dataStartRow,
      endRow: this.props.upload.dataEndRow
    };

    return this.props.uploadFile(options);
  }

  /** Upload button is disabled when data is not valid */
  public getUploadButtonStatus(): boolean {
    const dataReady: boolean = !!this.props.upload.dataFile;
    const sheetIndexReady: boolean = this.props.upload.sheetIndex !== null && this.props.upload.sheetIndex >= 0;

    const dataRangeReady: boolean = checkDataRange({
      start: this.props.upload.dataStartRow,
      end: this.props.upload.dataEndRow
    });

    return dataReady && sheetIndexReady && dataRangeReady;
  }

  public render(): JSX.Element {
    return (
      <DataUploadActionsContainer
        uploadFile={this.onDataFileUpload}
        getUploadButtonStatus={this.getUploadButtonStatus}
      />
    );
  }
}
