import * as React from 'react';
import { DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { DATA_UPLOAD_VIEW_TEXT, MA_TYPE_OPTIONS } from '../../../constants';
import './ma-data-upload-options-view.less';

/**
 * View for "MA Data Upload Options" component
 */
export class MADataUploadOptionsView extends React.Component<IMADataUploadOptionsViewProps> {
  constructor(props: IMADataUploadOptionsViewProps) {
    super(props);
  }

  /** Monitoring activity options are based on enum */
  public getMATypeInput(): JSX.Element {
    const options: DropdownItemProps[] = [];
    const keys: string[] = Object.keys(MA_TYPE_OPTIONS);

    for (let i: number = 0; i < keys.length; i++) {
      options.push({ value: keys[i], text: MA_TYPE_OPTIONS[keys[i]] });
    }

    return (
      <Form.Dropdown
        options={options}
        value={this.props.maType || ''}
        onChange={this.props.onMATypeChange}
        label={DATA_UPLOAD_VIEW_TEXT.MONITORING_ACTIVITY}
        selection
        required
      />
    );
  }

  public render(): JSX.Element {
    return (
      <Form data-component="ma-data-upload-options-view">
        {this.getMATypeInput()}
      </Form>
    );
  }
}

export interface IMADataUploadOptionsViewProps {
  readonly maType: string;
  onMATypeChange(e: React.ChangeEvent<HTMLInputElement>, changes: DropdownProps): void;
}
