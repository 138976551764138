import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { EmptyCellOptionsContainer } from '../../config/empty-cell-options';
import { UserManagementKeyOptionsContainer } from '../../config/match-user-management-key-options';
import './config-options.less';

/**
 * User Management config options component combines relevant config option containers
 */
export class ConfigOptions extends React.Component {
  constructor(props: object) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <Form data-component="user-management-config-options">
        <Form.Group widths="equal">
          <UserManagementKeyOptionsContainer />
          <EmptyCellOptionsContainer />
        </Form.Group>
      </Form>
    );
  }
}
