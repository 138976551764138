import { IAuthTicket } from '../interfaces';
import * as Actions from '../actions';

const initial: IAuthTicket = {
  email: '',
  token: '',
  username: '',
  password: '',
  domain: '',
  fullName: ''
};

export function auth(
  state: IAuthTicket = initial,
  action: Actions.Auth.ISetAuthToken
): IAuthTicket {
  switch (action.type) {
    case Actions.Auth.SET_AUTH_TOKEN:
      return { ...action.token };
    case Actions.Auth.LOGOUT_USER:
      return initial;
    default:
      return state;
  }
}
