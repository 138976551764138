import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { ILWorkflow } from './il-workflow';
import { IState, IUIState } from '../../../interfaces';

export interface IILWorkflowStateProps {
  readonly ui: IUIState;
}

export type IILWorkflowConnectProps = IILWorkflowStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IILWorkflowStateProps {
  return { ui: state.ui };
}

export const ILWorkflowContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(ILWorkflow);
