import * as React from 'react';
import { IPIWorkflowConnectProps } from './pi-workflow-container';
import { Workflow } from '../../../components';
import { PIDataUploadContainer } from '../pi-data-upload';
import { PIConfigContainer } from '../pi-config';
import { PIDataSaveContainer } from '../pi-data-save';
import { DataLoadApi } from '../../../api';
import { DATA_LOAD_STAGE } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Plan-Indicators workflow manages transitions between data load flow components
 */
export class PIWorkflow extends React.Component<IPIWorkflowConnectProps> {
  public componentWillMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_UPLOAD_INITIAL));
  }

  /** Clear state when route changes */
  public componentWillUnmount(): void {
    DataLoadApi.cancelDataLoad().request()
      .then((): void => {
        this.props.dispatch(Actions.Batch.clearAppState());
      })
      .catch((): void => {
        this.props.dispatch(Actions.Batch.clearAppState());
      });
  }

  public render(): JSX.Element {
    return (
      <Workflow
        stage={this.props.ui.stage}
        upload={<PIDataUploadContainer />}
        config={<PIConfigContainer />}
        result={<PIDataSaveContainer />}
      />
    );
  }
}
