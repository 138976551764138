import * as React from 'react';
import { Progress } from 'semantic-ui-react';
import { DataLoadApi } from '../../../api';
import { IDataLoadResult } from '../../../interfaces';
import { DATA_LOAD_STATUS } from '../../../constants';
import './data-progress.less';

/**
 * Displays progress of data action
 */
export class DataProgress extends React.Component<IDataProgressProps> {
  /** Prevent overlap of multiple check status requests */
  public loading: boolean;
  public interval: number;

  /** On component init start checking data status */
  public componentWillMount(): void {
    const REQUEST_INTERVAL: number = 1000;
    this.interval = window.setInterval((): void => {
      this.checkStatus();
    }, REQUEST_INTERVAL);
  }

  /** Clean up intervals */
  public componentWillUnmount(): void {
    window.clearInterval(this.interval);
  }

  /** Call parent callback on new updates from server */
  public checkStatus(): void {
    if (this.loading) {
      return;
    }

    this.loading = true;
    DataLoadApi.checkStatus().request()
      .then((response: IDataLoadResult): void => {
        /* If data request has been canceled from another component */
        if (response.status === DATA_LOAD_STATUS.CANCELED) {
          this.props.onDone(response);
          return window.clearInterval(this.interval);
        }

        this.loading = false;
        this.props.onProgress(response);

        /* If data request has been completed or has failed */
        if (response.status === DATA_LOAD_STATUS.SUCCESS || response.status === DATA_LOAD_STATUS.FAILED) {
          this.props.onDone(response);
          return window.clearInterval(this.interval);
        }
      })
      .catch((response: Response): void => {
        this.loading = false;
        window.clearInterval(this.interval);
        this.props.onError(response);
      });
  }

  public render(): JSX.Element {
    const percent: number = (this.props.total !== 0)
      ? Math.round(this.props.current / this.props.total * 100)
      : 100;

    return (
      <Progress
        data-component="data-progress"
        label={this.props.label}
        percent={percent}
        progress
        active
      />
    );
  }
}

export interface IDataProgressViewProps {
  readonly label: string;
  readonly total: number;
  readonly current: number;
}

export interface IDataProgressProps extends IDataProgressViewProps {
  onDone(response: IDataLoadResult): void;
  onError(response: Response): void;
  onProgress(response: IDataLoadResult): void;
}
