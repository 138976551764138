import * as React from 'react';
import { IConfigActionsConnectProps } from './config-actions-container';
import { ConfigActionsView } from './config-actions-view';
import { DATA_LOAD_STAGE } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Action buttons for "Configuration" workflow step
 */
export class ConfigActions extends React.Component<IConfigActionsConnectProps, IConfigActionsState> {

  constructor(props: IConfigActionsConnectProps) {
    super(props);
    this.state = {
      error: null,
      loading: false
    };

    this.onBackButtonClick = this.onBackButtonClick.bind(this);
    this.onCheckButtonClick = this.onCheckButtonClick.bind(this);
  }

  /** Cancel data load and reset Redux state to "Upload Data File" step */
  public onBackButtonClick(): void {
    this.props.dispatch(Actions.Batch.goToUploadStep());
  }

  /** Try to upload config on server for dry run */
  public onCheckButtonClick(): void {
    this.props.dispatch(Actions.UI.setUIData({ actionLoading: true }));
    this.setState({ loading: true });

    this.props.uploadConfig()
      .then((): void => {
        this.setState({ loading: false, error: null }, (): void => {
          this.props.dispatch(Actions.UI.setUIData({ actionLoading: false }));
          this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.CONFIGURATION_COMPLETE));
        });
      })
      .catch((response: Response): void => {
        this.setState({ loading: false, error: response });
        this.props.dispatch(Actions.UI.setUIData({ actionLoading: false }));
      });
  }

  public render(): JSX.Element {
    return (
      <ConfigActionsView
        error={this.state.error}
        loading={this.state.loading}
        disabled={this.state.loading || this.props.ui.actionLoading}
        checkButtonActive={!this.props.warning}
        onBackButtonClick={this.onBackButtonClick}
        onCheckButtonClick={this.onCheckButtonClick}
      />
    );
  }
}

export interface IConfigActionsState {
  readonly error: Response;
  readonly loading: boolean;
}
