import * as React from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { IIncidentDataUploadOptionsConnectProps } from './incident-data-upload-options-container';
import { IncidentDataUploadOptionsView } from './incident-data-upload-options-view';
import * as Actions from '../../../actions';

/**
 * Incidents specific file upload options
 */
export class IncidentDataUploadOptions
  extends React.Component<IIncidentDataUploadOptionsConnectProps> {
  constructor(props: IIncidentDataUploadOptionsConnectProps) {
    super(props);
    this.onIncidentTypeChange = this.onIncidentTypeChange.bind(this);
  }

  public shouldComponentUpdate(props: IIncidentDataUploadOptionsConnectProps): boolean {
    return this.props.upload.incidentType !== props.upload.incidentType
      || this.props.incidents !== props.incidents
      || this.props.loading !== props.loading;
  }

  public onIncidentTypeChange(e: React.ChangeEvent<HTMLInputElement>, changes: DropdownProps): void {
    this.props.dispatch(Actions.Upload.setUploadInfo({
      incidentType: changes.value.toString()
    }));
  }

  public render(): JSX.Element {
    return (
      <IncidentDataUploadOptionsView
        loading={this.props.loading}
        incidents={this.props.incidents}
        incidentType={this.props.upload.incidentType}
        onIncidentTypeChange={this.onIncidentTypeChange}
      />
    );
  }
}
