import { DATA_LOAD_STATUS } from './api';
import { DATA_LOAD_TYPE } from './common';

/**
 * List of columns in "Data Load History" table
 * Row index is shown to used instead of ID
 */
export enum DATA_LOAD_HISTORY_COLUMN {
  ROW_INDEX = 'rowIndex',
  ID = 'id',
  USER_ID = 'userId',
  TYPE = 'dataLoadType',
  FILE_NAME = 'dataFile',
  LAST_UPDATE = 'lastUpdateTime',
  STATUS = 'status'
}

export const DATA_LOAD_HISTORY_STATUS_OPTIONS: { [key: string]: string } = {
  [DATA_LOAD_STATUS.NEW]: 'New',
  [DATA_LOAD_STATUS.FILE_UPLOADED]: 'File Uploaded',
  [DATA_LOAD_STATUS.IN_PROGRESS]: 'In Progress',
  [DATA_LOAD_STATUS.SUCCESS]: 'Success',
  [DATA_LOAD_STATUS.CANCELED]: 'Cancelled',
  [DATA_LOAD_STATUS.FAILED]: 'Failed',
  [DATA_LOAD_STATUS.DRY_RUN_IN_PROGRESS]: 'Dry Run In Progress',
  [DATA_LOAD_STATUS.DRY_RUN_COMPLETED]: 'Dry Run Complete',
  [DATA_LOAD_STATUS.DRY_RUN_FAILED]: 'Dry Run Failed',
  [DATA_LOAD_STATUS.FLUSH_IN_PROGRESS]: 'Data Save In Progress',
  [DATA_LOAD_STATUS.FLUSH_COMPLETED]: 'Data Save Complete',
  [DATA_LOAD_STATUS.FLUSH_FAILED]: 'Data Save Failed'
};

export const DATA_LOAD_HISTORY_TYPE_OPTIONS: { [key: string]: string } = {
  [DATA_LOAD_TYPE.TAXONOMY]: 'Taxonomy: {type}',
  [DATA_LOAD_TYPE.PLAN_INDICATOR]: 'Plan Indicators',
  [DATA_LOAD_TYPE.INDICATOR_LIBRARY]: 'Indicator Library',
  [DATA_LOAD_TYPE.INDICATOR_INDICATOR_LINK]: 'Indicator-Indicator Mapping',
  [DATA_LOAD_TYPE.INDICATOR_PROCESS_LINK]: 'Indicator-Process Mapping',
  [DATA_LOAD_TYPE.INCIDENT]: 'Incidents',
  [DATA_LOAD_TYPE.CONTROL]: 'Controls',
  [DATA_LOAD_TYPE.ACTIVITY_INDICATOR_LINK]: 'Control-Indicator Mapping',
  [DATA_LOAD_TYPE.USER_MANAGEMENT]: 'User Management',
  [DATA_LOAD_TYPE.MONITORING_ACTIVITY]: 'Monitoring Design'
};
