import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { MA_DATA_LOAD_ACTION_OPTIONS } from '../../../constants';
import './ma-config-options.less';
import {
  DataLoadActionOptionsContainer,
  EmptyCellOptionsContainer,
  MatchMonitoringActivityKeyOptionsContainer,
  MatchPlanKeyOptionsContainer
} from '../../config';

/**
 * Monitoring activity config options component combines relevant config option containers
 */
export class MAConfigOptions extends React.Component {
  constructor(props: object) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <Form data-component="ma-config-options">
        <Form.Group widths="equal">
          <DataLoadActionOptionsContainer options={MA_DATA_LOAD_ACTION_OPTIONS} />
          <MatchPlanKeyOptionsContainer />
        </Form.Group>

        <Form.Group widths="equal">
          <MatchMonitoringActivityKeyOptionsContainer />
          <EmptyCellOptionsContainer />
        </Form.Group>
      </Form>
    );
  }
}
