import { ILocalStorageObject } from '../interfaces';
import { LOGIC_MANAGER_LOCAL_STORAGE_KEY } from '../constants';

/**
 * Get value from "Logic Manager" object in local storage or null, if it does not exist
 */
export function getLocalStorageValue(key: keyof ILocalStorageObject): string | null {
    const obj: string = window.localStorage.getItem(LOGIC_MANAGER_LOCAL_STORAGE_KEY);
    if (!obj) {
        return null;
    }

    const local: ILocalStorageObject = JSON.parse(obj);
    return local[key] || null;
}

/**
 * Set value in local storage using "Logic Manager" key
 */
export function setLocalStorageValue<T extends keyof ILocalStorageObject>(
    key: T,
    value: ILocalStorageObject[T]
): void {
    const obj: string = window.localStorage.getItem(LOGIC_MANAGER_LOCAL_STORAGE_KEY);
    const local: { token?: string } = (obj) ? JSON.parse(obj) : {};

    window.localStorage.setItem(LOGIC_MANAGER_LOCAL_STORAGE_KEY, JSON.stringify({
        ...local,
        [key]: value
    }, null, 2));
}

/**
 * Clear value from "Logic Manager" object
 */
export function clearLocalStorageValue(key: keyof ILocalStorageObject): void {
    const obj: string = window.localStorage.getItem(LOGIC_MANAGER_LOCAL_STORAGE_KEY);
    const local: { token?: string } = (obj) ? JSON.parse(obj) : {};
    delete local[key];

    window.localStorage.setItem(LOGIC_MANAGER_LOCAL_STORAGE_KEY, JSON.stringify(local, null, 2));
}

/**
 * Set value in local storage using "Logic Manager" key
 */
export function setSessionStorageValue<T extends keyof ILocalStorageObject>(
    key: T,
    value: ILocalStorageObject[T]
): void {
    const obj: string = window.sessionStorage.getItem(LOGIC_MANAGER_LOCAL_STORAGE_KEY);
    const local: { token?: string } = (obj) ? JSON.parse(obj) : {};

    window.sessionStorage.setItem(LOGIC_MANAGER_LOCAL_STORAGE_KEY, JSON.stringify({
        ...local,
        [key]: value
    }, null, 2));
}

/**
 * Get value from "Logic Manager" object in session storage or null, if it does not exist
 */
export function getSessionStorageValue(key: keyof ILocalStorageObject): string | null {
    const obj: string = window.sessionStorage.getItem(LOGIC_MANAGER_LOCAL_STORAGE_KEY);
    if (!obj) {
        return null;
    }

    const local: ILocalStorageObject = JSON.parse(obj);
    return local[key] || null;
}

/**
 * Clear value from "Logic Manager" object
 */
export function clearSessionStorageValue(key: keyof ILocalStorageObject): void {
    const obj: string = window.sessionStorage.getItem(LOGIC_MANAGER_LOCAL_STORAGE_KEY);
    const local: { token?: string } = (obj) ? JSON.parse(obj) : {};
    delete local[key];

    window.sessionStorage.setItem(LOGIC_MANAGER_LOCAL_STORAGE_KEY, JSON.stringify(local, null, 2));
}

/**
 * Get value from "Logic Manager" object in local storage or null, if it does not exist
 */
export function getAnyStorageValue(key: keyof ILocalStorageObject): string | null {
    const obj: string = window.localStorage.getItem(LOGIC_MANAGER_LOCAL_STORAGE_KEY);
    if (!obj) {
        const local: ILocalStorageObject = JSON.parse(obj);
        return local[key] || null;
    }

    const sessionObj: string = window.sessionStorage.getItem(LOGIC_MANAGER_LOCAL_STORAGE_KEY);
    if (!sessionObj) {
        return null;
    }

    const sessionLocal: ILocalStorageObject = JSON.parse(sessionObj);
    return sessionLocal[key] || null;
}

/**
 * Get value from "Logic Manager" object in local storage or null, if it does not exist
 */
export function clearBothStorageValue(key: keyof ILocalStorageObject): void {
    clearLocalStorageValue(key);
    clearSessionStorageValue(key);
}
