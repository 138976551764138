import React from 'react';
import { Dropdown, DropdownItemProps, DropdownProps, Icon, SemanticICONS, SemanticWIDTHS, Table } from 'semantic-ui-react';
import {
  CONFIG_VIEW_TEXT,
  MA_KEY,
  MA_R_KEY,
  MA_A_KEY,
  MA_KEY_OPTIONS,
  MA_R_KEY_OPTIONS,
  MA_A_KEY_OPTIONS,
  MA_MAPPING,
  MA_MAPPING_OPTIONS,
  NO_MAPPING_OPTION,
  PLAN_KEY,
  PLAN_KEY_OPTIONS,
  FF_KEY,
  FF_KEY_OPTIONS,
  TEST_STEP_KEY,
  TEST_STEP_KEY_OPTIONS,
  TESTING_FIELD_KEY,
  TESTING_FIELD_KEY_OPTIONS,
  SAMPLING_FIELD_KEY,
  SAMPLING_FIELD_KEY_OPTIONS,
  MA_MAPPING_KEYS
} from '../../../constants';
import { enumToArray, format, getOptions } from '../../../functions';

export class MAMappingOptions extends React.Component<IMAMappingOptionsProps, IMAMappingOptionsState> {
  constructor(props: IMAMappingOptionsProps) {
    super(props);
    this.state = {
      key: this.getMAKey(props),
      mappingType: this.getDefaultType(),
      mappingTypeOptions: this.getTypeOptions(),
      warning: false
    };

    this.onTypeChange = this.onTypeChange.bind(this);
    this.onMAColMappingChange = this.onMAColMappingChange.bind(this);
  }

  public getTypeOptions(): DropdownItemProps[] {
    const options: DropdownItemProps[] = getOptions(MA_MAPPING_OPTIONS);
    options.push({ value: NO_MAPPING_OPTION.key, text: NO_MAPPING_OPTION.caption });
    return options;
  }

  public getMAKey(props: IMAMappingOptionsProps): string {
    return props.monitoringActivityConfig[props.colIndex] || NO_MAPPING_OPTION.key;
  }

  public getDefaultType(): string {
    const key: string = this.getMAKey(this.props);
    const planIdKeys: string[] = enumToArray(PLAN_KEY);
    const maKeys: string[] = enumToArray(MA_KEY);
    const maRKeys: string[] = enumToArray(MA_R_KEY);
    const maAKeys: string[] = enumToArray(MA_A_KEY);
    const ffKeys: string[] = enumToArray(FF_KEY);
    const testStepKeys: string[] = enumToArray(TEST_STEP_KEY);
    const testingFieldKeys: string[] = enumToArray(TESTING_FIELD_KEY);
    const samplingFieldKeys: string[] = enumToArray(SAMPLING_FIELD_KEY);

    if (planIdKeys.indexOf(key) !== -1) { return MA_MAPPING.PLAN_IDENTIFIER; }
    if (maKeys.indexOf(key) !== -1) { return MA_MAPPING.MONITORING_DESIGN_FIELD; }
    if (maRKeys.indexOf(key) !== -1) { return MA_MAPPING.REVIEWER_FIELD; }
    if (maAKeys.indexOf(key) !== -1) { return MA_MAPPING.ADDITIONAL_SIGN_OFF_FIELD; }
    if (ffKeys.indexOf(key) !== -1) { return MA_MAPPING.FREQUENCY_FIELD; }
    if (testStepKeys.indexOf(key) !== -1) { return MA_MAPPING.TEST_STEPS; }
    if (testingFieldKeys.indexOf(key) !== -1) { return MA_MAPPING.TESTING_FIELDS; }
    if (samplingFieldKeys.indexOf(key) !== -1) { return MA_MAPPING.SAMPLING_FIELDS; }

    return NO_MAPPING_OPTION.key;
  }

  public onTypeChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    const warning: boolean = !(changes.value.toString() === NO_MAPPING_OPTION.key);
    this.setState({
      mappingType: changes.value.toString(),
      key: NO_MAPPING_OPTION.key
    });
    this.props.onMappingReset(this.props.colIndex);
    this.onWarningChange(warning);
  }

  public onWarningChange(warning: boolean): void {
    this.setState({ warning });
    this.props.onWarningChange(warning);
  }

  /** Update own value and call parent callback */
  public onMAColMappingChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.setState({ key: changes.value.toString() });
    switch (this.state.mappingType) {
      case MA_MAPPING.PLAN_IDENTIFIER:
        this.props.onMAColMappingChange(this.props.colIndex, changes.value.toString() as PLAN_KEY);
        this.onWarningChange(false);
        break;
      case MA_MAPPING.MONITORING_DESIGN_FIELD:
        this.props.onMAColMappingChange(this.props.colIndex, changes.value.toString() as MA_KEY);
        this.onWarningChange(false);
        break;
      case MA_MAPPING.REVIEWER_FIELD:
        this.props.onMAColMappingChange(this.props.colIndex, changes.value.toString() as MA_R_KEY);
        this.onWarningChange(false);
        break;
      case MA_MAPPING.ADDITIONAL_SIGN_OFF_FIELD:
        this.props.onMAColMappingChange(this.props.colIndex, changes.value.toString() as MA_A_KEY);
        this.onWarningChange(false);
        break;
      case MA_MAPPING.FREQUENCY_FIELD:
        this.props.onMAColMappingChange(this.props.colIndex, changes.value.toString() as FF_KEY);
        this.onWarningChange(false);
        break;
      case MA_MAPPING.TEST_STEPS:
        this.props.onMAColMappingChange(this.props.colIndex, changes.value.toString() as TEST_STEP_KEY);
        this.onWarningChange(false);
        break;
      case MA_MAPPING.TESTING_FIELDS:
        this.props.onMAColMappingChange(this.props.colIndex, changes.value.toString() as TESTING_FIELD_KEY);
        this.onWarningChange(false);
        break;
      case MA_MAPPING.SAMPLING_FIELDS:
        this.props.onMAColMappingChange(this.props.colIndex, changes.value.toString() as SAMPLING_FIELD_KEY);
        this.onWarningChange(false);
        break;
      default:
        break;
    }
  }

  /** Options for monitoring activities are based off mapping type */
  public getMAOptions(): JSX.Element {
    const options: DropdownItemProps[] = [];

    switch (this.state.mappingType) {
      case MA_MAPPING.PLAN_IDENTIFIER:
        options.push(...getOptions(PLAN_KEY_OPTIONS));
        break;
      case MA_MAPPING.MONITORING_DESIGN_FIELD:
        options.push(...getOptions(MA_KEY_OPTIONS));
        break;
      case MA_MAPPING.REVIEWER_FIELD:
        options.push(...getOptions(MA_R_KEY_OPTIONS));
        break;
      case MA_MAPPING.ADDITIONAL_SIGN_OFF_FIELD:
        options.push(...getOptions(MA_A_KEY_OPTIONS));
        break;
      case MA_MAPPING.FREQUENCY_FIELD:
        options.push(...getOptions(FF_KEY_OPTIONS));
        break;
      case MA_MAPPING.TEST_STEPS:
        options.push(...getOptions(TEST_STEP_KEY_OPTIONS));
        break;
      case MA_MAPPING.TESTING_FIELDS:
        options.push(...getOptions(TESTING_FIELD_KEY_OPTIONS));
        break;
      case MA_MAPPING.SAMPLING_FIELDS:
        options.push(...getOptions(SAMPLING_FIELD_KEY_OPTIONS));
        break;
      default:
        break;
    }

    return (
      <Dropdown
        data-component="ma-mapping-options"
        options={options}
        value={this.state.key}
        onChange={this.onMAColMappingChange}
        selectOnBlur={false}
        selection
        fluid
      />
    );
  }

  public getStatus(): JSX.Element {
    const STATUS_CELL_WIDTH: SemanticWIDTHS = 1;
    const empty: boolean = this.state.mappingType === NO_MAPPING_OPTION.key;
    const icon: SemanticICONS = (empty)
      ? 'warning'
      : (this.state.warning)
        ? 'warning sign'
        : 'checkmark';

    return (
      <Table.Cell
        textAlign="center"
        negative={!empty && this.state.warning}
        positive={!empty && !this.state.warning}
        width={STATUS_CELL_WIDTH}
      >
        <Icon name={icon} />
      </Table.Cell>
    );
  }

  public render(): JSX.Element {
    const name: string = this.props.name
      || format(CONFIG_VIEW_TEXT.COLUMN_INDEX, { index: (Number(this.props.colIndex) || 0) + 1 });

    return (
      <Table.Row data-component="activity-mapping-options">
        {this.getStatus()}

        <Table.Cell width={5}>
          {name}
        </Table.Cell>

        <Table.Cell width={5}>
          <Dropdown
            options={this.state.mappingTypeOptions}
            value={this.state.mappingType}
            onChange={this.onTypeChange}
            selectOnBlur={false}
            selection
            fluid
          />
        </Table.Cell>

        <Table.Cell width={5}>
          {this.getMAOptions()}
        </Table.Cell>
      </Table.Row>
    );
  }
}

export interface IMAMappingOptionsProps {
  readonly colIndex: string;
  readonly name: string;
  readonly monitoringActivityConfig: { [key: number]: MA_MAPPING_KEYS };

  onMappingReset(colIndex: string): void;
  onWarningChange(warning: boolean): void;
  onMAColMappingChange(colIndex: string, key: MA_MAPPING_KEYS): void;
}

export interface IMAMappingOptionsState {
  readonly key: string;
  readonly warning: boolean;
  readonly mappingType: string;
  readonly mappingTypeOptions: DropdownItemProps[];
}
