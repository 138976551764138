import * as React from 'react';
import { IIPLConfigConnectProps } from './ipl-config-container';
import { IPLConfigView } from './ipl-config-view';
import { IAppIPLConfig } from '../../../interfaces';
import { IPL_KEY } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Indicator-Process Links column mapping configuration
 */
export class IPLConfig extends React.Component<IIPLConfigConnectProps, IIPLConfigState> {
  constructor(props: IIPLConfigConnectProps) {
    super(props);
    this.state = {
      warning: false
    };

    this.onWarningChange = this.onWarningChange.bind(this);
    this.onMappingReset = this.onMappingReset.bind(this);
    this.onCoreEventColMappingChange = this.onCoreEventColMappingChange.bind(this);
  }

  public onCoreEventColMappingChange(colIndex: string, key: IPL_KEY): void {
    const config: IAppIPLConfig = {
      ...this.props.config.IPL,
      coreEventConfig: {
        ...this.props.config.IPL.coreEventConfig,
        [colIndex]: key
      }
    };

    this.props.dispatch(Actions.Config.setIPLConfig(config));
  }

  public onMappingReset(colIndex: string): void {
    const coreEventConfig: { [key: string]: IPL_KEY } = this.props.config.IPL.coreEventConfig;
    delete coreEventConfig[colIndex];

    const config: IAppIPLConfig = {
      ...this.props.config.IPL,
      coreEventConfig,
    };

    this.props.dispatch(Actions.Config.setIPLConfig(config));
  }

  public onWarningChange(warning: boolean): void {
    this.setState({ warning });
  }

  public showConfigMapping(): boolean {
    return !!this.props.ui.libraryType && !!this.props.ui.matchIndicatorKey
      && !!this.props.ui.matchKey && !!this.props.ui.processType;
  }

  public render(): JSX.Element {
    if (!this.props.config.IPL) { return null; }

    return (
      <IPLConfigView
        columns={this.props.config.columns}
        warning={this.state.warning}
        optionsReady={this.showConfigMapping()}
        appIPLConfig={this.props.config.IPL}

        onWarningChange={this.onWarningChange}
        onMappingReset={this.onMappingReset}
        onCoreEventColMappingChange={this.onCoreEventColMappingChange}
      />
    );
  }
}

export interface IIPLConfigState {
  readonly warning: boolean;
}
