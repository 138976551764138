import * as Redux from 'redux';
import { IDataState } from '../interfaces';

export const SET_DATA: string = 'SET_DATA';

export interface ISetData extends Redux.Action {
  readonly options: Partial<IDataState>;
}

/** Set any of data object values */
export function setData(options: Partial<IDataState>): ISetData {
  return { type: SET_DATA, options };
}
