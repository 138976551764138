import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { IPLWorkflow } from './ipl-workflow';
import { IState, IUIState } from '../../../interfaces';

export interface IIPLWorkflowStateProps {
  readonly ui: IUIState;
}

export type IIPLWorkflowConnectProps = IIPLWorkflowStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IIPLWorkflowStateProps {
  return { ui: state.ui };
}

export const IPLWorkflowContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(IPLWorkflow);
