import { RouteConfig } from 'react-router-config';
import { TxWorkflowContainer } from '../containers/tx-data-load';
import { PIWorkflowContainer } from '../containers/pi-data-load';
import { DataLoadHistoryContainer } from '../containers/data-load-history';
import { IndicatorWorkflowContainer } from '../containers/indicator-data-load';
import { IPLWorkflowContainer } from '../containers/ipl-data-load';
import { ILWorkflowContainer } from '../containers/il-data-load';
import { IncidentWorkflowContainer } from '../containers/incident-data-load';
import { ActivityWorkflowContainer } from '../containers/activity-data-load';
import { AILWorkflowContainer } from '../containers/ail-data-load';
import { MAWorkflowContainer } from '../containers/ma-data-load';
import { WorkflowContainer as UserManagementWorkflowContainer } from '../containers/user-management-data-load';

/** Route key is a unique link to component */
export enum ROUTE_KEY {
  DATALOAD = '/dataload',
  DATALOAD_TAXONOMY = '/dataload/taxonomy',
  DATALOAD_PLAN_INDICATORS = '/dataload/plan-indicators',
  DATALOAD_INDICATORS = '/dataload/indicators',
  DATALOAD_INDICATOR_INDICATOR_LINKS = '/dataload/indicator-indicator-links',
  DATALOAD_INDICATOR_PROCESS_LINKS = '/dataload/indicator-process-links',
  DATALOAD_INCIDENTS = '/dataload/incidents',
  DATALOAD_CONTROL = '/dataload/mitigation-activity',
  DATALOAD_ACTIVITY_EVENT_LINKS = '/dataload/activity-event-links',
  DATA_LOAD_HISTORY = '/dataload/data-load-history',
  DATALOAD_MONITORING_DESIGN = '/dataload/monitoring-design',
  USER_MANAGEMENT = '/dataload/user-management'
}

export interface IAppRoute extends RouteConfig {
  readonly caption: string;
  readonly sequence: number;
  readonly routes?: IAppRoute[];
}

/** All routes for "Data Load Web App" */
export const APPLICATION_ROUTES: IAppRoute[] = [
  {
    caption: 'Data Load Tool',
    path: ROUTE_KEY.DATALOAD,
    exact: true,
    sequence: 0,
    routes: [
      {
        caption: 'Users',
        path: ROUTE_KEY.USER_MANAGEMENT,
        exact: true,
        sequence: 0,
        component: UserManagementWorkflowContainer
      },
      {
        caption: 'Indicator Library',
        path: ROUTE_KEY.DATALOAD_INDICATORS,
        exact: true,
        sequence: 1,
        component: IndicatorWorkflowContainer
      },
      {
        caption: 'Indicator-Indicator Mapping',
        path: ROUTE_KEY.DATALOAD_INDICATOR_INDICATOR_LINKS,
        exact: true,
        sequence: 2,
        component: ILWorkflowContainer
      },
      {
        caption: 'Indicator-Process Mapping',
        path: ROUTE_KEY.DATALOAD_INDICATOR_PROCESS_LINKS,
        exact: true,
        sequence: 2,
        component: IPLWorkflowContainer
      },
      {
        caption: 'Plan Indicators',
        path: ROUTE_KEY.DATALOAD_PLAN_INDICATORS,
        exact: true,
        sequence: 3,
        component: PIWorkflowContainer
      },
      {
        caption: 'Controls',
        path: ROUTE_KEY.DATALOAD_CONTROL,
        exact: true,
        sequence: 4,
        component: ActivityWorkflowContainer
      },
      {
        caption: 'Indicator-Control Mapping',
        path: ROUTE_KEY.DATALOAD_ACTIVITY_EVENT_LINKS,
        exact: true,
        sequence: 5,
        component: AILWorkflowContainer
      },
      {
        caption: 'Monitoring Design',
        path: ROUTE_KEY.DATALOAD_MONITORING_DESIGN,
        exact: true,
        sequence: 6,
        component: MAWorkflowContainer
      },
      {
        caption: 'Incidents',
        path: ROUTE_KEY.DATALOAD_INCIDENTS,
        exact: true,
        sequence: 7,
        component: IncidentWorkflowContainer
      },
      {
        caption: 'Taxonomy',
        path: ROUTE_KEY.DATALOAD_TAXONOMY,
        exact: true,
        sequence: 8,
        component: TxWorkflowContainer
      }
    ]
  },
  {
    caption: 'Data Load History',
    path: ROUTE_KEY.DATA_LOAD_HISTORY,
    exact: true,
    sequence: 1,
    component: DataLoadHistoryContainer
  }
];
