import * as React from 'react';
import {
  Dropdown, DropdownItemProps, DropdownProps, Icon, SemanticICONS, SemanticWIDTHS, Table
} from 'semantic-ui-react';
import {
  CONFIG_VIEW_TEXT,
  IPL_INDICATOR_ID_OPTIONS,
  IPL_KEY,
  IPL_MAPPING,
  IPL_MAPPING_OPTIONS,
  IPL_PROCESS_ID_OPTIONS,
  NO_MAPPING_OPTION
} from '../../../constants';
import { format, getOptions } from '../../../functions';
import './ipl-mapping-options.less';

/**
 * Column mapping configuration options for Indicator-Process Links
 */
export class IPLMappingOptions extends React.Component<IIPLMappingOptionsProps, IIPLMappingOptionsState> {
  constructor(props: IIPLMappingOptionsProps) {
    super(props);
    this.state = {
      iplKey: this.getIplKey(props),
      mappingType: this.getDefaultType(),
      mappingTypeOptions: this.getTypeOptions(),
      warning: false
    };

    this.onTypeChange = this.onTypeChange.bind(this);
    this.onCoreEventColMappingChange = this.onCoreEventColMappingChange.bind(this);
  }

  /** "Type" option can be reset using NO_MAPPING */
  public getTypeOptions(): DropdownItemProps[] {
    const options: DropdownItemProps[] = getOptions(IPL_MAPPING_OPTIONS);
    options.push({ value: NO_MAPPING_OPTION.key, text: NO_MAPPING_OPTION.caption });
    return options;
  }

  public getIplKey(props: IIPLMappingOptionsProps): string {
    return props.coreEventConfig[props.colIndex] || NO_MAPPING_OPTION.key;
  }

  public getDefaultType(): string {
    const key: string = this.getIplKey(this.props);
    let type: string = NO_MAPPING_OPTION.key;
    if (IPL_PROCESS_ID_OPTIONS[key]) { type = IPL_MAPPING.PROCESS_ID; }
    if (IPL_INDICATOR_ID_OPTIONS[key]) { type = IPL_MAPPING.INDICATOR_ID; }

    return type;
  }

  public onTypeChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    const warning: boolean = !(changes.value.toString() === NO_MAPPING_OPTION.key);
    this.setState({
      mappingType: changes.value.toString(),
      iplKey: NO_MAPPING_OPTION.key
    });
    this.props.onMappingReset(this.props.colIndex);
    this.onWarningChange(warning);
  }

  /** Update own value and call parent callback */
  public onCoreEventColMappingChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.setState({ iplKey: changes.value.toString() });
    this.props.onCoreEventColMappingChange(this.props.colIndex, changes.value.toString() as IPL_KEY);
    this.onWarningChange(false);
  }

  /** Warning state shows or hides error sign for current row */
  public onWarningChange(warning: boolean): void {
    this.setState({ warning });
    this.props.onWarningChange(warning);
  }

  /**
   * Get status for column mapping (done, empty or warning)
   * Empty rows are allowed, the ones with warning are not
   */
  public getStatus(): JSX.Element {
    const STATUS_CELL_WIDTH: SemanticWIDTHS = 1;
    const empty: boolean = this.state.mappingType === NO_MAPPING_OPTION.key;
    const icon: SemanticICONS = (empty)
      ? 'warning'
      : (this.state.warning)
        ? 'warning sign'
        : 'checkmark';

    return (
      <Table.Cell
        textAlign="center"
        negative={!empty && this.state.warning}
        positive={!empty && !this.state.warning}
        width={STATUS_CELL_WIDTH}
      >
        <Icon name={icon} />
      </Table.Cell>
    );
  }

  public getIplOptions(): JSX.Element {
    let options: DropdownItemProps[] = [];
    switch (this.state.mappingType) {
      case IPL_MAPPING.PROCESS_ID:
        options = getOptions(IPL_PROCESS_ID_OPTIONS);
        break;
      case IPL_MAPPING.INDICATOR_ID:
        options = getOptions(IPL_INDICATOR_ID_OPTIONS);
        break;
      default: {
        return null;
      }
    }

    return (
      <Dropdown
        options={options}
        value={this.state.iplKey}
        onChange={this.onCoreEventColMappingChange}
        selectOnBlur={false}
        selection
        search
        fluid
      />
    );
  }

  public render(): JSX.Element {
    const name: string = this.props.name
      || format(CONFIG_VIEW_TEXT.COLUMN_INDEX, { index: (Number(this.props.colIndex) || 0) + 1 });

    return (
      <Table.Row data-component="ipl-mapping-options">
        {this.getStatus()}

        <Table.Cell width={5}>
          {name}
        </Table.Cell>

        <Table.Cell width={5}>
          <Dropdown
            options={this.state.mappingTypeOptions}
            value={this.state.mappingType}
            onChange={this.onTypeChange}
            selectOnBlur={false}
            selection
            fluid
          />
        </Table.Cell>

        <Table.Cell width={5}>
          {this.getIplOptions()}
        </Table.Cell>
      </Table.Row>
    );
  }
}

export interface IIPLMappingOptionsState {
  readonly iplKey: string;
  /** Used to display row warnings or errors */
  readonly warning: boolean;
  readonly mappingType: string;
  readonly mappingTypeOptions: DropdownItemProps[];
}

export interface IIPLMappingOptionsProps {
  readonly colIndex: string;
  readonly name: string;
  readonly coreEventConfig: { [key: number]: IPL_KEY };

  onMappingReset(colIndex: string): void;
  onWarningChange(warning: boolean): void;
  onCoreEventColMappingChange(colIndex: string, key: IPL_KEY): void;
}
