import * as React from 'react';
import { DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { CONFIG_VIEW_TEXT, MATCH_USER_MANAGEMENT_BY_OPTIONS } from '../../../constants';
import { format, getOptions } from '../../../functions';

/**
 * View for "Match User Management records Key Options" component
 */
export class MatchUserManagementKeyOptionsView extends React.Component<IMatchUserManagementKeyOptionsViewProps> {
  private static MATCH_TITLE: string = 'records';

  constructor(props: IMatchUserManagementKeyOptionsViewProps) {
    super(props);
  }

  public render(): JSX.Element {
    const label: string = format(
      CONFIG_VIEW_TEXT.MATCH_KEY_BY,
      { key: MatchUserManagementKeyOptionsView.MATCH_TITLE }
    );
    const options: DropdownItemProps[] = getOptions(MATCH_USER_MANAGEMENT_BY_OPTIONS);

    return (
      <Form.Dropdown
        label={label}
        options={options}
        value={this.props.value}
        onChange={this.props.onChange}
        selectOnBlur={false}
        selection
        required
      />
    );
  }
}

export interface IMatchUserManagementKeyOptionsViewProps {
  readonly value: string;
  onChange(e: React.SyntheticEvent<HTMLDivElement>, changes: DropdownProps): void;
}
