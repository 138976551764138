import * as React from 'react';
import {
  Dropdown, DropdownItemProps, DropdownProps, Icon, SemanticICONS, SemanticWIDTHS, Table
} from 'semantic-ui-react';
import { PlanEventMappingOptions } from '../plan-event-mapping-options';
import { PlanEventScoreMappingOptions } from '../plan-event-score-mapping-options';
import {
  CONFIG_VIEW_TEXT, LIBRARY_TYPE, NO_MAPPING_BLANK_OPTION, NO_MAPPING_OPTION, PI_MAPPING, PI_MAPPING_OPTIONS,
  PLAN_EVENT_KEY, PLAN_EVENT_SCORE_KEY, PLAN_KEY, PLAN_KEY_OPTIONS
} from '../../../constants';
import { enumToArray, format, getOptions } from '../../../functions';
import './pi-mapping-options.less';

/**
 * Options for mapping column into plan event or plan event score keys
 * Component receives only default value through props
 * Rest of actions are self-controlled
 */
export class PIMappingOptions extends React.Component<IPIMappingOptionsProps, IPIMappingOptionsState> {
  constructor(props: IPIMappingOptionsProps) {
    super(props);
    this.state = {
      key: this.getSelfKey(props),
      mappingType: this.getDefaultType(),
      mappingTypeOptions: this.getTypeOptions(),
      planIdOptions: getOptions(PLAN_KEY_OPTIONS),
    };

    this.onTypeChange = this.onTypeChange.bind(this);
    this.onPlanEventColMappingChange = this.onPlanEventColMappingChange.bind(this);
    this.onPlanEventScoreColMappingChange = this.onPlanEventScoreColMappingChange.bind(this);
  }

  /** Get own key from plan event or plan event score configs */
  public getSelfKey(props: IPIMappingOptionsProps): string {
    return props.planEventConfig[props.colIndex]
      || props.planEventScoreConfig[props.colIndex]
      || NO_MAPPING_BLANK_OPTION.key;
  }

  /** Track only library type and own state changes */
  public shouldComponentUpdate(props: IPIMappingOptionsProps, state: IPIMappingOptionsState): boolean {
    return (this.state !== state) || (this.props.libraryType !== props.libraryType);
  }

  /** If Plan-Indicators keys have been auto mapped, determine initial value of "plan indicator type" dropdown */
  public getDefaultType(): string {
    const key: string = this.getSelfKey(this.props);
    const planIdKeys: string[] = enumToArray(PLAN_KEY);
    const planEventKeys: string[] = enumToArray(PLAN_EVENT_KEY);
    const planEventScoreKeys: string[] = enumToArray(PLAN_EVENT_SCORE_KEY);

    if (planIdKeys.indexOf(key) !== -1) { return PI_MAPPING.PLAN_KEY; }
    if (planEventKeys.indexOf(key) !== -1) { return PI_MAPPING.PLAN_EVENT; }
    if (planEventScoreKeys.indexOf(key) !== -1) { return PI_MAPPING.PLAN_EVENT_SCORE; }

    return NO_MAPPING_OPTION.key;
  }

  /** Plan-Indicators type is only stored in local state */
  public onTypeChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.setState({ mappingType: changes.value.toString(), key: NO_MAPPING_BLANK_OPTION.key });
    this.props.onMappingReset(this.props.colIndex);
  }

  /** Update own value and call parent callback */
  public onPlanEventColMappingChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.setState({ key: changes.value.toString() });
    this.props.onPlanEventColMappingChange(this.props.colIndex, changes.value.toString() as PLAN_EVENT_KEY);
  }

  /** Update own value and call parent callback */
  public onPlanEventScoreColMappingChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.setState({ key: changes.value.toString() });
    this.props.onPlanEventScoreColMappingChange(this.props.colIndex, changes.value.toString() as PLAN_EVENT_SCORE_KEY);
  }

  /** "Type" option can be reset using NO_MAPPING */
  public getTypeOptions(): DropdownItemProps[] {
    const options: DropdownItemProps[] = getOptions(PI_MAPPING_OPTIONS);
    options.push({ value: NO_MAPPING_OPTION.key, text: NO_MAPPING_OPTION.caption });
    return options;
  }

  /** Get dropdown for mapping column into plan ID, plan event or plan event score keys */
  public getTypeConfigOptions(): JSX.Element {
    return (
      <Dropdown
        options={this.state.mappingTypeOptions}
        value={this.state.mappingType}
        onChange={this.onTypeChange}
        selectOnBlur={false}
        selection
        fluid
      />
    );
  }

  /** Get dropdown for mapping column into plan ID key */
  public getPlanIdKeyConfigOptions(): JSX.Element {
    return (
      <Dropdown
        options={this.state.planIdOptions}
        value={this.state.key}
        onChange={this.onPlanEventColMappingChange}
        selectOnBlur={false}
        selection
        search
        fluid
      />
    );
  }

  /** Get dropdown with key options based on selected Plan-Indicators type */
  public getKeyConfigOptions(): JSX.Element {
    if (this.state.mappingType === PI_MAPPING.PLAN_KEY) {
      return this.getPlanIdKeyConfigOptions();
    }

    if (this.state.mappingType === PI_MAPPING.PLAN_EVENT) {
      return (
        <PlanEventMappingOptions
          libraryType={this.props.libraryType}
          planEventKey={this.state.key}
          onPlanEventColMappingChange={this.onPlanEventColMappingChange}
        />
      );
    }

    if (this.state.mappingType === PI_MAPPING.PLAN_EVENT_SCORE) {
      return (
        <PlanEventScoreMappingOptions
          libraryType={this.props.libraryType}
          planEventScoreKey={this.state.key}
          onPlanEventScoreColMappingChange={this.onPlanEventScoreColMappingChange}
        />
      );
    }

    return null;
  }

  /**
   * Get status for column mapping (done, empty or warning)
   * Empty rows are allowed, the ones with warning are not
   */
  public getStatus(): JSX.Element {
    const STATUS_CELL_WIDTH: SemanticWIDTHS = 1;
    const empty: boolean = this.state.mappingType === NO_MAPPING_OPTION.key;
    const warning: boolean = this.state.key === NO_MAPPING_OPTION.key
      || this.state.key === NO_MAPPING_BLANK_OPTION.key;

    const icon: SemanticICONS = (empty)
      ? 'warning'
      : (warning)
        ? 'warning sign'
        : 'checkmark';

    return (
      <Table.Cell
        textAlign="center"
        negative={!empty && warning}
        positive={!empty && !warning}
        width={STATUS_CELL_WIDTH}
      >
        <Icon name={icon} />
      </Table.Cell>
    );
  }

  public render(): JSX.Element {
    const name: string = this.props.name
      || format(CONFIG_VIEW_TEXT.COLUMN_INDEX, { index: this.props.colIndex + 1 });

    return (
      <Table.Row data-component="pi-mapping-options">
        {this.getStatus()}

        <Table.Cell width={5}>
          {name}
        </Table.Cell>

        <Table.Cell width={5}>
          {this.getTypeConfigOptions()}
        </Table.Cell>

        <Table.Cell width={5}>
          {this.getKeyConfigOptions()}
        </Table.Cell>
      </Table.Row>
    );
  }
}

export interface IPIMappingOptionsState {
  readonly key: string;
  readonly mappingType: string;
  readonly mappingTypeOptions: DropdownItemProps[];
  readonly planIdOptions: DropdownItemProps[];
}

export interface IPIMappingOptionsProps {
  readonly name: string;
  readonly colIndex: string;
  readonly libraryType: LIBRARY_TYPE;
  readonly planEventConfig: { [key: number]: PLAN_EVENT_KEY };
  readonly planEventScoreConfig: { [key: number]: PLAN_EVENT_SCORE_KEY };
  onMappingReset(colIndex: string): void;
  onPlanEventColMappingChange(colIndex: string, key: PLAN_EVENT_KEY): void;
  onPlanEventScoreColMappingChange(colIndex: string, key: PLAN_EVENT_SCORE_KEY): void;
}
