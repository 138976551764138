import { FF_KEY } from './ff';
import { PLAN_KEY } from './pi';
import { SAMPLING_FIELD_KEY } from './sampling-fields';
import { TEST_STEP_KEY } from './test-steps';
import { TESTING_FIELD_KEY } from './testing-fields';

export enum MA_MAPPING {
  PLAN_IDENTIFIER = 'PLAN_IDENTIFIER',
  MONITORING_DESIGN_FIELD = 'MONITORING_DESIGN_FIELD',
  REVIEWER_FIELD = 'REVIEWER_FIELD',
  ADDITIONAL_SIGN_OFF_FIELD = 'ADDITIONAL_SIGN_OFF_FIELD',
  FREQUENCY_FIELD = 'FREQUENCY_FIELD',
  TEST_STEPS = 'TEST_STEPS',
  TESTING_FIELDS = 'TESTING_FIELDS',
  SAMPLING_FIELDS = 'SAMPLING_FIELDS'
}

/** Options with captions for "Mapping Type" */
export const MA_MAPPING_OPTIONS: { [key: string]: string } = {
  [MA_MAPPING.PLAN_IDENTIFIER]: 'Plan Identifier',
  [MA_MAPPING.MONITORING_DESIGN_FIELD]: 'Monitoring Design Field',
  [MA_MAPPING.REVIEWER_FIELD]: 'Reviewer Field',
  [MA_MAPPING.ADDITIONAL_SIGN_OFF_FIELD]: 'Additional Sign Off Field',
  [MA_MAPPING.FREQUENCY_FIELD]: 'Frequency Field',
  [MA_MAPPING.TEST_STEPS]: 'Test Steps',
  [MA_MAPPING.TESTING_FIELDS]: 'Testing Fields',
  [MA_MAPPING.SAMPLING_FIELDS]: 'Sampling Fields'
};

export enum MA_KEY {
  MONITORING_ID = 'monitoringId',
  MONITORING_NAME = 'monitoringName',
  MONITORING_DESCRIPTION = 'monitoringDescription',
  ASSIGNED_TO = 'assignedTo',
  COMMENT = 'comment',
  SEND_EMAIL = 'sendEmail',
  SEND_DAYS_BEFORE_DUE = 'sendDaysBeforeDue',
  RECURRING_EMAIL_INTERVAL = 'recurringEmailInterval',
  EMAIL_WHEN_COMPLETE = 'emailWhenComplete',
  STATUS = 'status'
}

/** Keys for when mapping type is "Reviewer Field" */
export enum MA_R_KEY {
  REVIEWER = 'reviewer',
  REVIEWER_DAYS_FOR_COMPLETION = 'reviewerDaysForCompletion',
  REVIEWER_COMMENT = 'reviewerComment',
  REVIEWER_SEND_EMAIL = 'reviewerSendEmail',
  REVIEWER_SEND_DAYS_BEFORE_DUE = 'reviewerSendDaysBeforeDue',
  REVIEWER_RECURRING_EMAIL_INTERVAL = 'reviewerRecurringEmailInterval',
  REVIEWER_EMAIL_WHEN_COMPLETE = 'reviewerEmailWhenComplete'
}

/** Keys for when mapping type is "Additional Sign Off Field" */
export enum MA_A_KEY {
  ADDITIONAL_SIGN_OFF = 'additionalSignOff',
  ADDITIONAL_SIGN_OFF_DAYS_FOR_COMPLETION = 'additionalSignOffDaysForCompletion',
  ADDITIONAL_SIGN_OFF_COMMENT = 'additionalSignOffComment',
  ADDITIONAL_SIGN_OFF_SEND_EMAIL = 'additionalSignOffSendEmail',
  ADDITIONAL_SIGN_OFF_SEND_DAYS_BEFORE_DUE = 'additionalSignOffSendDaysBeforeDue',
  ADDITIONAL_SIGN_OFF_RECURRING_EMAIL_INTERVAL = 'additionalSignOffRecurringEmailInterval',
  ADDITIONAL_SIGN_OFF_EMAIL_WHEN_COMPLETE = 'additionalSignOffEmailWhenComplete'
}

export const MA_KEY_OPTIONS: { [key: string]: string } = {
  [MA_KEY.MONITORING_ID]: 'ID',
  [MA_KEY.MONITORING_NAME]: 'Name',
  [MA_KEY.MONITORING_DESCRIPTION]: 'Description',
  [MA_KEY.ASSIGNED_TO]: 'Assigned To',
  [MA_KEY.COMMENT]: 'Comment',
  [MA_KEY.SEND_EMAIL]: 'Send email (to the assignee)',
  [MA_KEY.SEND_DAYS_BEFORE_DUE]: 'Send email X day(s) before Due Date',
  [MA_KEY.RECURRING_EMAIL_INTERVAL]: 'Send recurring emails every X day(s)',
  [MA_KEY.EMAIL_WHEN_COMPLETE]: 'Email assigner when task complete',
  [MA_KEY.STATUS]: 'Status'
};

/** Options for when mapping type is "Reviewer Field" */
export const MA_R_KEY_OPTIONS: { [key: string]: string } = {
  [MA_R_KEY.REVIEWER]: 'Reviewer',
  [MA_R_KEY.REVIEWER_DAYS_FOR_COMPLETION]: 'Days for completion',
  [MA_R_KEY.REVIEWER_COMMENT]: 'Comment',
  [MA_R_KEY.REVIEWER_SEND_EMAIL]: 'Send email (to the assignee)',
  [MA_R_KEY.REVIEWER_SEND_DAYS_BEFORE_DUE]: 'Send email X day(s) before Due Date',
  [MA_R_KEY.REVIEWER_RECURRING_EMAIL_INTERVAL]: 'Send recurring emails every X day(s)',
  [MA_R_KEY.REVIEWER_EMAIL_WHEN_COMPLETE]: 'Email assigner when task complete'
};

/** Options for when mapping type is "Additional Sign Off Field" */
export const MA_A_KEY_OPTIONS: { [key: string]: string } = {
  [MA_A_KEY.ADDITIONAL_SIGN_OFF]: 'Additional Sign Off',
  [MA_A_KEY.ADDITIONAL_SIGN_OFF_DAYS_FOR_COMPLETION]: 'Days for completion',
  [MA_A_KEY.ADDITIONAL_SIGN_OFF_COMMENT]: 'Comment',
  [MA_A_KEY.ADDITIONAL_SIGN_OFF_SEND_EMAIL]: 'Send email (to the assignee)',
  [MA_A_KEY.ADDITIONAL_SIGN_OFF_SEND_DAYS_BEFORE_DUE]: 'Send email X day(s) before Due Date',
  [MA_A_KEY.ADDITIONAL_SIGN_OFF_RECURRING_EMAIL_INTERVAL]: 'Send recurring emails every X day(s)',
  [MA_A_KEY.ADDITIONAL_SIGN_OFF_EMAIL_WHEN_COMPLETE]: 'Email assigner when task complete'
};

export enum MA_TYPE {
  TEST = 'test',
  // METRIC = 'metric'
}

export const MA_TYPE_OPTIONS: { [key: string]: string } = {
  [MA_TYPE.TEST]: 'Test'
};

export type MA_MAPPING_KEYS = PLAN_KEY
  | MA_KEY | FF_KEY | MA_A_KEY | MA_R_KEY
  | TEST_STEP_KEY | TESTING_FIELD_KEY
  | SAMPLING_FIELD_KEY;
