import { USER_MANAGEMENT_OPTIONS } from './user-management';

export enum DATA_LOAD_ACTION {
  ADD_NEW = 'ADD_NEW',
  UPDATE_EXISTING = 'UPDATE_EXISTING',
  ADD_NEW_AND_UPDATE_EXISTING = 'ADD_NEW_AND_UPDATE_EXISTING'
}

export enum EMPTY_CELLS_ACTION {
  CLEAR_EMPTY_CELLS = 'CLEAR_EMPTY_CELLS',
  DO_NOTHING = 'DO_NOTHING'
}

export enum PROCESS_TYPE {
  CORE_PROCESS = 'CP',
  OTHER_DIMENSION = 'OD'
}

export enum LIBRARY_TYPE {
  RISK = 'RISK',
  READINESS = 'READINESS',
  PERFORMANCE = 'PERFORMANCE'
}

export enum MATCH_KEY {
  ID = 'ID',
  NAME = 'NAME'
}

export enum MATCH_PLAN_KEY {
  PLAN_ID = 'PLAN_ID',
  PLAN_NAME = 'PLAN_NAME'
}

export enum MATCH_ACTIVITY_KEY {
  ACTIVITY_ID = 'ACTIVITY_ID',
  ACTIVITY_NAME = 'ACTIVITY_NAME'
}

export enum MATCH_INDICATOR_KEY {
  INDICATOR_ID = 'INDICATOR_ID',
  CATEGORY_FACTOR_INDICATOR = 'CATEGORY_FACTOR_INDICATOR'
}

export enum MATCH_MONITORING_ACTIVITY_KEY {
  MONITORING_ACTIVITY_ID = 'MONITORING_ACTIVITY_ID',
  MONITORING_ACTIVITY_NAME = 'MONITORING_ACTIVITY_NAME'
}

export const INDICATOR_DATA_LOAD_ACTION_OPTIONS: { [key: string]: string } = {
  [DATA_LOAD_ACTION.ADD_NEW]: 'Add to the library',
  [DATA_LOAD_ACTION.UPDATE_EXISTING]: 'Update the library',
};

export const DATA_LOAD_ACTION_OPTIONS: { [key: string]: string } = {
  [DATA_LOAD_ACTION.ADD_NEW]: 'Add new records',
  [DATA_LOAD_ACTION.UPDATE_EXISTING]: 'Update existing records',
  [DATA_LOAD_ACTION.ADD_NEW_AND_UPDATE_EXISTING]: 'Add new and update existing records'
};

export const PI_DATA_LOAD_ACTION_OPTIONS: { [key: string]: string } = {
  [DATA_LOAD_ACTION.ADD_NEW]: 'Add new Indicators to Plans',
  [DATA_LOAD_ACTION.UPDATE_EXISTING]: 'Update existing Indicators in Plans',
  [DATA_LOAD_ACTION.ADD_NEW_AND_UPDATE_EXISTING]: 'Add new and update Indicators in Plans'
};

export const ACTIVITY_DATA_LOAD_ACTION_OPTIONS: { [key: string]: string } = {
  [DATA_LOAD_ACTION.ADD_NEW]: 'Add new Controls to Plans',
  [DATA_LOAD_ACTION.UPDATE_EXISTING]: 'Update existing Controls in Plans',
  [DATA_LOAD_ACTION.ADD_NEW_AND_UPDATE_EXISTING]: 'Add new and update existing Controls in Plans'
};

export const MA_DATA_LOAD_ACTION_OPTIONS: { [key: string]: string } = {
  [DATA_LOAD_ACTION.ADD_NEW]: 'Add new Monitoring Activities to Plans',
  [DATA_LOAD_ACTION.UPDATE_EXISTING]: 'Update existing Monitoring Activities in Plans'
};

export const EMPTY_CELLS_ACTION_OPTIONS: { [key: string]: string } = {
  [EMPTY_CELLS_ACTION.CLEAR_EMPTY_CELLS]: 'Clear field data',
  [EMPTY_CELLS_ACTION.DO_NOTHING]: 'Do nothing'
};

export const PROCESS_TYPE_OPTIONS: { [key: string]: string } = {
  [PROCESS_TYPE.CORE_PROCESS]: 'Core Process',
  [PROCESS_TYPE.OTHER_DIMENSION]: 'Other Dimension'
};

export const LIBRARY_TYPE_OPTIONS: { [key: string]: string } = {
  [LIBRARY_TYPE.RISK]: 'Risk',
  [LIBRARY_TYPE.READINESS]: 'Readiness',
  [LIBRARY_TYPE.PERFORMANCE]: 'Performance'
};

export const MATCH_PLANS_BY_OPTIONS: { [key: string]: string } = {
  [MATCH_PLAN_KEY.PLAN_ID]: 'Plan ID',
  [MATCH_PLAN_KEY.PLAN_NAME]: 'Plan Name'
};

export const MATCH_USER_MANAGEMENT_BY_OPTIONS: { [key: string]: string } = {
  [USER_MANAGEMENT_OPTIONS.LOGIN_ID]: 'Login ID',
  [USER_MANAGEMENT_OPTIONS.EMAIL]: 'Email',
};

export const MATCH_USER_MANAGEMENT_KEY_OPTIONS: { [key: string]: string } = {
  ...MATCH_USER_MANAGEMENT_BY_OPTIONS,
  [USER_MANAGEMENT_OPTIONS.LAST_NAME]: 'Last Name',
  [USER_MANAGEMENT_OPTIONS.FIRST_NAME]: 'First Name',
  [USER_MANAGEMENT_OPTIONS.ACTIVE]: 'Status',
  [USER_MANAGEMENT_OPTIONS.CELL]: 'Cellular',
  [USER_MANAGEMENT_OPTIONS.COST_CENTER]: 'Cost Center',
  [USER_MANAGEMENT_OPTIONS.DEPARTMENT]: 'Department',
  [USER_MANAGEMENT_OPTIONS.EXPIRE_DATE]: 'Expiration Date',
  [USER_MANAGEMENT_OPTIONS.MANAGING_DIRECTOR]: 'Managing Director',
  [USER_MANAGEMENT_OPTIONS.ORGANIZATION]: 'Organization',
  [USER_MANAGEMENT_OPTIONS.PHONE]: 'Phone',
  [USER_MANAGEMENT_OPTIONS.TITLE]: 'Corporate Title',
  [USER_MANAGEMENT_OPTIONS.TEMPORARY]: 'Temporary',
  [USER_MANAGEMENT_OPTIONS.MANAGER]: 'Manager',
};

export const MATCH_INDICATORS_BY_OPTIONS: { [key: string]: string } = {
  [MATCH_INDICATOR_KEY.INDICATOR_ID]: 'Indicator ID',
  [MATCH_INDICATOR_KEY.CATEGORY_FACTOR_INDICATOR]: 'Category, Factor and Indicator Names'
};

export const MATCH_KEY_OPTIONS: { [key: string]: string } = {
  [MATCH_KEY.ID]: 'ID',
  [MATCH_KEY.NAME]: 'Name'
};

export const MATCH_ACTIVITY_KEY_OPTIONS: { [key: string]: string } = {
  [MATCH_ACTIVITY_KEY.ACTIVITY_ID]: 'Control ID',
  [MATCH_ACTIVITY_KEY.ACTIVITY_NAME]: 'Control Name'
};

export const MATCH_MONITORING_ACTIVITY_KEY_OPTIONS: { [key: string]: string } = {
  [MATCH_MONITORING_ACTIVITY_KEY.MONITORING_ACTIVITY_ID]: 'Activity ID',
  [MATCH_MONITORING_ACTIVITY_KEY.MONITORING_ACTIVITY_NAME]: 'Activity Name'
};
