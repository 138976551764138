import * as React from 'react';
import {
  Dropdown, DropdownItemProps, DropdownProps, Icon, SemanticICONS, SemanticWIDTHS, Table
} from 'semantic-ui-react';
import {
  CONTROL_KEY, AIL_ACTIVITY_ID_OPTIONS, AIL_INDICATOR_ID_OPTIONS, AIL_MAPPING, AIL_MAPPING_OPTIONS,
  AIL_PLAN_ID_OPTIONS, CONFIG_VIEW_TEXT, INDICATOR_KEY, NO_MAPPING_OPTION, PLAN_KEY
} from '../../../constants';
import { format, getOptions } from '../../../functions';
import './ail-mapping-options.less';

/**
 * Column mapping configuration options for Activity-Indicator Links
 */
export class AILMappingOptions extends React.Component<IAILMappingOptionsProps, IAILMappingOptionsState> {
  constructor(props: IAILMappingOptionsProps) {
    super(props);
    this.state = {
      activityEventKey: this.getActivityEventKey(props),
      mappingType: this.getDefaultType(),
      mappingTypeOptions: this.getTypeOptions(),
      warning: false
    };

    this.onTypeChange = this.onTypeChange.bind(this);
    this.onActivityEventColMappingChange = this.onActivityEventColMappingChange.bind(this);
  }

  public getTypeOptions(): DropdownItemProps[] {
    const options: DropdownItemProps[] = getOptions(AIL_MAPPING_OPTIONS);
    options.push({ value: NO_MAPPING_OPTION.key, text: NO_MAPPING_OPTION.caption });
    return options;
  }

  public getActivityEventKey(props: IAILMappingOptionsProps): string {
    return props.activityEventConfig[props.colIndex] || NO_MAPPING_OPTION.key;
  }

  public getDefaultType(): string {
    const key: string = this.getActivityEventKey(this.props);
    let type: string = NO_MAPPING_OPTION.key;
    if (AIL_PLAN_ID_OPTIONS[key]) { type = AIL_MAPPING.PLAN_ID; }
    if (AIL_INDICATOR_ID_OPTIONS[key]) { type = AIL_MAPPING.INDICATOR_ID; }
    if (AIL_ACTIVITY_ID_OPTIONS[key]) { type = AIL_MAPPING.CONTROL_ID; }

    return type;
  }

  public onTypeChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    const warning: boolean = !(changes.value.toString() === NO_MAPPING_OPTION.key);
    this.setState({
      mappingType: changes.value.toString(),
      activityEventKey: NO_MAPPING_OPTION.key
    });
    this.props.onMappingReset(this.props.colIndex);
    this.onWarningChange(warning);
  }

  public onActivityEventColMappingChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.setState({ activityEventKey: changes.value.toString() });
    this.props.onActivityEventColMappingChange(
      this.props.colIndex,
      changes.value.toString() as CONTROL_KEY | INDICATOR_KEY | PLAN_KEY
    );
    this.onWarningChange(false);
  }

  public onWarningChange(warning: boolean): void {
    this.setState({ warning });
    this.props.onWarningChange(warning);
  }

  public getStatus(): JSX.Element {
    const STATUS_CELL_WIDTH: SemanticWIDTHS = 1;
    const empty: boolean = this.state.mappingType === NO_MAPPING_OPTION.key;
    const icon: SemanticICONS = (empty)
      ? 'warning'
      : (this.state.warning)
        ? 'warning sign'
        : 'checkmark';

    return (
      <Table.Cell
        textAlign="center"
        negative={!empty && this.state.warning}
        positive={!empty && !this.state.warning}
        width={STATUS_CELL_WIDTH}
      >
        <Icon name={icon} />
      </Table.Cell>
    );
  }

  public getActivityEventOptions(): JSX.Element {
    let options: DropdownItemProps[] = [];
    switch (this.state.mappingType) {
      case AIL_MAPPING.CONTROL_ID:
        options = getOptions(AIL_ACTIVITY_ID_OPTIONS);
        break;
      case AIL_MAPPING.INDICATOR_ID:
        options = getOptions(AIL_INDICATOR_ID_OPTIONS);
        break;
      case AIL_MAPPING.PLAN_ID:
        options = getOptions(AIL_PLAN_ID_OPTIONS);
        break;
      default: {
        return null;
      }
    }

    return (
      <Dropdown
        options={options}
        value={this.state.activityEventKey}
        onChange={this.onActivityEventColMappingChange}
        selectOnBlur={false}
        selection
        search
        fluid
      />
    );
  }

  public render(): JSX.Element {
    const name: string = this.props.name
      || format(CONFIG_VIEW_TEXT.COLUMN_INDEX, { index: (Number(this.props.colIndex) || 0) + 1 });

    return (
      <Table.Row data-component="ail-mapping-options">
        {this.getStatus()}

        <Table.Cell width={5}>
          {name}
        </Table.Cell>

        <Table.Cell width={5}>
          <Dropdown
            options={this.state.mappingTypeOptions}
            value={this.state.mappingType}
            onChange={this.onTypeChange}
            selectOnBlur={false}
            selection
            fluid
          />
        </Table.Cell>

        <Table.Cell width={5}>
          {this.getActivityEventOptions()}
        </Table.Cell>
      </Table.Row>
    );
  }
}

export interface IAILMappingOptionsState {
  readonly activityEventKey: string;
  readonly warning: boolean;
  readonly mappingType: string;
  readonly mappingTypeOptions: DropdownItemProps[];
}

export interface IAILMappingOptionsProps {
  readonly colIndex: string;
  readonly name: string;
  readonly activityEventConfig: { [key: number]: CONTROL_KEY | INDICATOR_KEY | PLAN_KEY };

  onMappingReset(colIndex: string): void;
  onWarningChange(warning: boolean): void;
  onActivityEventColMappingChange(colIndex: string, key: CONTROL_KEY | INDICATOR_KEY | PLAN_KEY): void;
}
