import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { MAWorkflow } from './ma-workflow';
import { IState, IUIState } from '../../../interfaces';

export interface IMAWorkflowStateProps {
  readonly ui: IUIState;
}

export type IMAWorkflowConnectProps = IMAWorkflowStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IMAWorkflowStateProps {
  return { ui: state.ui };
}

export const MAWorkflowContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(MAWorkflow);
