import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { DataSaveActions } from './data-save-actions';
import { IState, IUIState } from '../../../interfaces';

export interface IDataSaveActionsProps {
  readonly error: Response;
  onRetry(): void;
}

export interface IDataSaveActionsStateProps extends IDataSaveActionsProps {
  readonly ui: IUIState;
}

export type IDataSaveActionsConnectProps = IDataSaveActionsStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IDataSaveActionsProps): IDataSaveActionsStateProps {
  return { ui: state.ui, ...props };
}

export const DataSaveActionsContainer: React.ComponentClass<IDataSaveActionsProps>
  = ReactRedux.connect(mapStateToProps)(DataSaveActions);
