import * as React from 'react';
import { IIncidentConfigConnectProps } from './incident-config-container';
import { IncidentConfigView } from './incident-config-view';
import { IAppTxConfig, IAppTxMapping } from '../../../interfaces';
import { DataApi, IGetTabsAndFieldsResponse } from '../../../api';
import { DATA_LOAD_STAGE, INCIDENT_KEY } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Incidents column mapping configuration
 */
export class IncidentConfig extends React.Component<IIncidentConfigConnectProps, IIncidentConfigState> {
  constructor(props: IIncidentConfigConnectProps) {
    super(props);
    this.state = {
      warning: false,
      dataReady: false
    };

    this.onMappingReset = this.onMappingReset.bind(this);
    this.onWarningChange = this.onWarningChange.bind(this);
    this.onSelfKeyMappingChange = this.onSelfKeyMappingChange.bind(this);
    this.onCustomTabMappingChange = this.onCustomTabMappingChange.bind(this);
    this.onCustomFieldMappingChange = this.onCustomFieldMappingChange.bind(this);
  }

  public componentDidMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.CONFIGURATION));
    if (!this.props.config.incidents) { return; }

    /* Wait for data to load before rendering */
    DataApi.getIncidentTabsAndFields(this.props.config.incidents.testId).request()
      .then((data: IGetTabsAndFieldsResponse): void => {
        this.props.dispatch(Actions.Data.setData(data));
        this.setState({ dataReady: true });
      });
  }

  /** Update config with new mapping for self field */
  public onSelfKeyMappingChange(colIndex: string, key: INCIDENT_KEY): void {
    /* Copy and update incidents config */
    const config: IAppTxConfig = {
      ...this.props.config.incidents,
      mapping: {
        ...this.props.config.incidents.mapping,
        [colIndex]: { key }
      }
    };
    this.props.dispatch(Actions.Config.setIncidentConfig(config));
  }

  /** Update config with new mapping for custom tab */
  public onCustomTabMappingChange(colIndex: string, tabId: string): void {
    /* Copy and update incidents config */
    const config: IAppTxConfig = {
      ...this.props.config.incidents,
      mapping: {
        ...this.props.config.incidents.mapping,
        [colIndex]: { tabId, fieldId: null }
      }
    };
    this.props.dispatch(Actions.Config.setIncidentConfig(config));
  }

  /** Update config with new mapping for custom field */
  public onCustomFieldMappingChange(colIndex: string, fieldId: string): void {
    /* Copy and update incidents config */
    const config: IAppTxConfig = {
      ...this.props.config.incidents,
      mapping: {
        ...this.props.config.incidents.mapping,
        [colIndex]: { ...this.props.config.incidents.mapping[colIndex], fieldId }
      }
    };
    this.props.dispatch(Actions.Config.setIncidentConfig(config));
  }

  /** Reset mapping when type changes */
  public onMappingReset(colIndex: string): void {
    /* Remove previous self or custom mapping from config if it exists */
    const mapping: { [key: string]: IAppTxMapping } = this.props.config.incidents.mapping;
    const previous: object = this.props.config.incidents.mapping[colIndex];
    if (previous !== null) {
      delete mapping[colIndex];
    }

    /* Copy and update incidents config */
    const config: IAppTxConfig = { ...this.props.config.incidents, mapping };

    this.onWarningChange(false);
    this.props.dispatch(Actions.Config.setIncidentConfig(config));
  }

  /** If any row contains invalid UI data (not config), set "warning" value */
  public onWarningChange(warning: boolean): void {
    this.setState({ warning });
  }

  public showConfigMapping(): boolean {
    return !!this.props.ui.dataLoadAction && !!this.props.ui.matchByIndex;
  }

  public render(): JSX.Element {
    if (!this.props.config.incidents) { return null; }

    return (
      <IncidentConfigView
        data={this.props.data}
        columns={this.props.config.columns}
        warning={this.state.warning}
        dataReady={this.state.dataReady}
        optionsReady={this.showConfigMapping()}
        appIncidentConfig={this.props.config.incidents}

        onWarningChange={this.onWarningChange}
        onMappingReset={this.onMappingReset}
        onSelfKeyMappingChange={this.onSelfKeyMappingChange}
        onCustomTabMappingChange={this.onCustomTabMappingChange}
        onCustomFieldMappingChange={this.onCustomFieldMappingChange}
      />
    );
  }
}

export interface IIncidentConfigState {
  readonly warning: boolean;
  readonly dataReady: boolean;
}
