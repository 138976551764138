import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { DataOptions } from './data-options';
import { IState, IUploadState } from '../../../interfaces';

export interface IDataOptionsStateProps {
  readonly upload: IUploadState;
}

export type IDataOptionsConnectProps = IDataOptionsStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IDataOptionsStateProps {
  return { upload: state.upload };
}

export const DataOptionsContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(DataOptions);
