import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { LibraryTypeDropdown } from './library-type-dropdown';
import { IState, IUIState } from '../../../interfaces';

export interface ILibraryTypeDropdownStateProps {
  readonly ui: IUIState;
}

export type ILibraryTypeDropdownConnectProps = ILibraryTypeDropdownStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): ILibraryTypeDropdownStateProps {
  return { ui: state.ui };
}

export const LibraryTypeDropdownContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(LibraryTypeDropdown);
