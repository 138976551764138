import * as React from 'react';
import { Grid, SemanticWIDTHS } from 'semantic-ui-react';
import { WorkflowSteps } from './workflow-steps';
import { DATA_LOAD_STAGE, WORKFLOW_STEP } from '../../constants';
import './workflow.less';

/**
 * Component that displays current workflow step and corresponding component
 */
export class Workflow extends React.Component<IWorkflowProps> {
  constructor(props: IWorkflowProps) {
    super(props);
  }

  /** Workflow step is based on data load stage */
  public getWorkflowStepByStage(stage: DATA_LOAD_STAGE): WORKFLOW_STEP {
    const step: WORKFLOW_STEP = WORKFLOW_STEP.RESULT;

    const upload: boolean = stage === DATA_LOAD_STAGE.DATA_UPLOAD_INITIAL
      || stage === DATA_LOAD_STAGE.DATA_UPLOAD;
    if (upload) {
      return WORKFLOW_STEP.UPLOAD;
    }

    const configuration: boolean = stage === DATA_LOAD_STAGE.DATA_UPLOAD_COMPLETE
      || stage === DATA_LOAD_STAGE.CONFIGURATION;
    if (configuration) {
      return WORKFLOW_STEP.CONFIG;
    }

    if (stage === DATA_LOAD_STAGE.DATA_SAVE_COMPLETE) {
      return WORKFLOW_STEP.DONE;
    }

    return step;
  }

  /** Select workflow component based on current step */
  public getWorkflowStep(step: WORKFLOW_STEP): JSX.Element {
    switch (step) {
      case WORKFLOW_STEP.INIT:
      case WORKFLOW_STEP.UPLOAD:
        return this.props.upload;
      case WORKFLOW_STEP.CONFIG:
        return this.props.config;
      case WORKFLOW_STEP.RESULT:
      case WORKFLOW_STEP.DONE:
        return this.props.result;
      default:
        return null;
    }
  }

  /** All workflows are children for "Data Load Tool" path */
  public getBreadcrumbs(): JSX.Element {
    // const defaultRoute: IAppRoute = APPLICATION_ROUTES[ROUTE_KEY.DATALOAD];
    // const navLink: JSX.Element = (
    //   <NavLink key={defaultRoute.key} to={defaultRoute.key}>
    //     {defaultRoute.caption}
    //   </NavLink>
    // );
    //
    // const sections: BreadcrumbProps[] = [
    //   { key: defaultRoute.key, content: navLink, link: true, as: 'div' },
    //   { key: this.props.route.key, content: this.props.route.caption, active: true }
    // ];
    //
    // return <Breadcrumb divider=">" sections={sections} />;
    return null;
  }

  public render(): JSX.Element {
    const STEPS_WIDTH: SemanticWIDTHS = 10;
    const CONTENT_WIDTH: SemanticWIDTHS = 16;

    /* Content can be determined using "step" or "stage" property */
    const step: WORKFLOW_STEP = this.props.step || this.getWorkflowStepByStage(this.props.stage);
    const content: JSX.Element = this.getWorkflowStep(step);

    return (
      <Grid data-component="workflow">
        {/* TODO: Enable Breadcrumbs */}
        {/*<Grid.Row columns={1} centered>*/}
        {/*<Grid.Column width={STEPS_WIDTH}>*/}
        {/*{this.getBreadcrumbs()}*/}
        {/*</Grid.Column>*/}
        {/*</Grid.Row>*/}

        <Grid.Row columns={1} centered>
          <Grid.Column width={STEPS_WIDTH}>
            <WorkflowSteps step={step} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1} centered>
          <Grid.Column width={CONTENT_WIDTH}>
            {content}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export interface IWorkflowProps {
  readonly step?: WORKFLOW_STEP;
  readonly stage?: DATA_LOAD_STAGE;

  readonly upload: JSX.Element;
  readonly config: JSX.Element;
  readonly result: JSX.Element;
}
