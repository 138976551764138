import * as React from 'react';
import { IDataSaveProgressConnectProps } from './data-save-progress-container';
import { DataProgress } from '../../../components';
import { DataLoadApi } from '../../../api';
import { IDataLoadResult, ILogEntity } from '../../../interfaces';
import { DATA_LOAD_STAGE, DATA_LOAD_STATUS, DATA_SAVE_VIEW_TEXT, LOG_ENTITY_TYPE } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Displays progress of "Data Save" action
 */
export class DataSaveProgress extends React.Component<IDataSaveProgressConnectProps, IDataSaveProgressState> {

  constructor(props: IDataSaveProgressConnectProps) {
    super(props);
    this.state = { total: 0 };

    this.onDone = this.onDone.bind(this);
    this.onProgress = this.onProgress.bind(this);
  }

  /** Notify workflow about start of data saving */
  public componentWillMount(): void {
    const total: number = this.props.result.dryRun.logs.filter((log: ILogEntity): boolean => {
      return log.logType === LOG_ENTITY_TYPE.ADDED || log.logType === LOG_ENTITY_TYPE.UPDATED;
    }).length;
    this.setState({ total });

    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_SAVE_IN_PROGRESS));
  }

  /** Save new status and progress in Redux state */
  public onProgress(response: IDataLoadResult): void {
    this.props.dispatch(Actions.Result.setUploadResult({ dataSave: { ...response } }));
  }

  /** Notify workflow about data save completion or failure */
  public onDone(response: IDataLoadResult): void {
    /* Request logs on successful completion */
    if (response.status === DATA_LOAD_STATUS.SUCCESS) {
      DataLoadApi.checkLogs().request()
        .then((withLogs: IDataLoadResult): void => {
          this.props.dispatch(Actions.Result.setUploadResult({ dataSave: { ...withLogs } }));
          this.props.dispatch(Actions.UI.setUIData({ actionLoading: false }));
          this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_SAVE_COMPLETE));
        })
        .catch((e: Response): void => {
          this.props.onError(e);
        });
    }

    if (response.status === DATA_LOAD_STATUS.FAILED || response.status === DATA_LOAD_STATUS.CANCELED) {
      this.props.dispatch(Actions.UI.setUIData({ actionLoading: false }));
      this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_SAVE_FAILED));
    }
  }

  public render(): JSX.Element {
    const current: number = this.props.result.dataSave.nReadyEntities;

    return (
      <DataProgress
        label={DATA_SAVE_VIEW_TEXT.DATA_SAVE_PROGRESS}
        total={this.state.total}
        current={current}
        onDone={this.onDone}
        onError={this.props.onError}
        onProgress={this.onProgress}
      />
    );
  }
}

export interface IDataSaveProgressState {
  readonly total: number;
}
