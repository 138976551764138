import * as Redux from 'redux';
import { IResultState } from '../interfaces';

export const SET_UPLOAD_RESULT: string = 'SET_UPLOAD_RESULT';

export interface ISetUploadResult extends Redux.Action {
  readonly options: Partial<IResultState>;
}

/** Set any value for dry run or data load result */
export function setUploadResult(options: Partial<IResultState>): ISetUploadResult {
  return { type: SET_UPLOAD_RESULT, options };
}
