import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { IncidentDataUploadActions } from './incident-data-upload-actions';
import { IState, IUploadState } from '../../../interfaces';

export interface IIncidentDataUploadActionsStateProps {
  readonly upload: IUploadState;
}

export type IIncidentDataUploadActionsConnectProps = IIncidentDataUploadActionsStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IIncidentDataUploadActionsStateProps {
  return { upload: state.upload };
}

export const IncidentDataUploadActionsContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(IncidentDataUploadActions);
