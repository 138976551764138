import * as React from 'react';
import { Grid, SemanticWIDTHS } from 'semantic-ui-react';
import { DataOptionsContainer } from '../../data-upload/data-options';
import { DataRangeOptionsContainer } from '../../data-upload/data-range-options';
import { DATA_LOAD_STAGE } from '../../../constants';
import { MADataUploadOptionsContainer } from '../ma-data-upload-options';
import { IMADataUploadConnectProps } from './ma-data-upload-container';
import { MADataUploadActionsContainer } from '../ma-data-upload-actions';
import * as Actions from '../../../actions';
import './ma-data-upload.less';

/**
 * Monitoring Design data upload component combines relevant containers
 * Contains unique option for monitoring activity type
 */
export class MADataUpload extends React.Component<IMADataUploadConnectProps> {
  constructor(props: IMADataUploadConnectProps) {
    super(props);
  }

  /** Notify workflow about "upload" stage being in progress */
  public componentWillMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_UPLOAD));
  }

  public render(): JSX.Element {
    const CONTENT_WIDTH: SemanticWIDTHS = 10;

    return (
      <Grid data-component="ma-data-upload">
        <Grid.Row columns="equal" centered>
          <Grid.Column width={CONTENT_WIDTH}>
            <MADataUploadOptionsContainer />
            {this.props.upload.maType ? <DataOptionsContainer /> : null}
            {this.props.upload.dataFile ? <DataRangeOptionsContainer /> : null}
            {this.props.upload.dataFile ? <MADataUploadActionsContainer /> : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
