import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Login } from './login';

export type ILoginProps = RouteComponentProps<{}>;
export type ILoginConnectProps = ILoginProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: object, props: RouteComponentProps<{}>): ILoginProps {
  return { ...props };
}

export const LoginContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(Login);
