import * as React from 'react';
import { Dropdown, DropdownItemProps, DropdownProps, Table } from 'semantic-ui-react';
import { IAppTxConfig, IAppTxMapping } from '../../../interfaces';
import {
  NO_MAPPING_BLANK_OPTION,
  TX_ELEMENT_FIELD_OPTIONS,
  TX_ELEMENT_FIELD_OPTIONS_BUSINESS_PROCESS,
  TX_ELEMENT_PARENT_FIELD_OPTIONS,
  TX_KEY,
  TX_MAPPING, TX_RESOURCE_TYPE
} from '../../../constants';
import './tx-self-mapping-options.less';

/**
 * Options for mapping column into Taxonomy self keys
 * Component receives only default value through props
 * Rest of actions are self-controlled
 */
export class TxSelfMappingOptions extends React.Component<ITxSelfMappingOptionsProps, ITxSelfMappingOptionsState> {
  constructor(props: ITxSelfMappingOptionsProps) {
    super(props);

    /* Set own state once on component initial load */
    const column: IAppTxMapping = this.props.appTxConfig.mapping[this.props.colIndex];
    this.state = {
      key: (column) ? column.key : NO_MAPPING_BLANK_OPTION.key
    };

    this.checkWarning();
    this.onSelfKeyMappingChange = this.onSelfKeyMappingChange.bind(this);
  }

  /** Warn parent about incomplete mapping */
  public checkWarning(): void {
    const warning: boolean = this.state.key === NO_MAPPING_BLANK_OPTION.key;
    this.props.onWarningChange(warning);
  }

  /** Update own value and call parent callback */
  public onSelfKeyMappingChange(e: React.ChangeEvent<HTMLInputElement>, changes: DropdownProps): void {
    this.setState({ key: changes.value as string }, (): void => {
      this.checkWarning();
      this.props.onSelfKeyMappingChange(this.props.colIndex, this.state.key as TX_KEY);
    });
  }

  /** Get options for self key dropdown */
  public getOptions(): { [key: string]: string } {
    switch (this.props.txType) {
      case TX_MAPPING.ELEMENT_FIELD:
        return this.props.appTxConfig.entity === TX_RESOURCE_TYPE.BUSINESS_PROCESS
          ? TX_ELEMENT_FIELD_OPTIONS_BUSINESS_PROCESS
          : TX_ELEMENT_FIELD_OPTIONS;
      case TX_MAPPING.ELEMENT_PARENT_FIELD:
        return TX_ELEMENT_PARENT_FIELD_OPTIONS;
      default:
        return {};
    }
  }

  /** Get dropdown with options for column mapping */
  public getSelfConfigOptions(): JSX.Element {
    const options: DropdownItemProps[] = [];
    const mapping: { [key: string]: string } = this.getOptions();
    const keys: string[] = Object.keys(mapping);

    for (let i: number = 0; i < keys.length; i++) {
      options.push({
        value: keys[i], text: mapping[keys[i]]
      });
    }

    return (
      <Dropdown
        options={options}
        value={this.state.key}
        onChange={this.onSelfKeyMappingChange}
        selectOnBlur={false}
        selection
        fluid
      />
    );
  }

  public render(): JSX.Element {
    return (
      <Table.Cell data-component="tx-self-mapping-options">
        {this.getSelfConfigOptions()}
      </Table.Cell>
    );
  }
}

export interface ITxSelfMappingOptionsState {
  readonly key: string;
}

export interface ITxSelfMappingOptionsProps {
  readonly colIndex: string;
  readonly txType: TX_MAPPING;
  readonly appTxConfig: IAppTxConfig;
  onWarningChange(warning: boolean): void;
  onSelfKeyMappingChange(colIndex: string, key: TX_KEY): void;
}
