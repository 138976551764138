import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Content } from './content';
import { IAuthTicket, IState, IUploadState } from '../../interfaces';

export interface IContentStateProps extends RouteComponentProps<{}> {
  readonly auth: IAuthTicket;
  readonly upload: IUploadState;
}

export type IContentConnectProps = IContentStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: RouteComponentProps<{}>): IContentStateProps {
  return {
    auth: state.auth,
    upload: state.upload,
    ...props
  };
}

export const ContentContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(Content);
