import * as React from 'react';
import { Grid, SemanticWIDTHS, Table } from 'semantic-ui-react';
import { HelpButton, PIMappingOptions } from '../../../components';
import { PIConfigActionsContainer } from '../pi-config-actions';
import { UpdateDataFileContainer } from '../../data-upload/update-data-file';
import { PIConfigOptions } from '../pi-config-options';
import { IAppPIConfig, IColumns, IUIState } from '../../../interfaces';
import { CONFIG_VIEW_TEXT, HELP_TEXT, PLAN_EVENT_KEY, PLAN_EVENT_SCORE_KEY } from '../../../constants';
import './pi-config-view.less';

/**
 * View for "Plan-Indicators Configuration" component
 */
export class PIConfigView extends React.Component<IPIConfigViewProps> {
  public readonly MAX_COLUMNS: SemanticWIDTHS = 4;
  public readonly CONTENT_WIDTH: SemanticWIDTHS = 12;

  constructor(props: IPIConfigViewProps) {
    super(props);
  }

  /** Get components for mapping columns into Plan-Indicators keys */
  public getConfigRows(): JSX.Element[] {
    const rows: JSX.Element[] = [];
    const colIndexes: string[] = Object.keys(this.props.columns);

    for (let i: number = 0; i < colIndexes.length; i++) {
      const colIndex: string = colIndexes[i];

      const row: JSX.Element = (
        <PIMappingOptions
          key={i}
          colIndex={colIndex}
          name={this.props.columns[colIndex]}
          libraryType={this.props.ui.libraryType}
          planEventConfig={this.props.appPIConfig.planEventConfig}
          planEventScoreConfig={this.props.appPIConfig.planEventScoreConfig}
          onMappingReset={this.props.onMappingReset}
          onPlanEventColMappingChange={this.props.onPlanEventColMappingChange}
          onPlanEventScoreColMappingChange={this.props.onPlanEventScoreColMappingChange}
        />
      );
      rows.push(row);
    }
    return rows;
  }

  /** Top table row contains title and help button */
  public getTableTitle(): JSX.Element {
    const caption: string = CONFIG_VIEW_TEXT.PI_FIELDS_CONFIG;
    const help: JSX.Element = <HelpButton caption={HELP_TEXT.HELP} text={HELP_TEXT.PI_CONFIG} />;

    return (
      <Table.HeaderCell className="top-title" colSpan={this.MAX_COLUMNS}>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>{caption}</Grid.Column>
            <Grid.Column textAlign="right">{help}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Table.HeaderCell>
    );
  }

  /** Get header with data load type and column captions */
  public getTableHeader(): JSX.Element {
    return (
      <Table.Header fullWidth>
        <Table.Row>
          {this.getTableTitle()}
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.STATUS_COLUMN_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.COLUMN_NAME_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.MAPPING_TYPE_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.FIELD_NAME_HEADER} />
        </Table.Row>
      </Table.Header>
    );
  }

  /** Table for mapping columns into Plan-Indicators keys */
  public getConfigTable(): JSX.Element {
    return (
      <Grid.Row columns="equal" centered>
        <Grid.Column width={this.CONTENT_WIDTH}>
          <Table celled compact columns={this.MAX_COLUMNS}>
            {this.getTableHeader()}

            <Table.Body>
              {this.getConfigRows()}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    );
  }

  /** Actions can be disabled if config warning is present */
  public getConfigActions(): JSX.Element {
    return (
      <Grid.Row columns="equal" centered>
        <Grid.Column width={this.CONTENT_WIDTH}>
          <PIConfigActionsContainer />
        </Grid.Column>
      </Grid.Row>
    );
  }

  public render(): JSX.Element {
    return (
      <Grid data-component="pi-config-view">
        <Grid.Row columns="equal" centered>
          <Grid.Column width={this.CONTENT_WIDTH}>
            <UpdateDataFileContainer />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="no-padding" columns="equal" centered>
          <Grid.Column width={this.CONTENT_WIDTH}>
            <PIConfigOptions />
          </Grid.Column>
        </Grid.Row>

        {this.props.optionsReady ? this.getConfigTable() : null}
        {this.props.optionsReady ? this.getConfigActions() : null}
      </Grid>
    );
  }
}

export interface IPIConfigViewProps {
  readonly optionsReady: boolean;
  readonly columns: IColumns;
  readonly appPIConfig: IAppPIConfig;
  readonly ui: IUIState;
  onMappingReset(colIndex: string): void;
  onPlanEventColMappingChange(colIndex: string, key: PLAN_EVENT_KEY): void;
  onPlanEventScoreColMappingChange(colIndex: string, key: PLAN_EVENT_SCORE_KEY): void;
}
