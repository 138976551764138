import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { LibraryTypeDropdownContainer } from '../../config/library-type-dropdown';
import { DataLoadActionOptionsContainer } from '../../config/data-load-action-options';
import { MatchPlanKeyOptionsContainer } from '../../config/match-plan-key-options';
import { MatchIndicatorKeyOptionsContainer } from '../../config/match-indicator-key-options';
import { EmptyCellOptionsContainer } from '../../config/empty-cell-options';
import { PI_DATA_LOAD_ACTION_OPTIONS } from '../../../constants';
import './pi-config-options.less';

/**
 * Plan-Indicators config options component combines relevant config option containers
 */
export class PIConfigOptions extends React.Component {
  constructor(props: object) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <Form data-component="pi-config-options">
        <Form.Group widths="equal">
          <DataLoadActionOptionsContainer options={PI_DATA_LOAD_ACTION_OPTIONS} />
          <LibraryTypeDropdownContainer />
        </Form.Group>

        <Form.Group widths="equal">
          <MatchPlanKeyOptionsContainer />
          <MatchIndicatorKeyOptionsContainer />
        </Form.Group>

        <EmptyCellOptionsContainer />
      </Form>
    );
  }
}
