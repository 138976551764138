import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { PIConfigActions } from './pi-config-actions';
import { IConfigState, IState, IUIState, IUploadState } from '../../../interfaces';

export interface IPIConfigActionsStateProps {
  readonly ui: IUIState;
  readonly upload: IUploadState;
  readonly config: IConfigState;
}

export type IPIConfigActionsConnectProps = IPIConfigActionsStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IPIConfigActionsStateProps {
  return {
    ui: state.ui,
    upload: state.upload,
    config: state.config
  };
}

export const PIConfigActionsContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(PIConfigActions);
