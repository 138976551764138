import * as React from 'react';
import { IIndicatorWorkflowConnectProps } from './indicator-data-upload-container';
import { BasicDataUploadContainer } from '../../data-upload/basic-data-upload';
import { IAppIndicatorConfig } from '../../../interfaces';
import { DataLoadApi, IIndicatorConfigOptions, IUploadBasicFileOptions } from '../../../api';
import { getAppIndicatorConfig } from '../../../functions';
import * as Actions from '../../../actions';

/**
 * Provides file upload callback for Basic Data Upload component
 */
export class IndicatorDataUpload extends React.Component<IIndicatorWorkflowConnectProps> {
  constructor(props: IIndicatorWorkflowConnectProps) {
    super(props);
    this.uploadFile = this.uploadFile.bind(this);
  }

  /** Upload indicators file to server */
  public uploadFile(options: IUploadBasicFileOptions): Promise<{}> {
    return DataLoadApi.uploadIndicatorFile(options).request()
      .then((response: IIndicatorConfigOptions): {} => {
        const config: IAppIndicatorConfig = getAppIndicatorConfig(response.config);

        this.props.dispatch(Actions.Config.setColumns(response.columns));
        this.props.dispatch(Actions.Config.setIndicatorConfig(config));
        return {};
      });
  }

  public render(): JSX.Element {
    return <BasicDataUploadContainer uploadFile={this.uploadFile} />;
  }
}
