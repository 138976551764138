import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Icon, Popup } from 'semantic-ui-react';
import './help-button.less';

/**
 * Help text is displayed as Popup on button click
 */
export class HelpButton extends React.Component<IHelpButtonProps, IHelpButtonState> {
  constructor(props: IHelpButtonProps) {
    super(props);
    this.state = { htmlRef: null };

    this.saveHtmlRef = this.saveHtmlRef.bind(this);
  }

  /** Custom mount node for Popup is required for scrolling */
  public saveHtmlRef(ref: HTMLButtonElement): void {
    this.setState({ htmlRef: ReactDOM.findDOMNode(ref) as HTMLButtonElement });
  }

  /** Trigger button for Semantic Popup with optional text */
  public getTriggerButton(): JSX.Element {
    const caption: JSX.Element = (this.props.caption)
      ? <span>{this.props.caption}</span>
      : null;

    return (
      <button data-component="help-popup" ref={this.saveHtmlRef} className="ui basic icon button">
        {caption}
        <Icon name="question circle outline" />
      </button>
    );
  }

  public render(): JSX.Element {
    return (
      <Popup
        trigger={this.getTriggerButton()}
        content={this.props.text}
        mountNode={this.state.htmlRef}
        className="help-popup"
        position="top right"
        on="click"
      />
    );
  }
}

export interface IHelpButtonState {
  readonly htmlRef: HTMLButtonElement;
}

export interface IHelpButtonProps {
  readonly text?: React.ReactNode;
  readonly caption?: string;
}
