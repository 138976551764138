import { IResultState } from '../interfaces';
import * as Actions from '../actions';

const initial: IResultState = {
  dryRun: { logs: [], status: null, nReadyEntities: 0 },
  dataSave: { logs: [], status: null, nReadyEntities: 0 },
};

export function result(
  state: IResultState = initial,
  action: Actions.Result.ISetUploadResult
): IResultState {
  switch (action.type) {
    case Actions.Result.SET_UPLOAD_RESULT:
      return { ...state, ...action.options };
    case Actions.Batch.CLEAR_APP_STATE:
    case Actions.Batch.GO_TO_CONFIG_STEP:
    case Actions.Batch.GO_TO_UPLOAD_STEP:
    case Actions.Batch.GO_TO_DATA_SAVE_STEP:
      return initial;
    default:
      return state;
  }
}
