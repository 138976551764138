import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { MatchPlanKeyOptionsContainer } from '../../config/match-plan-key-options';
import { MatchIndicatorKeyOptionsContainer } from '../../config/match-indicator-key-options';
import { MatchActivityKeyOptionsContainer } from '../../config/match-activity-key-options';
import { LibraryTypeDropdownContainer } from '../../config/library-type-dropdown';
import './ail-config-options.less';

/**
 * Activity-Indicator Links config options component combines relevant config option containers
 */
export class AILConfigOptions extends React.Component {
  constructor(props: object) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <Form data-component="ail-config-options">
        <Form.Group widths="equal">
          <MatchPlanKeyOptionsContainer />
          <MatchIndicatorKeyOptionsContainer />
        </Form.Group>

        <Form.Group widths="equal">
          <MatchActivityKeyOptionsContainer />
          <LibraryTypeDropdownContainer />
        </Form.Group>
      </Form>
    );
  }
}
