import * as React from 'react';
import { IAILDataSaveConnectProps } from './ail-data-save-container';
import { DataSaveContainer } from '../../data-save';
import { DataLoadApi } from '../../../api';
import { IAILConfig } from '../../../interfaces';
import { DATA_LOAD_TYPE } from '../../../constants';
import { getApiAILConfig } from '../../../functions';

/**
 * Wrapper around "Data Save" component for Activity-Indicator Links
 */
export class AILDataSave extends React.Component<IAILDataSaveConnectProps> {
  constructor(props: IAILDataSaveConnectProps) {
    super(props);
    this.onRetry = this.onRetry.bind(this);
  }

  public onRetry(): Promise<{}> {
    const config: IAILConfig = getApiAILConfig({
      config: this.props.config.AIL,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadAILConfig({ config, columns: this.props.config.columns }).request();
  }

  public render(): JSX.Element {
    if (!this.props.config.AIL) { return null; }

    const nRows: number = this.props.config.AIL.tableConfig.dataEndRow
      - this.props.config.AIL.tableConfig.dataStartRow;

    return (
      <DataSaveContainer
        nRows={nRows}
        dataLoadType={DATA_LOAD_TYPE.ACTIVITY_INDICATOR_LINK}
        onRetry={this.onRetry}
      />
    );
  }
}
