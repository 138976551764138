import { DATA_LOAD_TYPE } from './common';

export enum LOGIN_VIEW_TEXT {
  USER_ID = 'Login ID or Email',
  PASSWORD = 'Password',
  DOMAIN = 'Domain',
  LOG_IN = 'Log In',
  LOGO_ALT = 'Logic Manager',
  SESSION_EXPIRED = 'Session Expired'
}

export enum CONTENT_VIEW_TEXT {
  TITLE = 'Data Load Tool',
  LOGOUT = 'Log Out'
}

export enum DATA_UPLOAD_VIEW_TEXT {
  DATA_FILE = 'Data File',
  NO_FILE_SELECTED = 'Choose File',
  SHEET_INDEX = 'Sheet Index',
  TAXONOMY_RESOURCE = 'Taxonomy Kind',
  INCIDENT_TYPE = 'Incident Type',
  MONITORING_ACTIVITY = 'Activity Kind',
  START_ROW = 'Start Row',
  START_ROW_PLACEHOLDER = 'Second',
  END_ROW = 'End Row',
  END_ROW_PLACEHOLDER = 'Last',
  UPLOAD_FILE = 'Upload File',
  UPDATE_FILE = 'Update File'
}

export enum WORKFLOW_STEPS_TEXT {
  UPLOAD_TITLE = 'Upload File',
  UPLOAD_DESCRIPTION = 'Upload .xlsx file with data',
  CONFIG_TITLE = 'Configuration',
  CONFIG_DESCRIPTION = 'Map columns into entity fields',
  RESULT_TITLE = 'Write Data',
  RESULT_DESCRIPTION = 'Save information in database'
}

export enum CONFIG_VIEW_TEXT {
  TX_FIELDS_CONFIG = 'Taxonomy Fields Configuration',
  PI_FIELDS_CONFIG = 'Plan Indicators Configuration',
  INDICATOR_FIELDS_CONFIG = 'Indicator Library Configuration',
  IPL_FIELDS_CONFIG = 'Indicator-Process Mapping Configuration',
  IL_FIELDS_CONFIG = 'Indicator-Indicator Mapping Configuration',
  INCIDENT_FIELDS_CONFIG = 'Incident Fields Configuration',
  ACTIVITY_FIELDS_CONFIG = 'Control Configuration',
  AIL_FIELDS_CONFIG = 'Control-Indicator Mapping Configuration',
  USER_MANAGEMENT_FIELDS_CONFIG = 'User Management Configuration',
  MA_FIELDS_CONFIG = 'Monitoring Activity Configuration',

  WHAT_DO_YOU_WANT_TO_DO = 'What do you want to do?',
  EMPTY_CELLS_ACTION = 'Action for empty cells:',
  MATCH_RECORDS_BY = 'Match records by:',
  LIBRARY_TYPE = 'Library type:',
  MATCH_KEY_BY = 'Match {key} by:',
  MATCH_PLANS_BY = 'Match Plans by:',
  MATCH_INDICATORS_BY = 'Match Indicators by:',
  MATCH_CONTROL_BY = 'Match Control by:',
  MATCH_MONITORING_ACTIVITY_BY = 'Match Monitoring Activity by:',
  INDICATOR_CATEGORY_FACTOR = 'Indicator, Category and Factor',
  PROCESSES = 'Processes',
  PROCESS_TYPE = 'Process type:',
  TX_BUSINESS_PROCESS_TYPE = 'Business Processes Type:',

  COLUMN_INDEX = 'Column #{index}',
  STATUS_COLUMN_HEADER = 'Status',
  COLUMN_NAME_HEADER = 'Column Header',
  MAPPING_TYPE_HEADER = 'Mapping Type',
  TAB_ELEMENT_NAME_HEADER = 'Tab Name / Element Field',
  TAB_NAME_HEADER = 'Tab Name',
  FIELD_NAME_HEADER = 'Field Name',

  BACK = 'Back',
  CHECK_CONFIGURATION = 'Check Configuration'
}

export enum DATA_SAVE_VIEW_TEXT {
  DRY_RUN = 'Dry Run',
  DRY_RUN_PROGRESS = 'Dry Run Progress',
  DRY_RUN_ITEMS_ADDED = '{amount} {type} to be added',
  DRY_RUN_ITEMS_UPDATED = '{amount} {type} to be updated',
  DRY_RUN_ITEMS_SKIPPED = '{amount} row(s) to be skipped',
  DRY_RUN_ITEMS_WARN = '{amount} Warnings',
  DRY_RUN_ITEMS_OTHER = '{amount} Other',

  DATA_SAVE = 'Data Save',
  DATA_SAVE_PROGRESS = 'Data Save Progress',
  DATA_SAVE_ACTION_SINGLE = 'was',
  DATA_SAVE_ACTION_PLURAL = 'were',
  DATA_SAVE_ITEMS_ADDED = '{amount} {type} {action} added',
  DATA_SAVE_ITEMS_UPDATED = '{amount} {type} {action} updated',
  DATA_SAVE_ITEMS_SKIPPED = '{amount} {type} {action} skipped',
  DATA_SAVE_ITEMS_WARN = '{amount} Warn',
  DATA_SAVE_ITEMS_OTHER = '{amount} Other',

  BACK = 'Back',
  CANCEL_DATA_LOAD = 'Cancel Data Load',
  PROCEED_WITH_DATA_LOAD = 'Proceed With Data Load',
  RETRY_DRY_RUN = 'Retry Dry Run',
  DONE = 'Done!',
  ROW_INDEX_COLUMN_HEADER = 'Row Number',
  MESSAGE_COLUMN_HEADER = 'Description',
  COLUMN_NAME_HEADER = 'Column Name'
}

export const DATA_LOAD_ITEM_CAPTIONS: { [key: string]: { single: string, plural: string } } = {
  [DATA_LOAD_TYPE.TAXONOMY]: { single: 'Element', plural: 'Elements' },
  [DATA_LOAD_TYPE.PLAN_INDICATOR]: { single: 'Indicator', plural: 'Indicators' },
  [DATA_LOAD_TYPE.INDICATOR_PROCESS_LINK]: { single: 'Indicator-Process Link', plural: 'Indicator-Process Links' },
  [DATA_LOAD_TYPE.INDICATOR_INDICATOR_LINK]: {
    single: 'Indicator-Indicator Link',
    plural: 'Indicator-Indicator Links'
  },
  [DATA_LOAD_TYPE.INCIDENT]: { single: 'Incident', plural: 'Incidents' },
  [DATA_LOAD_TYPE.CONTROL]: { single: 'Control', plural: 'Controls' },
  [DATA_LOAD_TYPE.INDICATOR_LIBRARY]: { single: 'Indicator', plural: 'Indicators' },
  [DATA_LOAD_TYPE.ACTIVITY_INDICATOR_LINK]: {
    single: 'Indicator-Control link',
    plural: 'Indicator-Control links'
  },
  [DATA_LOAD_TYPE.USER_MANAGEMENT]: { single: 'User', plural: 'Users' },
  [DATA_LOAD_TYPE.CATEGORY]: { single: 'Category', plural: 'Categories' },
  [DATA_LOAD_TYPE.FACTOR]: { single: 'Factor', plural: 'Factors' },
  [DATA_LOAD_TYPE.MONITORING_ACTIVITY]: { single: 'Monitoring Activity', plural: 'Monitoring Activities' }
};

export enum DATA_LOAD_HISTORY_TEXT {
  DATA_LOAD_HISTORY_TITLE = 'Data Load History',
  ROW_INDEX = 'Index',
  ID = 'ID',
  USER_ID = 'User ID',
  TYPE = 'Type',
  TAXONOMY_TYPE = 'Taxonomy: {type}',
  FILE_NAME = 'File Name',
  LAST_UPDATE = 'Last Update',
  STATUS = 'Status',
  ACTIONS = 'Actions',
  DOWNLOAD_LOG_FILE = 'Log File'
}

export enum COMMON_TEXT {
  LOADING = 'Loading Information',
  CANCEL = 'Cancel',
  UNSAVED_CHANGES = 'All changes will be lost. Continue?'
}

export const GENERAL_ERROR_MESSAGE_TEXT: {
  NOT_FOUND_HEADER: string,
  NOT_FOUND_DESCRIPTION: string,
  UNHANDLED_HEADER: string,
  UNHANDLED_DESCRIPTION: string,
  DATABASE_EXCEPTION_HEADER: string,
  DATABASE_EXCEPTION_DESCRIPTION: string,
  LOG_IN_DENIED_HEADER: string,
  LOG_IN_DENIED_DESCRIPTION: string,
  DOMAIN_BUSY_HEADER: string,
  DOMAIN_BUSY_DESCRIPTION: string,
  AUTH_DENIED_HEADER: string,
  AUTH_DENIED_DESCRIPTION: string,
  FILE_MAX_SIZE_EXCEEDED_HEADER: string,
  FILE_MAX_SIZE_EXCEEDED_DESCRIPTION: string,
  MAX_ROWS_NUMBER_EXCEEDED_HEADER: string,
  MAX_ROWS_NUMBER_EXCEEDED_DESCRIPTION: string,
  MAX_FILENAME_LENGTH_EXCEEDED_HEADER: string,
  MAX_FILENAME_LENGTH_EXCEEDED_DESCRIPTION: string,
  FILE_STRUCTURE_WAS_CHANGED_HEADER: string,
  FILE_STRUCTURE_WAS_CHANGED_DESCRIPTION: string
} = {
  NOT_FOUND_HEADER: '404',
  NOT_FOUND_DESCRIPTION: 'Page Not Found!',
  UNHANDLED_HEADER: 'Oops!',
  UNHANDLED_DESCRIPTION: 'Something went wrong!',
  DATABASE_EXCEPTION_HEADER: 'Database Exception!',
  DATABASE_EXCEPTION_DESCRIPTION: 'Data save failed. Failure occurred when trying to process row: {rowNumber}',
  LOG_IN_DENIED_HEADER: 'Login Denied!',
  LOG_IN_DENIED_DESCRIPTION: 'The email address or password you entered is incorrect. Please try again.',
  DOMAIN_BUSY_HEADER: 'Domain Busy!',
  DOMAIN_BUSY_DESCRIPTION: `The Dataload Utility for "{domain}" is currently in use by "{username}".`
  + ` Please try back later!`,
  AUTH_DENIED_HEADER: '',
  AUTH_DENIED_DESCRIPTION: 'Your session has expired, please log in.',
  FILE_MAX_SIZE_EXCEEDED_HEADER: 'Max File Size Exceeded!',
  FILE_MAX_SIZE_EXCEEDED_DESCRIPTION: 'Maximum file size is 1MB.',
  MAX_ROWS_NUMBER_EXCEEDED_HEADER: 'Max Row Count Exceeded!',
  MAX_ROWS_NUMBER_EXCEEDED_DESCRIPTION: 'Maximum amount of rows is 2000.',
  MAX_FILENAME_LENGTH_EXCEEDED_HEADER: 'Max File Name Length Exceeded!',
  MAX_FILENAME_LENGTH_EXCEEDED_DESCRIPTION: 'Maximum file name length is 100 symbols.',
  FILE_STRUCTURE_WAS_CHANGED_HEADER: 'Data Update Failed!',
  FILE_STRUCTURE_WAS_CHANGED_DESCRIPTION: 'Column names do not match original file.'
};

export enum LOG_IN_ERROR_MESSAGE_TEXT {
  NO_PERMISSION_HEADER = 'Not Enough Permissions!',
  NO_PERMISSION_DESCRIPTION = 'User does not have \"Data Import\" permission.'
}

export enum SUCCESS_MESSAGE_TEXT {
  FILE_UPDATED_HEADER = 'Done!',
  FILE_UPDATED_DESCRIPTION = 'File data successfully updated'
}

export const HELP_TEXT: {
  HELP: string,
  TAXONOMY_CONFIG: string,
  PI_CONFIG: string,
  DATA_RANGE: string
} = {
  HELP: 'Help',
  TAXONOMY_CONFIG: 'Taxonomy configuration have to contain valid mapping for "Match Records By" column.',
  PI_CONFIG: '"Plan ID" or "Plan Name" and "Indicator ID" or "Category Name", "Factor Name"'
  + ' and "Indicator Name" must be filled out.',
  DATA_RANGE: `Maximum file size equals 1MB. Start and end row values have to be >= 2,`
  + ` their difference should be <= 2000.`
};
