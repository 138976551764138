export enum INCIDENT_MAPPING {
  INCIDENT_ID = 'incident_id',
  CUSTOM_FIELD = 'CUSTOM_FIELD'
}

export enum INCIDENT_KEY {
  INCIDENT_ID = 'incident_id',
  SUBJECT = 'subject',
  DESCRIPTION = 'combiDescription',
  REPORTED_TYPE = 'reported_type',
  REPORTED_BY = 'reported_by',
  REPORTED_ON = 'reported_on',
  INCIDENT_SEVERITY = 'inc_severity',
  INCIDENT_STATUS = 'inc_status',
  INCIDENT_DUE_DATE = 'inc_due_date',
  INCIDENT_APPROVAL_STATUS = 'inc_approval_status',
  INCIDENT_OWNER = 'incident_owner',
  INCIDENT_NOTIFY = 'incident_notify',
  SEND_EMAIL = 'send_email',
  OUTCOME = 'outcome',
  LOSS_EVENT_AMOUNT = 'loss_event_amount',
  RESOLUTION = 'resolution',
  EXTERNAL_REF = 'external_ref'
}

export const INCIDENT_MAPPING_OPTIONS: { [key: string]: string } = {
  [INCIDENT_MAPPING.INCIDENT_ID]: 'Incident ID',
  [INCIDENT_MAPPING.CUSTOM_FIELD]: 'Profile Field'
};

export const INCIDENT_KEY_OPTIONS: { [key: string]: string } = {
  [INCIDENT_KEY.INCIDENT_ID]: 'IncidentID',
  [INCIDENT_KEY.SUBJECT]: 'Subject',
  [INCIDENT_KEY.DESCRIPTION]: 'Description',
  [INCIDENT_KEY.REPORTED_TYPE]: 'Reported Type',
  [INCIDENT_KEY.REPORTED_BY]: 'Reported By',
  [INCIDENT_KEY.REPORTED_ON]: 'Reported On',
  [INCIDENT_KEY.INCIDENT_SEVERITY]: 'Incident Severity',
  [INCIDENT_KEY.INCIDENT_STATUS]: 'Incident Status',
  [INCIDENT_KEY.INCIDENT_DUE_DATE]: 'Incident Due Date',
  [INCIDENT_KEY.INCIDENT_APPROVAL_STATUS]: 'Incident Approval Status',
  [INCIDENT_KEY.INCIDENT_OWNER]: 'Incident Owner',
  [INCIDENT_KEY.INCIDENT_NOTIFY]: 'Incident Notify',
  [INCIDENT_KEY.SEND_EMAIL]: 'Send Email',
  [INCIDENT_KEY.OUTCOME]: 'Outcome',
  [INCIDENT_KEY.LOSS_EVENT_AMOUNT]: 'Loss Event Amount',
  [INCIDENT_KEY.RESOLUTION]: 'Resolution',
  [INCIDENT_KEY.EXTERNAL_REF]: 'External Ref',
};
