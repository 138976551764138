import * as React from 'react';
import { DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { CONFIG_VIEW_TEXT, EMPTY_CELLS_ACTION_OPTIONS } from '../../../constants';
import { getOptions } from '../../../functions';

/**
 * View for "Empty Cells Action Config" component
 */
export class EmptyCellOptionsView extends React.Component<IEmptyCellOptionsViewProps> {
  constructor(props: IEmptyCellOptionsViewProps) {
    super(props);
  }

  public render(): JSX.Element {
    const options: DropdownItemProps[] = getOptions(EMPTY_CELLS_ACTION_OPTIONS);

    return (
      <Form.Dropdown
        label={CONFIG_VIEW_TEXT.EMPTY_CELLS_ACTION}
        options={options}
        value={this.props.value}
        onChange={this.props.onChange}
        selectOnBlur={false}
        selection
        required
      />
    );
  }
}

export interface IEmptyCellOptionsViewProps {
  readonly value: string;
  onChange(e: React.SyntheticEvent<HTMLDivElement>, changes: DropdownProps): void;
}
