import * as React from 'react';
import { IIndicatorWorkflowConnectProps } from './indicator-workflow-container';
import { Workflow } from '../../../components/workflow';
import { IndicatorDataUploadContainer } from '../indicator-data-upload';
import { IndicatorConfigContainer } from '../indicator-config';
import { IndicatorDataSaveContainer } from '../indicator-data-save';
import { DataLoadApi } from '../../../api';
import { DATA_LOAD_STAGE } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Indicators workflow manages transitions between data load flow components
 */
export class IndicatorWorkflow extends React.Component<IIndicatorWorkflowConnectProps> {
  public componentWillMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_UPLOAD_INITIAL));
  }

  /** Clear state and cancel data load when route changes */
  public componentWillUnmount(): void {
    DataLoadApi.cancelDataLoad().request()
      .then((): void => {
        this.props.dispatch(Actions.Batch.clearAppState());
      })
      .catch((): void => {
        this.props.dispatch(Actions.Batch.clearAppState());
      });
  }

  public render(): JSX.Element {
    return (
      <Workflow
        stage={this.props.ui.stage}
        upload={<IndicatorDataUploadContainer />}
        config={<IndicatorConfigContainer />}
        result={<IndicatorDataSaveContainer />}
      />
    );
  }
}
