export enum TEST_STEP_KEY {
  STEP1 = 'matStep1',
  STEP2 = 'matStep2',
  STEP3 = 'matStep3',
  STEP4 = 'matStep4',
  STEP5 = 'matStep5',
  STEP6 = 'matStep6',
  STEP7 = 'matStep7',
  STEP8 = 'matStep8',
  STEP9 = 'matStep9',
  STEP10 = 'matStep10'
}

export const TEST_STEP_KEY_OPTIONS: { [key: string]: string } = {
  [TEST_STEP_KEY.STEP1]: 'Step 1',
  [TEST_STEP_KEY.STEP2]: 'Step 2',
  [TEST_STEP_KEY.STEP3]: 'Step 3',
  [TEST_STEP_KEY.STEP4]: 'Step 4',
  [TEST_STEP_KEY.STEP5]: 'Step 5',
  [TEST_STEP_KEY.STEP6]: 'Step 6',
  [TEST_STEP_KEY.STEP7]: 'Step 7',
  [TEST_STEP_KEY.STEP8]: 'Step 8',
  [TEST_STEP_KEY.STEP9]: 'Step 9',
  [TEST_STEP_KEY.STEP10]: 'Step 10',
};
