import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { TxWorkflow } from './tx-workflow';
import { IState, IUIState } from '../../../interfaces';

export interface ITxWorkflowStateProps {
  readonly ui: IUIState;
}

export type ITxWorkflowConnectProps = ITxWorkflowStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): ITxWorkflowStateProps {
  return { ui: state.ui };
}

export const TxWorkflowContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(TxWorkflow);
