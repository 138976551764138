import * as React from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { ITxDataUploadOptionsConnectProps } from './tx-data-upload-options-container';
import { TxDataUploadOptionsView } from './tx-data-upload-options-view';
import { PROCESS_TYPE, TX_RESOURCE_TYPE } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Taxonomy specific file upload options
 */
export class TxDataUploadOptions extends React.Component<ITxDataUploadOptionsConnectProps> {
  constructor(props: ITxDataUploadOptionsConnectProps) {
    super(props);

    this.onTxTypeChange = this.onTxTypeChange.bind(this);
  }

  public onTxTypeChange(e: React.ChangeEvent<HTMLInputElement>, changes: DropdownProps): void {
    this.props.dispatch(Actions.Upload.setUploadInfo({
      txResourceType: changes.value as TX_RESOURCE_TYPE
    }));
    /** Set default value resource types */
    let defaultProcessType: PROCESS_TYPE = null;
    if (changes.value === TX_RESOURCE_TYPE.BUSINESS_PROCESS) {
      defaultProcessType = PROCESS_TYPE.CORE_PROCESS;
    }
    this.props.dispatch(Actions.UI.setUIData({
      processType: defaultProcessType
    }));
  }

  public render(): JSX.Element {
    return (
      <TxDataUploadOptionsView
        txType={this.props.upload.txResourceType}
        onTxTypeChange={this.onTxTypeChange}
      />
    );
  }
}
