/** Taxonomy columns can be mapped into one of following field categories */
export enum TX_MAPPING {
  ELEMENT_FIELD = 'ELEMENT_FIELD',
  ELEMENT_PARENT_FIELD = 'ELEMENT_PARENT_FIELD',
  CUSTOM_FIELD = 'CUSTOM_FIELD',
  RISK_FACTOR = 'RISK_FACTOR'
}

/** Self fields that describe object in database */
export enum TX_KEY {
  ELEMENT_ID = 'element_id',
  ELEMENT_PARENT_ID = 'element_parent_id',
  ELEMENT_NAME = 'element_name',
  ELEMENT_DESCRIPTION = 'description',
  ELEMENT_PARENT_NAME = 'element_parent_name',
  ELEMENT_STATUS = 'element_status',
  ELEMENT_OWNER = 'element_owner',
  ELEMENT_APPROVAL_STATUS = 'element_approval_status',
  ELEMENT_SCORING = 'element_scoring'
}

/** Options with captions for "Mapping Type" */
export const TX_MAPPING_OPTIONS: { [key: string]: string } = {
  [TX_MAPPING.ELEMENT_FIELD]: 'Element Field',
  [TX_MAPPING.ELEMENT_PARENT_FIELD]: 'Element Parent Field',
  [TX_MAPPING.CUSTOM_FIELD]: 'Profile Field',
  [TX_MAPPING.RISK_FACTOR]: 'Risk Factor',
};

/** Options with captions for "Element Field" */
export const TX_ELEMENT_FIELD_OPTIONS: { [key: string]: string } = {
  [TX_KEY.ELEMENT_ID]: 'ElementID',
  [TX_KEY.ELEMENT_NAME]: 'ElementName',
  [TX_KEY.ELEMENT_STATUS]: 'ElementStatus',
  [TX_KEY.ELEMENT_OWNER]: 'ElementOwner',
  [TX_KEY.ELEMENT_APPROVAL_STATUS]: 'ElementApprovalStatus',
  [TX_KEY.ELEMENT_SCORING]: 'ElementScoring',
};

export const TX_ELEMENT_FIELD_OPTIONS_BUSINESS_PROCESS: { [key: string]: string } = {
  ...TX_ELEMENT_FIELD_OPTIONS,
  [TX_KEY.ELEMENT_DESCRIPTION]: 'Description',
};

/** Options with captions for "Element Parent Field" */
export const TX_ELEMENT_PARENT_FIELD_OPTIONS: { [key: string]: string } = {
  [TX_KEY.ELEMENT_PARENT_ID]: 'ElementParentID',
  [TX_KEY.ELEMENT_PARENT_NAME]: 'ElementParentName'
};
