import * as React from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { IDataLoadActionOptionsConnectProps } from './data-load-action-options-container';
import { DataLoadActionOptionsView } from './data-load-action-options-view';
import { DATA_LOAD_ACTION } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Options for "Data Load Action"
 */
export class DataLoadActionOptions extends React.Component<IDataLoadActionOptionsConnectProps> {
  constructor(props: IDataLoadActionOptionsConnectProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  /** Update UI reducer and call parent callback */
  public onChange(e: React.SyntheticEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.props.dispatch(Actions.UI.setUIData({ dataLoadAction: changes.value as DATA_LOAD_ACTION }));
    if (this.props.onChange) {
      this.props.onChange(changes.value as DATA_LOAD_ACTION);
    }
  }

  public render(): JSX.Element {
    return (
      <DataLoadActionOptionsView
        value={this.props.ui.dataLoadAction}
        options={this.props.options}
        onChange={this.onChange}
      />
    );
  }
}
