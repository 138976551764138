export enum PI_MAPPING {
  PLAN_KEY = 'PLAN_KEY',
  PLAN_EVENT = 'PLAN_EVENT',
  PLAN_EVENT_SCORE = 'PLAN_EVENT_SCORE'
}

export enum PLAN_KEY {
  PLAN_ID = 'plan_id',
  PLAN_NAME = 'plan_name'
}

export enum PLAN_EVENT_KEY {
  EXPLANATION = 'multiExplanation',
  SURVEY = 'survey',
  CATEGORY = 'category',
  FACTOR = 'factor',
  INDICATOR = 'event',
  INDICATOR_ID = 'event_id',
  OWNER = 'indicatorOwner'
}

export enum PLAN_EVENT_SCORE_KEY {
  IMPACT = 'impact',
  IMPACT_REASONING = 'impact_reasoning',
  LIKELIHOOD = 'likelihood',
  LIKELIHOOD_REASONING = 'likelihood_reasoning',
  ASSURANCE = 'control',
  ASSURANCE_REASONING = 'control_reasoning',
  IMPACT_2 = 'impact2',
  IMPACT_REASONING_2 = 'impact2_reasoning',
  LIKELIHOOD_2 = 'likelihood2',
  LIKELIHOOD_REASONING_2 = 'likelihood2_reasoning',
  VELOCITY = 'velocity',
  VELOCITY_REASONING = 'velocity_reasoning',
  EVALUATE = 'evaluate',
  EVALUATE_REASONING = 'status_reasoning',
  TARGET_INDEX = 'target_index',
  IMPACT_SUB_1 = 'impact_sub_1',
  IMPACT_SUB_2 = 'impact_sub_2',
  IMPACT_SUB_3 = 'impact_sub_3',
  IMPACT_SUB_4 = 'impact_sub_4',
  IMPACT_SUB_5 = 'impact_sub_5',
  IMPACT_SUB_6 = 'impact_sub_6',
  IMPACT_SUB_7 = 'impact_sub_7',
  IMPACT_SUB_8 = 'impact_sub_8',
  IMPACT_SUB_9 = 'impact_sub_9',
  IMPACT_SUB_10 = 'impact_sub_10',
  IMPACT_SUB_11 = 'impact_sub_11',
  IMPACT_SUB_12 = 'impact_sub_12',
  IMPACT_SUB_13 = 'impact_sub_13',
  IMPACT_SUB_14 = 'impact_sub_14',
  IMPACT_SUB_15 = 'impact_sub_15',
  IMPACT_SUB_16 = 'impact_sub_16',
  IMPACT_SUB_17 = 'impact_sub_17',
  IMPACT_SUB_18 = 'impact_sub_18',
  IMPACT_SUB_19 = 'impact_sub_19',
  IMPACT_SUB_20 = 'impact_sub_20',
  IMPACT_SUB_21 = 'impact_sub_21'
}

export const PI_MAPPING_OPTIONS: { [key: string]: string } = {
  [PI_MAPPING.PLAN_KEY]: 'Plan Identifier',
  [PI_MAPPING.PLAN_EVENT]: 'Indicator Identification',
  [PI_MAPPING.PLAN_EVENT_SCORE]: 'Indicator Assessment'
};

export const PLAN_KEY_OPTIONS: { [key: string]: string } = {
  [PLAN_KEY.PLAN_ID]: 'PlanID',
  [PLAN_KEY.PLAN_NAME]: 'PlanName'
};

export const PLAN_EVENT_KEY_OPTIONS: { [key: string]: string } = {
  [PLAN_EVENT_KEY.SURVEY]: 'Survey',
  [PLAN_EVENT_KEY.CATEGORY]: 'Category Name',
  [PLAN_EVENT_KEY.FACTOR]: 'Factor Name',
  [PLAN_EVENT_KEY.INDICATOR]: 'Indicator Name',
  [PLAN_EVENT_KEY.INDICATOR_ID]: 'IndicatorID',
  [PLAN_EVENT_KEY.OWNER]: 'Owner'
};

export const RISK_PLAN_EVENT_KEY_OPTIONS: { [key: string]: string } = {
  ...PLAN_EVENT_KEY_OPTIONS,
  [PLAN_EVENT_KEY.EXPLANATION]: 'What can go wrong?',
};

export const READINESS_PLAN_EVENT_KEY_OPTIONS: { [key: string]: string } = {
  ...PLAN_EVENT_KEY_OPTIONS,
  [PLAN_EVENT_KEY.EXPLANATION]: 'What are we doing currently?',
};

export const PERFORMANCE_PLAN_EVENT_KEY_OPTIONS: { [key: string]: string } = {
  ...PLAN_EVENT_KEY_OPTIONS,
  [PLAN_EVENT_KEY.EXPLANATION]: 'What needs to happen?',
};

export const RISK_PLAN_EVENT_SCORE_KEY_OPTIONS: { [key: string]: string } = {
  [PLAN_EVENT_SCORE_KEY.IMPACT]: 'Impact Score',
  [PLAN_EVENT_SCORE_KEY.IMPACT_REASONING]: 'Impact Reasoning',
  [PLAN_EVENT_SCORE_KEY.LIKELIHOOD]: 'Likelihood Score',
  [PLAN_EVENT_SCORE_KEY.LIKELIHOOD_REASONING]: 'Likelihood Reasoning',
  [PLAN_EVENT_SCORE_KEY.ASSURANCE]: 'Assurance Score',
  [PLAN_EVENT_SCORE_KEY.ASSURANCE_REASONING]: 'Assurance Reasoning',
  [PLAN_EVENT_SCORE_KEY.IMPACT_2]: 'Residual Impact Score',
  [PLAN_EVENT_SCORE_KEY.IMPACT_REASONING_2]: 'Residual Impact Reasoning',
  [PLAN_EVENT_SCORE_KEY.LIKELIHOOD_2]: 'Residual Likelihood Score',
  [PLAN_EVENT_SCORE_KEY.LIKELIHOOD_REASONING_2]: 'Residual Likelihood Reasoning',
  [PLAN_EVENT_SCORE_KEY.VELOCITY]: 'Velocity Score',
  [PLAN_EVENT_SCORE_KEY.VELOCITY_REASONING]: 'Velocity Reasoning',
  [PLAN_EVENT_SCORE_KEY.EVALUATE]: 'Evaluate',
  [PLAN_EVENT_SCORE_KEY.EVALUATE_REASONING]: 'Evaluate Reasoning',
  [PLAN_EVENT_SCORE_KEY.TARGET_INDEX]: 'Target Index',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_1]: 'Financial Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_2]: 'Regulatory Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_3]: 'Reputational Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_4]: 'Operational Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_5]: 'Compliance Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_6]: 'Fines and Judgements Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_7]: 'Health and Safety Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_8]: 'Human Life Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_9]: 'Legal Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_10]: 'Liquidity Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_11]: 'Market Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_12]: 'Membership Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_13]: 'Obligation Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_14]: 'Culture Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_15]: 'Productivity Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_16]: 'Competitive Advantage Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_17]: 'Replacement Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_18]: 'Credit Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_19]: 'Response Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_20]: 'Strategic Impact',
  [PLAN_EVENT_SCORE_KEY.IMPACT_SUB_21]: 'Social Impact'
};

export const READINESS_PLAN_EVENT_SCORE_KEY_OPTIONS: { [key: string]: string } = {
  [PLAN_EVENT_SCORE_KEY.IMPACT]: 'Effectiveness Score',
  [PLAN_EVENT_SCORE_KEY.IMPACT_REASONING]: 'Effectiveness Reasoning',
  [PLAN_EVENT_SCORE_KEY.LIKELIHOOD]: 'Proactivity Score',
  [PLAN_EVENT_SCORE_KEY.LIKELIHOOD_REASONING]: 'Proactivity Reasoning',
  [PLAN_EVENT_SCORE_KEY.ASSURANCE]: 'Coverage Score',
  [PLAN_EVENT_SCORE_KEY.ASSURANCE_REASONING]: 'Coverage Reasoning',
  [PLAN_EVENT_SCORE_KEY.IMPACT_2]: 'Residual Effectiveness Score',
  [PLAN_EVENT_SCORE_KEY.IMPACT_REASONING_2]: 'Residual Effectiveness Reasoning',
  [PLAN_EVENT_SCORE_KEY.LIKELIHOOD_2]: 'Residual Proactivity Score',
  [PLAN_EVENT_SCORE_KEY.LIKELIHOOD_REASONING_2]: 'Residual Proactivity Reasoning',
  [PLAN_EVENT_SCORE_KEY.VELOCITY]: 'Velocity Score',
  [PLAN_EVENT_SCORE_KEY.VELOCITY_REASONING]: 'Velocity Reasoning',
  [PLAN_EVENT_SCORE_KEY.EVALUATE]: 'Evaluate',
  [PLAN_EVENT_SCORE_KEY.EVALUATE_REASONING]: 'Evaluate Reasoning',
  [PLAN_EVENT_SCORE_KEY.TARGET_INDEX]: 'Target Index'
};

export const PERFORMANCE_PLAN_EVENT_SCORE_KEY_OPTIONS: { [key: string]: string } = {
  [PLAN_EVENT_SCORE_KEY.IMPACT]: 'Impact Score',
  [PLAN_EVENT_SCORE_KEY.IMPACT_REASONING]: 'Impact Reasoning',
  [PLAN_EVENT_SCORE_KEY.LIKELIHOOD]: 'Timing Score',
  [PLAN_EVENT_SCORE_KEY.LIKELIHOOD_REASONING]: 'Timing Reasoning',
  [PLAN_EVENT_SCORE_KEY.ASSURANCE]: 'Assurance Score',
  [PLAN_EVENT_SCORE_KEY.ASSURANCE_REASONING]: 'Assurance Reasoning',
  [PLAN_EVENT_SCORE_KEY.IMPACT_2]: 'Residual Impact Score',
  [PLAN_EVENT_SCORE_KEY.IMPACT_REASONING_2]: 'Residual Impact Reasoning',
  [PLAN_EVENT_SCORE_KEY.LIKELIHOOD_2]: 'Residual Timing Score',
  [PLAN_EVENT_SCORE_KEY.LIKELIHOOD_REASONING_2]: 'Residual Timing Reasoning',
  [PLAN_EVENT_SCORE_KEY.VELOCITY]: 'Velocity Score',
  [PLAN_EVENT_SCORE_KEY.VELOCITY_REASONING]: 'Velocity Reasoning',
  [PLAN_EVENT_SCORE_KEY.EVALUATE]: 'Evaluate',
  [PLAN_EVENT_SCORE_KEY.EVALUATE_REASONING]: 'Evaluate Reasoning',
  [PLAN_EVENT_SCORE_KEY.TARGET_INDEX]: 'Target Index'
};
