import * as React from 'react';
import { Grid, Loader, SemanticWIDTHS, Table } from 'semantic-ui-react';
import { UpdateDataFileContainer } from '../../data-upload/update-data-file';
import { IncidentConfigOptions } from '../incident-config-options';
import { IncidentMappingOptions } from '../../../components';
import { IncidentConfigActionsContainer } from '../incident-config-actions';
import { IAppTxConfig, IColumns, IDataState } from '../../../interfaces';
import { COMMON_TEXT, CONFIG_VIEW_TEXT, INCIDENT_KEY } from '../../../constants';
import './incident-config-view.less';

/**
 * View for "Incidents Configuration" component
 */
export class IncidentConfigView extends React.Component<IIncidentConfigViewProps> {
  public readonly MAX_COLUMNS: SemanticWIDTHS = 5;
  public readonly CONTENT_WIDTH: SemanticWIDTHS = 12;

  constructor(props: IIncidentConfigViewProps) {
    super(props);
  }

  /** Get components for mapping columns into incident self or profile fields */
  public getConfigRows(): JSX.Element[] {
    const rows: JSX.Element[] = [];
    const colIndexes: string[] = Object.keys(this.props.columns);

    for (let i: number = 0; i < colIndexes.length; i++) {
      const colIndex: string = colIndexes[i];

      const row: JSX.Element = (
        <IncidentMappingOptions
          key={i}
          colIndex={colIndex}
          name={this.props.columns[colIndex]}
          appIncidentConfig={this.props.appIncidentConfig}
          tabs={this.props.data.tabs}
          fields={this.props.data.fields}

          onWarningChange={this.props.onWarningChange}
          onMappingReset={this.props.onMappingReset}
          onSelfKeyMappingChange={this.props.onSelfKeyMappingChange}
          onCustomTabMappingChange={this.props.onCustomTabMappingChange}
          onCustomFieldMappingChange={this.props.onCustomFieldMappingChange}
        />
      );
      rows.push(row);
    }
    return rows;
  }

  /** Top table row contains title and help button */
  public getTableTitle(): JSX.Element {
    const caption: string = CONFIG_VIEW_TEXT.INCIDENT_FIELDS_CONFIG;

    return (
      <Table.HeaderCell className="top-title" colSpan={this.MAX_COLUMNS}>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>{caption}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Table.HeaderCell>
    );
  }

  /** Get header with data load type and column captions */
  public getTableHeader(): JSX.Element {
    return (
      <Table.Header fullWidth>
        <Table.Row>
          {this.getTableTitle()}
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.STATUS_COLUMN_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.COLUMN_NAME_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.MAPPING_TYPE_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.TAB_NAME_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.FIELD_NAME_HEADER} />
        </Table.Row>
      </Table.Header>
    );
  }

  /** Table for mapping columns into self or profile fields */
  public getConfigTable(): JSX.Element {
    return (
      <Grid.Row columns="equal" centered>
        <Grid.Column width={this.CONTENT_WIDTH}>
          <Table celled compact columns={this.MAX_COLUMNS}>
            {this.getTableHeader()}

            <Table.Body>
              {this.getConfigRows()}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    );
  }

  /** Actions can be disabled if config warning is present */
  public getConfigActions(): JSX.Element {
    return (
      <Grid.Row columns="equal" centered>
        <Grid.Column width={this.CONTENT_WIDTH}>
          <IncidentConfigActionsContainer warning={this.props.warning} />
        </Grid.Column>
      </Grid.Row>
    );
  }

  public render(): JSX.Element {
    if (!this.props.dataReady) {
      return <Loader active inline="centered" content={COMMON_TEXT.LOADING} />;
    }

    return (
      <Grid data-component="incident-config-view">
        <Grid.Row columns="equal" centered>
          <Grid.Column width={this.CONTENT_WIDTH}>
            <UpdateDataFileContainer />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="no-padding" columns="equal" centered>
          <Grid.Column width={this.CONTENT_WIDTH}>
            <IncidentConfigOptions />
          </Grid.Column>
        </Grid.Row>

        {this.props.optionsReady ? this.getConfigTable() : null}
        {this.props.optionsReady ? this.getConfigActions() : null}
      </Grid>
    );
  }
}

export interface IIncidentConfigViewProps {
  readonly data: IDataState;
  readonly columns: IColumns;
  readonly warning: boolean;
  readonly optionsReady: boolean;
  readonly dataReady: boolean;
  readonly appIncidentConfig: IAppTxConfig;

  onMappingReset(colIndex: string): void;
  onWarningChange(warning: boolean): void;
  onSelfKeyMappingChange(colIndex: string, key: INCIDENT_KEY): void;
  onCustomTabMappingChange(colIndex: string, tabId: string): void;
  onCustomFieldMappingChange(colIndex: string, fieldId: string): void;
}
