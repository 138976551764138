import * as React from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import {
  LIBRARY_TYPE, PERFORMANCE_PLAN_EVENT_SCORE_KEY_OPTIONS, READINESS_PLAN_EVENT_SCORE_KEY_OPTIONS,
  RISK_PLAN_EVENT_SCORE_KEY_OPTIONS
} from '../../../constants';
import { getOptions } from '../../../functions';
import './plan-event-score-mapping-options.less';

/**
 * Options for mapping "Indicator Assessment"
 */
export class PlanEventScoreMappingOptions extends React.Component<IPlanEventScoreMappingOptionsProps> {
  constructor(props: IPlanEventScoreMappingOptionsProps) {
    super(props);
  }

  /** Options for plan event score are based on selected library type */
  public getOptions(): DropdownItemProps[] {
    const options: DropdownItemProps[] = [];

    switch (this.props.libraryType) {
      case LIBRARY_TYPE.RISK:
        options.push(...getOptions(RISK_PLAN_EVENT_SCORE_KEY_OPTIONS));
        break;
      case LIBRARY_TYPE.READINESS:
        options.push(...getOptions(READINESS_PLAN_EVENT_SCORE_KEY_OPTIONS));
        break;
      case LIBRARY_TYPE.PERFORMANCE:
        options.push(...getOptions(PERFORMANCE_PLAN_EVENT_SCORE_KEY_OPTIONS));
        break;
      default:
        break;
    }

    return options;
  }

  public render(): JSX.Element {
    return (
      <Dropdown
        data-component="plan-event-score-mapping-options"
        options={this.getOptions()}
        value={this.props.planEventScoreKey}
        onChange={this.props.onPlanEventScoreColMappingChange}
        selectOnBlur={false}
        selection
        fluid
      />
    );
  }
}

export interface IPlanEventScoreMappingOptionsProps {
  readonly libraryType: LIBRARY_TYPE;
  readonly planEventScoreKey: string;
  onPlanEventScoreColMappingChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void;
}
