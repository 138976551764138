import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { MatchKeyOptions } from './match-key-options';
import { IState, IUIState } from '../../../interfaces';
import { MATCH_KEY } from '../../../constants';

export interface IMatchKeyOptionsProps {
  /** Options in drop down */
  readonly options: { [key: string]: string };
  /** Match {labelKey} by: - string */
  readonly labelKey: string;
  onChange?(action: MATCH_KEY): void;
}

export interface IMatchKeyStateProps extends IMatchKeyOptionsProps {
  readonly ui: IUIState;
}

export type IMatchKeyOptionsConnectProps = IMatchKeyStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IMatchKeyOptionsProps): IMatchKeyStateProps {
  return { ui: state.ui, ...props };
}

export const MatchKeyOptionsContainer: React.ComponentClass<IMatchKeyOptionsProps>
  = ReactRedux.connect(mapStateToProps)(MatchKeyOptions);
