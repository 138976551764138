import * as Redux from 'redux';
import { IUIState } from '../interfaces';
import { DATA_LOAD_STAGE } from '../constants';

export const SET_UI_DATA: string = 'SET_UI_DATA';
export const SET_DATA_LOAD_STAGE: string = 'SET_DATA_LOAD_STAGE';

export interface ISetUIData extends Redux.Action {
  readonly options: Partial<IUIState>;
}

export interface ISetDataLoadStage extends Redux.Action {
  readonly stage: DATA_LOAD_STAGE;
}

/** Set any of UI store variables */
export function setUIData(options: Partial<IUIState>): ISetUIData {
  return { type: SET_UI_DATA, options };
}

/** Setting data load stage is a separate action */
export function setDataLoadStage(stage: DATA_LOAD_STAGE): ISetDataLoadStage {
  return { type: SET_DATA_LOAD_STAGE, stage };
}
