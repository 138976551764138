import * as React from 'react';
import { Dropdown, DropdownItemProps, DropdownProps, Table } from 'semantic-ui-react';
import { IAppTxMapping, IFieldData, ITabData } from '../../../interfaces';
import { NO_MAPPING_BLANK_OPTION, RESOURCE_FIELD_TYPE } from '../../../constants';
import './custom-mapping-options.less';

/**
 * Options for mapping column into custom profile field
 * Component receives only default value through props
 * Rest of actions are self-controlled
 */
export class CustomMappingOptions extends React.Component<ICustomMappingOptionsProps, ICustomMappingOptionsState> {
  /** Resource types that are currently not available */
  public readonly LOCKED_OPTIONS: string[] = [
    RESOURCE_FIELD_TYPE.TABLE
  ];

  /** Resource types that can't be mapped */
  public readonly IGNORED_OPTIONS: string[] = [
    RESOURCE_FIELD_TYPE.SPACER, RESOURCE_FIELD_TYPE.TITLE, RESOURCE_FIELD_TYPE.SUBTITLE,
    RESOURCE_FIELD_TYPE.ATTACHMENT, RESOURCE_FIELD_TYPE.ATTACHMENT_TEMPLATES
  ];

  /* Static options for dropdowns */
  public customTabOptions: DropdownItemProps[];
  public customFieldOptions: DropdownItemProps[];

  constructor(props: ICustomMappingOptionsProps) {
    super(props);

    /* Set own state once on component initial load */
    const column: IAppTxMapping = this.props.config[this.props.colIndex];
    const defaultValue: IAppTxMapping = (column)
      ? { tabId: column.tabId, fieldId: column.fieldId }
      : { tabId: NO_MAPPING_BLANK_OPTION.key, fieldId: NO_MAPPING_BLANK_OPTION.key };

    this.state = { value: defaultValue };
    this.customTabOptions = this.getCustomTabOptions();
    this.customFieldOptions = this.getCustomFieldOptions();
    this.checkWarning();

    this.onCustomTabChange = this.onCustomTabChange.bind(this);
    this.onCustomFieldChange = this.onCustomFieldChange.bind(this);
  }

  /** Track only own state changes */
  public shouldComponentUpdate(props: ICustomMappingOptionsProps, state: ICustomMappingOptionsState): boolean {
    return this.state !== state;
  }

  /** Warn parent about incomplete mapping */
  public checkWarning(): void {
    const warning: boolean = this.state.value.tabId === NO_MAPPING_BLANK_OPTION.key
      || this.state.value.fieldId === NO_MAPPING_BLANK_OPTION.key;
    this.props.onWarningChange(warning);
  }

  /** On tab ID change, update own value and call parent callback */
  public onCustomTabChange(e: React.ChangeEvent<HTMLInputElement>, changes: DropdownProps): void {
    const value: IAppTxMapping = {
      tabId: changes.value.toString(),
      fieldId: NO_MAPPING_BLANK_OPTION.key
    };

    this.setState({ value }, (): void => {
      this.checkWarning();
      this.props.onCustomTabMappingChange(this.props.colIndex, changes.value.toString());
    });
  }

  /** On field ID change, update own value and call parent callback */
  public onCustomFieldChange(e: React.ChangeEvent<HTMLInputElement>, changes: DropdownProps): void {
    const value: IAppTxMapping = {
      ...this.state.value,
      fieldId: changes.value.toString()
    };

    this.setState({ value }, (): void => {
      this.checkWarning();
      this.props.onCustomFieldMappingChange(this.props.colIndex, this.state.value.fieldId.toString());
    });
  }

  /** List of tab options does not depend on state */
  public getCustomTabOptions(): DropdownItemProps[] {
    const options: DropdownItemProps[] = [];
    const keys: string[] = Object.keys(this.props.tabs);

    for (let i: number = 0; i < keys.length; i++) {
      const tab: ITabData = this.props.tabs[keys[i]];
      options.push({ text: tab.name, value: tab.id.toString() });
    }

    return options;
  }

  /** List of all field options does not depend on state */
  public getCustomFieldOptions(): DropdownItemProps[] {
    const options: DropdownItemProps[] = [];
    const keys: string [] = Object.keys(this.props.fields);

    for (let i: number = 0; i < keys.length; i++) {
      const field: IFieldData = this.props.fields[keys[i]];

      /* Do not display ignored options */
      if (this.IGNORED_OPTIONS.indexOf(field.type) !== -1) {
        continue;
      }

      options.push({
        text: field.name,
        value: field.id.toString(),
        /* Show locked options, but disable ability to select */
        disabled: this.LOCKED_OPTIONS.indexOf(field.type) !== -1
      });
    }

    return options;
  }

  /** Get field options based on selected tab ID */
  public getCustomFieldOptionsForTab(): DropdownItemProps[] {
    const options: DropdownItemProps[] = [];

    if (this.state.value.tabId !== NO_MAPPING_BLANK_OPTION.key) {
      const tab: ITabData = this.props.tabs[this.state.value.tabId];

      /* Filter options based on active tab ID */
      const result: DropdownItemProps[] = this.customFieldOptions.filter(
        (option: DropdownItemProps): boolean => {
          return tab.resourceFields.indexOf(parseInt(option.value as string, 10)) !== -1;
        }
      );

      options.push(...result);
    }

    return options;
  }

  /** Get dropdown for mapping column into custom tabs */
  public getCustomTabDropdown(): JSX.Element {
    return (
      <Dropdown
        options={this.customTabOptions}
        value={this.state.value.tabId}
        onChange={this.onCustomTabChange}
        selectOnBlur={false}
        selection
        search
        fluid
      />
    );
  }

  /** Get dropdown for mapping column into custom fields */
  public getCustomFieldDropdown(): JSX.Element {
    const options: DropdownItemProps[] = this.getCustomFieldOptionsForTab();
    return (
      <Dropdown
        options={options}
        value={this.state.value.fieldId}
        onChange={this.onCustomFieldChange}
        selectOnBlur={false}
        selection
        search
        fluid
      />
    );
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        <Table.Cell key="custom-tab-dropdown">
          {this.getCustomTabDropdown()}
        </Table.Cell>

        <Table.Cell key="custom-field-dropdown">
          {this.getCustomFieldDropdown()}
        </Table.Cell>
      </React.Fragment>
    );
  }
}

export interface ICustomMappingOptionsState {
  readonly value: IAppTxMapping;
}

export interface ICustomMappingOptionsProps {
  readonly colIndex: string;
  readonly config: { [key: number]: IAppTxMapping };
  readonly tabs: { [key: number]: ITabData };
  readonly fields: { [key: number]: IFieldData };
  onWarningChange(warning: boolean): void;
  onCustomTabMappingChange(colIndex: string, tabId: string): void;
  onCustomFieldMappingChange(colIndex: string, fieldId: string): void;
}
