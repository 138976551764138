import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { TxKindOptions } from './tx-kind-options';
import { IState } from '../../../interfaces';
import { TX_RESOURCE_TYPE } from '../../../constants';

export interface ITxKindOptionsStateProps {
  readonly activeType: TX_RESOURCE_TYPE | null;
}

export type ITxKindOptionsConnectProps = ITxKindOptionsStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): ITxKindOptionsStateProps {
  return { activeType: state.upload.txResourceType };
}

export const TxKindOptionsContainer: React.ComponentClass = ReactRedux.connect(mapStateToProps)(TxKindOptions);
