import * as React from 'react';
import { ITxDataUploadActionsConnectProps } from './tx-data-upload-actions-container';
import { DataUploadActionsContainer } from '../../data-upload/data-upload-actions';
import { DataLoadApi, ITxConfigOptions, IUploadTxFileOptions } from '../../../api';
import { checkDataRange, getAppTxConfig } from '../../../functions';
import * as Actions from '../../../actions';

/**
 * Taxonomy data upload actions checks input data and performs upload
 */
export class TxDataUploadActions extends React.Component<ITxDataUploadActionsConnectProps> {
  constructor(props: ITxDataUploadActionsConnectProps) {
    super(props);
    this.uploadFile = this.uploadFile.bind(this);
    this.getUploadButtonStatus = this.getUploadButtonStatus.bind(this);
  }

  /** Upload data file to server and save config on success */
  public uploadFile(): Promise<{}> {
    const options: IUploadTxFileOptions = {
      dataFile: this.props.upload.dataFile,
      sheetIndex: this.props.upload.sheetIndex,
      txResourceType: this.props.upload.txResourceType,
      startRow: this.props.upload.dataStartRow,
      endRow: this.props.upload.dataEndRow
    };

    return DataLoadApi.uploadTxFile(options).request()
      .then((response: ITxConfigOptions): {} => {
        this.props.dispatch(Actions.Config.setColumns(response.columns));
        this.props.dispatch(Actions.Config.setTxConfig(getAppTxConfig(response.config)));
        return {};
      });
  }

  /** Upload button is disabled when data is not valid */
  public getUploadButtonStatus(): boolean {
    const dataReady: boolean = !!this.props.upload.dataFile;
    const sheetIndexReady: boolean = this.props.upload.sheetIndex !== null && this.props.upload.sheetIndex >= 0;

    const txTypeReady: boolean = !!this.props.upload.txResourceType;
    const dataRangeReady: boolean = checkDataRange({
      start: this.props.upload.dataStartRow,
      end: this.props.upload.dataEndRow
    });

    return dataReady && sheetIndexReady && txTypeReady && dataRangeReady;
  }

  public render(): JSX.Element {
    return (
      <DataUploadActionsContainer
        uploadFile={this.uploadFile}
        getUploadButtonStatus={this.getUploadButtonStatus}
      />
    );
  }
}
