import * as React from 'react';
import { InputOnChangeData } from 'semantic-ui-react';
import { IDataRangeOptionsConnectProps } from './data-range-options-container';
import { DataRangeOptions as DataRangeOptionsView } from '../../../components';
import * as Actions from '../../../actions';

/**
 * Optional options for specifying data row range
 */
export class DataRangeOptions extends React.Component<IDataRangeOptionsConnectProps> {
  constructor(props: IDataRangeOptionsConnectProps) {
    super(props);

    this.onRowRangeChange = this.onRowRangeChange.bind(this);
  }

  /** Empty optional values are treated as default */
  public onRowRangeChange(e: React.KeyboardEvent<HTMLInputElement>, changes: InputOnChangeData): void {
    /* Ignore non-number key inputs */
    const value: number = parseInt(changes.value, 10);
    if (changes.value !== '' && isNaN(value)) {
      return;
    }

    this.props.dispatch(Actions.Upload.setUploadInfo({
      [changes.name]: changes.value === '' ? null : value - 1
    }));
  }

  public render(): JSX.Element {
    return (
      <DataRangeOptionsView
        dataStartRow={this.props.upload.dataStartRow}
        dataEndRow={this.props.upload.dataEndRow}
        onRowRangeChange={this.onRowRangeChange}
      />
    );
  }
}
