import * as React from 'react';
import { DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { ILibraryTypeDropdownConnectProps } from './library-type-dropdown-container';
import { CONFIG_VIEW_TEXT, LIBRARY_TYPE, LIBRARY_TYPE_OPTIONS } from '../../../constants';
import { getOptions } from '../../../functions';
import * as Actions from '../../../actions';

/**
 * View for "Library Type Dropdown" component
 */
export class LibraryTypeDropdown extends React.Component<ILibraryTypeDropdownProps> {
  public libraryTypeOptions: DropdownItemProps[];

  constructor(props: ILibraryTypeDropdownProps) {
    super(props);
    this.libraryTypeOptions = getOptions(LIBRARY_TYPE_OPTIONS);
    this.onLibraryTypeChange = this.onLibraryTypeChange.bind(this);
  }

  public onLibraryTypeChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.props.dispatch(Actions.UI.setUIData({ libraryType: changes.value as LIBRARY_TYPE }));
    if (this.props.onChange) {
      this.props.onChange(changes.value as LIBRARY_TYPE);
    }
  }

  public render(): JSX.Element {
    return (
      <Form.Dropdown
        label={CONFIG_VIEW_TEXT.LIBRARY_TYPE}
        options={this.libraryTypeOptions}
        value={this.props.ui.libraryType}
        onChange={this.onLibraryTypeChange}
        selectOnBlur={false}
        selection
        required
      />
    );
  }
}

export interface ILibraryTypeDropdownProps extends ILibraryTypeDropdownConnectProps {
  onChange?(libraryType: LIBRARY_TYPE): void;
}
