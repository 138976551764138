import { BaseApi } from './base-api';
import { UrlRequest } from './url-request';
import {
  API_URL_CONFIG, HTTP_METHOD, IActivityConfigOptions, IAILConfigOptions, IBasicConfigOptions, IIncidentConfigOptions,
  IIndicatorConfigOptions, IIPLConfigOptions, IILConfigOptions, IPIConfigOptions, ITxConfigOptions, IUpdateFileResponse,
  IUploadBasicFileOptions, IUploadIncidentFileOptions, IUploadTxFileOptions, IUrlObject, IUserManagementConfigOptions,
  IMAConfigOptions
} from './api-interfaces';
import { IDataLoadResult, IUserManagementConfig } from '../interfaces';
import { IUploadMAFileOptions } from '.';

/**
 * Wrapper for "Taxonomy Data Load" API
 */
export class DataLoadApi {
  /** Taxonomy file upload requires taxonomy resource type */
  public static uploadTxFile(options: IUploadTxFileOptions): UrlRequest<ITxConfigOptions> {
    const configObj: object = {
      sheetIndex: options.sheetIndex,
      resourceName: options.txResourceType,
      startRow: options.startRow,
      endRow: options.endRow
    };

    return DataLoadApi.uploadDataFile({
      urlObject: API_URL_CONFIG.taxonomy.upload,
      configObj,
      dataFile: options.dataFile,
      callback: (response: object): ITxConfigOptions => {
        return {
          columns: response['namingRow'],
          config: JSON.parse(response['config'])
        };
      }
    });
  }

  /** Incidents file upload requires test ID */
  public static uploadIncidentFile(options: IUploadIncidentFileOptions): UrlRequest<IIncidentConfigOptions> {
    const configObj: object = {
      sheetIndex: options.sheetIndex,
      testId: options.testId,
      startRow: options.startRow,
      endRow: options.endRow
    };

    return DataLoadApi.uploadDataFile({
      urlObject: API_URL_CONFIG.incidents.upload,
      configObj,
      dataFile: options.dataFile,
      callback: (response: object): IIncidentConfigOptions => {
        return {
          columns: response['namingRow'],
          config: JSON.parse(response['config'])
        };
      }
    });
  }

  public static uploadMAFile(options: IUploadMAFileOptions): UrlRequest<IMAConfigOptions> {
    const configObj: object = {
      sheetIndex: options.sheetIndex,
      maType: options.maType,
      startRow: options.startRow,
      endRow: options.endRow
    };

    return DataLoadApi.uploadDataFile({
      urlObject: API_URL_CONFIG.MA.upload,
      configObj,
      dataFile: options.dataFile,
      callback: (response: object): IMAConfigOptions => {
        return {
          columns: response['namingRow'],
          config: JSON.parse(response['config'])
        };
      }
    });
  }

  public static uploadPIFile(options: IUploadBasicFileOptions): UrlRequest<IPIConfigOptions> {
    return DataLoadApi.uploadBasicFile({ ...options, urlObject: API_URL_CONFIG.PI.upload });
  }

  public static uploadIPLFile(options: IUploadBasicFileOptions): UrlRequest<IIPLConfigOptions> {
    return DataLoadApi.uploadBasicFile({ ...options, urlObject: API_URL_CONFIG.IPL.upload });
  }

  public static uploadILFile(options: IUploadBasicFileOptions): UrlRequest<IILConfigOptions> {
    return DataLoadApi.uploadBasicFile({ ...options, urlObject: API_URL_CONFIG.IL.upload });
  }

  public static uploadIndicatorFile(options: IUploadBasicFileOptions): UrlRequest<IIndicatorConfigOptions> {
    return DataLoadApi.uploadBasicFile({ ...options, urlObject: API_URL_CONFIG.indicators.upload });
  }

  public static uploadActivityFile(options: IUploadBasicFileOptions): UrlRequest<IActivityConfigOptions> {
    return DataLoadApi.uploadBasicFile({ ...options, urlObject: API_URL_CONFIG.activity.upload });
  }

  public static uploadAILFile(options: IUploadBasicFileOptions): UrlRequest<IAILConfigOptions> {
    return DataLoadApi.uploadBasicFile({ ...options, urlObject: API_URL_CONFIG.AIL.upload });
  }

  public static uploadUserManagementFile(options: IUploadBasicFileOptions): UrlRequest<IUserManagementConfigOptions> {
    return DataLoadApi.uploadBasicFile({ ...options, urlObject: API_URL_CONFIG.userManagement.users.upload });
  }

  /** Update data file after data load has been initiated */
  public static updateDataFile(options: IUploadBasicFileOptions): UrlRequest<IUpdateFileResponse> {
    const configObj: object = {
      sheetIndex: options.sheetIndex,
      startRow: options.startRow,
      endRow: options.endRow
    };

    return DataLoadApi.uploadDataFile({
      urlObject: API_URL_CONFIG.dataLoad.updateFile,
      configObj,
      dataFile: options.dataFile,
      callback: (response: object): IUpdateFileResponse => {
        return {
          sheetIndex: response['sheet_index'],
          dataStartRow: response['start_row'],
          dataEndRow: response['end_row']
        };
      }
    });
  }

  public static uploadUserManagementConfig(options: IUserManagementConfig): UrlRequest<{}> {
    const urlObject: IUrlObject = { ...API_URL_CONFIG.userManagement.users.dryRun, body: options };
    return new UrlRequest({ urlObject });
  }

  public static uploadTxConfig(options: ITxConfigOptions): UrlRequest<{}> {
    const config: object = { config: options.config, namingRow: options.columns };
    const urlObject: IUrlObject = { ...API_URL_CONFIG.taxonomy.dryRun, body: config };
    return new UrlRequest({ urlObject });
  }

  public static uploadPIConfig(options: IPIConfigOptions): UrlRequest<{}> {
    const config: object = { config: options.config, namingRow: options.columns };
    const urlObject: IUrlObject = { ...API_URL_CONFIG.PI.dryRun, body: config };
    return new UrlRequest({ urlObject });
  }

  public static uploadActivityConfig(options: IActivityConfigOptions): UrlRequest<{}> {
    const config: object = { config: options.config, namingRow: options.columns };
    const urlObject: IUrlObject = { ...API_URL_CONFIG.activity.dryRun, body: config };
    return new UrlRequest({ urlObject });
  }

  public static uploadMAConfig(options: IMAConfigOptions): UrlRequest<{}> {
    const config: object = { config: options.config, namingRow: options.columns };
    const urlObject: IUrlObject = { ...API_URL_CONFIG.MA.dryRun, body: config };
    return new UrlRequest({ urlObject });
  }

  public static uploadIndicatorConfig(options: IIndicatorConfigOptions): UrlRequest<{}> {
    const config: object = { config: options.config, namingRow: options.columns };
    const urlObject: IUrlObject = { ...API_URL_CONFIG.indicators.dryRun, body: config };
    return new UrlRequest({ urlObject });
  }

  public static uploadIPLConfig(options: IIPLConfigOptions): UrlRequest<{}> {
    const config: object = { config: options.config, namingRow: options.columns };
    const urlObject: IUrlObject = { ...API_URL_CONFIG.IPL.dryRun, body: config };
    return new UrlRequest({ urlObject });
  }

  public static uploadILConfig(options: IILConfigOptions): UrlRequest<{}> {
    const config: object = { config: options.config, namingRow: options.columns };
    const urlObject: IUrlObject = { ...API_URL_CONFIG.IL.dryRun, body: config };
    return new UrlRequest({ urlObject });
  }

  public static uploadIncidentConfig(options: IIncidentConfigOptions): UrlRequest<{}> {
    const config: object = { config: options.config, namingRow: options.columns };
    const urlObject: IUrlObject = { ...API_URL_CONFIG.incidents.dryRun, body: config };
    return new UrlRequest({ urlObject });
  }

  public static uploadAILConfig(options: IAILConfigOptions): UrlRequest<{}> {
    const config: object = { config: options.config, namingRow: options.columns };
    const urlObject: IUrlObject = { ...API_URL_CONFIG.AIL.dryRun, body: config };
    return new UrlRequest({ urlObject });
  }

  /** Check dry run or data save request status */
  public static checkStatus(): UrlRequest<IDataLoadResult> {
    return new UrlRequest({
      urlObject: API_URL_CONFIG.dataLoad.checkStatus,
      callback: (response: object): IDataLoadResult => {
        return {
          status: response['status'],
          currentRowNumber: response['rowNumber'],
          nReadyEntities: response['processedNumber']
        };
      }
    });
  }

  /** Retrieve full result with logs for dry run or data save */
  public static checkLogs(): UrlRequest<IDataLoadResult> {
    return new UrlRequest({
      urlObject: API_URL_CONFIG.dataLoad.checkLogs,
      callback: (response: object): IDataLoadResult => {
        return {
          status: response['status'],
          logs: response['logList'],
          nReadyEntities: response['processedNumber']
        };
      }
    });
  }

  /** Cancel data load that is currently in progress */
  public static cancelDataLoad(): UrlRequest<{}> {
    return new UrlRequest({ urlObject: API_URL_CONFIG.dataLoad.cancelDataLoad });
  }

  /** Confirm or reset data load request */
  public static makeDataLoad(options: { confirm: boolean }): UrlRequest<{}> {
    const params: object = { confirm: options.confirm };
    const urlObject: IUrlObject = { ...API_URL_CONFIG.dataLoad.makeDataLoad, params };
    return new UrlRequest({ urlObject });
  }

  /** Upload file with only basic required options */
  protected static uploadBasicFile<T>(
    options: IUploadBasicFileOptions & { urlObject: IUrlObject }
  ): UrlRequest<IBasicConfigOptions<T>> {
    const configObj: object = {
      sheetIndex: options.sheetIndex,
      startRow: options.startRow,
      endRow: options.endRow
    };

    return DataLoadApi.uploadDataFile({
      urlObject: options.urlObject,
      configObj,
      dataFile: options.dataFile,
      callback: (response: object): IBasicConfigOptions<T> => {
        return {
          columns: response['namingRow'],
          config: JSON.parse(response['config'])
        };
      }
    });
  }

  /** Upload data file and config as multi-part request */
  protected static uploadDataFile<T>(options: {
    urlObject: IUrlObject,
    dataFile: File,
    configObj: object,
    callback?(response: object): T
  }): UrlRequest<T> {
    const data: FormData = new FormData();
    /* Send options as JSON string */
    const configString: string = JSON.stringify(options.configObj, null, 2);
    data.append('loadConfig', configString);
    data.append('file', options.dataFile);

    /* We have to delete custom "Content-Type" header in order to make browser put it's own header */
    const headers: Headers = new Headers(BaseApi.REQUEST_OPTIONS[HTTP_METHOD.POST].headers || {});
    headers.delete('Content-Type');

    const urlObject: IUrlObject = { ...options.urlObject, body: data };
    return new UrlRequest({ urlObject, callback: options.callback });
  }
}
