import * as React from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { MatchByIndexOptionsView } from './match-by-index-options-view';
import { IMatchByIndexOptionsConnectProps } from './match-by-index-options-container';
import * as Actions from '../../../actions';

/**
 * Options for "Match By Index"
 */
export class MatchByIndexOptions extends React.Component<IMatchByIndexOptionsConnectProps> {
  constructor(props: IMatchByIndexOptionsConnectProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  /** Update UI reducer and call parent callback */
  public onChange(e: React.SyntheticEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.props.dispatch(Actions.UI.setUIData({ matchByIndex: changes.value as string }));
    if (this.props.onChange) {
      this.props.onChange(changes.value as string);
    }
  }

  public render(): JSX.Element {
    return (
      <MatchByIndexOptionsView
        value={this.props.ui.matchByIndex}
        columns={this.props.columns}
        onChange={this.onChange}
      />
    );
  }
}
