import * as React from 'react';
import {
  Dropdown,
  DropdownItemProps,
  Grid,
  Icon,
  Loader,
  SemanticICONS,
  SemanticWIDTHS,
  Table
} from 'semantic-ui-react';
import { ConfigOptions } from '../config-options';
import { UpdateDataFileContainer } from '../../data-upload/update-data-file';
import { IAppUserManagementConfig, IColumns, IDataState } from '../../../interfaces';
import {
  COMMON_TEXT, CONFIG_VIEW_TEXT, DATA_LOAD_ACTION, MATCH_USER_MANAGEMENT_KEY_OPTIONS, NO_MAPPING_OPTION
} from '../../../constants';
import './config-view.less';
import { getOptions } from '../../../functions';
import { USER_MANAGEMENT_OPTIONS } from '../../../constants/user-management';
import { ConfigActionsContainer } from '../config-actions';

/**
 * View for "User Management Configuration" component
 */
export class ConfigView extends React.Component<IConfigViewProps> {
  public readonly MAX_COLUMNS: SemanticWIDTHS = 5;
  public readonly CONTENT_WIDTH: SemanticWIDTHS = 12;
  private readonly matchKeyOptions: DropdownItemProps[] = [];
  private readonly defaultValue: DropdownItemProps = { value: NO_MAPPING_OPTION.key, text: NO_MAPPING_OPTION.caption };

  constructor(props: IConfigViewProps) {
    super(props);
    this.matchKeyOptions.push(this.defaultValue);
    this.matchKeyOptions = this.matchKeyOptions.concat(getOptions(MATCH_USER_MANAGEMENT_KEY_OPTIONS));
  }

  /** Get components for mapping columns into User Management record */
  public getConfigRows(): JSX.Element[] {
    const rows: JSX.Element[] = [];

    const colIndexes: string[] = Object.keys(this.props.columns);

    for (let i: number = 0; i < colIndexes.length; i++) {
      rows.push(
        <Table.Row data-component="user-management-mapping-options">
          {this.getStatus(i)}
          <Table.Cell>{this.props.columns[i]}</Table.Cell>
          <Table.Cell>{this.getUserManagementMapping(i)}</Table.Cell>
        </Table.Row>
      );
    }

    return rows;
  }

  public onSelfKeyMappingChange(
    colIndex: string,
    e: React.SyntheticEvent<HTMLElement>,
    { value }: { value: USER_MANAGEMENT_OPTIONS }
  ): void {
    this.props.onFieldNameChange(colIndex, value);
  }

  public getUserManagementMapping(mappedOptionIndex: number): JSX.Element {
    const onChange: () => void = this.onSelfKeyMappingChange.bind(this, mappedOptionIndex);
    return (
      <Dropdown
        options={this.matchKeyOptions}
        value={this.props.appUserManagementConfig.userConfig[mappedOptionIndex] || this.defaultValue.value}
        onChange={onChange}
        selectOnBlur={false}
        selection
        fluid
      />
    );
  }

  public getStatus(mappedIndex: number): JSX.Element {
    const STATUS_CELL_WIDTH: SemanticWIDTHS = 1;
    const isValueExist: boolean = !!this.props.appUserManagementConfig.userConfig[mappedIndex];
    const isValueNoMapping: boolean =
      this.props.appUserManagementConfig.userConfig[mappedIndex] === NO_MAPPING_OPTION.key;

    const icon: SemanticICONS = isValueExist && !isValueNoMapping
        ? 'checkmark'
        : 'warning';

    return (
      <Table.Cell
        textAlign="center"
        positive={isValueExist && !isValueNoMapping}
        width={STATUS_CELL_WIDTH}
      >
        <Icon name={icon} />
      </Table.Cell>
    );
  }

  /** Top table row contains title and help button */
  public getTableTitle(): JSX.Element {
    const caption: string = `${CONFIG_VIEW_TEXT.USER_MANAGEMENT_FIELDS_CONFIG}`;

    return (
      <Table.HeaderCell className="top-title" colSpan={this.MAX_COLUMNS}>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>{caption}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Table.HeaderCell>
    );
  }

  /** Get header with data load type and column captions */
  public getTableHeader(): JSX.Element {
    return (
      <Table.Header fullWidth>
        <Table.Row>
          {this.getTableTitle()}
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.STATUS_COLUMN_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.COLUMN_NAME_HEADER} />
          <Table.HeaderCell content={CONFIG_VIEW_TEXT.FIELD_NAME_HEADER} />
        </Table.Row>
      </Table.Header>
    );
  }

  /** Table for mapping columns into User Management self or profile fields */
  public getConfigTable(): JSX.Element {
    return (
      <Grid.Row columns="equal" centered>
        <Grid.Column width={this.CONTENT_WIDTH}>
          <Table celled compact columns={this.MAX_COLUMNS}>
            {this.getTableHeader()}

            <Table.Body>
              {this.getConfigRows()}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    );
  }

  /** Actions can be disabled if config warning is present */
  public getConfigActions(): JSX.Element {
    return (
      <Grid.Row columns="equal" centered>
        <Grid.Column width={this.CONTENT_WIDTH}>
          <ConfigActionsContainer warning={false} />
        </Grid.Column>
      </Grid.Row>
    );
  }

  public render(): JSX.Element {
    if (!this.props.appUserManagementConfig) {
      return <Loader active inline="centered" content={COMMON_TEXT.LOADING} />;
    }

    return (
      <Grid>
        <Grid.Row columns="equal" centered>
          <Grid.Column width={this.CONTENT_WIDTH}>
            <UpdateDataFileContainer />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="no-padding" columns="equal" centered>
          <Grid.Column width={this.CONTENT_WIDTH}>
            <ConfigOptions />
          </Grid.Column>
        </Grid.Row>

        {this.props.matchByRecord ? this.getConfigTable() : null}
        {this.props.matchByRecord ? this.getConfigActions() : null}
      </Grid>
    );
  }
}

export interface IConfigViewProps {
  readonly data: IDataState;
  readonly columns: IColumns;
  readonly warning: boolean;
  readonly appUserManagementConfig: IAppUserManagementConfig;
  readonly dataLoadAction: DATA_LOAD_ACTION;
  readonly matchByRecord: string;

  onFieldNameChange(colIndex: string, key: USER_MANAGEMENT_OPTIONS): void;
}
