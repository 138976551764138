import * as React from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { IMADataUploadOptionsConnectProps } from './ma-data-upload-options-container';
import { MADataUploadOptionsView } from './ma-data-upload-options-view';
import { MA_TYPE } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Monitoring Activity specific file upload options
 */
export class MADataUploadOptions
  extends React.Component<IMADataUploadOptionsConnectProps> {
  constructor(props: IMADataUploadOptionsConnectProps) {
    super(props);
    this.onMATypeChange = this.onMATypeChange.bind(this);
  }

  public onMATypeChange(e: React.ChangeEvent<HTMLInputElement>, changes: DropdownProps): void {
    this.props.dispatch(Actions.Upload.setUploadInfo({
      maType: changes.value as MA_TYPE
    }));
  }

  public render(): JSX.Element {
    return (
      <MADataUploadOptionsView
        maType={this.props.upload.maType}
        onMATypeChange={this.onMATypeChange}
      />
    );
  }
}
