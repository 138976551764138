import * as React from 'react';
import { DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { CONFIG_VIEW_TEXT, MATCH_INDICATORS_BY_OPTIONS } from '../../../constants';
import { getOptions } from '../../../functions';

/**
 * View for "Match Indicator Key Options" component
 */
export class MatchIndicatorKeyOptionsView extends React.Component<IMatchIndicatorKeyOptionsViewProps> {
  constructor(props: IMatchIndicatorKeyOptionsViewProps) {
    super(props);
  }

  public render(): JSX.Element {
    const options: DropdownItemProps[] = getOptions(MATCH_INDICATORS_BY_OPTIONS);

    return (
      <Form.Dropdown
        label={CONFIG_VIEW_TEXT.MATCH_INDICATORS_BY}
        options={options}
        value={this.props.value}
        onChange={this.props.onChange}
        selectOnBlur={false}
        selection
        required
      />
    );
  }
}

export interface IMatchIndicatorKeyOptionsViewProps {
  readonly value: string;
  onChange(e: React.SyntheticEvent<HTMLDivElement>, changes: DropdownProps): void;
}
