import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { IPLDataSave } from './ipl-data-save';
import { IConfigState, IState, IUIState, IUploadState } from '../../../interfaces';

export interface IIPLDataSaveStateProps {
  readonly ui: IUIState;
  readonly upload: IUploadState;
  readonly config: IConfigState;
}

export type IIPLDataSaveConnectProps = IIPLDataSaveStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IIPLDataSaveStateProps {
  return {
    ui: state.ui,
    upload: state.upload,
    config: state.config
  };
}

export const IPLDataSaveContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(IPLDataSave);
