import * as React from 'react';
import { Container, Form, Icon, InputOnChangeData } from 'semantic-ui-react';
import { DATA_UPLOAD_VIEW_TEXT } from '../../../constants';
import './data-options.less';

/**
 * Options for uploading data file to server
 */
export class DataOptions extends React.Component<IDataOptionsProps> {
  constructor(props: IDataOptionsProps) {
    super(props);
  }

  /** Get layout for custom file input */
  public getStyleFileInput(caption: string): JSX.Element {
    return (
      <Container role="input-wrapper" fluid>
        <label htmlFor="data-upload-input">
          <Icon name="upload" inverted />
          {caption}
        </label>
        <input id="data-upload-input" type="file" accept=".xlsx" onChange={this.props.onDataFileChange} />
      </Container>
    );
  }

  /** Custom file input with special properties */
  public getDataFileInput(): JSX.Element {
    const caption: string = (this.props.dataFile)
      ? this.props.dataFile.name
      : DATA_UPLOAD_VIEW_TEXT.NO_FILE_SELECTED;

    return (
      <Form.Field key="dataFile" required>
        <label>
          {DATA_UPLOAD_VIEW_TEXT.DATA_FILE}
        </label>

        {this.getStyleFileInput(caption)}
      </Form.Field>
    );
  }

  /** Sheet index has default value and is required */
  public getSheetIndexInput(): JSX.Element {
    const error: boolean = this.props.sheetIndex === null || this.props.sheetIndex + 1 <= 0;
    const value: string = this.props.sheetIndex === null ? '' : (this.props.sheetIndex + 1).toString();

    return (
      <Form.Input
        type="text"
        label={DATA_UPLOAD_VIEW_TEXT.SHEET_INDEX}
        onChange={this.props.onSheetIndexChange}
        value={value}
        error={error}
        min={1}
        step={1}
        maxLength={6}
        required
      />
    );
  }

  public render(): JSX.Element {
    return (
      <Form data-component="data-options">
        {this.getDataFileInput()}
        {this.props.dataFile && this.props.showSheetIndex ? this.getSheetIndexInput() : null}
      </Form>
    );
  }
}

export interface IDataOptionsProps {
  readonly dataFile: File;
  readonly sheetIndex: number;
  readonly showSheetIndex?: boolean;
  onDataFileChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onSheetIndexChange(e: React.KeyboardEvent<HTMLInputElement>, changes: InputOnChangeData): void;
}
