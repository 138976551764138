import * as React from 'react';
import moment from 'moment';
import { IDataLoadHistoryConnectProps } from './data-load-history-container';
import { DataLoadHistoryView } from './data-load-history-view';
import { IDataLoadHistoryItem, IDataLoadState } from '../../interfaces';
import { DataLoadHistoryApi } from '../../api';
import {
  DATA_LOAD_HISTORY_COLUMN, DATA_LOAD_HISTORY_STATUS_OPTIONS, DATA_LOAD_HISTORY_TEXT, DATA_LOAD_HISTORY_TYPE_OPTIONS,
  DATA_LOAD_TYPE, SORT_DIRECTION, TX_RESOURCE_OPTIONS
} from '../../constants';
import { format } from '../../functions';

/**
 * Component for displaying list of active and completed data loads
 */
export class DataLoadHistory extends React.Component<IDataLoadHistoryConnectProps, IDataLoadHistoryState> {

  constructor(props: IDataLoadHistoryConnectProps) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      activeLoadingItemId: null,
      items: [],
      sortColumn: null,
      sortDirection: null
    };

    this.onSort = this.onSort.bind(this);
    this.onLogFileDownload = this.onLogFileDownload.bind(this);
    this.onDataFileDownload = this.onDataFileDownload.bind(this);
  }

  /** Load history on component mount */
  public componentWillMount(): void {
    this.setState({ loading: true, error: null });

    DataLoadHistoryApi.getDataLoadHistory().request()
      .then((data: IDataLoadState[]): void => {
        const items: IDataLoadHistoryItem[] = data.map(this.getHistoryItem.bind(this));
        this.setState({ items, loading: false });
      })
      .catch((response: Response): void => {
        this.setState({ error: response, loading: false });
      });
  }

  /** Convert API item to displayed item */
  public getHistoryItem(item: IDataLoadState, index: number): IDataLoadHistoryItem {
    return {
      id: item.id,
      userId: item.userId,
      rowIndex: index + 1,
      dataLoadType: this.getDataLoadType(item),
      dataFile: item.excelFileName,
      lastUpdateTime: item.lastUpdateTime,
      status: DATA_LOAD_HISTORY_STATUS_OPTIONS[item.status],
      originalStatus: item.status
    };
  }

  /** Get caption for "dataLoadType" property */
  public getDataLoadType(item: IDataLoadState): string {
    if (item.dataLoadType === DATA_LOAD_TYPE.TAXONOMY) {
      const type: string = (item.entity) ? TX_RESOURCE_OPTIONS[item.entity] : 'NO_TYPE';
      return format(DATA_LOAD_HISTORY_TEXT.TAXONOMY_TYPE, { type });
    }

    if (item.dataLoadType !== DATA_LOAD_TYPE.TAXONOMY) {
      return DATA_LOAD_HISTORY_TYPE_OPTIONS[item.dataLoadType];
    }

    return '';
  }

  /** Allow user to download data file using API */
  public onDataFileDownload(item: IDataLoadHistoryItem): void {
    this.setState({ activeLoadingItemId: item.id });

    DataLoadHistoryApi.getDataFile(item.id, item.dataFile).request()
      .then((): void => {
        this.setState({ activeLoadingItemId: null });
      })
      .catch((response: Response): void => {
        this.setState({ error: response });
      });
  }

  /** Allow user to download log file using API */
  public onLogFileDownload(item: IDataLoadHistoryItem): void {
    const date: string = moment(item.lastUpdateTime).format('D_MM_YYYY_H_mm_ss');
    const filename: string = `ZD_${date}.log`;

    this.setState({ activeLoadingItemId: item.id });
    DataLoadHistoryApi.getLogFile(item.id, filename).request()
      .then((): void => {
        this.setState({ activeLoadingItemId: null });
      })
      .catch((response: Response): void => {
        this.setState({ activeLoadingItemId: null, error: response });
      });
  }

  /** Apply sorting to data on column header click */
  public onSort(column: DATA_LOAD_HISTORY_COLUMN): void {
    if (column !== this.state.sortColumn) {
      const sort: IDataLoadHistoryItem[] = this.state.items
        .sort((a: IDataLoadHistoryItem, b: IDataLoadHistoryItem): number => {
          /* String comparison is case insensitive */
          const left: string | number = (typeof a[column] === 'string')
            ? (a[column] as string).toLowerCase()
            : a[column];
          const right: string | number = (typeof b[column] === 'string')
            ? (b[column] as string).toLowerCase()
            : b[column];

          return left > right ? 1 : left === right ? 0 : -1;
        });

      this.setState({
        items: sort,
        sortColumn: column,
        sortDirection: SORT_DIRECTION.ASC
      });

      return;
    }

    const direction: SORT_DIRECTION = (this.state.sortDirection === SORT_DIRECTION.ASC)
      ? SORT_DIRECTION.DESC
      : SORT_DIRECTION.ASC;

    this.setState({
      items: this.state.items.reverse(),
      sortColumn: column,
      sortDirection: direction
    });
  }

  public render(): JSX.Element {
    return (
      <DataLoadHistoryView
        error={this.state.error}
        loading={this.state.loading}
        activeLoadingItemId={this.state.activeLoadingItemId}
        items={this.state.items}
        sortColumn={this.state.sortColumn}
        sortDirection={this.state.sortDirection}
        onSort={this.onSort}
        onLogFileDownload={this.onLogFileDownload}
        onDataFileDownload={this.onDataFileDownload}
      />
    );
  }
}

export interface IDataLoadHistoryState {
  readonly error: Response;
  readonly loading: boolean;
  readonly activeLoadingItemId: string;
  readonly items: IDataLoadHistoryItem[];
  readonly sortColumn: DATA_LOAD_HISTORY_COLUMN;
  readonly sortDirection: SORT_DIRECTION;
}
