import * as React from 'react';
import { DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { DATA_UPLOAD_VIEW_TEXT, TX_RESOURCE_OPTIONS, TX_RESOURCE_TYPE } from '../../../constants';
import './tx-data-upload-options-view.less';

/**
 * View for "Taxonomy Data Upload Options" component
 */
export class TxDataUploadOptionsView extends React.Component<ITxOptionsViewProps> {
  constructor(props: ITxOptionsViewProps) {
    super(props);
  }

  /** Taxonomy resource options are based on enum */
  public getTxTypeInput(): JSX.Element {
    const options: DropdownItemProps[] = [];
    const keys: string[] = Object.keys(TX_RESOURCE_OPTIONS);

    for (let i: number = 0; i < keys.length; i++) {
      options.push({ value: keys[i], text: TX_RESOURCE_OPTIONS[keys[i]] });
    }

    return (
      <Form.Dropdown
        options={options}
        value={this.props.txType || ''}
        onChange={this.props.onTxTypeChange}
        label={DATA_UPLOAD_VIEW_TEXT.TAXONOMY_RESOURCE}
        selection
        required
      />
    );
  }

  public render(): JSX.Element {
    return (
      <Form data-component="tx-data-upload-options-view">
        {this.getTxTypeInput()}
      </Form>
    );
  }
}

export interface ITxOptionsViewProps {
  readonly txType: TX_RESOURCE_TYPE;
  onTxTypeChange(e: React.ChangeEvent<HTMLInputElement>, changes: DropdownProps): void;
}
