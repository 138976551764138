import * as React from 'react';
import { IHomeConnectProps } from './home-container';
import { BaseApi } from '../../api';
import { IAuthTicket } from '../../interfaces';
import * as Actions from '../../actions';
import { LoginContainer } from '../login';
import { Route } from 'react-router-dom';
import {
  getAnyStorageValue,
  getLocalStorageValue,
  setLocalStorageValue,
  setSessionStorageValue
} from '../../functions/storage';

/**
 * Login form with "user", "password" and "domain" inputs
 */
export class Home extends React.Component<IHomeConnectProps, IHomeState> {

  public accessDeniedTitle: string = 'Access denied';

  constructor(props: IHomeConnectProps) {
    super(props);
    this.state = {
      checkingRestrictions: true,
      accessDenied: true,
      error: null
    };
  }

  /** Attempt to log in with token saved in local storage */
  public componentWillMount(): void {
    const token: string = getLocalStorageValue('token');
    if (!token) {
      this.checkInternal();
      return;
    }

    BaseApi.setAuthToken({ token });
    this.checkToken();
  }

  /** Make fake login request to check token */
  public checkToken(): void {
    BaseApi.getAuthToken({ username: '', password: '', domain: '' })
        .then((ticket: IAuthTicket): void => {
          this.props.dispatch(Actions.Auth.setAuthToken(ticket));
          setLocalStorageValue('token', ticket.token);
          const logoutUrl: string = getLocalStorageValue('logoutUrl');
          if (logoutUrl) {
            setSessionStorageValue('logoutUrl', logoutUrl);
          }
        })
        .catch((): void => {
          BaseApi.setAuthToken({ token: '' });

          /* Reset auth token */
          setLocalStorageValue('token', '');
          if (getAnyStorageValue('logoutUrl')) {
            this.props.dispatch(Actions.UI.setUIData({ isLogoutRedirecting: true }));
          } else {
            this.checkInternal();
          }
        });
  }

  /** Make fake login request to check token */
  public checkInternal(): void {
    BaseApi.getInternalInfo()
        .then((response: { result: boolean }): void => {
          if (response.result === true) {
            this.setState({ accessDenied: false });
          } else {
            this.setState({ accessDenied: true });
          }
          this.setState({ checkingRestrictions: false });
        }).catch((): void => {
          this.setState({ accessDenied: true });
          this.setState({ checkingRestrictions: false });
      });
  }

  /** Show "Login" component if token is not set or has expired */
  public getLogInRoute(): JSX.Element {
    return <Route path="/" component={LoginContainer} />;
  }

  public render(): JSX.Element {
    return (this.state.checkingRestrictions) ? <div/>
        : (this.state.accessDenied) ? <h1>{this.accessDeniedTitle}</h1> : this.getLogInRoute();
  }
}

export interface IHomeState {
  readonly error: Response;
  readonly checkingRestrictions: boolean;
  readonly accessDenied: boolean;
}
