export enum TX_RESOURCE_TYPE {
  APPLICATIONS = 'applications',
  DATA = 'data-repositories',
  PHYSICAL_ASSETS = 'physical-assets',
  VENDORS = 'relationships',
  EMPLOYEES = 'people',
  POLICIES = 'policies',
  AUDITS = 'audits',
  INCIDENTS = 'incident',
  BUSINESS_PROCESS = 'core-process',
}

export const TX_RESOURCE_OPTIONS: { [key: string]: string } = {
  [TX_RESOURCE_TYPE.APPLICATIONS]: 'Applications',
  [TX_RESOURCE_TYPE.DATA]: 'Data Repositories',
  [TX_RESOURCE_TYPE.PHYSICAL_ASSETS]: 'Physical Assets',
  [TX_RESOURCE_TYPE.VENDORS]: 'Relationships',
  [TX_RESOURCE_TYPE.EMPLOYEES]: 'People',
  [TX_RESOURCE_TYPE.POLICIES]: 'Policies',
  [TX_RESOURCE_TYPE.AUDITS]: 'Audits',
  [TX_RESOURCE_TYPE.BUSINESS_PROCESS]: 'Business Processes',
};

export enum RESOURCE_FIELD_TYPE {
  NONE = 'NONE',
  TEXT = 'TEXT',
  DATE = 'DATE',
  COUNTRY = 'COUNTRY',
  STATE = 'STATE',
  SPACER = 'SPACER',
  USER_LOOKUP = 'USER_LOOKUP',
  PICK_LIST = 'PICK_LIST',
  TITLE = 'TITLE',
  NUMERIC = 'NUMERIC',
  RICH_TEXT = 'RICH_TEXT',
  /* Check Box has value "1" or "0"? */
  CHECK_BOX = 'CHECK_BOX',
  MULTI_SELECT_LIST = 'MULTI_SELECT_LIST',
  /* Checkbox has boolean value? */
  CHECKBOX = 'CHECKBOX',
  SUBTITLE = 'SUBTITLE',
  TABLE = 'TABLE',
  ATTACHMENT = 'ATTACHMENT',
  ATTACHMENT_TEMPLATES = 'ATTACHMENT_TEMPLATES'
}
