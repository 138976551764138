import * as Redux from 'redux';
import { IState } from '../interfaces';
import { ui } from './ui';
import { auth } from './auth';
import { data } from './data';
import { upload } from './upload';
import { config } from './config';
import { result } from './result';

/** Main reducer object */
export const Main: Redux.Reducer<IState> = Redux.combineReducers({
  ui,
  auth,
  data,
  upload,
  config,
  result
});
