import * as React from 'react';
import { ITxWorkflowConnectProps } from './tx-workflow-container';
import { Workflow } from '../../../components';
import { TxDataUploadContainer } from '../tx-data-upload';
import { TxConfigContainer } from '../tx-config';
import { TxDataSaveContainer } from '../tx-data-save';
import { DataLoadApi } from '../../../api';
import { DATA_LOAD_STAGE } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Taxonomy workflow manages transitions between data load flow components
 */
export class TxWorkflow extends React.Component<ITxWorkflowConnectProps> {
  public componentWillMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_UPLOAD_INITIAL));
  }

  /** Clear state and cancel data load when route changes */
  public componentWillUnmount(): void {
    DataLoadApi.cancelDataLoad().request()
      .then((): void => {
        this.props.dispatch(Actions.Batch.clearAppState());
      })
      .catch((): void => {
        this.props.dispatch(Actions.Batch.clearAppState());
      });
  }

  public render(): JSX.Element {
    return (
      <Workflow
        stage={this.props.ui.stage}
        upload={<TxDataUploadContainer />}
        config={<TxConfigContainer />}
        result={<TxDataSaveContainer />}
      />
    );
  }
}
