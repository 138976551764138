import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { PIConfig } from './pi-config';
import { IConfigState, IState, IUIState, IUploadState } from '../../../interfaces';

export interface IPIConfigStateProps {
  readonly ui: IUIState;
  readonly upload: IUploadState;
  readonly config: IConfigState;
}

export type IPIConfigConnectProps = IPIConfigStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IPIConfigStateProps {
  return {
    ui: state.ui,
    upload: state.upload,
    config: state.config
  };
}

export const PIConfigContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(PIConfig);
