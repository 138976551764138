import * as React from 'react';
import { SemanticICONS, Step, StepProps } from 'semantic-ui-react';
import { WORKFLOW_STEP, WORKFLOW_STEPS_TEXT } from '../../../constants';
import './workflow-steps.less';

/**
 * Workflow steps visually indicate current workflow position
 */
export class WorkflowSteps extends React.Component<IWorkflowStepsProps> {
  constructor(props: IWorkflowStepsProps) {
    super(props);
  }

  /** Define step option properties based on current step */
  public getOptions(): StepProps[] {
    const ICON_COMPLETE: SemanticICONS = 'check circle outline';

    const upload: StepProps = {
      key: 'upload',
      title: WORKFLOW_STEPS_TEXT.UPLOAD_TITLE,
      description: WORKFLOW_STEPS_TEXT.UPLOAD_DESCRIPTION,
      icon: (this.props.step === WORKFLOW_STEP.UPLOAD) ? 'file outline' : ICON_COMPLETE,
      active: (this.props.step === WORKFLOW_STEP.UPLOAD),
      completed: (this.props.step !== WORKFLOW_STEP.UPLOAD && this.props.step !== WORKFLOW_STEP.INIT)
    };

    const config: StepProps = {
      key: 'config',
      title: WORKFLOW_STEPS_TEXT.CONFIG_TITLE,
      description: WORKFLOW_STEPS_TEXT.CONFIG_DESCRIPTION,
      icon: (
        this.props.step === WORKFLOW_STEP.CONFIG ||
        this.props.step === WORKFLOW_STEP.UPLOAD ||
        this.props.step === WORKFLOW_STEP.INIT
      ) ? 'options' : ICON_COMPLETE,
      active: (this.props.step === WORKFLOW_STEP.CONFIG),
      completed: (this.props.step === WORKFLOW_STEP.RESULT || this.props.step === WORKFLOW_STEP.DONE),
      disabled: (this.props.step === WORKFLOW_STEP.UPLOAD || this.props.step === WORKFLOW_STEP.INIT)
    };

    const result: StepProps = {
      key: 'result',
      title: WORKFLOW_STEPS_TEXT.RESULT_TITLE,
      description: WORKFLOW_STEPS_TEXT.RESULT_DESCRIPTION,
      icon: (this.props.step !== WORKFLOW_STEP.DONE) ? 'database' : ICON_COMPLETE,
      active: (this.props.step === WORKFLOW_STEP.RESULT),
      completed: (this.props.step === WORKFLOW_STEP.DONE),
      disabled: (
        this.props.step === WORKFLOW_STEP.UPLOAD ||
        this.props.step === WORKFLOW_STEP.CONFIG ||
        this.props.step === WORKFLOW_STEP.INIT
      )
    };

    const options: StepProps[] = [upload, config, result];
    return options;
  }

  public render(): JSX.Element {
    return (
      <Step.Group
        data-component="workflow-steps"
        items={this.getOptions()}
        stackable="tablet"
        size="tiny"
        fluid
      />
    );
  }
}

export interface IWorkflowStepsProps {
  readonly step: WORKFLOW_STEP;
}
