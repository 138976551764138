import * as React from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';
import { IContentConnectProps } from './content-container';
import { ContentView } from './content-view';
import { ErrorHandlerContainer } from '../error-handler';
import { BaseApi } from '../../api';
import { APPLICATION_ROUTES, GENERAL_ERROR_CODE_TYPE, IAppRoute, ROUTE_KEY } from '../../constants';
import * as Actions from '../../actions';
import {
  clearLocalStorageValue,
  getAnyStorageValue
} from '../../functions/storage';

/**
 * Main content router component
 */
export class Content extends React.Component<IContentConnectProps, IContentState> {
  constructor(props: IContentConnectProps) {
    super(props);
    this.state = {
      error: false,
      links: this.getLinks(),
      routes: this.getAppRoutes()
    };

    this.onLogOut = this.onLogOut.bind(this);
  }

  /** Hide error on route changes */
  public componentWillReceiveProps(next: IContentConnectProps): void {
    if (this.props.location !== next.location) {
      this.setState({ error: false, links: this.getLinks() });
    }
  }

  /** Show "Unhandled Exception" on application error */
  public componentDidCatch(): void {
    this.props.dispatch(Actions.Batch.clearAppState());
    this.setState({ error: true });
  }

  /** Send log out request and navigate to home screen */
  public onLogOut(): void {
    const logOutCallback: () => void = (): void => {
      clearLocalStorageValue('token');
      this.props.dispatch(Actions.Batch.clearAppState());
      if (getAnyStorageValue('logoutUrl')) {
        this.props.dispatch(Actions.UI.setUIData({ isLogoutRedirecting: true }));
      } else {
        this.props.dispatch(Actions.Auth.logout());
        this.props.history.push('/');
      }
    };

    BaseApi.logOut().then(logOutCallback).catch(logOutCallback);
  }

  /** Generate routes based on APPLICATION_ROUTES constant */
  public getRoutes(root: IAppRoute[] = APPLICATION_ROUTES): JSX.Element[] {
    const routes: JSX.Element[] = [];
    /** Sort routes by sequence */
    root.sort((a: IAppRoute, b: IAppRoute): number => a.sequence - b.sequence);

    for (let i: number = 0; i < root.length; i++) {
      const route: JSX.Element = (
        <Route key={root[i].path} path={root[i].path} exact={root[i].exact} component={root[i].component} />
      );

      routes.push(route);

      if (root[i].routes) {
        routes.push(...this.getRoutes(root[i].routes));
      }
    }

    return routes;
  }

  /** Application routes also include index and 404 paths */
  public getAppRoutes(): JSX.Element {
    const routes: JSX.Element[] = this.getRoutes();

    /* Index page redirects to Data Load History */
    const indexRoute: (props: object) => JSX.Element = (): JSX.Element => {
      return <Redirect to={ROUTE_KEY.DATA_LOAD_HISTORY} />;
    };
    routes.push(<Route key="index" exact path="/" render={indexRoute} />);

    /* Default route shows 404 message */
    const defaultRoute: (props: object) => JSX.Element = (): JSX.Element => {
      return <ErrorHandlerContainer code={GENERAL_ERROR_CODE_TYPE.PAGE_NOT_FOUND} grid />;
    };
    routes.push(<Route key="default" render={defaultRoute} />);

    return <Switch>{routes}</Switch>;
  }

  /** Generate header navigation links based on APPLICATION_ROUTES constant */
  public getLinks(root: IAppRoute[] = APPLICATION_ROUTES, nested: boolean = false): JSX.Element[] {
    const links: JSX.Element[] = root.sort((a: IAppRoute, b: IAppRoute): number => a.sequence - b.sequence)
      .map((route: IAppRoute, index: number): JSX.Element => {
        if (route.routes) {
          return (
            <Dropdown key={index} item text={route.caption}>
              <Dropdown.Menu>
                {this.getLinks(route.routes, true)}
              </Dropdown.Menu>
            </Dropdown>
          );
        }

        /* Links without children are rendered as top-level links */
        const navLink: JSX.Element = (
          <NavLink key={route.path} to={route.path} exact>
            {route.caption}
          </NavLink>
        );

        const link: JSX.Element = (nested)
          ? <Dropdown.Item key={index} content={navLink} />
          : <Menu.Item key={index} content={navLink} />;

        return link;
      });

    return links;
  }

  public render(): JSX.Element {
    return (
      <ContentView
        error={this.state.error}
        location={this.props.location}
        links={this.state.links}
        routes={this.state.routes}
        auth={this.props.auth}
        upload={this.props.upload}
        onLogOut={this.onLogOut}
      />
    );
  }
}

export interface IContentState {
  readonly error: boolean;
  readonly links: JSX.Element[];
  readonly routes: JSX.Element;
}
