import * as React from 'react';
import { DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { CONFIG_VIEW_TEXT } from '../../../constants';
import { getOptions } from '../../../functions';

/**
 * View for "Data Load Action Options" component
 */
export class DataLoadActionOptionsView extends React.Component<IDataLoadActionOptionsViewProps> {
  constructor(props: IDataLoadActionOptionsViewProps) {
    super(props);
  }

  public render(): JSX.Element {
    const options: DropdownItemProps[] = getOptions(this.props.options);

    return (
      <Form.Dropdown
        label={CONFIG_VIEW_TEXT.WHAT_DO_YOU_WANT_TO_DO}
        options={options}
        value={this.props.value}
        onChange={this.props.onChange}
        selectOnBlur={false}
        selection
        required
      />
    );
  }
}

export interface IDataLoadActionOptionsViewProps {
  readonly value: string;
  readonly options: { [key: string]: string };
  onChange(e: React.SyntheticEvent<HTMLDivElement>, changes: DropdownProps): void;
}
