import * as React from 'react';
import { Dropdown, DropdownItemProps, DropdownProps, Table } from 'semantic-ui-react';
import { IAppTxMapping, IRiskFactor } from '../../interfaces';

export class RiskFactorOptions extends React.Component<IRiskFactorOptionsProps, IRiskFactorOptionsState> {

  constructor(props: IRiskFactorOptionsProps) {
    super(props);
    const mappedRiskFactor: IAppTxMapping = this.props.config[this.props.colIndex];
    this.state = { value: mappedRiskFactor ? mappedRiskFactor.riskFactorId : null, warning: true };
    this.onRiskFactorTabChange = this.onRiskFactorTabChange.bind(this);
    this.checkWarning();
  }

  /** Track only own state changes */
  public shouldComponentUpdate(props: IRiskFactorOptionsProps, state: IRiskFactorOptionsState): boolean {
    return this.state !== state;
  }

  /** Warn parent about incomplete mapping */
  public checkWarning(): void {
    this.props.onWarningChange(!this.state.value);
  }

  // TODO: reuse this function
  public getRiskFactorTabOptions(): DropdownItemProps[] {
    const options: DropdownItemProps[] = [];
    const keys: string[] = Object.keys(this.props.riskFactor);

    for (let i: number = 0; i < keys.length; i++) {
      const riskFactor: IRiskFactor = this.props.riskFactor[keys[i]];
      options.push({ text: riskFactor.name, value: riskFactor.id });
    }

    return options;
  }

  public onRiskFactorTabChange(e: React.ChangeEvent<HTMLInputElement>, changes: DropdownProps): void {
    this.setState({ value: changes.value as number }, (): void => {
      this.setState({ warning: false });
      this.props.onWarningChange(false);
      this.props.onRiskFactorTabChange(this.props.colIndex, changes.value as number);
    });
  }

  /** Get dropdown for mapping column into risk factor tabs */
  public getCustomTabDropdown(): JSX.Element {
    return (
      <Dropdown
        options={this.getRiskFactorTabOptions()}
        value={this.state.value}
        onChange={this.onRiskFactorTabChange}
        selectOnBlur={false}
        selection
        search
        fluid
      />
    );
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        <Table.Cell key="custom-tab-dropdown">
          {this.getCustomTabDropdown()}
        </Table.Cell>
      </React.Fragment>
    );
  }
}

export interface IRiskFactorOptionsState {
  readonly value: number;
  readonly warning: boolean;
}

export interface IRiskFactorOptionsProps {
  readonly colIndex: string;
  readonly config: { [key: number]: IAppTxMapping };
  readonly riskFactor: { [key: number]: IRiskFactor };
  onWarningChange(warning: boolean): void;
  onRiskFactorTabChange(colIndex: string, riskFactorId: number): void;
}
