import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { TxConfig } from './tx-config';
import { IConfigState, IDataState, IState, IUIState, IUploadState } from '../../../interfaces';

export interface ITxConfigStateProps {
  readonly ui: IUIState;
  readonly data: IDataState;
  readonly upload: IUploadState;
  readonly config: IConfigState;
}

export type ITxConfigConnectProps = ITxConfigStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): ITxConfigStateProps {
  return {
    ui: state.ui,
    data: state.data,
    upload: state.upload,
    config: state.config
  };
}

export const TxConfigContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(TxConfig);
