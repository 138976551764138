import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { IndicatorWorkflow } from './indicator-workflow';
import { IState, IUIState } from '../../../interfaces';

export interface IIndicatorWorkflowStateProps {
  readonly ui: IUIState;
}

export type IIndicatorWorkflowConnectProps = IIndicatorWorkflowStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IIndicatorWorkflowStateProps {
  return { ui: state.ui };
}

export const IndicatorWorkflowContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(IndicatorWorkflow);
