import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Root } from './root';
import { IAuthTicket, IState, IUIState } from '../../interfaces';

export interface IRootStateProps extends RouteComponentProps<{}> {
  readonly auth: IAuthTicket;
  readonly ui: IUIState;
}

export type IRootConnectProps = IRootStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: RouteComponentProps<{}>): IRootStateProps {
  return { auth: state.auth, ui: state.ui, ...props };
}

export const RootContainer: React.ComponentClass
  = withRouter(ReactRedux.connect(mapStateToProps)(Root));
