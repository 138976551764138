import * as React from 'react';
import { IILWorkflowConnectProps } from './il-data-upload-container';
import { BasicDataUploadContainer } from '../../data-upload/basic-data-upload';
import { IAppILConfig } from '../../../interfaces';
import { DataLoadApi, IILConfigOptions, IUploadBasicFileOptions } from '../../../api';
import { getAppILConfig } from '../../../functions';
import * as Actions from '../../../actions';

export class ILDataUpload extends React.Component<IILWorkflowConnectProps> {
  constructor(props: IILWorkflowConnectProps) {
    super(props);
    this.uploadFile = this.uploadFile.bind(this);
  }

  public uploadFile(options: IUploadBasicFileOptions): Promise<{}> {
    return DataLoadApi.uploadILFile(options).request()
      .then((response: IILConfigOptions): {} => {
        const config: IAppILConfig = getAppILConfig(response.config);

        this.props.dispatch(Actions.Config.setColumns(response.columns));
        this.props.dispatch(Actions.Config.setILConfig(config));
        return {};
      });
  }

  public render(): JSX.Element {
    return <BasicDataUploadContainer uploadFile={this.uploadFile} />;
  }
}
