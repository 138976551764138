import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { EmptyCellOptions } from './empty-cell-options';
import { IState, IUIState } from '../../../interfaces';
import { EMPTY_CELLS_ACTION } from '../../../constants';

export interface IEmptyCellOptionsProps {
  onChange?(action: EMPTY_CELLS_ACTION): void;
}

export interface IEmptyCellOptionsStateProps extends IEmptyCellOptionsProps {
  readonly ui: IUIState;
}

export type IEmptyCellOptionsConnectProps = IEmptyCellOptionsStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IEmptyCellOptionsProps): IEmptyCellOptionsStateProps {
  return { ui: state.ui, ...props };
}

export const EmptyCellOptionsContainer: React.ComponentClass<IEmptyCellOptionsProps>
  = ReactRedux.connect(mapStateToProps)(EmptyCellOptions);
