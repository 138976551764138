import * as React from 'react';
import { IILWorkflowConnectProps } from './il-workflow-container';
import { Workflow } from '../../../components/workflow';
import { ILDataUploadContainer } from '../il-data-upload';
import { ILConfigContainer } from '../il-config';
import { ILDataSaveContainer } from '../il-data-save';
import { DataLoadApi } from '../../../api';
import { DATA_LOAD_STAGE } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Indicator-Indicator Links workflow manages transitions between data load flow components
 */
export class ILWorkflow extends React.Component<IILWorkflowConnectProps> {
  public componentWillMount(): void {
    this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_UPLOAD_INITIAL));
  }

  public componentWillUnmount(): void {
    DataLoadApi.cancelDataLoad().request()
      .then((): void => {
        this.props.dispatch(Actions.Batch.clearAppState());
      })
      .catch((): void => {
        this.props.dispatch(Actions.Batch.clearAppState());
      });
  }

  public render(): JSX.Element {
    return (
      <Workflow
        stage={this.props.ui.stage}
        upload={<ILDataUploadContainer />}
        config={<ILConfigContainer />}
        result={<ILDataSaveContainer />}
      />
    );
  }
}
