import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { UserManagementKeyOptions } from './user-management-key-options';
import { IState, IUIState } from '../../../interfaces';
import { USER_MANAGEMENT_OPTIONS } from '../../../constants/user-management';

export interface IUserManagementKeyOptionsProps {
  onChange?(action: USER_MANAGEMENT_OPTIONS): void;
}

export interface IUserManagementKeyStateProps extends IUserManagementKeyOptionsProps {
  readonly ui: IUIState;
}

export type IUserManagementKeyOptionsConnectProps =
  IUserManagementKeyStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IUserManagementKeyOptionsProps): IUserManagementKeyStateProps {
  return { ui: state.ui, ...props };
}

export const UserManagementKeyOptionsContainer: React.ComponentClass<IUserManagementKeyOptionsProps>
  = ReactRedux.connect(mapStateToProps)(UserManagementKeyOptions);
