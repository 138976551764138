import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { TxDataUpload } from './tx-data-upload';
import { IState, IUploadState } from '../../../interfaces';

export interface ITxDataUploadStateProps {
  readonly upload: IUploadState;
}

export type ITxDataUploadConnectProps = ITxDataUploadStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): ITxDataUploadStateProps {
  return { upload: state.upload };
}

export const TxDataUploadContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(TxDataUpload);
