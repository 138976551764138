import * as React from 'react';
import { IDataUploadActionsConnectProps } from './data-upload-actions-container';
import { DataUploadActionsView } from './data-upload-actions-view';
import { DATA_LOAD_STAGE } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Action buttons for uploading data file to server
 * Also displays error messages
 */
export class DataUploadActions extends React.Component<IDataUploadActionsConnectProps, IDataUploadActionsState> {

  constructor(props: IDataUploadActionsConnectProps) {
    super(props);
    this.state = {
      error: null,
      loading: false
    };

    this.onDataFileUpload = this.onDataFileUpload.bind(this);
  }

  /** Upload data file to server and save config on success */
  public onDataFileUpload(): void {
    this.setState({ loading: true, error: null });

    this.props.uploadFile()
      .then((): void => {
        this.setState({ loading: false }, (): void => {
          this.props.dispatch(Actions.UI.setDataLoadStage(DATA_LOAD_STAGE.DATA_UPLOAD_COMPLETE));
        });
      })
      .catch((response: Response): void => {
        this.setState({ loading: false, error: response });
      });
  }

  public render(): JSX.Element {
    return (
      <DataUploadActionsView
        error={this.state.error}
        loading={this.state.loading}
        readyToLoad={this.props.getUploadButtonStatus()}
        onDataFileUpload={this.onDataFileUpload}
      />
    );
  }
}

export interface IDataUploadActionsState {
  readonly error: Response;
  readonly loading: boolean;
}
