import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { PIWorkflow } from './pi-workflow';
import { IState, IUIState } from '../../../interfaces';

export interface IPIWorkflowStateProps {
  readonly ui: IUIState;
}

export type IPIWorkflowConnectProps = IPIWorkflowStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IPIWorkflowStateProps {
  return { ui: state.ui };
}

export const PIWorkflowContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(PIWorkflow);
