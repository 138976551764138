import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { IncidentWorkflow } from './incident-workflow';
import { IState, IUIState } from '../../../interfaces';

export interface IIncidentWorkflowStateProps {
  readonly ui: IUIState;
}

export type IIncidentWorkflowConnectProps = IIncidentWorkflowStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState): IIncidentWorkflowStateProps {
  return { ui: state.ui };
}

export const IncidentWorkflowContainer: React.ComponentClass
  = ReactRedux.connect(mapStateToProps)(IncidentWorkflow);
