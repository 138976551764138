import * as Redux from 'redux';
import { IUploadState } from '../interfaces';

export const SET_UPLOAD_INFO: string = 'SET_UPLOAD_INFO';

export interface ISetUploadInfo extends Redux.Action {
  readonly options: Partial<IUploadState>;
}

/** Set any value for upload description object */
export function setUploadInfo(options: Partial<IUploadState>): ISetUploadInfo {
  return { type: SET_UPLOAD_INFO, options };
}
