import * as React from 'react';
import { IPIDataSaveConnectProps } from './pi-data-save-container';
import { DataSaveContainer } from '../../data-save';
import { DataLoadApi } from '../../../api';
import { IPIConfig } from '../../../interfaces';
import { DATA_LOAD_TYPE } from '../../../constants';
import { getApiPIConfig } from '../../../functions';

/**
 * Wrapper around "Data Save" component for Plan-Indicators
 */
export class PIDataSave extends React.Component<IPIDataSaveConnectProps> {
  constructor(props: IPIDataSaveConnectProps) {
    super(props);
    this.onRetry = this.onRetry.bind(this);
  }

  public onRetry(): Promise<{}> {
    const config: IPIConfig = getApiPIConfig({
      config: this.props.config.PI,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadPIConfig({ config, columns: this.props.config.columns }).request();
  }

  public render(): JSX.Element {
    if (!this.props.config.PI) { return null; }

    const nRows: number = this.props.config.PI.tableConfig.dataEndRow
      - this.props.config.PI.tableConfig.dataStartRow;

    return (
      <DataSaveContainer
        nRows={nRows}
        dataLoadType={DATA_LOAD_TYPE.PLAN_INDICATOR}
        onRetry={this.onRetry}
      />
    );
  }
}
