import * as React from 'react';
import { Button, Container } from 'semantic-ui-react';
import { ErrorHandlerContainer } from '../../error-handler';
import { DATA_UPLOAD_VIEW_TEXT } from '../../../constants';
import './data-upload-actions-view.less';

/**
 * View for "Data Upload Actions" component
 */
export class DataUploadActionsView extends React.Component<IDataUploadActionsViewProps> {
  constructor(props: IDataUploadActionsViewProps) {
    super(props);
  }

  /** Upload button is disabled when conditions are not met */
  public getUploadButton(): JSX.Element {
    return (
      <Button
        onClick={this.props.onDataFileUpload}
        loading={this.props.loading}
        disabled={!this.props.readyToLoad}
        content={DATA_UPLOAD_VIEW_TEXT.UPLOAD_FILE}
        icon="upload"
        positive
        fluid
      />
    );
  }

  /** Show default error message */
  public getError(): JSX.Element {
    return (this.props.error)
      ? <ErrorHandlerContainer response={this.props.error} />
      : null;
  }

  public render(): JSX.Element {
    return (
      <Container data-component="data-upload-actions-view" fluid>
        {this.getError()}
        {this.getUploadButton()}
      </Container>
    );
  }
}

export interface IDataUploadActionsViewProps {
  readonly error: Response;
  readonly loading: boolean;
  readonly readyToLoad: boolean;
  onDataFileUpload(): void;
}
