import * as React from 'react';
import { IPIDataUploadConnectProps } from './pi-data-upload-container';
import { BasicDataUploadContainer } from '../../data-upload/basic-data-upload';
import { IAppPIConfig } from '../../../interfaces';
import { DataLoadApi, IPIConfigOptions, IUploadBasicFileOptions } from '../../../api';
import { getAppPIConfig } from '../../../functions';
import * as Actions from '../../../actions';

export class PIDataUpload extends React.Component<IPIDataUploadConnectProps> {
  constructor(props: IPIDataUploadConnectProps) {
    super(props);
    this.uploadFile = this.uploadFile.bind(this);
  }

  /** Upload data file to server and save config on success */
  public uploadFile(): Promise<{}> {
    const options: IUploadBasicFileOptions = {
      dataFile: this.props.upload.dataFile,
      sheetIndex: this.props.upload.sheetIndex,
      startRow: this.props.upload.dataStartRow,
      endRow: this.props.upload.dataEndRow
    };

    return DataLoadApi.uploadPIFile(options).request()
      .then((response: IPIConfigOptions): {} => {
        const config: IAppPIConfig = getAppPIConfig(response.config);

        this.props.dispatch(Actions.Config.setColumns(response.columns));
        this.props.dispatch(Actions.Config.setPIConfig(config));
        return {};
      });
  }

  public render(): JSX.Element {
    return <BasicDataUploadContainer uploadFile={this.uploadFile} />;
  }
}
