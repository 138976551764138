import * as React from 'react';
import { IILConfigActionsConnectProps } from './il-config-actions-container';
import { ConfigActionsContainer } from '../../config/config-actions';
import { DataLoadApi } from '../../../api';
import { IILConfig } from '../../../interfaces';
import { getApiILConfig } from '../../../functions';

/**
 * Indicator-Indicator Links "Check Configuration" action buttons
 */
export class ILConfigActions extends React.Component<IILConfigActionsConnectProps> {
  constructor(props: IILConfigActionsConnectProps) {
    super(props);
    this.uploadConfig = this.uploadConfig.bind(this);
  }

  public uploadConfig(): Promise<{}> {
    const config: IILConfig = getApiILConfig({
      config: this.props.config.IL,
      ui: this.props.ui,
      upload: this.props.upload
    });

    return DataLoadApi.uploadILConfig({ config, columns: this.props.config.columns }).request();
  }

  public render(): JSX.Element {
    return (
      <ConfigActionsContainer
        warning={this.props.warning}
        uploadConfig={this.uploadConfig}
      />
    );
  }
}
