import * as React from 'react';
import * as Redux from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createLogger } from 'redux-logger';

import { RootContainer } from './root';
import { Main } from '../reducers';
import { IState } from '../interfaces';

/**
 * App component holds application state object
 */
export class App extends React.Component<{}, IAppState> {
  constructor(props: object) {
    super(props);
    this.state = {
      store: Redux.createStore(Main, Redux.applyMiddleware(createLogger({ collapsed: true })))
    };
  }

  public render(): JSX.Element {
    return (
      <Provider store={this.state.store}>
        <BrowserRouter>
          <RootContainer />
        </BrowserRouter>
      </Provider>
    );
  }
}

export interface IAppState {
  /** Instance of Redux global state */
  readonly store: Redux.Store<IState>;
}
