import * as React from 'react';
import { IAILDataUploadConnectProps } from './ail-data-upload-container';
import { BasicDataUploadContainer } from '../../data-upload/basic-data-upload';
import { IAppAILConfig } from '../../../interfaces';
import { DataLoadApi, IAILConfigOptions, IUploadBasicFileOptions } from '../../../api';
import { getAppAILConfig } from '../../../functions';
import * as Actions from '../../../actions';

export class AILDataUpload extends React.Component<IAILDataUploadConnectProps> {
  constructor(props: IAILDataUploadConnectProps) {
    super(props);
    this.uploadFile = this.uploadFile.bind(this);
  }

  public uploadFile(): Promise<{}> {
    const options: IUploadBasicFileOptions = {
      dataFile: this.props.upload.dataFile,
      sheetIndex: this.props.upload.sheetIndex,
      startRow: this.props.upload.dataStartRow,
      endRow: this.props.upload.dataEndRow
    };

    return DataLoadApi.uploadAILFile(options).request()
      .then((response: IAILConfigOptions): {} => {
        const config: IAppAILConfig = getAppAILConfig(response.config);

        this.props.dispatch(Actions.Config.setColumns(response.columns));
        this.props.dispatch(Actions.Config.setAILConfig(config));
        return {};
      });
  }

  public render(): JSX.Element {
    return <BasicDataUploadContainer uploadFile={this.uploadFile} />;
  }
}
