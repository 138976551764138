import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { IPLConfigActions } from './ipl-config-actions';
import { IConfigState, IState, IUIState, IUploadState } from '../../../interfaces';

export interface IIPLConfigActionsProps {
  readonly warning: boolean;
}

export interface IIPLConfigActionsStateProps extends IIPLConfigActionsProps {
  readonly ui: IUIState;
  readonly upload: IUploadState;
  readonly config: IConfigState;
}

export type IIPLConfigActionsConnectProps = IIPLConfigActionsStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IIPLConfigActionsProps): IIPLConfigActionsStateProps {
  return {
    ui: state.ui,
    upload: state.upload,
    config: state.config,
    ...props
  };
}

export const IPLConfigActionsContainer: React.ComponentClass<IIPLConfigActionsProps>
  = ReactRedux.connect(mapStateToProps)(IPLConfigActions);
