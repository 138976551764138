import * as React from 'react';
import moment from 'moment';
import { Button, Grid, InputOnChangeData, Message, Modal } from 'semantic-ui-react';
import { DataOptions, DataRangeOptions } from '../../../components';
import { ErrorHandlerContainer } from '../../error-handler';
import { COMMON_TEXT, DATA_UPLOAD_VIEW_TEXT, SUCCESS_MESSAGE_TEXT } from '../../../constants';
import { checkDataRange } from '../../../functions';
import './update-data-file-view.less';

/**
 * View for "Update Data File" component
 * Contains buttons and modal for editing information
 */
export class UpdateDataFileView extends React.Component<IUpdateDataFileViewProps, IUpdateDataFileViewState> {
  constructor(props: IUpdateDataFileViewProps) {
    super(props);
    this.state = { open: false };

    this.onClose = this.onClose.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
  }

  /** Close modal and call parent callback */
  public onClose(): void {
    this.setState({ open: false });
    this.props.onCancel();
  }

  /** Close modal and call parent callback */
  public onUpdate(): void {
    this.setState({ open: false });
    this.props.onUpdate();
  }

  /** Button for triggering modal window */
  public getActionButton(): JSX.Element {
    const date: string = moment(this.props.dataFile.lastModified).toLocaleString();
    const caption: string = `${this.props.dataFile.name} (${date})`;
    const onOpen: () => void = (): void => {
      this.setState({ open: true });
    };

    return (
      <Button
        content={caption}
        onClick={onOpen}
        icon="file text outline"
        loading={this.props.loading}
        disabled={this.props.disabled}
        fluid
        primary
      />
    );
  }

  /** Buttons for modal content actions */
  public getModalButtons(): JSX.Element {
    const dataRangeReady: boolean = checkDataRange({
      start: this.props.dataStartRow,
      end: this.props.dataEndRow
    });
    const allowUpdate: boolean = this.props.sheetIndex >= 0
      && dataRangeReady;

    return (
      <Grid className="modal-action-buttons">
        <Grid.Row columns="equal">
          <Grid.Column>
            <Button
              icon="delete"
              content={COMMON_TEXT.CANCEL}
              onClick={this.onClose}
              negative
              fluid
            />
          </Grid.Column>

          <Grid.Column>
            <Button
              icon="repeat"
              content={DATA_UPLOAD_VIEW_TEXT.UPDATE_FILE}
              onClick={this.onUpdate}
              disabled={!allowUpdate}
              positive
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  /** Content with form for updating upload variables */
  public getModalContent(): JSX.Element {
    return (
      <Grid data-component="update-data-file-view-modal">
        <Grid.Row columns="equal">
          <Grid.Column>
            <DataOptions
              dataFile={this.props.dataFile}
              sheetIndex={this.props.sheetIndex}
              onDataFileChange={this.props.onDataFileChange}
              onSheetIndexChange={this.props.onSheetIndexChange}
            />

            <DataRangeOptions
              dataStartRow={this.props.dataStartRow}
              dataEndRow={this.props.dataEndRow}
              onRowRangeChange={this.props.onRowRangeChange}
            />

            {this.getModalButtons()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  /** Modal window gets triggered by action button */
  public getModal(): JSX.Element {
    return (
      <Modal
        open={this.state.open}
        trigger={this.getActionButton()}
        content={this.getModalContent()}
        onClose={this.onClose}
        dimmer="inverted"
      />
    );
  }

  /** Show either success or error message for user */
  public getResult(): JSX.Element {
    let result: JSX.Element = null;

    if (this.props.error) {
      result = (
        <Grid.Row columns="equal" centered>
          <Grid.Column>
            <ErrorHandlerContainer
              response={this.props.error}
              onDismiss={this.props.onDismiss}
            />
          </Grid.Column>
        </Grid.Row>
      );
    }

    if (this.props.success) {
      result = (
        <Grid.Row columns="equal" centered>
          <Grid.Column>
            <Message
              icon="checkmark"
              header={SUCCESS_MESSAGE_TEXT.FILE_UPDATED_HEADER}
              content={SUCCESS_MESSAGE_TEXT.FILE_UPDATED_DESCRIPTION}
              onDismiss={this.props.onDismiss}
              size="tiny"
              positive
            />
          </Grid.Column>
        </Grid.Row>
      );
    }

    return result;
  }

  public render(): JSX.Element {
    return (
      <Grid data-component="update-data-file-view">
        <Grid.Row columns="equal" centered>
          <Grid.Column>
            {this.getModal()}
          </Grid.Column>
        </Grid.Row>

        {this.getResult()}
      </Grid>
    );
  }
}

export interface IUpdateDataFileViewState {
  readonly open: boolean;
}

export interface IUpdateDataFileViewProps {
  readonly error: Response;
  readonly success: boolean;
  readonly loading: boolean;
  readonly disabled: boolean;

  readonly dataFile: File;
  readonly sheetIndex: number;
  readonly dataStartRow: number;
  readonly dataEndRow: number;

  onUpdate(): void;
  onCancel(): void;
  onDismiss(): void;
  onDataFileChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onSheetIndexChange(e: React.KeyboardEvent<HTMLInputElement>, changes: InputOnChangeData): void;
  onRowRangeChange(e: React.KeyboardEvent<HTMLInputElement>, changes: InputOnChangeData): void;
}
