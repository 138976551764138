import * as Redux from 'redux';
import {
  IAppActivityConfig,
  IAppAILConfig,
  IAppIndicatorConfig,
  IAppIPLConfig,
  IAppILConfig,
  IAppPIConfig,
  IAppTxConfig,
  IAppUserManagementConfig,
  IColumns,
  IAppMAConfig
} from '../interfaces';

export const SET_COLUMNS: string = 'SET_COLUMNS';
export const SET_TX_CONFIG: string = 'SET_TX_CONFIG';
export const SET_PI_CONFIG: string = 'SET_PI_CONFIG';
export const SET_INDICATOR_CONFIG: string = 'SET_INDICATOR_CONFIG';
export const SET_IPL_CONFIG: string = 'SET_IPL_CONFIG';
export const SET_IL_CONFIG: string = 'SET_IL_CONFIG';
export const SET_USER_MANAGEMENT_CONFIG: string = 'SET_USER_MANAGEMENT_CONFIG';
export const SET_INCIDENT_CONFIG: string = 'SET_INCIDENT_CONFIG';
export const SET_ACTIVITY_CONFIG: string = 'SET_ACTIVITY_CONFIG';
export const SET_AIL_CONFIG: string = 'SET_AIL_CONFIG';
export const SET_MA_CONFIG: string = 'SET_MA_CONFIG';

export interface ISetColumns extends Redux.Action {
  readonly columns: IColumns;
}

export interface ISetConfig<T> extends Redux.Action {
  readonly config: T;
}

export type ISetTxConfig = ISetConfig<IAppTxConfig>;
export type ISetPIConfig = ISetConfig<IAppPIConfig>;
export type ISetIndicatorConfig = ISetConfig<IAppIndicatorConfig>;
export type ISetIPLConfig = ISetConfig<IAppIPLConfig>;
export type ISetILConfig = ISetConfig<IAppILConfig>;
export type ISetUserManagementConfig = ISetConfig<IAppUserManagementConfig>;
export type ISetIncidentConfig = ISetConfig<IAppTxConfig>;
export type ISetActivityConfig = ISetConfig<IAppActivityConfig>;
export type ISetAILConfig = ISetConfig<IAppAILConfig>;
export type ISetMAConfig = ISetConfig<IAppMAConfig>;

/** Set map of column names */
export function setColumns(columns: IColumns): ISetColumns {
  return { type: SET_COLUMNS, columns };
}

export function setTxConfig(config: IAppTxConfig): ISetTxConfig {
  return { type: SET_TX_CONFIG, config };
}

export function setPIConfig(config: IAppPIConfig): ISetPIConfig {
  return { type: SET_PI_CONFIG, config };
}

export function setIndicatorConfig(config: IAppIndicatorConfig): ISetIndicatorConfig {
  return { type: SET_INDICATOR_CONFIG, config };
}

export function setIPLConfig(config: IAppIPLConfig): ISetIPLConfig {
  return { type: SET_IPL_CONFIG, config };
}

export function setILConfig(config: IAppILConfig): ISetILConfig {
  return { type: SET_IL_CONFIG, config };
}

export function setUserManagementConfig(config: IAppUserManagementConfig): ISetUserManagementConfig {
  return { type: SET_USER_MANAGEMENT_CONFIG, config };
}

export function setIncidentConfig(config: IAppTxConfig): ISetIncidentConfig {
  return { type: SET_INCIDENT_CONFIG, config };
}

export function setActivityConfig(config: IAppActivityConfig): ISetActivityConfig {
  return { type: SET_ACTIVITY_CONFIG, config };
}

export function setAILConfig(config: IAppAILConfig): ISetAILConfig {
  return { type: SET_AIL_CONFIG, config };
}

export function setMAConfig(config: IAppMAConfig): ISetMAConfig {
  return { type: SET_MA_CONFIG, config };
}
