import * as React from 'react';
import { Grid, SemanticWIDTHS, Table } from 'semantic-ui-react';
import { DataSaveActionsContainer } from './data-save-actions';
import { DryRunProgressContainer } from './dry-run-progress';
import { DataSaveProgressContainer } from './data-save-progress';
import { UpdateDataFileContainer } from '../data-upload/update-data-file';
import { DataResult } from '../../components';
import { ErrorHandlerContainer } from '../error-handler';
import { IColumns, IResultState, IUIState, ILogEntity } from '../../interfaces';
import {
  DATA_LOAD_STAGE,
  DATA_LOAD_TYPE,
  DATA_SAVE_VIEW_TEXT,
  GENERAL_ERROR_CODE_TYPE
} from '../../constants';
import './data-save-view.less';

/**
 * View for "Data Save" component
 */
export class DataSaveView extends React.Component<IDataSaveViewProps> {
  public readonly MAX_COLUMNS: SemanticWIDTHS = 1;
  private readonly SPLIT_LOGS_IF_DATA_LOAD_TYPE: Array<DATA_LOAD_TYPE> = [
    DATA_LOAD_TYPE.INDICATOR_LIBRARY,
  ];

  constructor(props: IDataSaveViewProps) {
    super(props);
  }

  /** Get title for table with results */
  public getTableHeader(): JSX.Element {
    let title: string = '';

    /* When dry run is in progress */
    const showDryRunTitle: boolean = this.props.ui.stage === DATA_LOAD_STAGE.DRY_RUN_INITIAL
      || this.props.ui.stage === DATA_LOAD_STAGE.DRY_RUN_IN_PROGRESS
      || this.props.ui.stage === DATA_LOAD_STAGE.DRY_RUN_COMPLETE
      || this.props.ui.stage === DATA_LOAD_STAGE.DRY_RUN_FAILED;
    if (showDryRunTitle) {
      title = DATA_SAVE_VIEW_TEXT.DRY_RUN;
    }

    /* When data save is in progress */
    const showDataSaveTitle: boolean = this.props.ui.stage === DATA_LOAD_STAGE.DATA_SAVE_INITIAL
      || this.props.ui.stage === DATA_LOAD_STAGE.DATA_SAVE_IN_PROGRESS
      || this.props.ui.stage === DATA_LOAD_STAGE.DATA_SAVE_COMPLETE
      || this.props.ui.stage === DATA_LOAD_STAGE.DATA_SAVE_FAILED;
    if (showDataSaveTitle) {
      title = DATA_SAVE_VIEW_TEXT.DATA_SAVE;
    }

    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={this.MAX_COLUMNS}>
            {title}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );
  }

  public getDataResults(logList: ILogEntity[], type: 'DRY_RUN' | 'DATA_SAVE'): JSX.Element[] {
    const dataResults: Map<string, ILogEntity[]> = new Map();
    for (const logs of logList) {
      const key: string = DATA_LOAD_TYPE[logs.entity] ? logs.entity : this.props.dataLoadType;

      if (dataResults.has(key)) {
        dataResults.get(key).push(logs);
      } else {
        dataResults.set(key, [logs]);
      }
    }

    const newContent: JSX.Element[] = [];
    for (const [key, value] of dataResults.entries()) {
      newContent.push((
        <DataResult
          type={type}
          logList={{...this.props.result.dryRun, logs: value}}
          dataLoadType={key || this.props.dataLoadType}
          columns={this.props.columns}
        />
      ));
    }

    return newContent;
  }

  /** Get main content, like progress bar or result table */
  public getTableContent(): JSX.Element {
    let content: JSX.Element | JSX.Element[] = null;

    const showDryRunLoader: boolean = this.props.ui.stage === DATA_LOAD_STAGE.DRY_RUN_INITIAL
      || this.props.ui.stage === DATA_LOAD_STAGE.DRY_RUN_IN_PROGRESS;
    const showDataSaveLoader: boolean = this.props.ui.stage === DATA_LOAD_STAGE.DATA_SAVE_INITIAL
      || this.props.ui.stage === DATA_LOAD_STAGE.DATA_SAVE_IN_PROGRESS;

    const showDryRunTable: boolean = this.props.ui.stage === DATA_LOAD_STAGE.DRY_RUN_COMPLETE;
    const showDataSaveTable: boolean = this.props.ui.stage === DATA_LOAD_STAGE.DATA_SAVE_COMPLETE;

    const showError: boolean = this.props.ui.stage === DATA_LOAD_STAGE.DRY_RUN_FAILED
      || this.props.ui.stage === DATA_LOAD_STAGE.DATA_SAVE_FAILED;

    if (showDryRunLoader) {
      content = <DryRunProgressContainer nRows={this.props.nRows} onError={this.props.onError} />;
    }

    if (showDryRunTable) {
      content = (
        <DataResult
          type="DRY_RUN"
          logList={this.props.result.dryRun}
          dataLoadType={this.props.dataLoadType}
          columns={this.props.columns}
        />
      );

      if (this.SPLIT_LOGS_IF_DATA_LOAD_TYPE.find((record: DATA_LOAD_TYPE) => record === this.props.dataLoadType)) {
        content = this.getDataResults(this.props.result.dryRun.logs, 'DRY_RUN');
      }
    }

    if (showDataSaveLoader) {
      content = <DataSaveProgressContainer onError={this.props.onError} />;
    }

    if (showDataSaveTable) {
      content = (
        <DataResult
          type="DATA_SAVE"
          logList={this.props.result.dataSave}
          dataLoadType={this.props.dataLoadType}
          columns={this.props.columns}
        />
      );

      if (this.SPLIT_LOGS_IF_DATA_LOAD_TYPE.find((record: DATA_LOAD_TYPE) => record === this.props.dataLoadType)) {
        content = this.getDataResults(this.props.result.dryRun.logs, 'DATA_SAVE');
      }
    }

    if (showError) {
      content = (
        <ErrorHandlerContainer
          code={GENERAL_ERROR_CODE_TYPE.DATABASE_EXCEPTION}
          dataloadResult={this.props.result.dataSave}
        />
      );
    }

    return (
      <Table.Body>
        <Table.Row>
          <Table.Cell colSpan={this.MAX_COLUMNS}>
            {content}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    );
  }

  /** Get table footer with action buttons */
  public getTableFooter(): JSX.Element {
    return (
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={this.MAX_COLUMNS}>
            <DataSaveActionsContainer
              error={this.props.error}
              onRetry={this.props.onRetry}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    );
  }

  /** Results are displayed as table with header, body and footer */
  public getTable(): JSX.Element {
    return (
      <Table celled compact>
        {this.getTableHeader()}
        {this.getTableContent()}
        {this.getTableFooter()}
      </Table>
    );
  }

  public render(): JSX.Element {
    const CONTENT_WIDTH: SemanticWIDTHS = 12;

    return (
      <Grid data-component="data-save-view">
        <Grid.Row columns="equal" centered>
          <Grid.Column width={CONTENT_WIDTH}>
            <UpdateDataFileContainer />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns="equal" centered>
          <Grid.Column width={CONTENT_WIDTH}>
            {this.getTable()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export interface IDataSaveViewProps {
  readonly error: Response;
  readonly nRows: number;
  readonly dataLoadType: DATA_LOAD_TYPE;
  readonly ui: IUIState;
  readonly result: IResultState;
  readonly columns: IColumns;
  onRetry(): void;
  onError(response: Response): void;
}
