import * as React from 'react';
import { IMADataUploadActionsConnectProps } from './ma-data-upload-actions-container';
import { DataUploadActionsContainer } from '../../data-upload/data-upload-actions';
import { IAppMAConfig } from '../../../interfaces';
import { DataLoadApi, IMAConfigOptions, IUploadMAFileOptions } from '../../../api';
import { checkDataRange, getAppMAConfig } from '../../../functions';
import * as Actions from '../../../actions';

/**
 * MAs data upload actions component checks input data and performs upload
 */
export class MADataUploadActions extends React.Component<IMADataUploadActionsConnectProps> {
  constructor(props: IMADataUploadActionsConnectProps) {
    super(props);
    this.uploadFile = this.uploadFile.bind(this);
    this.getUploadButtonStatus = this.getUploadButtonStatus.bind(this);
  }

  /** Upload data file to server and save config on success */
  public uploadFile(): Promise<{}> {
    const options: IUploadMAFileOptions = {
      dataFile: this.props.upload.dataFile,
      sheetIndex: this.props.upload.sheetIndex,
      maType: this.props.upload.maType,
      startRow: this.props.upload.dataStartRow,
      endRow: this.props.upload.dataEndRow
    };

    return DataLoadApi.uploadMAFile(options).request()
      .then((response: IMAConfigOptions): {} => {
        const config: IAppMAConfig = getAppMAConfig(response.config);

        this.props.dispatch(Actions.Config.setColumns(response.columns));
        this.props.dispatch(Actions.Config.setMAConfig(config));
        return {};
      });
  }

  /** Upload button is disabled when data is not valid */
  public getUploadButtonStatus(): boolean {
    const dataReady: boolean = !!this.props.upload.dataFile;
    const sheetIndexReady: boolean = this.props.upload.sheetIndex !== null && this.props.upload.sheetIndex >= 0;

    const maTypeReady: boolean = !!this.props.upload.maType;
    const dataRangeReady: boolean = checkDataRange({
      start: this.props.upload.dataStartRow,
      end: this.props.upload.dataEndRow
    });

    return dataReady && sheetIndexReady && maTypeReady && dataRangeReady;
  }

  public render(): JSX.Element {
    return (
      <DataUploadActionsContainer
        uploadFile={this.uploadFile}
        getUploadButtonStatus={this.getUploadButtonStatus}
      />
    );
  }
}
