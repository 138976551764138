import * as React from 'react';
import { IIPLWorkflowConnectProps } from './ipl-data-upload-container';
import { BasicDataUploadContainer } from '../../data-upload/basic-data-upload';
import { IAppIPLConfig } from '../../../interfaces';
import { DataLoadApi, IIPLConfigOptions, IUploadBasicFileOptions } from '../../../api';
import { getAppIPLConfig } from '../../../functions';
import * as Actions from '../../../actions';

export class IPLDataUpload extends React.Component<IIPLWorkflowConnectProps> {
  constructor(props: IIPLWorkflowConnectProps) {
    super(props);
    this.uploadFile = this.uploadFile.bind(this);
  }

  public uploadFile(options: IUploadBasicFileOptions): Promise<{}> {
    return DataLoadApi.uploadIPLFile(options).request()
      .then((response: IIPLConfigOptions): {} => {
        const config: IAppIPLConfig = getAppIPLConfig(response.config);

        this.props.dispatch(Actions.Config.setColumns(response.columns));
        this.props.dispatch(Actions.Config.setIPLConfig(config));
        return {};
      });
  }

  public render(): JSX.Element {
    return <BasicDataUploadContainer uploadFile={this.uploadFile} />;
  }
}
