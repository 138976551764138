import * as React from 'react';
import { InputOnChangeData } from 'semantic-ui-react';
import { IDataOptionsConnectProps } from './data-options-container';
import { DataOptions as DataOptionsView } from '../../../components';
import * as Actions from '../../../actions';

/**
 * Options required for uploading data file
 */
export class DataOptions extends React.Component<IDataOptionsConnectProps> {
  constructor(props: IDataOptionsConnectProps) {
    super(props);

    this.onDataFileChange = this.onDataFileChange.bind(this);
    this.onSheetIndexChange = this.onSheetIndexChange.bind(this);
  }

  /** Get file from native input event */
  public onDataFileChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const list: FileList = e.target.files;
    if (list && list.length > 0) {
      this.props.dispatch(Actions.Upload.setUploadInfo({ dataFile: list[0] }));
    }
  }

  /** Displayed value of sheet index is 1 more than actual */
  public onSheetIndexChange(e: React.KeyboardEvent<HTMLInputElement>, changes: InputOnChangeData): void {
    /* Ignore non-number key inputs */
    const sheetIndex: number = parseInt(changes.value, 10) - 1;
    if (changes.value !== '' && isNaN(sheetIndex)) {
      return;
    }

    this.props.dispatch(Actions.Upload.setUploadInfo({
      sheetIndex: changes.value === '' ? null : sheetIndex
    }));
  }

  public render(): JSX.Element {
    return (
      <DataOptionsView
        dataFile={this.props.upload.dataFile}
        sheetIndex={this.props.upload.sheetIndex}
        showSheetIndex
        onDataFileChange={this.onDataFileChange}
        onSheetIndexChange={this.onSheetIndexChange}
      />
    );
  }
}
