import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { MatchByIndexOptions } from './match-by-index-options';
import { IColumns, IState, IUIState } from '../../../interfaces';

export interface IMatchByIndexOptionsProps {
  onChange?(action: string): void;
}

export interface IMatchByIndexStateProps extends IMatchByIndexOptionsProps {
  readonly ui: IUIState;
  readonly columns: IColumns;
}

export type IMatchByIndexOptionsConnectProps = IMatchByIndexStateProps & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IMatchByIndexOptionsProps): IMatchByIndexStateProps {
  return { ui: state.ui, columns: state.config.columns, ...props };
}

export const MatchByIndexOptionsContainer: React.ComponentClass<IMatchByIndexOptionsProps>
  = ReactRedux.connect(mapStateToProps)(MatchByIndexOptions);
