import * as React from 'react';
import { DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { IMatchActivityKeyOptionsConnectProps } from './match-activity-key-options-container';
import { CONFIG_VIEW_TEXT, MATCH_ACTIVITY_KEY, MATCH_ACTIVITY_KEY_OPTIONS } from '../../../constants';
import { getOptions } from '../../../functions';
import * as Actions from '../../../actions';

/**
 * Options for "Match Control By"
 */
export class MatchActivityKeyOptions extends React.Component<IMatchActivityKeyOptionsProps> {
  public matchActivityKeyOptions: DropdownItemProps[];

  constructor(props: IMatchActivityKeyOptionsProps) {
    super(props);
    this.matchActivityKeyOptions = getOptions(MATCH_ACTIVITY_KEY_OPTIONS);
    this.onMatchActivityKeyChange = this.onMatchActivityKeyChange.bind(this);
  }

  public onMatchActivityKeyChange(e: React.ChangeEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.props.dispatch(Actions.UI.setUIData({ matchActivityKey: changes.value as MATCH_ACTIVITY_KEY }));
    if (this.props.onChange) {
      this.props.onChange(changes.value as MATCH_ACTIVITY_KEY);
    }
  }

  public render(): JSX.Element {
    return (
      <Form.Dropdown
        label={CONFIG_VIEW_TEXT.MATCH_CONTROL_BY}
        options={this.matchActivityKeyOptions}
        value={this.props.ui.matchActivityKey}
        onChange={this.onMatchActivityKeyChange}
        selectOnBlur={false}
        selection
        required
      />
    );
  }
}

export interface IMatchActivityKeyOptionsProps extends IMatchActivityKeyOptionsConnectProps {
  onChange?(mathActivityKey: MATCH_ACTIVITY_KEY): void;
}
