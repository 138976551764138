import * as React from 'react';
import { DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { IColumns } from '../../../interfaces';
import { CONFIG_VIEW_TEXT } from '../../../constants';
import { format } from '../../../functions';

export class MatchByIndexOptionsView extends React.Component<IMatchByIndexOptionsViewProps> {
  constructor(props: IMatchByIndexOptionsViewProps) {
    super(props);
  }

  public render(): JSX.Element {
    const options: DropdownItemProps[] = [];
    const keys: string[] = Object.keys(this.props.columns) || [];

    for (const key of keys) {
      /* Column name can be empty */
      const text: string = this.props.columns[key] || format(CONFIG_VIEW_TEXT.COLUMN_INDEX, { index: key });
      options.push({ value: key, text });
    }

    return (
      <Form.Dropdown
        label={CONFIG_VIEW_TEXT.MATCH_RECORDS_BY}
        options={options}
        value={this.props.value}
        onChange={this.props.onChange}
        selectOnBlur={false}
        selection
        required
      />
    );
  }
}

export interface IMatchByIndexOptionsViewProps {
  readonly value: string;
  readonly columns: IColumns;
  onChange(e: React.SyntheticEvent<HTMLDivElement>, changes: DropdownProps): void;
}
