import { API_URL_CONFIG, IUrlObject } from './api-interfaces';
import { UrlRequest } from './url-request';
import { IDataLoadState } from '../interfaces';

/**
 * Wrapper for "Taxonomy Data Load History" API
 */
export class DataLoadHistoryApi {

  /** Get history of data loads for current user */
  public static getDataLoadHistory(): UrlRequest<IDataLoadState[]> {
    const urlObject: IUrlObject = { ...API_URL_CONFIG.history.dataLoadList };
    return new UrlRequest({
      urlObject, callback: (response: object): IDataLoadState[] => {
        return response as IDataLoadState[];
      }
    });
  }

  /** Show window for saving data file from server */
  public static getDataFile(id: string, filename: string): UrlRequest<{}> {
    const params: object = { id };
    const urlObject: IUrlObject = { ...API_URL_CONFIG.history.loadDataFile, params };
    return DataLoadHistoryApi.getDownloadLink(urlObject, filename);

  }

  /** Show window for saving log file from server */
  public static getLogFile(id: string, filename: string): UrlRequest<{}> {
    const params: object = { id };
    const urlObject: IUrlObject = { ...API_URL_CONFIG.history.loadLogFile, params };
    return DataLoadHistoryApi.getDownloadLink(urlObject, filename);

  }

  /** Create download link for given URL file request */
  protected static getDownloadLink(urlObject: IUrlObject, filename: string): UrlRequest<{}> {
    return new UrlRequest({
      urlObject, callback: (response: Blob): object => {
        /* Convert response to fake link */
        const objectUrl: string = window.URL.createObjectURL(response);
        const link: HTMLAnchorElement = document.createElement('a');
        link.setAttribute('href', objectUrl);
        link.setAttribute('download', filename);
        link.style.display = 'none';

        /* Show "Save File" window */
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(objectUrl);
        return {};
      }
    });
  }
}
