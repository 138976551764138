import * as React from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { IProcessTypeOptionsConnectProps } from './process-type-options-container';
import { ProcessTypeOptionsView } from './process-type-options-view';
import { PROCESS_TYPE } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Options for process type (business process or other dimension)
 */
export class ProcessTypeOptions extends React.Component<IProcessTypeOptionsConnectProps> {
  constructor(props: IProcessTypeOptionsConnectProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  /** Update UI reducer and call parent callback */
  public onChange(e: React.SyntheticEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.props.dispatch(Actions.UI.setUIData({ processType: changes.value as PROCESS_TYPE }));
    if (this.props.onChange) {
      this.props.onChange(changes.value as PROCESS_TYPE);
    }
  }

  public render(): JSX.Element {
    return (
      <ProcessTypeOptionsView
        value={this.props.ui.processType}
        label={this.props.label}
        onChange={this.onChange}
      />
    );
  }
}
