import * as React from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { IMatchPlanKeyOptionsConnectProps } from './match-plan-key-options-container';
import { MatchPlanKeyOptionsView } from './match-plan-key-options-view';
import { MATCH_PLAN_KEY } from '../../../constants';
import * as Actions from '../../../actions';

/**
 * Options for "Match Plan Key"
 */
export class MatchPlanKeyOptions extends React.Component<IMatchPlanKeyOptionsConnectProps> {
  constructor(props: IMatchPlanKeyOptionsConnectProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  /** Update UI reducer and call parent callback */
  public onChange(e: React.SyntheticEvent<HTMLDivElement>, changes: DropdownProps): void {
    this.props.dispatch(Actions.UI.setUIData({ matchPlanKey: changes.value as MATCH_PLAN_KEY }));
    if (this.props.onChange) {
      this.props.onChange(changes.value as MATCH_PLAN_KEY);
    }
  }

  public render(): JSX.Element {
    return (
      <MatchPlanKeyOptionsView
        value={this.props.ui.matchPlanKey}
        onChange={this.onChange}
      />
    );
  }
}
