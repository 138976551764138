import * as React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { DryRunProgress } from './dry-run-progress';
import { IResultState, IState } from '../../../interfaces';

export interface IDryRunProgressProps {
  readonly nRows: number;
  onError(response: Response): void;
}

export interface IDryRunProgressStateProps extends IDryRunProgressProps {
  readonly result: IResultState;
}

export type IDryRunProgressConnectProps = IDryRunProgressStateProps
  & ReactRedux.DispatchProp<Redux.Action>;

export function mapStateToProps(state: IState, props: IDryRunProgressProps): IDryRunProgressStateProps {
  return { result: state.result, ...props };
}

export const DryRunProgressContainer: React.ComponentClass<IDryRunProgressProps>
  = ReactRedux.connect(mapStateToProps)(DryRunProgress);
